<div class="Block-content">
  <!-- LINE 綁定 -->
  <div class="title-02">LINE 綁定</div>
  <div class="basic-data-table">
    <div class="w-100">
      <div class="basic-left">LINE 綁定</div>
      <div class="basic-right" *ngIf="!PersonalizationSettingsForSubmitParams.CLineBinding">
        <a href="{{ lineLoginUrl }}">點此綁定</a>
      </div>
      <div class="basic-right" *ngIf="PersonalizationSettingsForSubmitParams.CLineBinding">
        LIND ID: {{ PersonalizationSettingsForSubmitParams.CLineId }}
        <button class="button" (click)="RemoveLine()">解除綁定</button>
      </div>
    </div>
  </div>
  <!-- 個人化通知 -->
  <div class="title-02">個人化通知</div>
  <div class="basic-data-table">
    <!-- 交易通知 -->
    <div class="w-100">
      <div class="basic-left">交易通知</div>
      <div class="basic-right" style="display: flex; padding-top: 22px">
        <div class="clear">
          <input type="checkbox" name="trade" id="trade01" disabled
            [(ngModel)]="PersonalizationSettingsForSubmitParams.CTradeEmail" /><label for="trade01"
            class="form">E-mail</label>
        </div>
        <div class="clear">
          <input type="checkbox" name="trade" id="trade02"
            [(ngModel)]="PersonalizationSettingsForSubmitParams.CTradeLine" /><label for="trade02" class="form">LINE
            推播</label>
        </div>
      </div>
    </div>
    <!-- 鎖利通知 -->
    <div class="w-100">
      <div class="basic-left">鎖利通知</div>
      <div class="basic-right" style="display: flex; padding-top: 22px">
        <div class="clear">
          <input type="checkbox" name="lockup" id="lockup01"
            [(ngModel)]="PersonalizationSettingsForSubmitParams.CWarningEmail" /><label for="lockup01"
            class="form">E-mail</label>
        </div>
        <div class="clear">
          <input type="checkbox" name="lockup" id="lockup02"
            [(ngModel)]="PersonalizationSettingsForSubmitParams.CWarningLine" /><label for="lockup02" class="form">LINE
            推播</label>
        </div>
      </div>
    </div>
    <!-- 個人通知 -->
    <div class="w-100">
      <div class="basic-left">個人通知</div>
      <div class="basic-right" style="display: flex; padding-top: 22px">
        <div class="clear">
          <input type="checkbox" name="personal" id="personal01"
            [(ngModel)]="PersonalizationSettingsForSubmitParams.CPersonalEmail" /><label for="personal01"
            class="form">E-mail</label>
        </div>
        <div class="clear">
          <input type="checkbox" name="personal" id="personal02"
            [(ngModel)]="PersonalizationSettingsForSubmitParams.CPersonalLine" /><label for="personal02"
            class="form">LINE 推播</label>
        </div>
      </div>
    </div>
    <!-- 最新消息 -->
    <div class="w-100">
      <div class="basic-left">最新消息</div>
      <div class="basic-right" style="display: flex; padding-top: 22px">
        <div class="clear">
          <input type="checkbox" name="news" id="news01"
            [(ngModel)]="PersonalizationSettingsForSubmitParams.CNewEmail" /><label for="news01"
            class="form">E-mail</label>
        </div>
        <div class="clear">
          <input type="checkbox" name="news" id="news02"
            [(ngModel)]="PersonalizationSettingsForSubmitParams.CNewLine" /><label for="news02" class="form">LINE
            推播</label>
        </div>
      </div>
    </div>
  </div>
  <!-- 儲存送出 -->

  <div class="button-box">
    <a class="Main-button" href="javascript:void(0)" (click)="submitPersonalizationSettings()">儲存送出</a>
  </div>
</div>
