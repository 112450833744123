<!-- 定期定額申購 -->
<div *ngIf="RegularSavingPlanModel.Step == 1">
  <div class="Block-content">
    <!-- <div class="transaction-hr">交易時間 2020/04/14 09:43:33</div> -->
    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">1</div>
        <div class="p-contact-form__step-label">定期定額申購</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">2</div>
        <div class="p-contact-form__step-label">定期定額確認</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">3</div>
        <div class="p-contact-form__step-label">定期定額完成</div>
      </div>
    </div>

    <div class="basic-data-table">
      <div class="w-100">
        <div class="basic-left">基金名稱</div>
        <div class="basic-right">
          <div class="fund-in">
            <div class="SelectStyle2 w-100">
              <select [(ngModel)]="selectedFundValue" (change)="selectAFund($event.target)"
                [disabled]="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')">
                <option value="">請選擇</option>
                <option *ngFor="let fund of GetFundListResponse.FundList" [value]="fund.FUND_ID">
                  {{fund.FUND_NAME}}---({{fund.FUND_RISK_NM}})
                </option>
              </select>
            </div>
          </div>
          <div
            *ngIf="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')">
            <br /><span class="text-red"><b>*您的kyc已過期，無法進行交易</b></span><br />
            <a href="kyc"><img alt="riskbtn" src=".././../../assets/images/riskbtn.png" /></a>
          </div>
          <div *ngIf="selectedFundValue != '' && canBuy == false">
            <br /><b>您留存之風險屬性為{{this.jwt.KYC_RISK_ATTR_NM}}，未達此商品之風險等級</b><br />
            <a href="kyc"><img alt="riskbtn" src=".././../../assets/images/riskbtn.png" /></a>
          </div>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">幣別</div>
        <div class="basic-right">
          <span *ngIf="selectedFundValue != ''">{{
            selectedFund.FUND_CURRENCY_NM
            }}</span>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">配息方式</div>
        <div
          [ngClass]="{'basic-right-c4': selectedFund?.DIVIDEND_TYPE != '4','basic-right': selectedFund?.DIVIDEND_TYPE == '4'}">
          <span *ngIf="selectedFundValue != ''" style="font-size: 18px;">
            {{ selectedFund.DIVIDEND_TYPE_NM }}
          </span>
        </div>
        <div class="basic-left" *ngIf="selectedFundValue != '' && selectedFund?.DIVIDEND_TYPE == '2'">
          配息收益約定帳戶：
        </div>
        <div class="basic-right" *ngIf="selectedFundValue != '' && selectedFund?.DIVIDEND_TYPE == '2'">
          <div class="SelectStyle2 w-100">
            <select (change)="sendtype(RegularSavingPlanModel.ACCOUNT_DIV_NO)"
              [(ngModel)]="RegularSavingPlanModel.ACCOUNT_DIV_NO"
              [disabled]="!canBuy || !(selectedFund.DIVIDEND_TYPE != '4' && HasDivAcc)">
              <option value="">請選擇</option>
              <option *ngFor="let account of GetDivAccResponse.DivAccList" value="{{ account.ACCOUNT_NO }}">
                {{ account.BANK_BRH_SHNM }} {{ account.ACCOUNT_NO_SHOW }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">每期申購金額</div>
        <div class="basic-right">
          <div *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE == '4'" class="unit-box">
            <input [(ngModel)]="RegularSavingPlanModel.ApplyAmount" type="text" class="input-25" step="1" min="0"
              [disabled]="!canBuy" (blur)="getBestFeeRate($event.target)" />
            <p>元</p>
          </div>
          <p style="margin-top: 10px;"
            *ngIf="ShowAlert != '' && selectedFundValue != '' && selectedFund.DIVIDEND_TYPE == '4'" class="text-red">
            *{{ShowAlert}}
          </p>
          <div *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE != '4'" class="unit-box">
            <input [(ngModel)]="RegularSavingPlanModel.ApplyAmount" type="text" class="input-25" step="1" min="0"
              [disabled]="!canBuy || !(selectedFund.DIVIDEND_TYPE != '4' && HasDivAcc)"
              (blur)="getBestFeeRate($event.target)" />
            <p>元</p>
          </div>
          <p style="margin-top: 10px;"
            *ngIf="ShowAlert != '' && selectedFundValue != '' && selectedFund.DIVIDEND_TYPE != '4'" class="text-red">
            *{{ShowAlert}}
          </p>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">扣款日期</div>
        <div class="basic-right">
          <div *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE == '4'" class="kyc-box"
            style="padding-top: 12px">
            <div *ngFor="
                let day of GetRspDayListResponse.RspAllotDaysList;
                let i = index
              " class="clear">
              <input [(ngModel)]="RegularSavingPlanModel.RspDay" id="{{ i }}" value="{{ day.DAYS }}" type="radio"
                name="Investment-experience" class="form" [disabled]="!canBuy" />
              <label for="{{ i }}" class="form">{{ day.DAYS }}</label>
            </div>
          </div>
          <div *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE != '4'" class="kyc-box"
            style="padding-top: 12px">
            <div *ngFor="
                          let day of GetRspDayListResponse.RspAllotDaysList;
                          let i = index
                        " class="clear">
              <input [(ngModel)]="RegularSavingPlanModel.RspDay" id="{{ i }}" value="{{ day.DAYS }}" type="radio"
                name="Investment-experience" class="form"
                [disabled]="!canBuy || !(selectedFund.DIVIDEND_TYPE != '4' && HasDivAcc)" />
              <label for="{{ i }}" class="form">{{ day.DAYS }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">手續費</div>
        <div class="basic-right">
          <div *ngIf="selectedFundValue != ''" class="unit-box">
            <!--  -->
            <span class="r-10" *ngIf="!isBestFee">
              {{ RegularSavingPlanModel.Fee | number }}</span>
            <span class="r-10" *ngIf="isBestFee" style="text-decoration: line-through">
              {{ RegularSavingPlanModel.Fee | number}}</span>
            <span class="r-10">元</span>
            <span *ngIf="isBestFee">
              享
              <span style="color: #ff0000">{{ feeNAME }}</span>
              手續費：
              <span style="color: #ff0000;">
                {{ RegularSavingPlanModel.BestFee | number }}</span>
              元
            </span>
          </div>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">扣款帳號</div>
        <div class="basic-right">
          <div *ngIf="selectedFundValue != '' && selectedFund.FUND_CURRENCY_NM == '新臺幣'" class="SelectStyle2 w-100">
            <select [(ngModel)]="RegularSavingPlanModel.ACCOUNT_NO" [disabled]="!canBuy || !HasDivAcc">
              <option value="">請選擇</option>
              <option *ngFor="let account of TWDList" value="{{ account.ACCOUNT_NO }}">
                {{ account.BANK_BRH_SHNM }} {{ account.ACCOUNT_NO_SHOW }}
              </option>
            </select>
          </div>
          <div *ngIf="selectedFundValue != '' && selectedFund.FUND_CURRENCY_NM != '新臺幣'" class="SelectStyle2 w-100">
            <select [(ngModel)]="RegularSavingPlanModel.ACCOUNT_NO" [disabled]="!canBuy || !HasDivAcc">
              <option value="">請選擇</option>
              <option *ngFor="let account of FORList" value="{{ account.ACCOUNT_NO }}">
                {{ account.BANK_BRH_SHNM }} {{ account.ACCOUNT_NO_SHOW }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="button-box basic-data-table-bottom-line">
      <a *ngIf="isSelected" href="#" class="cancel-button">取消</a>
      <a *ngIf="isSelected && canBuy" href="javascript:void(0)" class="Main-button" (click)="goToStep2()">下一步<i
          class="fas fa-chevron-right"></i></a>
      <!-- <button class="Main-button" (click)="goToStep2()" [disabled]="!canBuy">
        下一步<i class="fas fa-chevron-right"></i>
      </button> -->
    </div>

    <div class="basic-note-div">
      <h1>注意事項：</h1>
      如需取消委託交易，請於交易時間截止前，於【取消交易】中點選該筆交易，超過交易時間該筆委託將無法進行取消。<br>
      凡進行各項交易申請，申請成功後，系統皆會寄發確認信至受益人約定之電子郵件信箱。 委託成功不代表交易成功，請於交易截止時間後，至電子交易平台點選【歷史交易明細】功能，查詢交易是否成功。
      交易委託時間以本系統實際完成收件為準，如委託交易營業日與完成委託營業日不同時，系統無法接受該筆委託，請重新下單。各營業日截止時間前完成之委託視為當日申請之交易；非營業日或逾各基金交易截止時間之委託視為次一營業日之申請。<br>
      如因扣款機構之電腦轉帳系統故障或其他不可抗力事由，未能於指定之日期進行轉帳扣款作業時，本公司得順延至扣款機構之電腦轉帳系統恢復正常之營業日進行轉帳扣款作業並以該日為基金申購日。交易所授權之扣款帳戶將在同一天內自同一帳戶執行數筆扣款交易。若授權之帳戶餘額不足支付自動轉帳申購之價金時，指定銀行有權自行決定各筆扣款之先後順序或不予進行扣款轉帳作業。
      若因重大事件導致基金暫停計價，致使各項交易日順延，實際各項交易日期以「交易確認單」或「投資對帳單」為準。<br>
      本公司網路交易申請係透過財金股份有限公司全國性繳費（稅）機制進行扣款作業，依該機構規定單筆最高轉帳扣繳限額為等值新臺幣伍百萬元，每日最高轉帳扣繳限額為等值新臺幣參仟萬元，但前述金額經調整者，依調整後金額定之。各扣款銀行或有不同限額限制，申購人申購前應自行與該扣款銀行確認相關業務之額度限制，並依該額度限制來申購基金，以避免額度限制造成扣款失敗。<br>
      當本公司所發行之受益權單位數達金管會所核准之最高發行基金規模或有其他事由時，本公司有權拒絕受益人之申購申請，並無息返還申購價金。<br>
      台中銀投信所發行之基金因其受益憑證以無實體方式發行，未開放證券商保管劃撥帳戶功能，故無法提供投資人要求可透過證券商保管劃撥帳戶辦理申購贖回及登載於證券商之保管戶劃撥帳戶之服務且受益人不得申請領回實體受益憑證。<br>
      受益人同意經理公司得依個人資料保護法及相關法令規定，對其個人資料為搜集、處理、利用或國際傳遞。受益人並同意經理公司基於客戶服務、資訊提供、或為受益人之利益等目的或依法令規定，得將其個人開戶及交易等資料，提供予受經理公司委託辦理相關業務之機構。<br>
      辦理基金銷售業務時，對於明知已屬明顯弱勢族群之投資人，包括年齡為70歲以上，教育程度為國中畢業以下或有全民健康保險重大傷病證明等，不主動介紹屬高風險之基金產品。<br>
      投資人進行高收益債券基金交易前，應審慎評估本身之財務狀況與風險承受能力是否適合此種交易，並充分瞭解下列事項：投資人投資高收益債券基金不宜占其投資組合過高之比重。由於高收益債券之信用評等未達投資等級或未經信用評等，且對利率變動的敏感度甚高，故基金可能會因利率上升、市場流動性下降，或債券發行機構違約不支付本金、利息或破產而蒙受虧損。基金不適合無法承擔相關風險之投資人。<br>
      配息或不配息、手續費為前收或後收等，以投資合適之級別。不同級別之費用率與報酬率或有差異，現提供該檔基金各級別近五年度之費用率與報酬率資訊，請詳閱本公司提供該檔基金各級別近五年度之費用率與報酬率資訊。<br>
      本公司基金經金管會核准或同意生效，或經金管會申報備查，惟不表示基金絕無風險。本公司以往之經理績效，不保證本基金之最低投資效益；本公司除盡善良管理人之注意義務外，不負責本基金之盈虧，亦不保證最低之收益，投資人申購前應詳閱本基金公開/投資說明書。公開說明書備索，或至本公司網址，或至公開資訊觀測站查詢下載。<br>
      投資人若欲瞭解本公司銷售機構報酬揭露相關情形，可至本公司網站之「銷售機構報酬揭露專區」
    </div>
  </div>
</div>

<!-- 定期定額確認 -->
<div *ngIf="RegularSavingPlanModel.Step == 2">
  <div class="Block-content">
    <!-- <div class="transaction-hr">交易時間 2020/04/14 09:43:33</div> -->

    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">1</div>
        <div class="p-contact-form__step-label">定期定額申購</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">2</div>
        <div class="p-contact-form__step-label">定期定額確認</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">3</div>
        <div class="p-contact-form__step-label">定期定額完成</div>
      </div>
    </div>

    <div class="basic-data-table">
      <div class="w-100">
        <div class="basic-left">基金名稱</div>
        <div class="basic-right">
          {{ RegularSavingPlanModel.FUND_NAME }}
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">幣別</div>
        <div class="basic-right">
          {{ RegularSavingPlanModel.FUND_CURRENCY_NM }}
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">配息方式</div>
        <div class="basic-right">
          {{ RegularSavingPlanModel.DIVIDEND_TYPE_NM }}
        </div>
      </div>
      <div *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE == '2'" class="w-100">
        <div class="basic-left">配息收益約定帳戶</div>
        <div class="basic-right">
          {{ RegularSavingPlanModel.BANK_BRH_DIV_SHNM }}
          {{ RegularSavingPlanModel.ACCOUNT_DIV_NO }}
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">每期申購金額</div>
        <div class="basic-right">{{ RegularSavingPlanModel.ApplyAmount | number }}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">扣款日期</div>
        <div class="basic-right">{{ RegularSavingPlanModel.RspDay }}</div>
      </div>
      <div class="w-100" *ngIf="!isBestFee">
        <div class="basic-left">手續費</div>
        <div class="basic-right">{{ RegularSavingPlanModel.Fee | number}}</div>
      </div>
      <div class="w-100" *ngIf="isBestFee">
        <div class="basic-left">手續費</div>
        <div class="basic-right">{{ RegularSavingPlanModel.BestFee | number}}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">扣款帳號</div>
        <div class="basic-right">
          {{ RegularSavingPlanModel.BANK_BRH_SHNM }}
          {{ RegularSavingPlanModel.ACCOUNT_NO }}
        </div>
      </div>
    </div>

    <div class="basic-data-table transaction-password">
      <div class="w-100">
        <div class="basic-left">交易密碼</div>
        <div class="basic-right" *ngIf="selectedFundValue != ''">
          <input [(ngModel)]="RegularSavingPlanModel.Password" [type]="passwordType" id="password" class="input-25"
            style="float: left" />
          <div [class]="conceal" (click)="onClickConceal()"></div>
        </div>
      </div>
    </div>

    <div class="i-have-read">
      <a href="{{ selectedFund.OfficialManualPath }}" target="_blank"><i class="fas fa-file-pdf"></i> 公開說明書</a>
      <a href="{{ selectedFund.RiskForecastPath }}" target="_blank"><i class="fas fa-file-pdf"></i> 風險預告書</a>
      <div class="checkbox-wrap">
        <input [(ngModel)]="RegularSavingPlanModel.ReadInfo" type="checkbox" value="guangpan" name="investment"
          id="f1" />
        <label for="f1"></label><samp>我已閱讀公開說明書 風險預告書</samp>
      </div>
    </div>

    <div class="button-box">
      <a href="RegularSavingPlan" class="cancel-button">取消</a>
      <a href="javascript:void(0)" class="Main-button" (click)="goToStep3()">確認<i class="fas fa-chevron-right"></i></a>
    </div>
  </div>
</div>

<!-- 定期定額確認 -->
<div *ngIf="RegularSavingPlanModel.Step == 3">
  <div class="Block-content">
    <!-- <div class="transaction-hr">交易時間 2020/04/14 09:43:33</div> -->

    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">1</div>
        <div class="p-contact-form__step-label">定期定額申購</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">2</div>
        <div class="p-contact-form__step-label">定期定額確認</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">3</div>
        <div class="p-contact-form__step-label">定期定額完成</div>
      </div>
    </div>

    <div class="Details">
      <div class="w-60-1">
        <p class="txt">
          <i class="fas fa-check-circle"></i> 定期定額
          <span class="txt-red">{{ RegularSavingPlanModel.FUND_NAME }}</span>
          設定完成！
        </p>
      </div>
      <div class="w-60-1">
        <table border="0" cellpadding="1" cellspacing="0" class="ptable">
          <tbody>
            <tr>
              <th>委託確認時間</th>
            </tr>
            <tr>
              <td>
                {{ RegularSavingPlanModel.ApplyTime | date: "yyyy/MM/dd" }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="button-box">
      <a href="/RegularSavingPlan" class="Main-button">回定期定額申購<i class="fas fa-chevron-right"></i></a>
    </div>
  </div>
</div>
