import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'AppFrontend';
  browser: string;

  ngOnInit(): void {
    this.detectBrowser();
    console.log('更新時間 2022/04/28 12:57')
  }

  detectBrowser() {
    var isIE = navigator.userAgent.search("MSIE") > -1;
    var isIE7 = navigator.userAgent.search("Trident/7.0") > -1;
    var isFirefox = navigator.userAgent.search("Firefox") > -1;
    var isOpera = navigator.userAgent.search("Opera") > -1;
    var isSafari = navigator.userAgent.search("Safari") > -1;//Google瀏覽器是用這核心
    // debugger
    if (isIE7) {
      this.browser = 'IE7';
    }
    if (isIE) {
      this.browser = 'IE';
    }
    if (isFirefox) {
      this.browser = 'Firefox';
    }
    if (isOpera) {
      this.browser = 'Opera';
    }
    if (isSafari) {
      this.browser = 'Safari/Chrome';
    }
    // debugger
    if (this.browser == 'IE' || this.browser == 'IE7') {
      alert('本系統不支援IE瀏覽器，請使用其他瀏覽器(Chrome,Edge,Firefox,Safari)')
    }
    return this.browser;
  }
}
