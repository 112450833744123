import { TitleComponent } from './_layout/title/title.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { TokenInterceptor } from './auth/token.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './_layout/header/header.component';
import { FooterComponent } from './_layout/footer/footer.component';
import { RegularQuotaComponent } from './pages/regular-quota/regular-quota.component';
import { SingletransactionComponent } from './pages/singletransaction/singletransaction.component';
import { ManageMemberVerificationComponent } from './pages/manage-member-verification/manage-member-verification.component';
import { NowComponent } from './_layout/now/now.component';
import { TransfersubscriptionComponent } from './pages/transfersubscription/transfersubscription.component';
import { LoginLayoutComponent } from './_layout/login-layout/login-layout.component';
import { LogoutLayoutComponent } from './_layout/logout-layout/logout-layout.component';
import { LoginNavbarComponent } from './_layout/login-navbar/login-navbar.component';
import { LogoutNavbarComponent } from './_layout/logout-navbar/logout-navbar.component';
import { NewsComponent } from './pages/news/news.component';
import { SharedModule } from './pages/shared/shared.module';
import { CancellationtradeComponent } from './pages/cancellationtrade/cancellationtrade.component';
import { FundredemptionComponent } from './pages/fundredemption/fundredemption.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { EditMemberProfileComponent } from './pages/edit-member-profile/edit-member-profile.component';
import { RegularSavingPlanComponent } from './pages/regular-saving-plan/regular-saving-plan.component';
import { KycComponent } from './pages/kyc/kyc.component';
import { KycHistoryComponent } from './pages/kyc-history/kyc-history.component';
import { ETradeSearchComponent } from './pages/e-trade-search/e-trade-search.component';
import { EstablishAccountSearchComponent } from './pages/establish-account-search/establish-account-search.component';
import { PersonalInvestmentComponent } from './pages/personal-investment/personal-investment.component';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PersonalizationSettingsComponent } from './pages/personalization-settings/personalization-settings.component';
import { OpenAccountAllComponent } from './pages/open-account-all/open-account-all.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { PersonalizationLockSettingsComponent } from './pages/personalization-lock-settings/personalization-lock-settings.component';
import {
  NbThemeModule,
  NbLayoutModule,
  NbDialogModule,
  NbDatepickerModule,
} from '@nebular/theme';
// import { NbEvaIconsModule } from '@nebular/eva-icons';
import { GetPersonalMsgComponent } from './pages/get-personal-msg/get-personal-msg.component';
import { StatementQueryComponent } from './pages/statement-query/statement-query.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    RegularQuotaComponent,
    TitleComponent,
    SingletransactionComponent,
    ManageMemberVerificationComponent,
    NowComponent,
    TransfersubscriptionComponent,
    LoginLayoutComponent,
    LogoutLayoutComponent,
    LoginNavbarComponent,
    LogoutNavbarComponent,
    NewsComponent,
    CancellationtradeComponent,
    FundredemptionComponent,
    DisclaimerComponent,
    EditMemberProfileComponent,
    RegularSavingPlanComponent,
    KycComponent,
    KycHistoryComponent,
    ETradeSearchComponent,
    EstablishAccountSearchComponent,
    PersonalInvestmentComponent,
    PersonalizationSettingsComponent,
    OpenAccountAllComponent,
    ChangePasswordComponent,
    PersonalizationLockSettingsComponent,
    GetPersonalMsgComponent,
    StatementQueryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    ChartsModule,
    NgxSpinnerModule,
    // NoopAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbDialogModule.forRoot(),
    NbLayoutModule,
    NbDatepickerModule.forRoot(),
    // NbEvaIconsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
