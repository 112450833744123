import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ResponseBase } from '../model/ResponseBase';
import {
  ToastForCountParams,
  ToastForCountResponse,
  ToastParams,
  ToastResponse,
} from '../model/toast';
import { ServiceBase } from './service-base';

@Injectable({
  providedIn: 'root',
})
export class ToastService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/PersonMsg`;

  constructor(http: HttpClient) {
    super(http);
  }

  RetrieveToastCount(
    request: ToastForCountParams
  ): Observable<ResponseBase<ToastForCountResponse>> {
    const url = `${this.baseUrl}/GetPersonMsgCount`;

    return this.http.post<ResponseBase<ToastForCountResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  RetrieveToastContent(
    request: ToastParams
  ): Observable<ResponseBase<ToastResponse[]>> {
    const url = `${this.baseUrl}/GetPersonMsg`;

    return this.http.post<ResponseBase<ToastResponse[]>>(
      url,
      request,
      this.httpOptions
    );
  }
}
