import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import {
  RegularSavingPlanModel,
  RegularSavingPlanModelVerifyResponse,
  SingletransactionModel,
  SingletransactionModelVerifyResponse,
} from '../model/open-data';
@Injectable({
  providedIn: 'root',
})
export class VerifyService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/Verify`;
  constructor(http: HttpClient) {
    super(http);
  }

  GetSingletransactionVerify(
    request: SingletransactionModel
  ): Observable<ResponseBase<SingletransactionModelVerifyResponse>> {
    const url = `${this.baseUrl}/GetSingletransactionVerify`;
    return this.http.post<ResponseBase<SingletransactionModelVerifyResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetSingletransactionVerify2(
    request: SingletransactionModel
  ): Observable<ResponseBase<SingletransactionModelVerifyResponse>> {
    const url = `${this.baseUrl}/GetSingletransactionVerify2`;
    return this.http.post<ResponseBase<SingletransactionModelVerifyResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetRegularSavingPlanVerify(
    request: RegularSavingPlanModel
  ): Observable<ResponseBase<RegularSavingPlanModelVerifyResponse>> {
    const url = `${this.baseUrl}/GetRegularSavingPlanVerify`;
    return this.http.post<ResponseBase<RegularSavingPlanModelVerifyResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetRegularSavingPlanVerify2(
    request: RegularSavingPlanModel
  ): Observable<ResponseBase<RegularSavingPlanModelVerifyResponse>> {
    const url = `${this.baseUrl}/GetRegularSavingPlanVerify2`;
    return this.http.post<ResponseBase<RegularSavingPlanModelVerifyResponse>>(
      url,
      request,
      this.httpOptions
    );
  }
}
