<div class="Block-content">
  <div class="basic-data-table">
    <div class="w-50">
      <div class="basic-left">姓名</div>
      <div class="basic-right">
        <input
          class="w-80"
          [(ngModel)]="loadMemberProfileResponse.BF_NAME"
          name="BF_NAME"
          disabled
        />
      </div>
      <div class="basic-left">申請日期</div>
      <div class="basic-right">
        {{ loadMemberProfileResponse.APPLY_DTTM }}
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">生日(民國)</div>
      <div class="basic-right">
        <div class="Enter-box w-80">
          <input class="input-20" [(ngModel)]="bdYear" name="bdYear" disabled />
          <p>年</p>
          <input
            class="input-20"
            [(ngModel)]="bdMonth"
            name="bdMonth"
            disabled
          />
          <p>月</p>
          <input class="input-20" [(ngModel)]="bdDay" name="bdDay" disabled />
          <p>日</p>
        </div>
      </div>
      <div class="basic-left">身分證字號</div>
      <div class="basic-right">
        {{ loadMemberProfileResponse.ID_NO }}
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">住家電話<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <div class="Enter-box w-80">
          <!-- 區號 -->
          <input
            class="input-20"
            [(ngModel)]="loadMemberProfileResponse.HM_TEL_AREA"
            name="HM_TEL_AREA" [disabled]="!canChange"
          />
          <!-- 電話號碼 -->
          <input
            class="input-70"
            [(ngModel)]="loadMemberProfileResponse.HM_TEL"
            name="HM_TEL" [disabled]="!canChange"
          />
        </div>
      </div>
      <div class="basic-left">公司電話<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <div class="Enter-box">
          <div class="w-80 Enter-box">
            <!-- 區號 -->
            <input
              class="input-20"
              [(ngModel)]="loadMemberProfileResponse.OF_TEL_AREA"
              name="OF_TEL_AREA" [disabled]="!canChange"
            />
            <!-- 電話號碼 -->
            <input
              class="input-60"
              [(ngModel)]="loadMemberProfileResponse.OF_TEL"
              name="OF_TEL" [disabled]="!canChange"
            />
          </div>
          <p>#</p>
          <!-- 分機號碼 -->
          <input
            class="input-15"
            [(ngModel)]="loadMemberProfileResponse.OF_TEL_EXT"
            name="OF_TEL_EXT" [disabled]="!canChange"
          />
        </div>
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">傳真電話<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <div class="Enter-box w-80">
          <!-- 區號 -->
          <input
            class="input-20"
            [(ngModel)]="loadMemberProfileResponse.FAX_TEL_AREA1"
            name="FAX_TEL_AREA1" [disabled]="!canChange"
          />
          <!-- 電話號碼 -->
          <input
            class="input-70"
            [(ngModel)]="loadMemberProfileResponse.FAX_TEL1"
            name="FAX_TEL1" [disabled]="!canChange"
          />
        </div>
      </div>
      <div class="basic-left">手機電話<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <input
          class="w-80"
          [(ngModel)]="loadMemberProfileResponse.CELL_PHONE"
          name="CELL_PHONE" [disabled]="!canChange"
        />
      </div>
    </div>

    <div class="w-100">
      <div class="basic-left">通訊地址</div>
      <div class="basic-right">
        <div class="post-box">
          <p>郵遞區號</p>
          <input
            type="text"
            class="input-15"
            [(ngModel)]="loadMemberProfileResponse.MailZip"
            disabled
          />
        </div>
        <div class="address-box">
          <input
            class="input-40"
            [(ngModel)]="loadMemberProfileResponse.MAIL_ADDR"
            name="MAIL_ADDR"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">戶籍地址</div>
      <div class="basic-right">
        <div class="post-box">
          <!-- <div class="checkbox-wrap">
            <input type="checkbox" id="theSame" disabled />
            <label for="theSame"><samp>同通訊地</samp></label>
          </div> -->
          <p>郵遞區號</p>
          <input
            type="text"
            class="input-15"
            [(ngModel)]="loadMemberProfileResponse.MailZip"
            disabled
          />
        </div>
        <div class="address-box">
          <input
            class="input-40"
            [(ngModel)]="loadMemberProfileResponse.PERM_ADDR"
            name="PERM_ADDR"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">電子信箱</div>
      <div class="basic-right">
        <div class="address-box">
          <input
            [(ngModel)]="loadMemberProfileResponse.EMAIL1"
            name="EMAIL1"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">連絡人<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <input
          class="input-30"
          [(ngModel)]="loadMemberProfileResponse.CNT_PERSON"
          name="CNT_PERSON" [disabled]="!canChange"
        />
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">法定代理人姓名</div>
      <div class="basic-right">
        <input
          class="w-80"
          [(ngModel)]="loadMemberProfileResponse.AGENT_IN_LAW1"
          name="AGENT_IN_LAW1"
          disabled
        />
      </div>
      <div class="basic-left">法定代理人身分證字號</div>
      <div class="basic-right">
        <input
          class="w-80"
          [(ngModel)]="loadMemberProfileResponse.AGENT_IN_LAW_ID"
          name="AGENT_IN_LAW_ID"
          disabled
        />
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">法定代理人姓名</div>
      <div class="basic-right">
        <input
          class="w-80"
          [(ngModel)]="loadMemberProfileResponse.AGENT_IN_LAW2"
          name="AGENT_IN_LAW2"
          disabled
        />
      </div>
      <div class="basic-left">法定代理人身分證字號</div>
      <div class="basic-right">
        <input
          class="w-80"
          [(ngModel)]="loadMemberProfileResponse.AGENT_IN_LAW_ID2"
          name="AGENT_IN_LAW_ID2"
          disabled
        />
      </div>
    </div>
  </div>

  <div class="button-box">
    <a href="/login" class="cancel-button">取消</a>
    <a class="Main-button" (click)="save()"
      >確認<i class="fas fa-chevron-right"></i
    ></a>
  </div>
</div>
