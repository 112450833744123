import { Component, OnInit } from '@angular/core';
import { GetArticleByUnitArgs, Data, GetNewsListResponse } from './../../model/News';
import { NewsService } from '../../services/News.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AllowHelper } from '../../helper/allowHelper';
import { PetternHelper } from '../../helper/petternHelper';
import { ValidationHelper } from '../../helper/validationHelper';
import { MessageService } from '../../services/message.service';
import { BaseComponent } from '../base/baseComponent';
import { MemberService } from 'src/app/services/member.service';
import { PageParam } from 'src/app/model/member';

//import { SharedObservable } from '../shared/shared.observable';
//import { NbDialogService } from '@nebular/theme';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent extends BaseComponent implements OnInit {
  navigationSubscription;
  GetArticleByUnitArgs = new GetArticleByUnitArgs();
  GetNewsListResponse = new GetNewsListResponse();
  NewsTypeDropdown = 1707;
  NewsListData = [] as Data[];

  pageparam = new PageParam();

  constructor(
    private _NewsService: NewsService,
    private _MemberService: MemberService,

    //private dialogService: NbDialogService,
    //private share: SharedObservable,
    //private _CardSNService: CardSNService,
    private message: MessageService,
    private valid: ValidationHelper,
    protected allow: AllowHelper,
    private router: Router,
    private pettern: PetternHelper,
    private activatedRoute: ActivatedRoute
    //private baseService: SharedService

  ) {
    super(allow);
    this.navigationSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.pageIndex = 1;
        this.pageSize = 25;
        this.NewsTypeDropdown = ~~(this.activatedRoute.snapshot.queryParams["cFunctionId"] || 1707);
        this.NewsListData = [] as Data[];

      }
    });

  }

  ngOnInit(): void {
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 14;
    this.pageparam.Page = '最新消息';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.getList();
      }
      else {
        return
      }
    })
  }

  getList() {

    this.GetArticleByUnitArgs.cFunctionId = this.NewsTypeDropdown;
    this.GetArticleByUnitArgs.PageSize = this.pageSize;
    this.GetArticleByUnitArgs.PageIndex = this.pageIndex;


    this._NewsService.GetList(this.GetArticleByUnitArgs).subscribe(res => {

      this.NewsListData = res.Entries.data
      this.totalRecords = res.TotalItems;

    });
  }

  changeValue(newValue: number) {
    this.NewsTypeDropdown = newValue;
    this.getList();
    //this.CvendorProductNoArg.cvendorNo = newValue;
    //this.GetProductByChannelList(this.CvendorProductNoArg);
  }
}
