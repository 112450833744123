import { CheckIdArgs } from './../model/disclaimer';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import { ChkMemStatusArgs, ChkMemStatusResponse, GetCountryCDResponse, GetBankDataArgs, GetBankDataResponse } from '../model/disclaimer';
import { GetKycQuestionArgs, GetKycQuestionResponse } from 'src/app/model/personal';
// { GetKYCQuestionArgs, GetKYCQuestionResponse, } from '../model/disclaimer';
import { OpenMemberArgs, OpenMemberResponse } from '../model/disclaimer';
import { GetSubBankDataArgs, GetSubBankDataResponse } from '../model/disclaimer';
import { GetKycRiskScoreArgs, GetKycRiskScoreResponse, } from './../model/open-data';
@Injectable({
  providedIn: 'root',
})
export class disclaimerService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/Disclaimer`;
  constructor(
    http: HttpClient
  ) {
    super(http);

  }

  OpenAccount(request: ChkMemStatusArgs): Observable<ResponseBase<ChkMemStatusResponse>> {
    const url = `${this.baseUrl}/OpenAccount`;
    return this.http.post<ResponseBase<ChkMemStatusResponse>>(
      url,
      request,
      this.httpOptions);
  }

  GetCountryCD(): Observable<ResponseBase<GetCountryCDResponse>> {
    const url = `${this.baseUrl}/GetCountryCD`;
    return this.http.post<ResponseBase<GetCountryCDResponse>>(
      url,
      this.httpOptions);
  }

  GetBankData(request: GetBankDataArgs): Observable<ResponseBase<GetBankDataResponse>> {
    const url = `${this.baseUrl}/GetBankData`;
    return this.http.post<ResponseBase<GetBankDataResponse>>(
      url,
      request,
      this.httpOptions);
  }
  GetSubBankData(request: GetSubBankDataArgs): Observable<ResponseBase<GetSubBankDataResponse>> {
    const url = `${this.baseUrl}/GetSubBankData`;
    return this.http.post<ResponseBase<GetSubBankDataResponse>>(
      url,
      request,
      this.httpOptions);
  }
  GetKYCQuestion(request: GetKycQuestionArgs): Observable<ResponseBase<GetKycQuestionResponse>> {
    const url = `${this.baseUrl}/GetKYCQuestion`;
    return this.http.post<ResponseBase<GetKycQuestionResponse>>(
      url,
      request,
      this.httpOptions);
  }
  GetKycRiskScore(
    request: GetKycRiskScoreArgs
  ): Observable<ResponseBase<GetKycRiskScoreResponse>> {
    const url = `${this.baseUrl}/GetKycRiskScore`;
    return this.http.post<ResponseBase<GetKycRiskScoreResponse>>(
      url,
      request,
      this.httpOptions
    );
  }
  CheckExgOpenData(request: OpenMemberArgs): Observable<ResponseBase<string>> {
    const url = `${this.baseUrl}/CheckExgOpenData`;
    return this.http.post<ResponseBase<string>>(
      url,
      request,
      this.httpOptions);
  }
  SaveDisclaimer(request: OpenMemberArgs): Observable<ResponseBase<string>> {
    const url = `${this.baseUrl}/SaveDisclaimer`;
    return this.http.post<ResponseBase<string>>(
      url,
      request,
      this.httpOptions);
  }
  SendParper(request: OpenMemberArgs): Observable<ResponseBase<string>> {
    const url = `${this.baseUrl}/SendParper`;
    return this.http.post<ResponseBase<string>>(
      url,
      request,
      this.httpOptions);
  }

  OpenAccountAll(request: ChkMemStatusArgs): Observable<ResponseBase<OpenMemberResponse>> {
    const url = `${this.baseUrl}/OpenAccountAll`;
    return this.http.post<ResponseBase<OpenMemberResponse>>(
      url,
      request,
      this.httpOptions);
  }

  CheckId(request: CheckIdArgs): Observable<number> {
    const url = `${this.baseUrl}/CheckId`;
    return this.http.post<number>(
      url,
      request,
      this.httpOptions);
  }
}
