export class GetKycQuestionArgs {
  BF_COUNTRY_X: "01";
}

export class GetKycQuestionResponse {
  KYCQuestionList: KYCQuestionList[];
}

export class KYCQuestionDtl {
  ANS_SEQNO: number;
  ANS_ORDER: number;
  ANS_ITEM: string;
  ANS_SCORE: number;
  IS_FREE_INPUT: string;
  LAST_IS_CHK_YN: string;
  LAST_ANS_KEY_IN: string;
  LAST_ANS_TEXT_IN: string;
  ANS_SEQNO_SELECTED: any;
}

export class KYCQuestionList {
  VERSION_NO: string;
  QSN_SEQNO: number;
  QSN_ORDER: string;
  KYC_QUESTION: string;
  IS_ALLOW_MULIT: string;
  KYCQuestionDtl: KYCQuestionDtl[];
  KYCQuestionResult: any;
  ANS_TEXT_IN = ''; //2021/11/25 Yuu 新增
  IS_FREE_INPUT = 'N'; //2021/11/25 Yuu 新增
  Display = false; //2021/11/25 Yuu 新增
}



export class KycHistoryArgs {

}

export class KYCList {
  VERSION_NO: string;
  KYC_ANS_NO: string;
  APPLY_DTTM: Date;
  KYCQuestionList: KYCQuestionList[];
}

export class KycHistoryResponse {
  KYCList: KYCList[];
}


export class CusKycDetail {
  QSN_SEQNO: number;
  QSN_ORDER: string;
  KYC_QUESTION: string;
  ANS_SEQNO: number;
  ANS_ORDER: number;
  ANS_ITEM: string;
  ANS_SCORE: number;
  IS_CHK_YN: string;
  ANS_KEY_IN: string;
  ANS_TEXT_IN: string;
  ROW: number;
  IS_FREE_INPUT: string;
}

export class PostKycQuestionArgs {
  Password: string;
  BF_SRNO: number;
  BF_NO: number;
  KYC_BF_COUNTRY_X: string;
  VERSION_NO: string;
  KYC_TOTAL_SCORE: number;
  KYC_RISK_ATTR: string;
  CusKycDetail: CusKycDetail[];
}

export class PostKycQuestionResponse {
  token: '';
}
