<!-- <script
  type="text/javascript"
  src="https://ajax.googleapis.com/ajax/libs/jqueryui/1.8.11/jquery-ui.min.js"
></script> -->

<!-- 測試 -->
<!-- <div class="container-fluid">
  <div class="row" style="line-height: 50px">
    <input
      nbInput
      placeholder="請選擇日期..."
      [nbDatepicker]="ngmodel"
      [(ngModel)]="ngModelDate"
      (click)="clickPickerNB()"
      style="width: 125px"
    />
    <nb-datepicker #ngmodel format="yyyy/MM/dd"></nb-datepicker>
  </div>
</div> -->

<div class="Block-content">
  <div class="calendar-box">
    <samp>結餘截止日期</samp>
    <input type="date" class="calendar" style="position: relative" [(ngModel)]="FormattedBalDate"
      [value]="FormattedBalDate" />
    <!-- <input
      type="text"
      class="calendar datepicker"
      [(ngModel)]="FormattedBalDate"
      [value]="FormattedBalDate"
    /> -->
    <a href="javascript:void(0)" class="Main-button" (click)="searchFunds()">開始搜尋<i
        class="fas fa-chevron-right"></i></a>
  </div>

  <div class="title-02">單筆申購基金結餘</div>
  <div class="ec-area">
    <table class="scroll-left ec-table">
      <thead>
        <tr>
          <th style="width: 180px;">基金名稱</th>
          <th>幣別</th>
          <th>持有單位數</th>
          <th>投資成本</th>
          <th>平均投資成本</th>
          <th>淨值日期</th>
          <th>期末淨值</th>
          <th>市價</th>
          <th>損益金額</th>
          <th>不含息報酬率%</th>
          <th>領息金額</th>
          <th>含息報酬率％</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of SingleSubscription">
        <tr style="background-color: #f4f4f4">
          <td data-th="基金名稱">
            {{ item.FUND_NAME }}
          </td>
          <td data-th="幣別" align="center">{{ item.FUND_CURRENCY_NM }}</td>
          <td data-th="持有單位數" align="center">
            {{ item.BAL_UNIT | number :'1.0-4'}}
            <button class="add-fund none-web" *ngIf="item.AllotDataList.length > 0"
              (click)="displayAllotDataList(item.FUND_ID, item.TRADE_TYPE)">
              <i class="fas fa-info-circle"></i>
            </button>
            <button class="add-fund add-fund1-1 mobile-b" *ngIf="item.AllotDataList.length > 0"
              (click)="displayAllotDataList(item.FUND_ID, item.TRADE_TYPE)">
              <i class="fas fa-info-circle"></i>
            </button>
          </td>
          <!-- Mobile Web -->
          <td class="mobile-b add-content1-1">
            <div class="add-content">
              <div class="add-content-table"
                *ngIf="IsAllotDataList && item.AllotDataList.length > 0 && showFundID == item.FUND_ID">
                <p>後收型基金明細</p>
                <table>
                  <thead>
                    <tr>
                      <th>申購書號</th>
                      <th>申購日期</th>
                      <th>持有單位數</th>
                      <th>遞延手續費率</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of AllotDataList">
                      <td data-th="申購書號">{{ item.ALLOT_NO }}</td>
                      <td data-th="申購日期">
                        {{ item.ALLOT_DATE | date: "yyyy/MM/dd" }}
                      </td>
                      <td data-th="持有單位數">{{ item.RUNIT | number :'1.0-4' }}</td>
                      <td data-th="遞延手續費率">{{ item.FEE | number :'1.0-4'}}%</td>
                    </tr>
                  </tbody>
                </table>
                <span>後收型基金買回時的手續費率會依持有時間時間而不同，買回時採先進先出。</span>
              </div>
            </div>
          </td>
          <td data-th="投資成本" align="center">
            {{ item.BAL_COST | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="平均投資成本" align="center">
            {{ item.AVG_COST | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="淨值日期" align="center">
            {{ item.NAV_DATE_s }}
          </td>
          <td data-th="期末淨值" align="center">{{ item.NAV_B | currency :'$':'symbol': '1.0-4'}}</td>
          <td data-th="市價" align="center">
            {{ item.MARKET_AMT | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="損益金額" align="center">
            <p class="text-red">{{ item.UNRL_AMT | currency :'$':'symbol': '1.0-4'}}</p>
          </td>
          <td data-th="不含息報酬率%" align="center">
            <p class="text-green">{{ item.RETURN_RATE }}%</p>
          </td>
          <td data-th="領息金額" align="center">
            {{ item.DIV_AMT | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="含息報酬率%" align="center">
            {{ item.RETURN_RATE_DIV }}%
          </td>
        </tr>
        <!-- Web -->
        <tr class="add-allot-data-list-content add-content1 none-web"
          *ngIf="IsAllotDataList && item.AllotDataList.length > 0 && showFundID == item.FUND_ID">
          <td colspan="12">
            <div class="add-content-table">
              <p>後收型基金明細</p>
              <table>
                <thead>
                  <tr>
                    <th>申購書號</th>
                    <th>申購日期</th>
                    <th>持有單位數</th>
                    <th>遞延手續費率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of AllotDataList">
                    <td data-th="申購書號">{{ item.ALLOT_NO }}</td>
                    <td data-th="申購日期">
                      {{ item.ALLOT_DATE | date: "yyyy/MM/dd" }}
                    </td>
                    <td data-th="持有單位數">{{ item.RUNIT | number :'1.0-4'}}</td>
                    <td data-th="遞延手續費率">{{ item.FEE | number :'1.0-4'}}%</td>
                  </tr>
                </tbody>
              </table>
              <span>後收型基金買回時的手續費率會依持有時間時間而不同，買回時採先進先出。</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="title-02">定期定額基金結餘</div>
  <div class="ec-area">
    <table class="scroll-left ec-table">
      <thead>
        <tr>
          <th>基金名稱</th>
          <th>幣別</th>
          <th>持有單位數</th>
          <th>投資成本</th>
          <th>平均投資成本</th>
          <th>淨值日期</th>
          <th>期末淨值</th>
          <th>市價</th>
          <th>損益金額</th>
          <th>不含息報酬率%</th>
          <th>領息金額</th>
          <th>含息報酬率％</th>
        </tr>
      </thead>
      <tbody *ngFor="let item of RegularSavingPlan">
        <!-- <div > -->
        <tr style="background-color: #f4f4f4">
          <td data-th="基金名稱">
            {{ item.FUND_NAME }}
          </td>
          <td data-th="幣別" align="center">{{ item.FUND_CURRENCY_NM }}</td>
          <td data-th="持有單位數" align="center">
            {{ item.BAL_UNIT | number :'1.0-4'}}
            <button class="add-fund add-fund2 none-web" *ngIf="item.AllotDataList.length > 0">
              <i class="fas fa-info-circle"></i>
            </button>
            <button class="add-fund add-fund2-1 mobile-b" *ngIf="item.AllotDataList.length > 0">
              <i class="fas fa-info-circle"></i>
            </button>
          </td>
          <!-- Mobile Web -->
          <td class="mobile-b add-content2-1">
            <div class="add-content">
              <div class="add-content-table">
                <p>後收型基金明細</p>
                <table>
                  <thead>
                    <tr>
                      <th>申購書號</th>
                      <th>申購日期</th>
                      <th>持有單位數</th>
                      <th>遞延手續費率</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of item.AllotDataList">
                      <td data-th="申購書號">{{ data.ALLOT_NO }}</td>
                      <td data-th="申購日期">
                        {{ data.ALLOT_DATE | date: "yyyy/MM/dd" }}
                      </td>
                      <td data-th="持有單位數">{{ data.RUNIT | number :'1.0-4'}}</td>
                      <td data-th="遞延手續費率">{{ data.FEE | number :'1.0-4'}}%</td>
                    </tr>
                  </tbody>
                </table>
                <span>後收型基金買回時的手續費率會依持有時間時間而不同，買回時採先進先出。</span>
              </div>
            </div>
          </td>
          <td data-th="投資成本" align="center">
            {{ item.BAL_COST | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="平均投資成本" align="center">
            {{ item.AVG_COST | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="淨值日期" align="center">
            {{ item.NAV_DATE_s }}
          </td>
          <td data-th="期末淨值" align="center">{{ item.NAV_B}}</td>
          <td data-th="市價" align="center">
            {{ item.MARKET_AMT | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="損益金額" align="center">
            <p class="text-red">{{ item.UNRL_AMT | currency :'$':'symbol': '1.0-4'}}</p>
          </td>
          <td data-th="不含息報酬率%" align="center">
            <p class="text-green">{{ item.RETURN_RATE | number :'1.0-4'}}%</p>
          </td>
          <td data-th="領息金額" align="center">
            {{ item.DIV_AMT | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="含息報酬率%" align="center">
            {{ item.RETURN_RATE_DIV | number :'1.0-4'}}%
          </td>
        </tr>
        <!-- Web -->
        <tr class="add-allot-data-list-content add-content2 none-web"
          *ngIf="IsAllotDataList && item.AllotDataList.length > 0">
          <td colspan="12">
            <div class="add-content-table">
              <p>後收型基金明細</p>
              <table>
                <thead>
                  <tr>
                    <th>申購書號</th>
                    <th>申購日期</th>
                    <th>持有單位數</th>
                    <th>遞延手續費率</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of AllotDataList">
                    <td data-th="申購書號">{{ item.ALLOT_NO }}</td>
                    <td data-th="申購日期">
                      {{ item.ALLOT_DATE | date: "yyyy/MM/dd" }}
                    </td>
                    <td data-th="持有單位數">{{ item.RUNIT | number :'1.0-4'}}</td>
                    <td data-th="遞延手續費率">{{ item.FEE | number :'1.0-4'}}%</td>
                  </tr>
                </tbody>
              </table>
              <span>後收型基金買回時的手續費率會依持有時間時間而不同，買回時採先進先出。</span>
            </div>
          </td>
        </tr>
        <!-- </div> -->
      </tbody>
    </table>
  </div>

  <div class="title-02">總基金結餘</div>
  <div class="ec-area">
    <table class="scroll-left ec-table">
      <thead>
        <tr>
          <th style="width: 180px;">基金名稱</th>
          <th>幣別</th>
          <th>持有單位數</th>
          <th>投資成本</th>
          <th>平均投資成本</th>
          <th>淨值日期</th>
          <th>期末淨值</th>
          <th>市價</th>
          <th>損益金額</th>
          <th>不含息報酬率%</th>
          <th>領息金額</th>
          <th>含息報酬率％</th>
        </tr>
      </thead>
      <tbody>
        <tr style="background-color: #f4f4f4" *ngFor="let item of BalanceSum">
          <td data-th="基金名稱">
            {{ item.FUND_NAME }}
          </td>
          <td data-th="幣別" align="center">{{ item.FUND_CURRENCY_NM }}</td>
          <td data-th="持有單位數" align="center">
            {{ item.BAL_UNIT | number :'1.0-4'}}
          </td>
          <td data-th="投資成本" align="center">
            {{ item.BAL_COST | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="平均投資成本" align="center">
            {{ item.AVG_COST | currency :'$':'symbol': '1.0-4'}}
          </td>
          <td data-th="淨值日期" align="center">
            {{ item.NAV_DATE_s }}
          </td>
          <td data-th="期末淨值" align="center">{{ item.NAV_B | currency :'$':'symbol': '1.0-4'}}</td>
          <td data-th="市價" align="center">{{ item.MARKET_AMT | currency :'$':'symbol': '1.0-4'}}</td>
          <td data-th="損益金額" align="center">
            <p class="text-red">{{ item.UNRL_AMT | currency :'$':'symbol': '1.0-4'}}</p>
          </td>
          <td data-th="不含息報酬率%" align="center">
            <p class="text-green">{{ item.RETURN_RATE }}%</p>
          </td>
          <td data-th="領息金額" align="center">{{ item.DIV_AMT | currency :'$':'symbol': '1.0-4'}}</td>
          <td data-th="含息報酬率%" align="center">
            {{ item.RETURN_RATE_DIV}}%
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="analysis-box">
    <div class="analysis">
      <div class="title-02">基金類型維度</div>
      <h5>投資類型比例</h5>
      <div class="chart">
        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels" [chartType]="pieChartType"
          [options]="pieChartOptions" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
        </canvas>
      </div>
    </div>
    <div class="analysis">
      <div class="title-02">幣別維度</div>
      <h5>投資類型比例</h5>
      <div class="chart">
        <canvas baseChart [data]="pieChartData2" [labels]="pieChartLabels2" [chartType]="pieChartType"
          [options]="pieChartOptions2" [plugins]="pieChartPlugins" [colors]="pieChartColors" [legend]="pieChartLegend">
        </canvas>
      </div>
    </div>
  </div>
</div>
