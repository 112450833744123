<div class="Block-content">
    <div class="title-01">最新消息</div>
    <div class="ah-tab-wrapper">
        <div class="ah-tab center-tab">
            <a class="ah-tab-item" [attr.data-ah-tab-active]="GetArticleByUnitArgs.cFunctionId===1707"
                (click)="GetNewsByLogin(1707)">全部公告</a>
            <a class="ah-tab-item" [attr.data-ah-tab-active]="GetArticleByUnitArgs.cFunctionId===1726"
                (click)="GetNewsByLogin(1726)">優惠快訊</a>
            <a class="ah-tab-item" [attr.data-ah-tab-active]="GetArticleByUnitArgs.cFunctionId===1708"
                (click)="GetNewsByLogin(1708)">基金公告</a>
            <a class="ah-tab-item" [attr.data-ah-tab-active]="GetArticleByUnitArgs.cFunctionId===3940"
                (click)="GetNewsByLogin(3940)">其他公告</a>
        </div>
    </div>

    <div class="ah-tab-content-wrapper">
        <div class="ah-tab-content" data-ah-tab-active="true">
            <ul class="news-list">
                <li *ngFor="let data of NewsListData">
                    <div>
                        <div class="news-c" [ngSwitch]="data?.cFunctionId">
                            <ng-container *ngSwitchCase="1726"><span class="Red-label">優惠快訊</span><a
                                    [href]=data.cLinkUrl target="_blank">{{data?.cName}}</a></ng-container>
                            <ng-container *ngSwitchCase="1708"><span class="blue-label">基金公告</span><a
                                    [href]=data.cLinkUrl target="_blank">{{data?.cName}}</a></ng-container>
                            <ng-container *ngSwitchCase="3940"><span class="green-label">其他公告</span><a
                                    [href]=data.cLinkUrl target="_blank">{{data?.cName}}</a></ng-container>
                            <ng-container *ngSwitchDefault><span class="green-label">{{data?.cFunctionId}}</span><a
                                    [href]=data.cLinkUrl target="_blank">{{data?.cName}}</a></ng-container>
                        </div>
                        <samp>{{ data.cShowStartDt | date : 'yyyy.MM.dd' }}-{{ data.cShowEndDt | date : 'yyyy.MM.dd'
                            }}</samp>
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div class="button-box">
        <a routerLink="/news" [queryParams]="{cFunctionId: GetArticleByUnitArgs.cFunctionId}" rel="modal:open" class="Main-button">了解更多<i class="fas fa-chevron-right"></i></a>
    </div>
</div>

<div class="Block-content">
    <div class="title-01">個人資產配置</div>
    <div class="title-02">單筆申購基金結餘</div>
    <div class="ec-area">
        <table class="ec-table">
            <thead>
                <tr>
                    <th>基金名稱</th>
                    <th>持有單位數</th>
                    <th>投資成本</th>
                    <th>平均投資成本</th>
                    <th>期末淨值</th>
                    <th>市價</th>
                    <th>損益金額</th>
                    <th>投資報酬率</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of SingleDetail">
                    <td data-th="基金名稱"><a href="javascript:void(0)">{{data.FUND_NAME}}</a></td>
                    <td data-th="持有單位數" align="center">{{data.BAL_UNIT | number}}</td>
                    <td data-th="投資成本" align="center">{{data.BAL_COST | number}}</td>
                    <td data-th="平均投資成本" align="center">{{data.AVG_COST | number}}</td>
                    <td data-th="期末淨值" align="center">
                        <p class="text-red">{{data.NAV_B | number}}</p>
                    </td>
                    <td data-th="市價" align="center">{{data.MARKET_AMT | number}}</td>
                    <td data-th="損益金額" align="center">
                        <p class="text-red">{{data.UNRL_AMT | number}}</p>
                    </td>
                    <td data-th="投資報酬率" align="center">
                        <p class="text-red">{{data.RETURN_RATE}}%</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="title-02">定期定額基金結餘</div>
    <div class="ec-area">
        <table class="scroll-left ec-table">
            <thead>
                <tr>
                    <th>基金名稱</th>
                    <th>持有單位數</th>
                    <th>投資成本</th>
                    <th>平均投資成本</th>
                    <th>期末淨值</th>
                    <th>市價</th>
                    <th>損益金額</th>
                    <th>投資報酬率</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of RegularQuotaDetail">
                    <td><a href="javascript:void(0)">{{data.FUND_NAME}}</a></td>
                    <td align="center">{{data.BAL_UNIT | number}}</td>
                    <td align="center">{{data.BAL_COST | number}}</td>
                    <td align="center">{{data.AVG_COST | number}}</td>
                    <td align="center">
                        <p class="text-red">{{data.NAV_B | number}}</p>
                    </td>
                    <td align="center">{{data.MARKET_AMT | number}}</td>
                    <td align="center">
                        <p class="text-red">{{data.UNRL_AMT | number}}</p>
                    </td>
                    <td align="center">
                        <p class="text-red">{{data.RETURN_RATE}}%</p>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
