export class GetBucAccArgs {
  CRNCY_CD = '';
}

export class GetBucAccResponse {
  BucAccList!: BucAccList[];
}

export class BucAccList {
  DATA_SEQ!: number;
  BANK_BRH!: string;
  BANK_BRH_SHNM!: string;
  ACCOUNT_NO!: string;
  ACCOUNT_NO_SHOW!: string;
  SEAL_TYPE!: string;
  ACC_NO_TYPE: string;
}

export class GetDivAccArgs {
  CRNCY_CD = '';
  FUND_ID = '';
}

export class GetDivAccResponse {
  DivAccList: DivAccList[] = [];
}

export class DivAccList {
  DATA_SEQ!: number;
  BANK_BRH!: string;
  BANK_BRH_SHNM!: string;
  ACCOUNT_NO!: string;
  ACCOUNT_NO_SHOW!: string;
  cType: string;
}

export class GetFundListArgs {
  FUND_ID = '';
  FUND_TYPE = 0;
}

export class GetFundListResponse {
  FundList!: FundDetail[];
}

export class FundDetail {
  FUND_ID!: string;
  FUND_NAME!: string;
  FUND_CURRENCY!: string;
  FUND_CURRENCY_NM!: string;
  FUND_RISK!: string;
  FUND_RISK_NM!: string;
  AFEE_TYPE!: string;
  AFEE_TYPE_NM!: string;
  NAV_DATE!: Date;
  NAV_B!: number;
  DIV_TYPE!: string;
  DIV_TYPE_NM!: string;
  DIVIDEND_TYPE!: string;
  DIVIDEND_TYPE_NM!: string;
  UNI_CD!: string;
  HIGHDIVIDEND_YN!: string;
  ALLOT_YN!: string;
  RSP_YN!: string;
  ManagerRate!: string;
  CustodyRate!: string;
  DistributionRate!: string;
  OtherFee!: string;
  NAV_DEC!: number;
  UNIT_DEC!: number;
  DEC_LEN!: number;
  OfficialManualPath!: string;
  RiskForecastPath!: string;
  OfficialManualFileName!: string;
  RiskForecastFileName!: string;
}

export class GetFeeRateArgs {
  TRADE_TYPE!: string;
  FUND_ID!: string;
  SWITCH_FUND_ID!: string;
  CRNCY_CD!: string;
  TRADE_AMT!: number;
  TRADE_DATE!: Date;
}

export class GetFeeRateResponse {
  FeeRateList!: FeeRateItem[];
}

export class FeeRateItem {
  FEE_CHOICE!: string;
  FEE_NAME!: string;
  FEE_TYPE!: string;
  FEE_RATE!: number;
  CAMPAIGN_CODE!: string;
  CAMPAIGN_SHNM!: string;
}

export class SingletransactionModel {
  FUND_ID!: string;
  FUND_NAME!: string;
  FUND_CURRENCY!: string;
  FUND_CURRENCY_NM!: string;
  DIVIDEND_TYPE!: string;
  DIVIDEND_TYPE_NM!: string;
  ApplyAmount = 0; // 申購金額
  ApplyFee = 0; // 手續費
  ApplyFeeRate = 0; // 手續費率
  ApplyTotalAmount = 0; // 申購總額
  ACCOUNT_NO = ''; // 扣款帳號
  ACCOUNT_NO_SHOW = ''; // 扣款帳號(隱碼)
  ACCOUNT_DIV_NO = ''; // 收益配息帳號
  ACCOUNT_DIV_NO_SHOW = ''; // 收益配息扣款帳號(隱碼)
  BANK_BRH_SHNM = ''; // 扣款分行名稱
  BANK_BRH_DIV_SHNM = ''; //配息分行名稱
  Password = ''; // 交易密碼
  ApplyTime!: Date; // 申請時間
  Step = 1;
  ReadInfo = false; // 已讀相關說明
  FEE_CHOICE = '1'; // 手續費優惠方案代碼 1：原始手續費(EC牌告手續費率) 2：特定受益人 3：優惠關係人 4：促銷活動 5：壽星優惠
  FEE_NAME = ''; // 手續費優惠方案名稱
  CAMPAIGN_CODE = ''; // 手續費促銷活動代碼
  CAMPAIGN_SHNM = ''; // 手續費促銷活動名稱
  SUB_BANK_BRH = ''; // 扣款銀行代碼
  SUB_BANK_BRH_DIV = ''// 配息銀行代碼
  CommissionDate!: Date; // 預計委託生效時間(交易日期) TransferDate
  ListPrice = 0; // 牌價
  OnlyListPrice = true;
  cType: string;
  BF_NO: string;
}

export class SingletransactionModelVerifyResponse {
  VerifyMsg: string[];
  CommissionDate!: Date;
}

export class RegularSavingPlanModel {
  FUND_ID!: string; // 基金代碼
  FUND_NAME!: string; // 基金名稱
  FUND_CURRENCY!: string; // 計價幣別
  FUND_CURRENCY_NM!: string; // 計價幣別名稱
  DIVIDEND_TYPE!: string; // 配息方式
  DIVIDEND_TYPE_NM!: string; // 配息方式名稱
  ACCOUNT_NO = ''; // 扣款帳號
  ACCOUNT_NO_SHOW = ''; // 扣款帳號（隱碼）
  ACCOUNT_DIV_NO = ''; // 收益配息帳號
  ACCOUNT_DIV_NO_SHOW = ''; // 收益配息扣款帳號（隱碼）
  BANK_BRH_SHNM = ''; // 金融機構分行名稱
  BANK_BRH_DIV_SHNM = ''; // 金融機構分行名稱（配息收益約定帳戶）;
  CAMPAIGN_CODE = ''; // 促銷活動代碼
  FEE_CHOICE = ''; // 手續費優惠方案代碼
  SUB_BANK_CODE = ''; // 扣款帳號 = BANK_BRH
  DIV_SUB_BANK_CODE = ''; //分行銀行代碼
  SEAL_TYPE = ''; // 核印扣款方式（扣款帳號）
  ApplyTime: Date; // 委託確認時間
  RspDay = ''; // 扣款日期 = CCHARGEDATE
  Password = ''; // 交易密碼
  ReadInfo = false; // 已讀相關說明
  Step = 1;
  Fee: number = 0; // 手續費
  FeeRate: number = 0; //牌告費率
  BestFee: number = 0; // 最優手續費
  BestFeeRate: number = -1; //最優惠的費率
  ApplyFee: number = 0; // 手續費 | 最優手續費
  ApplyAmount = 0; // 申購金額
  ApplyTotalAmount = 0; // 申購總額
  cType: string;
  // 以下待討論
  FUND_RISK: string;
  FUND_RISK_NM: string;
  AFEE_TYPE: string;
  NAV_DATE: string;
  NAV_B: number;
  DIV_TYPE: string;
  UNI_CD: string;
  HIGHDIVIDEND_YN: string;
  ALLOT_YN: string;
  RSP_YN: string;
  ManagerRate: string;
  CustodyRate: string;
  DistributionRate: string;
  OtherFee: string;
  NAV_DEC: number;
  UNIT_DEC: number;
  DEC_LEN: number;
  CommissionDate!: Date; //預計委託生效時間(交易日期) TransferDate
}

export class RegularSavingPlanModelVerifyResponse {
  VerifyMsg: string[];
  CommissionDate!: Date;
}

export class GetKycRiskScoreArgs {
  VERSION_NO: string;
  ANS_SCORE: number;
}

export class GetKycRiskScoreResponse {
  KYC_RISK_ATTR: string;
  KYC_RISK_ATTR_NM: string;
}

// 歷史交易明細
export class GetHistoryDataParams {
  BEGIN_DATE: Date; // 查詢期間(起)
  END_DATE: Date; // 查詢期間(迄)
  FUND_ID: string; // 基金代碼 ALL：全部
  TRADE_TYPE: string; // 交易類別 0：全部, 1：單筆申購, 2：買回交易, 3：轉申購交易, 4：定期定額, 5：收益分配
}

export class GetHistoryDataResponse {
  AllotList: AllotList[]; // 申購/定額資料清單
  RedemList: RedemList[]; // 買回資料清單
  SwitchList: SwitchList[]; // 轉申購資料清單
  DivList: DivList[]; // 收益分配資料清單
}

export class AllotList {
  TRADE_TYPE: string; // 交易類別 1：單筆申購, 4：定期定額
  FUND_ID: string; // 基金代碼
  FUND_NAME: string; // 基金名稱
  CRNCY_CD: string; // 交易幣別代碼
  CRNCY_NM: string; // 交易幣別名稱
  SYSTEM_ID: string; // 交易通路
  SYSTEM_NM: string; // 交易通路名稱 0：臨櫃, 1：網路
  ALLOT_DATE: Date; // 交易日期
  CAN_REDEM_DATE: Date; // 可贖回日
  ALLOT_UNIT: number; // 交易單位數
  NAV_B: number; // 當日淨值
  ALLOT_AMT: number; // 申購金額
  ALLOT_FEE: number; // 手續費
  SUM_AMT: number; // 交易總金額
  TRADE_STATUS: string; // 狀態
  TRADE_STATUS_NM: string; // 狀態名稱 1：處理中, 2：扣款成功, 3：扣款失敗, 4：已結轉, 5：交易作廢
  SUB_BANK_BRH_NM: string; // 扣款銀行名稱
  SUB_ACCOUNT_NO: string; // 扣款帳號
  UNIT_DEC: number; // 單位數小數位數
  NAV_DEC: number; // 淨值小數位數
  DEC_LEN: number; // 金額小數位數
}

export class RedemList {
  FUND_ID: string; // 基金代碼
  FUND_NAME: string; // 基金名稱
  CRNCY_CD: string; // 交易幣別代碼
  CRNCY_NM: string; // 交易幣別名稱
  SYSTEM_ID: string; // 交易通路
  SYSTEM_NM: string; // 交易通路名稱 0：臨櫃, 1：網路
  REDEM_DATE: Date; // 交易日期
  REDEM_UNIT: number; // 交易單位數
  RTOT_AMT: number; // 交易金額
  REDEM_NAV_DATE: Date; // 淨值日期
  NAV_B: number; // 當日淨值
  REDEM_COST: number; // 投資成本
  UNRL_AMT: number; // 損益金額
  RETURN_RATE: number; // 投資報酬率
  TRADE_STATUS: string; // 狀態
  TRADE_STATUS_NM: string; // 狀態名稱 1：處理中, 4：已結轉
  BANK_BRH_NM: string; // 贖回銀行名稱
  REMIT_ACC_NO: string; // 贖回帳號
  UNIT_DEC: number; // 單位數小數位數
  NAV_DEC: number; // 淨值小數位數
  DEC_LEN: number; // 金額小數位數
}

export class SwitchList {
  FUND_ID: string; // 基金代碼
  FUND_NAME: string; // 基金名稱
  CRNCY_CD: string; // 交易幣別代碼
  CRNCY_NM: string; // 交易幣別名稱
  SYSTEM_ID: string; // 交易通路
  SYSTEM_NM: string; // 交易通路名稱 0：臨櫃, 1：網路
  REDEM_DATE: Date; // 交易日期
  REDEM_UNIT: number; // 交易單位數
  RTOT_AMT: number; // 交易金額
  REDEM_NAV_DATE: Date; // 淨值日期
  NAV_B: number; // 當日淨值
  REDEM_COST: number; // 投資成本
  UNRL_AMT: number; // 損益金額
  RETURN_RATE: number; // 投資報酬率
  TRADE_STATUS: string; // 狀態
  TRADE_STATUS_NM: string; // 狀態名稱 1：處理中, 4：已結轉
  SWITCH_FEE_R: number; // 轉申購手續費率%
  SWITCH_FUND_ID: string; // 轉申購基金代碼
  SWITCH_FUND_NAME: string; // 轉申購基金名稱
  SWITCH_FUND_CURRENCY: string; // 轉申購基金幣別代碼
  SWITCH_FUND_CURRENCY_NM: string; // 轉申購基金幣別名稱
  UNIT_DEC: number; // 單位數小數位數
  NAV_DEC: number; // 淨值小數位數
  DEC_LEN: number; // 金額小數位數
}

export class DivList {
  GET_WAY: string; // 分配方式 1：匯款, 3：再申購, 9：金額太小，無法再申購
  FUND_ID: string; // 基金代碼
  FUND_NAME: string; // 基金名稱
  CRNCY_CD: string; // 交易幣別代碼
  CRNCY_NM: string; // 交易幣別名稱
  BASE_DATE: Date; // 配息基準日
  PROVIDE_DATE: Date; // 發放準日
  BAL_UNIT: number; // 參與配息單位數
  TOTAL_ASSIGN_AMT: number; // 分配金額
  REMIT_FEE: number; // 相關費用
  TOTAL_PAY_AMT: number; // 入帳配息金額
  ALLOT_DATE: Date; // 申購日期
  ALLOT_AMT: number; // 再投資金額
  ALLOT_FEE: number; // 手續費
  NAV_B: number; // 申購淨值
  ALLOT_UNIT: number; // 申購單位數
  BANK_BRH_NM: string; // 匯款銀行名稱
  REMIT_ACC_NO: string; // 匯款帳號
  UNIT_DEC: number; // 單位數小數位數
  NAV_DEC: number; // 淨值小數位數
  DEC_LEN: number; // 金額小數位數
}

export class AllFundsList {
  FUND_ID: string; // 基金代碼
  FUND_NAME: string; // 基金名稱
}

// 個人投資現況
export class GetDashboardInfoParams {
  BAL_DATE: Date; // 結餘截止日期
}

export class GetDashboardInfoResponse {
  TABalanceDetailList: TABalanceDetailList[]; // 境內基金結餘明細  申購、定額分類基金結餘
  BalanceSumList: BalanceSumList[]; // 總基金彙總  各基金彙總
  FundDataList: FundDataList[]; // 基金比率清單
  drawingPieChart: drawingPieChart;
}

export class TABalanceDetailList {
  FUND_ID: string; //	基金代碼
  FUND_NAME: string; //	基金名稱  FOR 網頁使用
  FUND_CURRENCY: string; // 計價幣別
  FUND_CURRENCY_NM: string; // 計價幣別名稱
  TRADE_TYPE: string; // 交易種類  1:單筆申購 2:定期定額
  BAL_UNIT: number; // 持有單位數
  BAL_COST: number; // 成本
  AVG_COST: number; // 平均成本
  NAV_DATE: Date; // 淨值日期
  NAV_DATE_s: string;
  NAV_B: number; // 淨值
  MARKET_AMT: number; // 市值
  UNRL_AMT: number; // 損益金額
  RETURN_RATE: number; // 報酬率(不含息)
  DIV_AMT: number; // 配息金額
  RETURN_RATE_DIV: number; // 含息報酬率
  NAV_DEC: number; // 淨值/平均成本小數位數
  UNIT_DEC: number; // 單位數小數位數
  DEC_LEN: number; // 金額小數位數
  AllotDataList: AllotDataList[]; // 申購交易明細  後收基金才有資料
  CDSCFeeRateList: CDSCFeeRateList[]; // 後收手續費率設定清單  後收基金才有資料
}

export class AllotDataList {
  ALLOT_NO: string; // 申購書號
  ALLOT_SRNO: number; // 申購序號
  ALLOT_DATE: Date; // 原始申購日期
  RUNIT: number; //	剩餘單位數
  FEE: number = 0; // 手續費
}

export class CDSCFeeRateList {
  HoldRule: string; // 計算規則 "1：日 3：年"
  HoldBegDay: number; // 起算日/年(含)
  HoldEndDay: number; // 終止日/年(不含)
  CDSCFeeRate: number; // 後收手續費率(%)
}

export class BalanceSumList {
  FUND_ID: string; // 基金代碼
  FUND_NAME: string; // 基金名稱  FOR 網頁使用
  FUND_CURRENCY: string; // 計價幣別
  FUND_CURRENCY_NM: string; // 計價幣別名稱
  NAV_DATE: Date; // 淨值日期
  NAV_DATE_s: string;
  NAV_B: number; // 淨值
  BAL_UNIT: number; // 持有單位數
  BAL_COST: number; // 成本
  AVG_COST: number; // 平均成本
  MARKET_AMT: number; // 市值
  UNRL_AMT: number; // 損益金額
  RETURN_RATE: number; // 報酬率(不含息)
  DIV_AMT: number; // 配息金額
  RETURN_RATE_DIV: number; // 含息報酬率
  NAV_DEC: number; // 淨值/平均成本小數位數
  UNIT_DEC: number; // 單位數小數位數
  DEC_LEN: number; // 金額小數位數
  MARKET_AMT_PERCENT: number = 0; // 市值百分比
}

export class FundDataList {
  FUND_ID: string; // 基金代碼
  FUND_NAME: string; // 基金名稱  FOR 網頁使用
  FUND_CURRENCY: string
  CRNCY_NM: string
  PROF_TYPE: string
  PROF_TYPE_NM_C: string
  FUND_VAL: number; // 基金占有率
}

export class drawingPieChart {
  cury = [] as FundList[]
  fund = [] as FundList[];
}

export class FundList {
  TypeName: string;
  TypeValue: number;
}

export class GetFundWarningListArgs {
  FUND_ID = '';
}

export class GetFundWarningListResponse {
  FundWarningList!: FundWarningDetail[];
}

export class FundWarningDetail {
  FUND_ID!: string;
  MAX_VAL: Number;
  MIN_VAL: Number;
  INCREASE: Number;
  DECLINE: Number;
  CREATE_DT: Date;
  UPDATE_DT: Date;
}

export class GetFundLockData {
  FundLockList!: FundLockData[];
}

export class FundLockData {
  IS_BALANCE: boolean; // 是否庫存
  FUND_ID: string;
  FUND_NAME: string;
  FUND_RISK_NM: string;
  NAV_DATE: string;
  NAV_B: number;
  BAL_UNIT: number;
  BAL_COST: number;
  MAX_VAL: string;
  MIN_VAL: string;
  INCREASE: string;
  DECLINE: string;
  AVG_COST: number; // 平均成本
  RETURN_RATE: number; // 報酬率(不含息)
  NAV_DEC: number;
  UNIT_DEC: number;
  DEC_LEN: number;
  // RETURN_RATE_DIV: number; // 含息報酬率
}
export class SaveFundWarningArgs {
  FUND_ID!: string;
  MAX_VAL: Number;
  MIN_VAL: Number;
  INCREASE: Number;
  DECLINE: Number;
  CREATE_DT: Date;
  UPDATE_DT: Date;
}
export class SaveFundWarningResponse {

}

export class GetStatementListResponse {
  StatementList: GetStatementResponse[];
}

export class GetStatementResponse {
  FileName: string;
  FilePath: string;
  FileMonth: string;
  FileExist: boolean;
}

export class GetMonthTradeArgs {
  Month: string;
  ID: string;
  BF_NO: string;
  Link: string;
}


export class GetMonthTradeResponse {

}
