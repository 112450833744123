import { Component, OnInit } from '@angular/core';
import { JwtPayload } from 'src/app/model/base';
import jwt_decode from 'jwt-decode';
import {
  BucAccList,
  DivAccList,
  FeeRateItem,
  FundDetail,
  GetBucAccArgs,
  GetBucAccResponse,
  GetDivAccArgs,
  GetDivAccResponse,
  GetFeeRateArgs,
  GetFeeRateResponse,
  GetFundListArgs,
  GetFundListResponse,
  RegularSavingPlanModel,
  RegularSavingPlanModelVerifyResponse,
} from 'src/app/model/open-data';
import {
  GetRspDayListArgs,
  GetRspDayListResponse,
  RspAllotDaysList,
} from 'src/app/model/rsp';
import { OpenDataService } from 'src/app/services/open-data.service';
import { ValidationHelper } from 'src/app/helper/validationHelper';
import { VerifyService } from 'src/app/services/verify.service';
import { MessageService } from 'src/app/services/message.service';
import { RSPService } from 'src/app/services/rsp.service';
import { PageParam } from 'src/app/model/member';
import { MemberService } from 'src/app/services/member.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Router } from '@angular/router';

@Component({
  selector: 'app-regular-saving-plan',
  templateUrl: './regular-saving-plan.component.html',
  styleUrls: ['./regular-saving-plan.component.css'],
})
export class RegularSavingPlanComponent implements OnInit {
  RegularSavingPlanModel = new RegularSavingPlanModel();
  GetFundListArgs = new GetFundListArgs();
  GetFundListResponse = new GetFundListResponse();
  GetRspDayListArgs = new GetRspDayListArgs();
  GetRspDayListResponse = new GetRspDayListResponse();
  GetBucAccResponse = new GetBucAccResponse();
  GetDivAccResponse = new GetDivAccResponse();
  GetFeeRateArgs = new GetFeeRateArgs();
  GetFeeRateResponse = new GetFeeRateResponse();
  jwt = {} as JwtPayload;
  selectedFund: FundDetail;
  selectedFundValue = '';
  RspAllotDaysList: RspAllotDaysList[];
  canBuy: boolean = false;
  feeNAME: string = '';
  isBestFee: boolean = false;
  HasDivAcc: boolean = true;

  //
  passwordType: string = 'password';
  conceal: string = 'conceal';
  isSelected: boolean = false;

  RegularSavingPlanModelVerifyResponse =
    new RegularSavingPlanModelVerifyResponse();

  pageparam = new PageParam();

  TWDList = [] as BucAccList[];//台幣帳戶
  FORList = [] as BucAccList[];//外幣帳戶

  AmountCheck: number;//檢查小數後幾位
  ShowAlert = ''; //紅字警告
  numbercheck: RegExpExecArray;
  AccAlert = ''; //帳號警告

  constructor(
    private openDataService: OpenDataService,
    private valid: ValidationHelper,
    private verifyService: VerifyService,
    private router: Router,
    private message: MessageService,
    private rspService: RSPService,
    private _MemberService: MemberService
  ) { }

  ngOnInit(): void {
    this.jwt = jwt_decode(localStorage.getItem('token') || '');
    // if (this.jwt.KYC_STATUS != 'Y') {
    //   if (confirm('您的kyc已過期，無法進行交易，是否導頁到kyc頁填寫?')) {
    //     this.router.navigateByUrl('kyc');
    //   }
    // }
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 4;
    this.pageparam.Page = '定期定額申購';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.getFundList();
        // this.jwt = jwt_decode(localStorage.getItem('token') || '');
      }
      else {
        return
      }
    })
  }

  // 取得可申購基金
  getFundList() {
    this.GetFundListArgs.FUND_ID = 'ALL';
    this.GetFundListArgs.FUND_TYPE = 2;

    this.openDataService.GetFundList(this.GetFundListArgs).subscribe((res) => {
      this.GetFundListResponse = res.Entries;
    });
  }

  // 取得扣款日期
  getRspDayList(params: GetRspDayListArgs) {
    this.openDataService.GetRspDayList(params).subscribe((res) => {
      this.GetRspDayListResponse = res.Entries;
      this.RegularSavingPlanModel.RspDay =
        this.GetRspDayListResponse.RspAllotDaysList[0].DAYS;
    });
  }

  // 取得扣款帳號
  getBucAcc(params: GetBucAccArgs) {
    this.openDataService.GetBucAcc(params).subscribe((res) => {
      this.GetBucAccResponse = res.Entries;

      var TWDList = [] as BucAccList[];//台幣帳戶
      var FORList = [] as BucAccList[];//外幣帳戶

      this.GetBucAccResponse.BucAccList.forEach(data => {
        if (data.SEAL_TYPE == '3') {
          switch (data.ACC_NO_TYPE) {
            case '0':
              TWDList.push(data);
              break;
            case '2':
              FORList.push(data);
              break;
          }
        }
      });
      this.TWDList = TWDList;
      this.FORList = FORList;

      if (params.CRNCY_CD == 'TWD') {
        if (TWDList.length == 0) {
          this.HasDivAcc = false;
          alert('無台幣扣款帳號，暫時無法進行外幣交易');
        }
      }
      else {
        if (FORList.length == 0) {
          this.HasDivAcc = false;
          alert('無外幣扣款帳號，暫時無法進行外幣交易');
        }
      }
    });
  }

  // 選擇基金
  selectAFund(e: any) {

    this.selectedFund = this.GetFundListResponse.FundList.filter((x) => x.FUND_ID === e.value)[0];

    this.AmountCheck = this.selectedFund.DEC_LEN;

    if (e.value == '') {
      this.isSelected = false;
    }
    else {
      this.isSelected = true;
    }

    this.getBucAcc({ CRNCY_CD: this.selectedFund.FUND_CURRENCY });
    this.getDivAcc({ CRNCY_CD: this.selectedFund.FUND_CURRENCY, FUND_ID: this.selectedFund.FUND_ID });
    this.checkTypeOfRisk(
      this.jwt.KYC_RISK_ATTR,
      parseInt(this.selectedFund.FUND_RISK, 10)
    );
    // 清空填寫欄位
    this.RegularSavingPlanModel = new RegularSavingPlanModel();
    this.getRspDayList(GetFeeRateArgs);
  }

  // 檢核申購基金是否符合個人風險屬性
  checkTypeOfRisk(kycRisk: string, fundRiskInt: number) {
    const kycRiskInt = parseInt(kycRisk, 10);
    switch (kycRisk) {
      case '1': // 保守型
        this.canBuy = kycRiskInt >= fundRiskInt;
        break;
      case '2': // 穩健型
        this.canBuy = kycRiskInt + 1 >= fundRiskInt;
        break;
      case '3': // 積極型
        this.canBuy = kycRiskInt + 2 >= fundRiskInt;
        break;
    }
  }

  // 取得收益配息帳號
  getDivAcc(params: GetDivAccArgs) {
    this.openDataService.GetDivAcc2(params).subscribe((res) => {
      if (res.Entries.DivAccList.length > 0) {
        this.GetDivAccResponse = res.Entries;
        this.HasDivAcc = true;
      }
      else {
        if (this.selectedFund.DIVIDEND_TYPE == '4') {
          this.HasDivAcc = true;
        }
        else {
          this.HasDivAcc = false;
        }

        if (!this.HasDivAcc && this.selectedFund.DIVIDEND_TYPE != '4') {
          alert('您目前無配息帳號，請至臨櫃申請')
        }
      }
    });
  }

  // 取得優惠手續費
  getBestFeeRate(request: any) {

    if (typeof (request) == 'object') {
      request = request.value
    }

    // this.AmountCheck = 2;

    // 判斷有無小數點
    //
    this.numbercheck = /^([\d]+)\.?([\d]*)$/.exec(request) as RegExpExecArray;
    /*
        1.先抓出輸入是否只有數字沒有特殊符號
        2.判斷基金可否接受輸入小數，如果可 可接受幾位數
        3.可輸入小數的基金，沒有輸入小數點後的數字
        4.只可輸入整數的基金，如果輸入了小數點則幫他進位
    */

    //1.
    if (this.numbercheck == null) {
      this.ShowAlert = `輸入數字錯誤，請重新輸入`;
      this.RegularSavingPlanModel.ApplyAmount = 0;
      return;
    }
    //2.
    if (this.numbercheck[2].length > this.AmountCheck) {
      if (this.AmountCheck == 0) {
        this.ShowAlert = `此基金僅可申購整數位`;
        return;
      }
      else {
        this.ShowAlert = `此基金只可申購至小數點後${this.AmountCheck}位`;
        return;
      }
    }
    //3.
    if (this.numbercheck[2].length <= this.AmountCheck) {
      if (this.AmountCheck == 0 && request.indexOf('.') > -1) {
        this.ShowAlert = `僅可申購整數位之基金不可輸入小數點`;
        // request = parseInt(request).toString();
        return;
      }
      else if (this.AmountCheck > 0 && request.indexOf('.') == request.length - 1) {
        this.ShowAlert = `如輸入小數點，則小數點後不可沒有輸入數字`;
        // request = parseFloat(request).toString();
        return;
      }
    }

    this.ShowAlert = '';
    // else {
    this.GetFeeRateArgs.TRADE_TYPE = '2'; // 交易種類, 2：定額
    this.GetFeeRateArgs.FUND_ID = this.selectedFund.FUND_ID; // 基金代碼
    this.GetFeeRateArgs.CRNCY_CD = this.selectedFund.FUND_CURRENCY; // 交易幣別代碼
    if (this.AmountCheck == 0) {
      this.RegularSavingPlanModel.ApplyAmount = parseInt(request);
      this.GetFeeRateArgs.TRADE_AMT = parseInt(request); // 交易金額
    }
    else {
      this.RegularSavingPlanModel.ApplyAmount = parseFloat(request);
      this.GetFeeRateArgs.TRADE_AMT = parseFloat(request);
    }
    this.GetFeeRateArgs.TRADE_DATE = new Date(); // 交易日期

    this.openDataService
      .GetBestFeeRate(this.GetFeeRateArgs)
      .subscribe((res) => {
        if (res.StatusCode === 1) {
          return;
        }
        this.GetFeeRateResponse = res.Entries;

        // 取得牌告費率
        const feeRate = this.GetFeeRateResponse.FeeRateList.filter(
          (x) => x.FEE_CHOICE == '1'
        );
        this.RegularSavingPlanModel.FeeRate = feeRate[0].FEE_RATE;

        this.RegularSavingPlanModel.FEE_CHOICE = feeRate[0].FEE_CHOICE;


        this.RegularSavingPlanModel.Fee = Number.parseFloat(
          (
            this.RegularSavingPlanModel.ApplyAmount * feeRate[0].FEE_RATE
          ).toFixed(this.selectedFund.DEC_LEN)
        );

        this.RegularSavingPlanModel.ApplyFee = this.RegularSavingPlanModel.Fee;

        // 取得最優惠的費率
        const bestFeeRate = this.GetFeeRateResponse.FeeRateList.filter(
          (x) => x.FEE_CHOICE != '1'
        );

        if (bestFeeRate != null && bestFeeRate.length > 0) {
          this.RegularSavingPlanModel.BestFeeRate = bestFeeRate[0].FEE_RATE;

          this.RegularSavingPlanModel.BestFee = Number.parseFloat(
            (
              this.RegularSavingPlanModel.ApplyAmount * bestFeeRate[0].FEE_RATE
            ).toFixed(this.selectedFund.DEC_LEN)
          );

          this.RegularSavingPlanModel.ApplyFee =
            this.RegularSavingPlanModel.BestFee;

          this.feeNAME = bestFeeRate[0].FEE_NAME;

          this.RegularSavingPlanModel.CAMPAIGN_CODE =
            bestFeeRate[0].CAMPAIGN_CODE;

          this.RegularSavingPlanModel.FEE_CHOICE = bestFeeRate[0].FEE_CHOICE;

          this.isBestFee = true;
        }
      });
    // }
  }

  clearData(): void {
    this.RegularSavingPlanModel = new RegularSavingPlanModel();
    this.selectedFundValue = '';
  }

  goToStep1(): void {
    this.RegularSavingPlanModel.Step = 1;
  }

  goToStep2(): void {
    if (this.ShowAlert != '') {

    }
    else {

      if (this.selectedFund != null) {
        this.RegularSavingPlanModel.FUND_ID = this.selectedFund.FUND_ID; // 基金代碼
        this.RegularSavingPlanModel.FUND_NAME = this.selectedFund.FUND_NAME; // 基金名稱
        this.RegularSavingPlanModel.FUND_CURRENCY =
          this.selectedFund.FUND_CURRENCY; // 計價幣別
        this.RegularSavingPlanModel.FUND_CURRENCY_NM =
          this.selectedFund.FUND_CURRENCY_NM; // 計價幣別名稱
        this.RegularSavingPlanModel.DIVIDEND_TYPE =
          this.selectedFund.DIVIDEND_TYPE; // 配息方式
        this.RegularSavingPlanModel.DIVIDEND_TYPE_NM =
          this.selectedFund.DIVIDEND_TYPE_NM; // 配息方式名稱
      }

      if (this.RegularSavingPlanModel.ACCOUNT_NO != '') {
        const bucAcc = this.GetBucAccResponse.BucAccList.filter(
          (x) => x.ACCOUNT_NO === this.RegularSavingPlanModel.ACCOUNT_NO // 扣款帳號
        )[0];
        if (bucAcc != null) {
          this.RegularSavingPlanModel.ACCOUNT_NO_SHOW = bucAcc.ACCOUNT_NO_SHOW;
          this.RegularSavingPlanModel.BANK_BRH_SHNM = bucAcc.BANK_BRH_SHNM;
          this.RegularSavingPlanModel.SUB_BANK_CODE = bucAcc.BANK_BRH;
          this.RegularSavingPlanModel.SEAL_TYPE = bucAcc.SEAL_TYPE;
        }
      }

      if (this.RegularSavingPlanModel.ACCOUNT_DIV_NO != '') {
        const divAcc = this.GetDivAccResponse.DivAccList.filter(
          (x) => x.ACCOUNT_NO === this.RegularSavingPlanModel.ACCOUNT_DIV_NO // 收益配息帳號
        )[0];
        if (divAcc != null) {
          this.RegularSavingPlanModel.ACCOUNT_DIV_NO_SHOW = divAcc.ACCOUNT_NO_SHOW;
          this.RegularSavingPlanModel.BANK_BRH_DIV_SHNM = divAcc.BANK_BRH_SHNM;
        }
      }

      this.RegularSavingPlanModel.ApplyTotalAmount =
        this.RegularSavingPlanModel.ApplyFee +
        this.RegularSavingPlanModel.ApplyAmount;

      this.validation();
    }
  }

  goToStep3(): void {
    this.RegularSavingPlanModel.ApplyTime = new Date();
    this.validation2();
  }

  validation(): void {
    this.valid.clear();

    const regex = /^[0-9\s]*$/;

    // if (this.RegularSavingPlanModel.Fee === 0) {
    //   this.valid.addErrorMessage('交易失敗');
    //   this.message.showErrorMSGs(this.valid.errorMessages);
    //   return;
    // }

    if (this.selectedFund == null) {
      this.valid.addErrorMessage('請選擇基金！');
    }

    var tempcheck = parseInt(this.RegularSavingPlanModel.ApplyAmount.toFixed(0))

    if (this.RegularSavingPlanModel.ApplyAmount % tempcheck !== 0) {
      this.valid.addErrorMessage('申購金額請輸入整數！');
    }

    // if (!regex.test(this.RegularSavingPlanModel.ApplyAmount.toString())) {
    //   this.valid.addErrorMessage('申購金額請輸入數字！');
    //   this.valid.addErrorMessage('申購金額請輸入大於零的數字！');
    // }

    if (this.selectedFund.DIVIDEND_TYPE == '2') {
      if (this.RegularSavingPlanModel.ACCOUNT_DIV_NO == '') {
        this.valid.addErrorMessage('請選擇配息帳號');
      }
    }

    if (this.RegularSavingPlanModel.ACCOUNT_NO === '') {
      this.valid.addErrorMessage('請選擇扣款帳號');
    }

    if (this.RegularSavingPlanModel.ApplyTotalAmount > 5000000) {
      this.valid.addErrorMessage('定期定額限額新臺幣伍百萬元(申購金額+手續費)');
    }


    this.verifyService
      .GetRegularSavingPlanVerify(this.RegularSavingPlanModel)
      .subscribe((res) => {
        this.RegularSavingPlanModelVerifyResponse = res.Entries;
        this.RegularSavingPlanModel.CommissionDate = res.Entries.CommissionDate;
        this.RegularSavingPlanModelVerifyResponse.VerifyMsg.forEach(
          (element) => {
            this.valid.addErrorMessage(element);
          }
        );

        if (this.valid.errorMessages.length > 0) {
          this.message.showErrorMSGs(this.valid.errorMessages);
        } else {
          this.RegularSavingPlanModel.Step = 2;
        }
      });
  }

  validation2(): void {
    this.valid.clear();

    if (this.RegularSavingPlanModel.Password === '') {
      this.valid.addErrorMessage('請輸入密碼');
    }
    if (!this.RegularSavingPlanModel.ReadInfo) {
      this.valid.addErrorMessage('請勾選我已閱讀公開說明書及風險預告書');
    }

    this.verifyService
      .GetRegularSavingPlanVerify2(this.RegularSavingPlanModel)
      .subscribe((res) => {
        this.RegularSavingPlanModelVerifyResponse = res.Entries;
        this.RegularSavingPlanModel.CommissionDate = res.Entries.CommissionDate;
        this.RegularSavingPlanModelVerifyResponse.VerifyMsg.forEach(
          (element) => {
            this.valid.addErrorMessage(element);
          }
        );

        if (this.valid.errorMessages.length > 0) {
          this.message.showErrorMSGs(this.valid.errorMessages);
        } else {
          this.RegularSavingPlanModel.Step = 3;
        }
      });
  }

  onClickConceal() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.conceal = 'conceal yincang';
    } else {
      this.passwordType = 'password';
      this.conceal = 'conceal';
    }
  }

  sendtype(value: string) {
    var list = this.GetDivAccResponse.DivAccList;

    for (let i = 0; i < list.length; i++) {
      if (value == list[i].ACCOUNT_NO) {
        this.RegularSavingPlanModel.BANK_BRH_DIV_SHNM = list[i].BANK_BRH_SHNM;
        this.RegularSavingPlanModel.DIV_SUB_BANK_CODE = list[i].BANK_BRH;
        this.RegularSavingPlanModel.cType = list[i].cType;
        this.RegularSavingPlanModel.ACCOUNT_DIV_NO_SHOW = list[i].ACCOUNT_NO_SHOW
      }
    }
  }
}
