import { BaseComponent } from '../base/baseComponent';
import { ChangePswDataArgs, PswTxDataArgs, PageParam } from './../../model/member';
import { Component, OnInit } from '@angular/core';
import { MemberService } from 'src/app/services/member.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { SharedObservable } from '../shared/shared.observable';
import { Router } from '@angular/router';
import { AllowHelper } from 'src/app/helper/allowHelper';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent extends BaseComponent implements OnInit {

  PswTxDataArgs = new PswTxDataArgs();
  ChangePswDataArgs = new ChangePswDataArgs();
  pageparam = new PageParam();

  public showPasswordOnPress: boolean;

  pwdDesc = '無';
  pwdLevel = 0;

  token = '';
  conceal: string = 'conceal';
  passwordType: string = 'password';

  conceal1: string = 'conceal';
  passwordType1: string = 'password';

  conceal2: string = 'conceal';
  passwordType2: string = 'password';

  constructor(
    private _MemberService: MemberService,
    private share: SharedObservable,
    private router: Router,
    protected allow: AllowHelper,
  ) {
    super(allow);
    this.token = localStorage.getItem('token') || "";
    this.ChangePswDataArgs.NEW_PSW = '';
    this.ChangePswDataArgs.OLD_PSW = '';
    this.PswTxDataArgs.PSW = '';
  }

  ngOnInit(): void {
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 10;
    this.pageparam.Page = '變更密碼';

    this._MemberService.WebLog(this.pageparam).subscribe();
  }

  ChangePassword() {
    debugger
    if (this.ChangePswDataArgs.OLD_PSW == '') {
      alert('請輸入您的舊密碼');
    }
    else if (this.ChangePswDataArgs.NEW_PSW == '') {
      alert('您的新密碼未輸入');
    }
    else if (this.PswTxDataArgs.PSW == '' || this.ChangePswDataArgs.NEW_PSW == '' || this.ChangePswDataArgs.NEW_PSW == '') {
      alert('請確實填寫三個欄位');
    }
    else if (this.PswTxDataArgs.PSW != this.ChangePswDataArgs.NEW_PSW) {
      alert('您的新密碼與確認新密碼輸入不一致');
      this.ChangePswDataArgs.NEW_PSW = '';
      this.PswTxDataArgs.PSW = '';
    }
    else if (this.ChangePswDataArgs.OLD_PSW == this.ChangePswDataArgs.NEW_PSW) {
      alert('新密碼不能與舊密碼一樣');
      this.ChangePswDataArgs.NEW_PSW = '';
      this.PswTxDataArgs.PSW = '';
    }
    else if (this.pwdLevel < 2) {
      alert('密碼等級太低');
      this.ChangePswDataArgs.NEW_PSW = '';
      this.PswTxDataArgs.PSW = '';
    }
    else {
      this._MemberService.ChangePassword(this.ChangePswDataArgs).subscribe(res => {
        if (res.StatusCode === EnumStatusCode.Success) {
          alert('密碼已變更，請使用新密碼重新登入');
          // this.router.navigateByUrl('/');
          this.logout();
        }
        else if (res.StatusCode === EnumStatusCode.Fail) {
          alert('密碼變更失敗');
          this.ChangePswDataArgs.NEW_PSW = '';
          this.ChangePswDataArgs.OLD_PSW = '';
          this.PswTxDataArgs.PSW = '';
        }
      });
    }
  }

  logout() {
    this.token = '';
    this.share.SetToken(this.token);
    localStorage.removeItem('token');
    this.router.navigateByUrl('login');
  }


  // CharMode函数
  // 测试某个字符是属于哪一类.
  CharMode(iN: any) {
    if (iN >= 48 && iN <= 57) // 数字
      return 1;
    if (iN >= 65 && iN <= 90) // 大写字母
      return 2;
    if (iN >= 97 && iN <= 122) // 小写
      return 4;
    else
      return 8; // 特殊字符
  }

  // bitTotal函数
  // 计算出当前密码当中一共有多少种模式
  bitTotal(num: any) {
    let modes = 0;
    for (let i = 0; i < 4; i++) {
      if (num & 1) modes++;
      num >>= 1;
    }
    return modes;
  }

  // checkStrong函数
  // 返回密码的强度级别

  checkStrong(sPW: any) {
    // if (sPW.length <= 4)
      // return 0; // 密码太短
    let Modes = 0;
    for (let i = 0; i < sPW.length; i++) {
      // 测试每一个字符的类别并统计一共有多少种模式.
      Modes |= this.CharMode(sPW.charCodeAt(i));
    }
    return this.bitTotal(Modes);
  }

  // pwStrength函数
  // 当用户放开键盘或密码输入框失去焦点时,根据不同的级别显示不同的颜色

  pwStrength(pwd: any) {
    let level = '無';
    if (pwd == null || pwd === '') {
      level = '無';
      this.pwdLevel = 0;
    } else {
      this.pwdLevel = this.checkStrong(pwd);
      switch (this.pwdLevel) {
        case 0:
          level = '無';
          break;
        case 1:
          level = '低';
          break;
        case 2:
          level = '中';
          break;
        default:
          level = '高';
      }
    }
    this.pwdDesc = level;
    return;
  }

  test(value: any) {
    console.log(value);
  }

  onClickConceal(value: number) {

    switch (value) {
      case 1:
        if (this.passwordType == 'password') {
          this.passwordType = 'text';
          this.conceal = 'conceal yincang';
        } else {
          this.passwordType = 'password';
          this.conceal = 'conceal';
        }
        break;
      case 2:
        if (this.passwordType1 == 'password') {
          this.passwordType1 = 'text';
          this.conceal1 = 'conceal yincang';
        } else {
          this.passwordType1 = 'password';
          this.conceal1 = 'conceal';
        }
        break;
      case 3:
        if (this.passwordType2 == 'password') {
          this.passwordType2 = 'text';
          this.conceal2 = 'conceal yincang';
        } else {
          this.passwordType2 = 'password';
          this.conceal2 = 'conceal';
        }
        break;
    }


  }

}
