<div class="Block-content">



  <h3>可取消交易清單</h3>

  <div class="title-02">申購資料</div>
  <div class="ec-area">
    <table class="ec-table">
      <thead>
        <tr>
          <th width="35%">基金名稱</th>
          <th width="25%">交易日期</th>
          <th>金額</th>
          <th>銀行</th>
          <th width="10%">取消交易</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of GetBuyResponse">
          <td data-th="基金名稱">
            <div>{{data?.CFUNDNAME}}</div>
          </td>
          <td data-th="交易日期" align="center">
            <div>{{strsubstr(data.CTRANSFERDATE)}}</div>
          </td>
          <td data-th="金額" align="center">
            <div>{{data?.CBUYAMOUNT| currency}}</div>
          </td>
          <td data-th="銀行" align="center">
            <div>{{data?.cbrank}}</div>
          </td>
          <td data-th="取消交易" align="center">
            <div><button class="text-red"
                (click)="CancellationTrade(1,data.CBUYID,data.CFUNDNAME,data.CFUNDNAME)">取消交易</button></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="title-02">定期定額資料</div>
  <div class="ec-area">
    <table class="ec-table">
      <thead>
        <tr>
          <th width="35%">基金名稱</th>
          <th width="25%">申請時間</th>
          <!--<th>第一次扣款日期</th>-->
          <th>金額</th>
          <th>銀行</th>
          <th width="10%">取消交易</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of GetTBLREGULARLYBUYResponse">
          <td data-th="基金名稱">
            <div>{{data?.CFUNDNAME}}</div>
          </td>
          <td data-th="申請日期" align="center">
            <div>{{data.CDATE}}</div>
          </td>
          <!--<td data-th="第一次扣款日期" align="center"><div>{{data.cTEMP04 != null ?strsubstr(data.cTEMP04):""}}</div></td>-->
          <td data-th="金額" align="center">
            <div>{{data?.CAMOUNT| currency}}</div>
          </td>
          <td data-th="銀行" align="center">
            <div>{{data?.cbrank}}</div>
          </td>
          <td data-th="取消交易" align="center">
            <div><button class="text-red"
                (click)="CancellationTrade(2,data.CREGULARLYBUYID,data.CFUNDNAME,data.CFUNDNAME)">取消交易</button></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="title-02">定期定額契約異動資料</div>
  <div class="ec-area">
    <table class="ec-table">
      <thead>
        <tr>
          <th width="35%">基金名稱</th>
          <th width="25%">申請時間</th>
          <th>異動內容</th>
          <th width="10%">取消交易</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of GetRegularlyTransactionResponse">
          <td data-th="基金名稱"  style="vertical-align: middle;">
            <div>{{data?.CFUNDNAME}}</div>
          </td>
          <td data-th="申請日期" align="center" style="vertical-align: middle;">
            <div>{{data.CDATE}}</div>
          </td>
          <td data-th="異動內容" align="left" style="padding-left:10%;vertical-align: middle;">
            <div *ngIf="data.CCHARGEDATE_Check" style="margin-bottom: 10px;">{{data?.CCHARGEDATE}}</div>
            <div *ngIf="data.CAMOUNT_Check" style="margin-bottom: 10px;">{{data.CAMOUNT == 0 ? '':"扣款金額: "}}{{data.CAMOUNT == 0 ? '':data.CAMOUNT| currency:'TWD'}}</div>
            <div *ngIf="data.CCHARGESTATE_Check" style="margin-bottom: 10px;">{{data.cChargeState}}</div>
            <div *ngIf="data.CSUB_BANK_Check">{{data?.cAccount}}</div>
          </td>
          <td data-th="取消交易" align="center" style="vertical-align: middle;">
            <div style="padding-top: 10%;">
              <button class="text-red"
                (click)="CancellationTrade(3,data.CTRANSACTIONID,data.CFUNDNAME,data.CFUNDNAME)">取消交易
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="title-02">買回資料</div>
  <div class="ec-area">
    <table class="ec-table">
      <thead>
        <tr>
          <th width="35%">基金名稱</th>
          <th width="25%">交易日期</th>
          <th>單位數</th>
          <th>銀行</th>
          <th width="10%">取消交易</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of GetSaleResponse">
          <td data-th="基金名稱">
            <div>{{data?.CFUNDNAME}}</div>
          </td>
          <td data-th="交易日期" align="center">
            <div>{{strsubstr(data.CTRANSFERDATE)}}</div>
          </td>
          <td data-th="單位數" align="center">
            <div>{{data?.CSALECOUNT | number : '0.2-3'}}</div>
          </td>
          <td data-th="銀行" align="center">
            <div>{{data?.CBRANCH}}</div>
          </td>
          <td data-th="取消交易" align="center">
            <div><button class="text-red"
                (click)="CancellationTrade(4,data.CSALEID,data.CFUNDNAME,data.CFUNDNAME)">取消交易</button></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="title-02">轉申購資料</div>
  <div class="ec-area">
    <table class="ec-table">
      <thead>
        <tr>
          <th width="35%">買回基金名稱</th>
          <th>申購基金名稱</th>
          <th>交易日期</th>
          <th>轉申購日期</th>
          <th>單位數</th>
          <th width="10%">取消交易</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of GetTsResponse">
          <td data-th="基金名稱">
            <div>{{data?.OldFund}}</div>
          </td>
          <td data-th="申購基金名稱" align="center">
            <div>{{data?.NewFund}}</div>
          </td>
          <td data-th="交易日期" align="center">
            <div>{{strsubstr(data.CTRANSFERDATE)}}</div>
          </td>
          <td data-th="轉申購日期" align="center">
            <div>{{strsubstr(data.CTSDATE)}}</div>
          </td>
          <td data-th="單位數" align="center">
            <div>{{data?.CTSUNIT | number : '0.2-3'}}</div>
          </td>
          <td data-th="取消交易" align="center">
            <div><button class="text-red"
                (click)="CancellationTrade(5,data.CTSID,data.OldFund,data.NewFund)">取消交易</button></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
