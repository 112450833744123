import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import {
  Color,
  Label,
  monkeyPatchChartJsLegend,
  monkeyPatchChartJsTooltip,
  SingleDataSet,
} from 'ng2-charts';
import { Observable, Subscribable, Subscription } from 'rxjs';
import { PageParam } from 'src/app/model/member';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';

import {
  AllotDataList,
  BalanceSumList,
  CDSCFeeRateList,
  FundDataList,
  GetDashboardInfoParams,
  GetDashboardInfoResponse,
  TABalanceDetailList,
} from 'src/app/model/open-data';
import { ResponseBase } from 'src/app/model/ResponseBase';
import { MemberService } from 'src/app/services/member.service';
import { OpenDataService } from 'src/app/services/open-data.service';

@Component({
  selector: 'app-personal-investment',
  templateUrl: './personal-investment.component.html',
  styleUrls: ['./personal-investment.component.css'],
})
export class PersonalInvestmentComponent implements OnInit {
  FormattedBalDate: string;
  GetDashboardInfoParams = new GetDashboardInfoParams();
  GetDashboardInfoResponse = new GetDashboardInfoResponse();
  SingleSubscription: TABalanceDetailList[]; // 單筆申購基金結餘
  RegularSavingPlan: TABalanceDetailList[]; // 定期定額基金結餘
  BalanceSum: BalanceSumList[]; // 總基金結餘
  FundData: FundDataList[]; // 基金比率清單
  AllotDataList: AllotDataList[]; // 後收型基金明細
  CDSCFeeRateList: CDSCFeeRateList[]; // 後收型基金手續費率設定清單
  IsAllotDataList: boolean = false;

  showFundID = '';

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  public pieChartOptions2: ChartOptions = {
    responsive: true,
  };
  public pieChartLabels: Label[] = [];
  public pieChartLabels2: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartData2: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend: boolean = true;
  public pieChartColors: Color[] = [
    {
      backgroundColor: [
        'rgba(255, 0, 0, 0.1)',
        'rgba(0, 255, 0, 0.1)',
        'rgba(0, 0, 255, 0.1)',
        'rgba(255, 0, 0, 0.2)',
        'rgba(0, 255, 0, 0.2)',
        'rgba(0, 0, 255, 0.2)',
        'rgba(255, 0, 0, 0.3)',
        'rgba(0, 255, 0, 0.3)',
        'rgba(0, 0, 255, 0.3)',
      ],
    },
  ];
  public pieChartPlugins = [];

  pageparam = new PageParam();

  constructor(
    private openDateService: OpenDataService,
    private _MemberService: MemberService
  ) {
    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();
  }

  ngOnInit(): void {
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 19;
    this.pageparam.Page = '個人投資現況';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.FormattedBalDate = this.formattingBalDate(new Date());
        this.setGetDashboardInfoParams();
        this.getDashboardInfo();
      }
      else {
        return
      }
    })
  }

  str(data: number, round: number) {
    // debugger
    return data.toFixed(round);
  }

  private formattingBalDate(d: Date) {
    // const formattedBalDate =
    // date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
    var dd = d.getDate().toString();

    return (
      yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0])
    );
  }

  private setGetDashboardInfoParams() {
    this.GetDashboardInfoParams.BAL_DATE = new Date(this.FormattedBalDate);
  }

  // 取得個人資產配置
  getDashboardInfo() {
    this.openDateService
      .GetDashboardInfo(this.GetDashboardInfoParams)
      .subscribe((response) => {
        this.GetDashboardInfoResponse = response.Entries;
        if (this.GetDashboardInfoResponse != null) {
          if (this.GetDashboardInfoResponse.TABalanceDetailList != null) {
            // 取得單筆申購基金結餘
            this.SingleSubscription =
              this.GetDashboardInfoResponse.TABalanceDetailList.filter(
                (x) => x.TRADE_TYPE == '1'
              );
            // 取得定期定額基金結餘
            this.RegularSavingPlan =
              this.GetDashboardInfoResponse.TABalanceDetailList.filter(
                (x) => x.TRADE_TYPE == '2'
              );
            // 取得
            for (let item of this.SingleSubscription) {
              const holdRule = item.CDSCFeeRateList[0];
              for (let data of item.AllotDataList) {
                let timeStampPrevious = Date.parse(data.ALLOT_DATE.toString());
                let timeStamp = Date.parse(new Date().toString());
                let timeStampDiff = timeStamp - timeStampPrevious;
                let diffDays = timeStampDiff / 1000 / 86400;
                if (diffDays > 36500) {
                  diffDays = 365;
                }
                let diffYears = timeStampDiff / 1000 / 86400 / 365;
                if (diffYears > 100) {
                  diffYears = 1;
                }

                if (holdRule.HoldRule == '1') {
                  const feeRate = item.CDSCFeeRateList.filter(
                    (x) => x.HoldBegDay <= diffDays && diffDays < x.HoldEndDay
                  )[0];
                  data.FEE = feeRate.CDSCFeeRate;
                  // (data.RUNIT * item.NAV_B * feeRate.CDSCFeeRate) / 100; // 最終手續費
                }
                if (holdRule.HoldRule == '3') {
                  const feeRate = item.CDSCFeeRateList.filter(
                    (x) => x.HoldBegDay <= diffYears && diffYears < x.HoldEndDay
                  )[0];
                  data.FEE = feeRate.CDSCFeeRate;
                  // (data.RUNIT * item.NAV_B * feeRate.CDSCFeeRate) / 100; // 最終手續費
                }
              }
            }
            // 取得
            for (let item of this.RegularSavingPlan) {
              const holdRule = item.CDSCFeeRateList[0];
              for (let data of item.AllotDataList) {
                let timeStampPrevious = Date.parse(data.ALLOT_DATE.toString());
                let timeStamp = Date.parse(new Date().toString());
                let timeStampDiff = timeStamp - timeStampPrevious;
                let diffDays = timeStampDiff / 1000 / 86400;
                if (diffDays > 36500) {
                  diffDays = 365;
                }
                let diffYears = timeStampDiff / 1000 / 86400 / 365;
                if (diffYears > 100) {
                  diffYears = 1;
                }

                if (holdRule.HoldRule == '1') {
                  const feeRate = item.CDSCFeeRateList.filter(
                    (x) => x.HoldBegDay <= diffDays && diffDays < x.HoldEndDay
                  )[0];
                  data.FEE =
                    (data.RUNIT * item.NAV_B * feeRate.CDSCFeeRate) / 100; // 最終手續費
                }
                if (holdRule.HoldRule == '3') {
                  const feeRate = item.CDSCFeeRateList.filter(
                    (x) => x.HoldBegDay <= diffYears && diffYears < x.HoldEndDay
                  )[0];
                  data.FEE =
                    (data.RUNIT * item.NAV_B * feeRate.CDSCFeeRate) / 100; // 最終手續費
                }
              }
            }
          }
          if (this.GetDashboardInfoResponse.BalanceSumList != null) {
            // 取得總基金結餘
            this.BalanceSum = this.GetDashboardInfoResponse.BalanceSumList;
            let sum = 0;
            for (let item of this.BalanceSum) {
              sum = sum + item.MARKET_AMT;
            }
            for (let item of this.BalanceSum) {
              item.MARKET_AMT_PERCENT = item.MARKET_AMT / sum;
            }
          }
          if (this.GetDashboardInfoResponse.FundDataList != null) {
            // 取得基金比率清單
            this.FundData = this.GetDashboardInfoResponse.FundDataList;
          }
          this.drawingPieChart();
        }
      });
  }

  // 開始搜尋基金
  searchFunds() {
    if (this.FormattedBalDate == '') {
      this.formattingBalDate(new Date());
    } else {
      const date = new Date(this.FormattedBalDate).toString();
      if (date == 'Invalid Date') {
        this.formattingBalDate(new Date());
      }
    }

    this.GetDashboardInfoParams.BAL_DATE = new Date(this.FormattedBalDate);

    this.getDashboardInfo();
    this.drawingPieChart();
  }

  // 繪出圓餅圖
  drawingPieChart() {
    // 資產配置
    for (let item of this.GetDashboardInfoResponse.drawingPieChart.fund) {
      this.pieChartLabels.push(item.TypeName);
      this.pieChartData.push(item.TypeValue);
    }
    // 基金類型配置
    for (let item of this.GetDashboardInfoResponse.drawingPieChart.cury) {
      this.pieChartLabels2.push(item.TypeName);
      this.pieChartData2.push(item.TypeValue);
    }
  }

  displayAllotDataList(fundId: string, tradeType: string) {
    this.showFundID = fundId;

    this.IsAllotDataList = !this.IsAllotDataList;
    this.AllotDataList =
      this.GetDashboardInfoResponse.TABalanceDetailList.filter(
        (x) => x.FUND_ID == fundId && x.TRADE_TYPE == tradeType
      )[0].AllotDataList;
  }
}
