export class GetRemitAccountArgs {
  CRNCY_CD = '';
}

export class GetRemitAccountResponse {
  RemitAccountList!: RemitAccountList[];
}

export class RemitAccountList {
  BF_SRNO!: string;
  BANK_BRH!: string;
  BANK_BRH_SHNM !: string;
  ACCOUNT_NO !: string;
  ACCOUNT_NO_SHOW !: string;
  ACC_NO_TYPE: string;
}

export class CheckFundRedemptionArgs {
  FUND_ID: string;
  ACCOUNT_NO: string;
  REDEM_UNIT: number;
  REDEM_PAY_DATE: Date;
  TOTAL!: number;
}

export class SaveFundRedemptionArgs {
  FUND_CURRENCY = '';
  FUND_CURRENCY_NM = '';
  FUND_ID: string;
  FUND_NAME: string;
  BANK_BRH!: string;
  BANK_BRH_SHNM !: string;
  ACCOUNT_NO: string;
  REDEM_UNIT: number;
  REDEM_PAY_DATE: Date;
  PASSWORD: string;
  ReadInfo = false; // 已讀相關說明
  TOTAL_SHORT_UNIT: number;
  SHORT_FEE: number;
  TOTAL: number;
  REDEM_NAV_DATE: Date;
}

export class SaveFundRedemptionResponse {
  REDEM_PAY_DATE: string;
  APPLY_DATE: string;

}
