import { Component, OnInit } from '@angular/core';
import { CancellationtradeService } from '../../services/Cancellationtrade.service';
import { CancellationTradeArgs, GetCancellationTradeResponse } from './../../model/cancellationtrade';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { MessageService } from '../../services/message.service';

import { GetBuyResponse, GetTBLREGULARLYBUYResponse, GetRegularlyTransactionResponse, GetSaleResponse, GetTsResponse } from './../../model/cancellationtrade';
import { MemberService } from 'src/app/services/member.service';
import { PageParam } from 'src/app/model/member';
@Component({
  selector: 'app-cancellationtrade',
  templateUrl: './cancellationtrade.component.html',
  styleUrls: ['./cancellationtrade.component.css']
})
export class CancellationtradeComponent implements OnInit {

  CancellationTradeArgs = new CancellationTradeArgs();
  GetCancellationTradeResponse = new GetCancellationTradeResponse();

  GetBuyResponse = [] as GetBuyResponse[];
  GetTBLREGULARLYBUYResponse = [] as GetTBLREGULARLYBUYResponse[];
  GetRegularlyTransactionResponse = [] as GetRegularlyTransactionResponse[];
  GetSaleResponse = [] as GetSaleResponse[];
  GetTsResponse = [] as GetTsResponse[];

  pageparam = new PageParam();

  constructor(
    private message: MessageService,
    private _CancellationtradeService: CancellationtradeService,
    private _MemberService: MemberService
  ) { }

  ngOnInit(): void {

    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 8;
    this.pageparam.Page = '取消交易';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.getList();
      }
      else {
        return
      }
    })
  }

  getList() {
    this._CancellationtradeService.GetList().subscribe(res => {

      this.GetBuyResponse = res.Entries.GetBuyResponse;
      this.GetTBLREGULARLYBUYResponse = res.Entries.GetTBLREGULARLYBUYResponse;
      this.GetRegularlyTransactionResponse = res.Entries.GetRegularlyTransactionResponse;
      this.GetSaleResponse = res.Entries.GetSaleResponse;
      this.GetTsResponse = res.Entries.GetTsResponse;
      //console.log("this.GetBuyResponse",this.GetBuyResponse);
      //console.log("this.GetTBLREGULARLYBUYResponse",this.GetTBLREGULARLYBUYResponse);
      //console.log("this.GetRegularlyTransactionResponse",this.GetRegularlyTransactionResponse);
      //console.log("this.GetSaleResponse",this.GetSaleResponse);
      //console.log("this.GetTsResponse",this.GetTsResponse);
    });
  }

  CancellationTrade(type: number, id: number, CFUNDNAME: string, iCFUNDNAME2d: string) {
    var r = confirm("確定要取消?");
    if (r == true) {
      this.CancellationTradeArgs.Type = type;
      this.CancellationTradeArgs.ID = id;
      this.CancellationTradeArgs.CFUNDNAME = CFUNDNAME;
      this.CancellationTradeArgs.CFUNDNAME2 = iCFUNDNAME2d;
      //console.log("this.CancellationTradeArgs",this.CancellationTradeArgs);
      this._CancellationtradeService.CancellationTrade(this.CancellationTradeArgs).subscribe(res => {
        console.log("res.StatusCode", res.StatusCode);
        if (res.StatusCode === EnumStatusCode.Success) {
          this.message.showSucessMSG("取消成功");
          this.getList();
        }
        else {
          this.message.showSucessMSG(res.Message);
        }

      });
    }
    else {

    }


  }

  strsubstr(v: string) {
    //console.log("strsubstr", v.substr(0, 4)+'/'+ v.substr(4, 2)+'/'+ v.substr(6, 2)+'/');
    return v.substring(0, 4) + '/' + v.substring(4, 6) + '/' + v.substring(6, 8);
  };


}
