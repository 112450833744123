import { Page } from "./page";

export class GetArticleByUnitArgs extends Page{

  cFunctionId : number;
}

export class GetNewsListResponse extends Page{

  data : Data[];
}

export class Data extends Page {
  cFunctionName: string;
  cFunctionId : number;
  cName :string;
  cLinkUrl :string;
  cIsSticky :boolean;
  cShowStartDt :Date;
  cShowEndDt :Date;
}

