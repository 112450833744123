<div class="Block-content">

  <div class="Sign-box-line">
    <div class="Sign-box" style="max-width: 600px;">
      <div class="basic-data-table" style="max-width: 600px;">
        <div class="w-50">
          <div class="basic-left">請輸入你的舊密碼</div>
          <div class="basic-right">
            <input style="max-width: 300px;" [type]="passwordType" [(ngModel)]="ChangePswDataArgs.OLD_PSW"
              placeholder="請輸入舊密碼">
            <div style="float: right;" [class]="conceal" (click)="onClickConceal(1)"></div>
          </div>
        </div>

        <div class="w-50">
          <div class="basic-left">請輸入您的新密碼</div>
          <div class="basic-right" style="padding-bottom: 4px;">
            <input style="max-width: 300px;" [type]="passwordType1" (keydown)="pwStrength(inputvalue.value)"
              (blur)="pwStrength(inputvalue.value)" (ngModelChange)="pwStrength(inputvalue.value)"
              [(ngModel)]="ChangePswDataArgs.NEW_PSW" placeholder="請輸入新密碼" #inputvalue>
            <div style="float: right;" [class]="conceal1" (click)="onClickConceal(2)"></div>
            <div class="basic-right" style="padding-bottom: 0px;padding-left: 0px;">
              <p style="margin-bottom: 5px;">&nbsp;&nbsp;密碼強度：{{pwdDesc}} </p>
              <p *ngIf="pwdLevel == 1 || pwdLevel == 0" style="margin-bottom: 5px;" class="text-red">* 密碼須為數字英文混合</p>
              <p *ngIf="pwdLevel == 1 || pwdLevel == 0" style="margin-bottom: 5px;" class="text-red">* 密碼強度必須為中以上。</p>
            </div>
          </div>
        </div>

        <div class="w-50">
          <div class="basic-left">請再輸入您的新密碼</div>
          <div class="basic-right">
            <input style="max-width: 300px;" [type]="passwordType2" [(ngModel)]="PswTxDataArgs.PSW"
              placeholder="請再輸入一次新密碼">
            <div style="float: right;" [class]="conceal2" (click)="onClickConceal(3)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="button-box">
    <a href="javascript:void(0)" class="Main-button" (click)="ChangePassword()">確認修改<i
        class="fas fa-chevron-right"></i></a>
  </div>

</div>


<div id="modify" class="modal">
  <h3>提示訊息</h3>
  <p style="padding:20px;text-align: center;">您的密碼已經變更完成，請用新密碼重新登入！</p>
  <div class="button-box">
    <a href="#" rel="modal:close" class="Main-button">確認<i class="fas fa-chevron-right"></i></a>
  </div>
</div>
