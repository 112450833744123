import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';

import {SaveTransferSubscriptionResponse,SaveTransferSubscriptionArgs,CheckTransferSubscriptionArgs, GetSwitchFundListResponse, GetRedemFundDataResponse, GetRedemFundDataArgs, GetAllowRedemDataArgs, GetSwitchFundListArgs,GetAllowRedemDataResponse } from '../model/transfersubscription';
@Injectable({
  providedIn: 'root',
})
export class transfersubscriptionService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/TransferSubscription`;
  constructor(
    http: HttpClient
  ) {
    super(http);

  }

  GetRedemFundData(): Observable<ResponseBase<GetRedemFundDataResponse>> {
    const url = `${this.baseUrl}/GetRedemFundData`;
    return this.http.post<ResponseBase<GetRedemFundDataResponse>>(
      url,
      this.httpOptions);
  }

  GetSwitchFundList(request: GetSwitchFundListArgs): Observable<ResponseBase<GetSwitchFundListResponse>> {
    const url = `${this.baseUrl}/GetSwitchFundList`;
    return this.http.post<ResponseBase<GetSwitchFundListResponse>>(
      url,
      request,
      this.httpOptions);
  }

  GetAllowRedemData(request: GetAllowRedemDataArgs): Observable<ResponseBase<GetAllowRedemDataResponse>> {
    const url = `${this.baseUrl}/GetAllowRedemData`;
    return this.http.post<ResponseBase<GetAllowRedemDataResponse>>(
      url,
      request,
      this.httpOptions);
  }

  CheckTransferSubscription(request: CheckTransferSubscriptionArgs): Observable<ResponseBase<GetSwitchFundListResponse>> {
    const url = `${this.baseUrl}/CheckTransferSubscription`;
    return this.http.post<ResponseBase<GetSwitchFundListResponse>>(
      url,
      request,
      this.httpOptions);
  }

  SaveTransferSubscription(request: SaveTransferSubscriptionArgs): Observable<ResponseBase<SaveTransferSubscriptionResponse>> {
    const url = `${this.baseUrl}/SaveTransferSubscription`;
    return this.http.post<ResponseBase<SaveTransferSubscriptionResponse>>(
      url,
      request,
      this.httpOptions);
  }
}


