<style type="text/css">
  .stable td {
    padding: 15px;
    text-align: center;
  }
</style>

<div class="Block-content" *ngIf="pagecontact==1">

  <div class="p-contact-form__step">
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-current">1</div>
      <div class="p-contact-form__step-label">買回設定</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">2</div>
      <div class="p-contact-form__step-label">買回確認</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">3</div>
      <div class="p-contact-form__step-label">買回完成</div>
    </div>
  </div>

  <div class="basic-data-table">
    <div class="w-100">
      <div class="basic-single-bgd">
        <div class="SelectStyle2">
          <select [(ngModel)]="selectedFundValue" (change)="selectFund($event.target,$event)">
            <option value="">-請選擇欲買回基金-</option>
            <option *ngFor="let fund of this.GetRedemFundDataResponse.InventoryList" [value]='fund.FUND_ID'>
              {{fund.FUND_NAME}}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="w-100" *ngIf="isSelected">
      <div class="basic-left">計價幣別</div>
      <div class="basic-right-c4">{{ InventoryList.FUND_CURRENCY_NM}}{{ InventoryList.FUND_CURRENCY
        ==null?'':'('+InventoryList.FUND_CURRENCY+')'}}</div>
      <div class="basic-left">不含息投資報酬率</div>
      <div class="basic-right-c4" style="color:#FF0000;">
        {{ InventoryList.RETURN_RATE==null?'':+InventoryList.RETURN_RATE+'%'}}
      </div>

    </div>
    <div class="w-100" *ngIf="isSelected">
      <div class="basic-left">持有單位數</div>
      <div class="basic-right-c4">{{ InventoryList.BAL_UNIT | number}}</div>
      <div class="basic-left">可買回單位數</div>
      <div class="basic-right-c4">{{ InventoryList.R_UNIT | number}}</div>
    </div>
    <div class="w-100" *ngIf="isSelected">
      <div class="basic-left">憑證單位數</div>
      <div class="basic-right-c4" style="font-size: 16px;">{{ InventoryList.BAL_SCRIP_UNIT | number}}</div>
      <div class="basic-left">預估買回費</div>
      <div class="basic-right-c4">
        <div class="unit-box">
          <span class="r-10">{{ GetAllowRedemDataResponse.REDEM_FEE | number}}</span>
          <span class="r-60">元</span>
        </div>
      </div>
    </div>
    <div class="w-100" *ngIf="isSelected">
      <div class="basic-left">買回單位數</div>
      <div class="basic-right-c4">
        <div class="unit-box">
          <input class="input-25" [(ngModel)]="REDEM_UNIT" type="number" class="input-25" style="width: 80%;"
            (blur)="RedemData()">
          <p>單位</p>
        </div>
      </div>
      <div class="basic-left" style="visibility: hidden;"></div>
      <div class="basic-right-c4">
        <div class="unit-box">

        </div>
      </div>
    </div>
    <div class="w-100" *ngIf="isSelected">
      <table border="0" cellpadding="1" cellspacing="0" class="stable">
        <tbody>
          <tr>
            <th>( </th>
            <th>買回單位數</th>
            <th>X</th>
            <th>預估淨值 </th>
            <th>) -</th>
            <th>預估短線交易費 </th>
            <th>-</th>
            <th>預估買回費 </th>
            <th>=</th>
            <th>預估買回價金</th>
          </tr>
          <tr>
            <td> ( </td>
            <td><span>{{ this.REDEM_UNIT}}</span></td>
            <td>X</td>
            <td><span>{{ GetAllowRedemDataResponse.F_NAV}}</span></td>
            <td> ) -</td>
            <td><span>{{ GetAllowRedemDataResponse.SHORT_FEE | number}}</span></td>
            <td>-</td>
            <td><span>{{ GetAllowRedemDataResponse.REDEM_FEE| number}}</span></td>
            <td>=</td>
            <td><span>{{ GetAllowRedemDataResponse.TOTAL| number}}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="w-100" *ngIf="isSelected">
      <div class="basic-single">
        買回價金直接撥入受益人帳戶：（限受益人本人之帳戶）<br>
        預估淨值:最新淨值 x 折扣率 / 100
      </div>
    </div>
    <div class="w-100" *ngIf="isSelected">
      <div class="basic-left">存入帳號</div>
      <div class="basic-right" style="background-color: #f0eeee;">
        <div *ngIf="selectedFundValue!=''" class="SelectStyle2 w-100">
          <select [(ngModel)]="selectedACCOUNT_NO" (change)="selecRemitAccount($event.target)">
            <option value=''>請選擇存入帳號</option>
            <option *ngFor="let account of GetRemitAccountResponse.RemitAccountList" value="{{account.ACCOUNT_NO}}">
              {{account.BANK_BRH_SHNM}} {{account.ACCOUNT_NO_SHOW}}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div class="button-box basic-data-table-bottom-line">
    <a *ngIf="isSelected" href="#" class="cancel-button">取消</a>
    <a *ngIf="isSelected" href="javascript:void(0)" (click)="save1()" class="Main-button">確認<i
        class="fas fa-chevron-right"></i></a>

  </div>

  <div class="basic-note-div">
    <h1>注意事項：</h1>
    如需取消委託交易，請於交易時間截止前，於【取消交易】中點選該筆交易，超過交易時間該筆委託將無法進行取消。<br>
    凡進行各項交易申請，申請成功後，系統皆會寄發確認信至受益人約定之電子郵件信箱。 委託成功不代表交易成功，請於交易截止時間後，至電子交易平台點選【歷史交易明細】功能，查詢交易是否成功。
    交易委託時間以本系統實際完成收件為準，如委託交易營業日與完成委託營業日不同時，系統無法接受該筆委託，請重新下單。各營業日截止時間前完成之委託視為當日申請之交易；非營業日或逾各基金交易截止時間之委託視為次一營業日之申請。若因重大事件導致基金暫停計價，致使各項交易日順延，實際各項交易日期以「交易確認單」或「投資對帳單」為準。<br>
    受益人之申購價金以外幣支付者，其買回價金及收益分配亦以外幣為之。本國貨幣與外國貨幣因匯款方式不同，外幣匯款之郵匯費用需依各金融機構規定辦理。價金實際入帳金額為扣除買回費用、掛號郵費、匯費及其他必要費用後之金額。<br>
    定時定額全部買回者，若欲同時停止原定時定額契約者，於【異動定時定額契約】申請契約暫停。
    台中銀投信所發行之基金因其受益憑證以無實體方式發行，未開放證券商保管劃撥帳戶功能，故無法提供投資人要求可透過證券商保管劃撥帳戶辦理申購贖回及登載於證券商之保管戶劃撥帳戶之服務且受益人不得申請領回實體受益憑證。<br>
    為避免因受益人短線交易頻繁，造成基金管理及交易成本，增加進而損及基金長期持有之受益人權益，並稀釋基金之權利，故不歡迎受益人進行短線交易。若受益人進行短線交易，經理公司將另洽收買回費用。<br>
    基金受益權單位數之買回限制規定，買回淨值計算日及買回價金給付日請詳閱各基金公開說明書/投資說明書。<br>
    本公司以先進先出方式處理買回單位數。<br>
    當日申購之受益權單位數不得當日提出買回申請。<br>
    受益人同意經理公司得依個人資料保護法及相關法令規定，對其個人資料為搜集、處理、利用或國際傳遞。受益人並同意經理公司基於客戶服務、資訊提供、或為受益人之利益等目的或依法令規定，得將其個人開戶及交易等資料，提供予受經理公司委託辦理相關業務之機構。<br>
    若持有受益憑證， 應先將憑證送達經理公司方可辦理買回。 (如為封閉式基金之受益憑證應於背面受讓人處加蓋原留印鑑及檢附交付清單、轉讓過戶申請書。)<br>
    本公司基金經金管會核准或同意生效，或經金管會申報備查，惟不表示基金絕無風險。本公司以往之經理績效，不保證本基金之最低投資效益；本公司除盡善良管理人之注意義務外，不負責本基金之盈虧，亦不保證最低之收益，投資人申購前應詳閱本基金公開/投資說明書。公開說明書備索，或至本公司網址(<a
      href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>)，或至公開資訊觀測站<a
      href="http://newmops.tse.com.tw">http://newmops.tse.com.tw</a>查詢下載。<br>
  </div>

</div>

<div class="Block-content" *ngIf="pagecontact==2 ">

  <div class="p-contact-form__step">
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">1</div>
      <div class="p-contact-form__step-label">買回設定</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-current">2</div>
      <div class="p-contact-form__step-label">買回確認</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">3</div>
      <div class="p-contact-form__step-label">買回完成</div>
    </div>
  </div>

  <div class="basic-data-table ">

    <div class="w-100">
      <div class="basic-left">買回基金</div>
      <div class="basic-right">{{ InventoryList.FUND_NAME}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">買回計價幣別</div>
      <div class="basic-right">{{ InventoryList.FUND_CURRENCY_NM}}{{ InventoryList.FUND_CURRENCY
        ==null?'':'('+InventoryList.FUND_CURRENCY+')'}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">買回單位數</div>
      <div class="basic-right">{{REDEM_UNIT | number}}單位</div>
    </div>
    <div class="w-100">
      <div class="basic-left">預估買回價金
      </div>
      <div class="basic-right">{{GetAllowRedemDataResponse.TOTAL | number}}元</div>
    </div>
    <div class="w-100">
      <div class="basic-left">存入帳號</div>
      <div class="basic-right">{{this.RemitAccountList.BANK_BRH_SHNM}} {{this.RemitAccountList.ACCOUNT_NO_SHOW}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">預估買回費
      </div>
      <div class="basic-right">
        {{ GetAllowRedemDataResponse.REDEM_FEE | number}}元
      </div>
    </div>
  </div>

  <div class="basic-data-table transaction-password">
    <div class="w-100">
      <div class="basic-left">交易密碼</div>
      <div class="basic-right" *ngIf="selectedFundValue!=''">
        <input [(ngModel)]="PASSWORD" style="float:left" [type]="passwordType" id="password" class="input-25">

        <div [class]="conceal" (click)="onClickConceal()"></div>
      </div>
    </div>
  </div>

  <div class="i-have-read">
    <a href="{{InventoryList.OfficialManualPath}}" target="_blank"><i class="fas fa-file-pdf"></i> 公開說明書</a>
    <a href="{{InventoryList.RiskForecastPath}}" target="_blank"><i class="fas fa-file-pdf"></i> 風險預告書</a>
    <div class="checkbox-wrap">
      <input type="checkbox" [(ngModel)]="SaveFundRedemptionArgs.ReadInfo" value="guangpan" name="investment" id="f1">
      <label for="f1"><samp>我已閱讀公開說明書 風險預告書</samp></label>
    </div>
  </div>



  <div class="button-box">
    <a href="fundredemption" class="cancel-button">取消</a>
    <a href="javascript:void(0)" (click)="save2()" class="Main-button">確認<i class="fas fa-chevron-right"></i></a>
  </div>

</div>

<div class="Block-content" *ngIf="pagecontact==3">

  <div class="p-contact-form__step">
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-finish">1</div>
      <div class="p-contact-form__step-label">買回設定</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-finish">2</div>
      <div class="p-contact-form__step-label">買回確認</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-current">3</div>
      <div class="p-contact-form__step-label">買回完成</div>
    </div>
  </div>

  <div class="Details">
    <div class="w-60">
      <p class="txt">
        <i class="fas fa-check-circle"></i> 買回
        <span class="txt-red">{{InventoryList.FUND_NAME}}</span> 完成！
      </p>
    </div>
    <div class="w-60">
      <table border="0" cellpadding="1" cellspacing="0" class="ptable">
        <tbody>
          <tr>
            <th>委託確認時間</th>
            <th>買回淨值日</th>
            <th>付款日</th>
          </tr>
          <tr>
            <td>{{ SaveFundRedemptionResponse.APPLY_DATE}}</td>
            <td>{{ GetAllowRedemDataResponse.REDEM_NAV_DATE | date: "yyyy/MM/dd"}}</td>
            <td>{{ SaveFundRedemptionResponse.REDEM_PAY_DATE}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="button-box">
    <a href="fundredemption" class="Main-button">回買回頁<i class="fas fa-chevron-right"></i></a>
  </div>

</div>
