import { MemberService } from 'src/app/services/member.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { PetternHelper } from 'src/app/helper/petternHelper';
import { ValidationHelper } from 'src/app/helper/validationHelper';
import { PageParam } from 'src/app/model/member';
import { GetDashboardInfoArgs, GetDashboardInfoResponse, TABalanceDetailList } from 'src/app/model/my-account';
import { Data, GetArticleByUnitArgs, GetNewsListResponse } from 'src/app/model/News';
import { MessageService } from 'src/app/services/message.service';
import { MyAccountService } from 'src/app/services/my-account.service';
import { NewsService } from 'src/app/services/News.service';
import { BaseComponent } from '../base/baseComponent';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit {
  GetArticleByUnitArgs = new GetArticleByUnitArgs();
  GetNewsListResponse = new GetNewsListResponse();

  GetDashboardInfoArgs = new GetDashboardInfoArgs();
  GetDashboardInfoResponse = new GetDashboardInfoResponse();
  SingleDetail = [] as TABalanceDetailList[];
  RegularQuotaDetail = [] as TABalanceDetailList[];

  NewsListData = [] as Data[];
  FormattedBalDate: string;

  pageparam = new PageParam();

  constructor(
    private _NewsService: NewsService,
    private _MyAccountService: MyAccountService,
    private _MemberService: MemberService,
    //private dialogService: NbDialogService,
    //private share: SharedObservable,
    //private _CardSNService: CardSNService,
    private message: MessageService,
    private valid: ValidationHelper,
    protected allow: AllowHelper,
    private router: Router,
    private pettern: PetternHelper,
    //private baseService: SharedService

  ) {
    super(allow);


  }
  ngOnInit(): void {
    this.Weblog();
  }

  private formattingBalDate(d: Date) {
    // const formattedBalDate =
    //   date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
    var dd = d.getDate().toString();

    return (
      yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0])
    );

    // return formattedBalDate;
  }

  private setGetDashboardInfoParams() {
    this.GetDashboardInfoArgs.BAL_DATE = new Date(this.FormattedBalDate);
  }

  GetNewsByLogin(cFunctionId: number) {
    this.GetArticleByUnitArgs.cFunctionId = cFunctionId;
    this._NewsService.GetNewsByLogin(this.GetArticleByUnitArgs).subscribe(res => {
      this.NewsListData = res.Entries.data
      this.totalRecords = res.TotalItems;
    });
  }

  GetDashboardInfo() {
    this._MyAccountService.GetDashboardInfo(this.GetDashboardInfoArgs).subscribe(res => {
      this.GetDashboardInfoResponse = res.Entries
      this.SingleDetail = this.GetDashboardInfoResponse.TABalanceDetailList.filter(detail => detail.TRADE_TYPE == "1");
      this.RegularQuotaDetail = this.GetDashboardInfoResponse.TABalanceDetailList.filter(detail => detail.TRADE_TYPE == "2");
    });
  }

  Weblog() {
    this.pageparam.Value = 0;
    this.pageparam.Page = '主頁';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.FormattedBalDate = this.formattingBalDate(new Date());
        this.setGetDashboardInfoParams();
        this.GetNewsByLogin(1707);
        this.GetDashboardInfo();
      }
      else {
        return
      }
    })
  }

}
