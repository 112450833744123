import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { OpenDataService } from 'src/app/services/open-data.service';
import { GetFundListArgs, GetFundListResponse, GetFundWarningListArgs, GetFundWarningListResponse, GetFundLockData, FundLockData, GetDashboardInfoParams, GetDashboardInfoResponse, BalanceSumList, SaveFundWarningArgs } from 'src/app/model/open-data';
import { JwtPayload } from 'src/app/model/base';
import jwt_decode from 'jwt-decode';
import { ValidationHelper } from 'src/app/helper/validationHelper';
import { MessageService } from 'src/app/services/message.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { MemberService } from 'src/app/services/member.service';
import { PageParam } from 'src/app/model/member';
// import {MatDialog} from '@angular/material/dialog';
declare var jquery: any; // 這邊用 var
declare let $: any; // 當然 let 也可以
@Component({
  selector: 'app-personalization-lock-settings',
  templateUrl: './personalization-lock-settings.component.html',
  styleUrls: ['./personalization-lock-settings.component.css']
})
export class PersonalizationLockSettingsComponent implements OnInit {
  GetFundListArgs = new GetFundListArgs();
  GetFundListResponse = new GetFundListResponse();
  GetFundWarningListArgs = new GetFundWarningListArgs();
  GetFundWarningListResponse = new GetFundWarningListResponse();
  GetFundLockData = new GetFundLockData();
  ThisFund: FundLockData = {
    IS_BALANCE: false, // 是否庫存
    FUND_ID: '',
    FUND_NAME: '',
    FUND_RISK_NM: '',
    NAV_DATE: '',
    NAV_B: 0,
    BAL_UNIT: 0,
    BAL_COST: 0,
    MAX_VAL: '',
    MIN_VAL: '',
    INCREASE: '',
    DECLINE: '',
    AVG_COST: 0, // 平均成本
    RETURN_RATE: 0, // 報酬率(不含息)
    NAV_DEC: 0,
    UNIT_DEC: 0,
    DEC_LEN: 0,
  };//設定頁的基金
  FundShowList: FundLockData[];//顯示在畫面的基金
  FundLockList: FundLockData[];//全部基金
  FundLockBalanceList: FundLockData[];//庫存基金
  FundLockNonBalanceList: FundLockData[];//非庫存基金
  GetDashboardInfoParams = new GetDashboardInfoParams();
  GetDashboardInfoResponse = new GetDashboardInfoResponse();
  SaveFundWarningArgs = new SaveFundWarningArgs();
  private setUpDialog: any;
  jwt = {} as JwtPayload;
  pageparam = new PageParam();

  constructor(
    private dialogService: NbDialogService,
    private openDataService: OpenDataService,
    private valid: ValidationHelper,
    private message: MessageService,
    private _MemberService: MemberService
  ) { }

  ngOnInit(): void {
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 17;
    this.pageparam.Page = '個人化鎖利設定';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.getFundList();
        this.jwt = jwt_decode(localStorage.getItem('token') || '');
      }
      else {
        return
      }
    })
  }

  str(data: number, round: number) {
    // debugger
    return data.toFixed(round);
  }

  // onEdit(fund : FundLockData, dialog:TemplateRef<any>) {
  onEdit(fund: FundLockData) {
    this.setUpDialog = $('#set-up')[0];
    debugger;
    this.ThisFund = fund;
    this.showSetUpDialog();
    // this.dialogService.open(dialog);
    // this.dialogService.open(DialogPersonalizationLockSettings);
  }
  showSetUpDialog() {
    // let dia = $('#set-up')[0];
    // dia.showModal();
    if (typeof this.setUpDialog.showModal === "function") {
      this.setUpDialog.showModal();
    }
    else {
      alert("The <dialog> API is not supported by this browser");
      // this.setUpDialog.show();
    }
  }
  closeSetUpDialog() {
    this.setUpDialog.close();
  }

  // 取得可申購基金
  getFundList() {
    this.GetFundListArgs.FUND_ID = 'ALL';
    this.GetFundListArgs.FUND_TYPE = 0;
    this.openDataService.GetFundList(this.GetFundListArgs).subscribe((res) => {
      this.GetFundListResponse = res.Entries;
      this.getFundWarningList();
    });
  }
  // 取得鎖利基金設定
  getFundWarningList() {
    this.GetFundWarningListArgs.FUND_ID = 'ALL';
    this.openDataService.GetFundWarningList(this.GetFundWarningListArgs).subscribe((res) => {
      this.GetFundWarningListResponse = res.Entries;
      this.getFuncLockData();
      this.getFundBalanceList();
    });
  }
  //組合基金資訊與鎖利設定
  getFuncLockData() {
    // debugger;
    this.FundLockList = [];
    this.GetFundListResponse.FundList.forEach(data => {
      let fundLockData = new FundLockData();
      fundLockData.IS_BALANCE = false;
      fundLockData.FUND_ID = data.FUND_ID;
      fundLockData.FUND_NAME = data.FUND_NAME;
      fundLockData.FUND_RISK_NM = data.FUND_RISK_NM;
      fundLockData.NAV_DATE = data.NAV_DATE.toString().split('T')[0];
      fundLockData.NAV_B = data.NAV_B;
      let fundWarningData = this.GetFundWarningListResponse.FundWarningList.filter(x => x.FUND_ID === data.FUND_ID)[0];
      if (fundWarningData) {
        fundLockData.MAX_VAL = fundWarningData.MAX_VAL ? fundWarningData.MAX_VAL.toString() : '--';
        fundLockData.MIN_VAL = fundWarningData.MIN_VAL ? fundWarningData.MIN_VAL.toString() : '--';
        fundLockData.INCREASE = fundWarningData.INCREASE ? fundWarningData.INCREASE.toString() : '--';
        fundLockData.DECLINE = fundWarningData.DECLINE ? fundWarningData.DECLINE.toString() : '--';
      }
      else {
        fundLockData.MAX_VAL = '--';
        fundLockData.MIN_VAL = '--';
        fundLockData.INCREASE = '--';
        fundLockData.DECLINE = '--';
      }
      this.FundLockList.push(fundLockData);
    });
  }
  //分拆庫存/非庫存基金庫存基金
  getFundBalanceList() {
    this.FundLockBalanceList = [];
    this.FundLockNonBalanceList = this.FundLockList;
    this.GetDashboardInfoParams.BAL_DATE = new Date();
    // debugger;
    this.openDataService
      .GetDashboardInfo(this.GetDashboardInfoParams)
      .subscribe((response) => {
        this.GetDashboardInfoResponse = response.Entries;
        if (this.GetDashboardInfoResponse != null) {
          if (this.GetDashboardInfoResponse.BalanceSumList != null) {
            this.FundLockNonBalanceList = [];
            this.FundLockList.forEach(data => {
              let thisBalanceFund = this.GetDashboardInfoResponse.BalanceSumList.filter(x => x.FUND_ID === data.FUND_ID);
              if (thisBalanceFund && thisBalanceFund.length > 0) {
                // debugger
                data.AVG_COST = thisBalanceFund[0].AVG_COST;
                data.RETURN_RATE = thisBalanceFund[0].RETURN_RATE;
                data.IS_BALANCE = true;
                data.BAL_UNIT = thisBalanceFund[0].BAL_UNIT;
                data.BAL_COST = thisBalanceFund[0].BAL_COST;
                data.NAV_DEC = thisBalanceFund[0].NAV_DEC
                data.UNIT_DEC = thisBalanceFund[0].UNIT_DEC;
                data.DEC_LEN = thisBalanceFund[0].DEC_LEN;
                this.FundLockBalanceList.push(data);
              }
              else {
                this.FundLockNonBalanceList.push(data);
              }
            });
          }
        }
      });
    this.FundShowList = this.FundLockList;
    // this.ThisFund = this.FundShowList[0];
  }
  //選擇庫存/非庫存
  selectFundType(e: any) {
    switch (e.value) {
      case '0':
        this.FundShowList = this.FundLockList;
        break;
      case '1':
        this.FundShowList = this.FundLockBalanceList;
        break;
      case '2':
        this.FundShowList = this.FundLockNonBalanceList;
        break;
    }
  }

  saveFundWarning() {
    this.valid.clear();
    if (this.ThisFund.MAX_VAL.replace('--', '') == '') {
      this.valid.addErrorMessage('請輸入賣出提醒');
    }
    if (this.ThisFund.MIN_VAL.replace('--', '') == '') {
      this.valid.addErrorMessage('請輸入買進提醒');
    }
    if (this.ThisFund.IS_BALANCE && this.ThisFund.INCREASE.replace('--', '') == '') {
      this.valid.addErrorMessage('請輸入停利報酬率');
    }
    if (this.ThisFund.IS_BALANCE && this.ThisFund.DECLINE.replace('--', '') == '') {
      this.valid.addErrorMessage('請輸入停損報酬率');
    }

    if (this.valid.errorMessages.length > 0) {
      this.message.showErrorMSGs(this.valid.errorMessages);
    }
    else {
      this.SaveFundWarningArgs.FUND_ID = this.ThisFund.FUND_ID;
      this.SaveFundWarningArgs.MAX_VAL = this.ThisFund.MAX_VAL == '--' ? 0 : +this.ThisFund.MAX_VAL;
      this.SaveFundWarningArgs.MIN_VAL = this.ThisFund.MIN_VAL == '--' ? 0 : +this.ThisFund.MIN_VAL;
      this.SaveFundWarningArgs.INCREASE = this.ThisFund.INCREASE == '--' ? 0 : +this.ThisFund.INCREASE;
      this.SaveFundWarningArgs.DECLINE = this.ThisFund.DECLINE == '--' ? 0 : +this.ThisFund.DECLINE;

      this.openDataService.SaveFundWarning(this.SaveFundWarningArgs).subscribe(res => {
        if (res.StatusCode === EnumStatusCode.Success) {
          this.message.showSucessMSG('儲存成功');
          this.closeSetUpDialog();
        }
        else {
          this.message.showSucessMSG(res.Message);
        }
      });
    }
  }

}

@Component({
  selector: 'app-personalization-lock-settings-dialog',
  templateUrl: './personalization-lock-settings.dialog.html',
})
export class DialogPersonalizationLockSettings {

}
