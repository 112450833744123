import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  showErrorMSG(content: string, canDuplicates: boolean = false) {
    alert(content);
  }
  showSucessMSG(content: string, canDuplicates: boolean = false) {
    alert(content);
  }
  showErrorMSGs(content: string[], canDuplicates: boolean = false) {
    alert(content.map(x => x).join('\n'));
  }
  constructor() {

  }
}
