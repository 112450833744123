<div class="Block-content">
  <div class="calendar-box">
    <samp>查詢項目</samp>
    <div class="SelectStyle2">
      <select [(ngModel)]="GetHistoryDataParams.TRADE_TYPE">
        <option value="0">全部</option>
        <option selected="selected" value="1">單筆申購</option>
        <option value="2">買回交易</option>
        <option value="3">轉申購交易</option>
        <option value="4">定期定額</option>
        <option value="5">收益分配</option>
      </select>
    </div>
    <samp>查詢基金</samp>
    <div class="SelectStyle2">
      <select [(ngModel)]="GetHistoryDataParams.FUND_ID">
        <option selected="selected" value="ALL">全部</option>
        <option *ngFor="let item of AllFunds" [value]="item.FUND_ID">{{ item.FUND_NAME }}</option>
      </select>
    </div>
  </div>
  <div class="calendar-box">
    <samp>開始年月日</samp>
    <input type="date" class="calendar" style="position: relative" [(ngModel)]="FormattedBeginDate"
      [value]="FormattedBeginDate" />
    <!-- <input
      type="text"
      class="calendar datepicker"
      [(ngModel)]="FormattedBeginDate"
      value="{{ FormattedBeginDate }}"
    /> -->
    <samp>截止年月日</samp>
    <input type="date" class="calendar" style="position: relative" [(ngModel)]="FormattedEndDate"
      [value]="FormattedEndDate" />
    <!-- <input
      type="text"
      class="calendar datepicker"
      [(ngModel)]="FormattedEndDate"
      value="{{ FormattedEndDate }}"
    /> -->
    <a href="javascript:void(0)" class="Main-button" (click)="searchFunds()">開始搜尋<i
        class="fas fa-chevron-right"></i></a>
  </div>

  <div class="title-02" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '1' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    單筆申購基金明細
  </div>
  <div class="fundtable" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '1' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    <div class="tr">
      <div class="th text-left" style="width: 16%">基金名稱</div>
      <div class="th" style="width: 6%">幣別</div>
      <div class="th" style="width: 9%">交易通路</div>
      <div class="th" style="width: 10%">交易日期</div>
      <div class="th" style="width: 10%">可買回日</div>
      <div class="th" style="width: 10%">交易單位數</div>
      <div class="th" style="width: 8%">申購淨值</div>
      <div class="th" style="width: 8%">申購金額</div>
      <div class="th" style="width: 7%">手續費</div>
      <div class="th" style="width: 9%">交易總金額</div>
      <div class="th" style="width: 7%">狀態</div>
    </div>
    <div class="tr fundtable-tab" *ngFor="let item of SingleSubscription">
      <div data-th="基金名稱" class="td text-left" style="width: 16%">
        {{ item.FUND_NAME }}
      </div>
      <div data-th="幣別" class="td" style="width: 6%">{{ item.CRNCY_NM }}</div>
      <div data-th="交易通路" class="td" style="width: 9%">
        {{ item.SYSTEM_NM }}
      </div>
      <div data-th="交易日期" class="td" style="width: 10%">
        {{ item.ALLOT_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="可買回日" class="td" style="width: 10%">
        {{ item.CAN_REDEM_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="交易單位數" class="td" style="width: 10%">
        {{ item.ALLOT_UNIT | number :'1.0-4'}}
      </div>
      <div data-th="當日淨值" class="td" style="width: 8%">
        {{ item.NAV_B | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="申購金額" class="td" style="width: 8%">
        {{ item.ALLOT_AMT | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="手續費" class="td" style="width: 7%">
        {{ item.ALLOT_FEE | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="交易總金額" class="td" style="width: 9%">
        {{ item.SUM_AMT | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="狀態" class="td" style="width: 7%">
        {{ item.TRADE_STATUS_NM }}
      </div>
      <div class="account-number text-left">
        <samp class="Details-hide-web">扣款帳號：</samp>{{ item.SUB_BANK_BRH_NM }}
        {{ item.SUB_ACCOUNT_NO }}
      </div>
    </div>
  </div>

  <div class="title-02" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '2' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    買回基金明細
  </div>
  <div class="fundtable" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '2' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    <div class="tr">
      <div class="th text-left" style="width: 15%">基金名稱</div>
      <div class="th" style="width: 6%">幣別</div>
      <div class="th" style="width: 8%">交易通路</div>
      <div class="th" style="width: 10%">交易日期</div>
      <div class="th" style="width: 9%">交易單位數</div>
      <div class="th" style="width: 7%">交易金額</div>
      <div class="th" style="width: 8%">淨值日</div>
      <div class="th" style="width: 8%">買回淨值</div>
      <div class="th" style="width: 7%">投資成本</div>
      <div class="th" style="width: 7%">損益金額</div>
      <div class="th" style="width: 8%">投資報酬率</div>
      <div class="th" style="width: 7%">狀態</div>
    </div>
    <div class="tr fundtable-tab" *ngFor="let item of Redemption">
      <div data-th="基金名稱" class="td text-left" style="width: 15%">
        {{ item.FUND_NAME }}
      </div>
      <div data-th="幣別" class="td" style="width: 6%">{{ item.CRNCY_NM }}</div>
      <div data-th="交易通路" class="td" style="width: 8%">
        {{ item.SYSTEM_NM }}
      </div>
      <div data-th="交易日期" class="td" style="width: 10%">
        {{ item.REDEM_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="交易單位數" class="td" style="width: 9%">
        {{ item.REDEM_UNIT | number :'1.0-4'}}
      </div>
      <div data-th="交易金額" class="td" style="width: 7%">
        {{ item.RTOT_AMT | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="淨值日" class="td" style="width: 8%">
        {{ item.REDEM_NAV_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="當日淨值" class="td" style="width: 8%">
        {{ item.NAV_B | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="投資成本" class="td" style="width: 7%">
        {{ item.REDEM_COST | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="損益金額" class="td" style="width: 7%">
        {{ item.UNRL_AMT | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="投資報酬率" class="td" style="width: 8%">
        {{ item.RETURN_RATE | number :'1.0-2'}}%
      </div>
      <div data-th="狀態" class="td" style="width: 7%">
        {{ item.TRADE_STATUS_NM }}
      </div>
      <div class="account-number text-left">
        <samp class="Details-hide-web">扣款帳號：</samp>{{ item.BANK_BRH_NM }}
        {{ item.REMIT_ACC_NO }}
      </div>
    </div>
  </div>

  <div class="title-02" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '3' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    轉申購基金明細
  </div>
  <div class="fundtable" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '3' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    <div class="tr">
      <div class="th text-left" style="width: 16%">基金名稱</div>
      <div class="th" style="width: 6%">幣別</div>
      <div class="th" style="width: 9%">交易通路</div>
      <div class="th" style="width: 10%">交易日期</div>
      <div class="th" style="width: 10%">交易單位數</div>
      <div class="th" style="width: 10%">交易金額</div>
      <div class="th" style="width: 8%">當日淨值</div>
      <div class="th" style="width: 8%">投資成本</div>
      <div class="th" style="width: 7%">損益金額</div>
      <div class="th" style="width: 9%">投資報酬率</div>
      <div class="th" style="width: 7%">狀態</div>
    </div>
    <div class="tr fundtable-tab" *ngFor="let item of Switch">
      <div data-th="基金名稱" class="td text-left" style="width: 16%">
        {{ item.FUND_NAME }}
      </div>
      <div data-th="幣別" class="td" style="width: 6%">{{ item.CRNCY_NM }}</div>
      <div data-th="交易通路" class="td" style="width: 9%">
        {{ item.SYSTEM_NM }}
      </div>
      <div data-th="交易日期" class="td" style="width: 10%">
        {{ item.REDEM_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="交易單位數" class="td" style="width: 10%">
        {{ item.REDEM_UNIT | number : '1.0-4' }}
      </div>
      <div data-th="交易金額" class="td" style="width: 10%">
        {{ item.RTOT_AMT | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="當日淨值" class="td" style="width: 8%">
        {{ item.NAV_B | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="投資成本" class="td" style="width: 8%">
        {{ item.REDEM_COST | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="損益金額" class="td" style="width: 7%">
        {{ item.UNRL_AMT | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="投資報酬率" class="td" style="width: 9%">
        {{ item.RETURN_RATE | number :'1.0-2'}}%
      </div>
      <div data-th="狀態" class="td" style="width: 7%">
        {{ item.TRADE_STATUS_NM }}
      </div>
      <div data-th="轉申購手續費率%" class="account-number td text-left">
        <samp class="Details-hide-web">轉申購手續費率%：</samp>
        {{ item.SWITCH_FEE_R }}%
      </div>
      <div data-th="轉申購基金名稱" class="account-number td text-left">
        <samp class="Details-hide-web">轉申購基金名稱：</samp>
        {{ item.SWITCH_FUND_NAME }}
      </div>
      <!-- <samp class="Details-hide-web">扣款帳號：</samp>{{ item.SUB_BANK_BRH_NM }}
        {{ item.SUB_ACCOUNT_NO }} -->
      <!-- </div> -->
    </div>
  </div>

  <div class="title-02" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '4' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    定期定額明細
  </div>
  <div class="fundtable" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '4' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    <div class="tr">
      <div class="th text-left" style="width: 16%">基金名稱</div>
      <div class="th" style="width: 6%">幣別</div>
      <div class="th" style="width: 9%">交易通路</div>
      <div class="th" style="width: 10%">交易日期</div>
      <div class="th" style="width: 10%">可買回日</div>
      <div class="th" style="width: 10%">交易單位數</div>
      <div class="th" style="width: 8%">申購淨值</div>
      <div class="th" style="width: 8%">申購金額</div>
      <div class="th" style="width: 7%">手續費</div>
      <div class="th" style="width: 9%">交易總金額</div>
      <div class="th" style="width: 7%">狀態</div>
    </div>
    <div class="tr fundtable-tab" *ngFor="let item of RegularSavingPlan">
      <div data-th="基金名稱" class="td text-left" style="width: 16%">
        {{ item.FUND_NAME }}
      </div>
      <div data-th="幣別" class="td" style="width: 6%">{{ item.CRNCY_NM }}</div>
      <div data-th="交易通路" class="td" style="width: 9%">
        {{ item.SYSTEM_NM }}
      </div>
      <div data-th="交易日期" class="td" style="width: 10%">
        {{ item.ALLOT_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="可買回日" class="td" style="width: 10%">
        {{ item.CAN_REDEM_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="交易單位數" class="td" style="width: 10%">
        {{ item.ALLOT_UNIT | number :'1.0-4'}}
      </div>
      <div data-th="當日淨值" class="td" style="width: 8%">
        {{ item.NAV_B | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="申購金額" class="td" style="width: 8%">
        {{ item.ALLOT_AMT | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="手續費" class="td" style="width: 7%">
        {{ item.ALLOT_FEE | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="交易總金額" class="td" style="width: 9%">
        {{ item.SUM_AMT | currency :'$':'symbol': '1.0-4'}}
      </div>
      <div data-th="狀態" class="td" style="width: 7%">
        {{ item.TRADE_STATUS_NM }}
      </div>
      <div class="account-number text-left">
        <samp class="Details-hide-web">扣款帳號：</samp>{{ item.SUB_BANK_BRH_NM }}
        {{ item.SUB_ACCOUNT_NO }}
      </div>
    </div>
  </div>

  <div class="title-02" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '5' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    收益配息明細
  </div>
  <div class="fundtable" *ngIf="
      GetHistoryDataParams.TRADE_TYPE == '5' ||
      GetHistoryDataParams.TRADE_TYPE == '0'
    ">
    <div class="tr">
      <div class="th" style="width: 10%">分配方式</div>
      <div class="th text-left" style="width: 20%">基金名稱</div>
      <div class="th" style="width: 8%">幣別</div>
      <div class="th" style="width: 10%">配息基準日</div>
      <div class="th" style="width: 10%">發放日期</div>
      <div class="th" style="width: 12%">參與配息單位數</div>
      <div class="th" style="width: 10%">分配金額</div>
      <div class="th" style="width: 10%">相關費用</div>
      <div class="th" style="width: 10%">入帳配息金</div>
    </div>
    <div class="tr fundtable-tab" *ngFor="let item of Dividend">
      <div data-th="分配方式" class="td" style="width: 10%">
        {{ item.GET_WAY }}
      </div>
      <div data-th="基金名稱" class="td text-left" style="width: 20%">
        {{ item.FUND_NAME }}
      </div>
      <div data-th="幣別" class="td" style="width: 8%">{{ item.CRNCY_NM }}</div>
      <div data-th="配息基準日" class="td" style="width: 10%">
        {{ item.BASE_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="發放日期" class="td" style="width: 10%">
        {{ item.PROVIDE_DATE | date: "yyyy/MM/dd" }}
      </div>
      <div data-th="參與配息單位數" class="td" style="width: 12%">
        {{ item.BAL_UNIT | number :'1.0-4'}}
      </div>
      <div data-th="分配金額" class="td" style="width: 10%">
        {{ item.TOTAL_ASSIGN_AMT | number :'1.0-4'}}
      </div>
      <div data-th="相關費用" class="td" style="width: 10%">
        {{ item.REMIT_FEE | number :'1.0-4'}}
      </div>
      <div data-th="入帳配息金" class="td" style="width: 10%">
        {{ item.TOTAL_PAY_AMT | number :'1.0-4'}}
      </div>
      <div class="account-number2 text-left">
        預計{{ item.PROVIDE_DATE | date: "yyyy/MM/dd" }}匯款至
        {{ item.BANK_BRH_NM }}{{ item.REMIT_ACC_NO }}
      </div>
      <div class="qa" data-aos="fade-up">
        <ul>
          <li>
            <button class="accordion">
              <div style="width: 8%">{{ item.GET_WAY }}</div>
              <div style="width: 21%">
                {{ item.FUND_NAME }}
              </div>
              <div style="width: 10%">{{ item.CRNCY_NM }}</div>
              <div style="width: 20%">
                {{ item.BASE_DATE | date: "yyyy/MM/dd" }}
              </div>
              <div style="width: 10%">-</div>
              <div style="width: 10%">{{ item.BAL_UNIT }}</div>
              <div style="width: 10%">{{ item.TOTAL_ASSIGN_AMT }}</div>
              <div style="width: 10%">{{ item.REMIT_FEE }}</div>
              <div style="width: 10%">-</div>
              <div>0</div>
            </button>
            <div class="panel">
              <div class="add-content-table">
                <p>2021/06/03 再投資</p>
                <table>
                  <thead>
                    <tr>
                      <th>再投資金額</th>
                      <th>手續費</th>
                      <th>申購淨值</th>
                      <th>申購單位數</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-th="再投資金額">222</td>
                      <td data-th="手續費">0</td>
                      <td data-th="申購淨值">9.26</td>
                      <td data-th="申購單位數">24.0</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
