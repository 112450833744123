<div class="Block-content">
  <div class="SearchDiv">
    <div class="label notice">最新消息類型</div>
    <div class="SelectStyle2">
      <select id="FundType" [(ngModel)]="NewsTypeDropdown" (ngModelChange)="changeValue($event)">
        <option [ngValue]=1707>全部</option>
        <option [ngValue]=1708>基金公告</option>
        <option [ngValue]=1726>優惠快訊</option>
        <option [ngValue]=3940>其他公告</option>
      </select>
    </div>
  </div>
  <div class="news-list notice">
    <li *ngFor="let data of NewsListData">
      <div>
        <div class="news-c" [ngSwitch]="data?.cFunctionId">

          <span *ngSwitchCase="1708" class="green-label">{{data?.cFunctionName}}</span>
          <span *ngSwitchCase="1726" class="Red-label">{{data?.cFunctionName}}</span>
          <span *ngSwitchCase="3940" class="Orange2-label">{{data?.cFunctionName}}</span>
          <span *ngSwitchDefault>{{data?.cFunctionId}}</span>
          <a [href]=data.cLinkUrl target="_blank">{{ data.cName }}</a>
        </div>
        <samp>{{ data.cShowStartDt| date : 'yyyy.MM.dd' }}-{{ data.cShowEndDt | date : 'yyyy.MM.dd'
          }}</samp>
      </div>
    </li>
    <!--
    <li>
      <div>
        <div class="news-c">
          <span class="blue-label">個人通知</span><a href="#">德信TAROBO機器人量化中國基金定時定額優惠活動延長...</a>
        </div>
        <samp>2020.03.01-2020.05.31</samp>
      </div>
    </li>
    <li>
      <div>
        <div class="news-c">
          <span class="green-label">交易通知</span><a href="#">德信TAROBO機器人量化中國基金定時定額優惠活動延長...</a>
        </div>
        <samp>2020.03.01-2020.05.31</samp>
      </div>
    </li>
    <li>
      <div>
        <div class="news-c">
          <span class="Orange2-label">最新消息</span><a href="#">德信TAROBO機器人量化中國基金定時定額優惠活動延長...</a>
        </div>
        <samp>2020.03.01-2020.05.31</samp>
      </div>
    </li>
-->
  </div>


  <ngx-pagination [(CollectionSize)]="totalRecords" [(Page)]="pageIndex" [(PageSize)]="pageSize"
    (PageChange)="getList()">
  </ngx-pagination>

  <!--
  <div class="pagination">
    <div class="pagination-container">
      <ul class="pagination circle">
        <li class="active"><a>1</a></li>
        <li><a href="/News?Tab=1708&amp;pages=2">2</a></li>
        <li class="PagedList-skipToNext"><a href="/News?Tab=1708&amp;pages=2" rel="next">»</a></li>
      </ul>
    </div>
  </div>
-->

</div>
