<div class="pagination">
  <div class="pagination-container">
    <ul class="pagination circle">
      <li class="PagedList-skipToPrev" *ngIf="Page!=1" (click)="pageChange(Page - 1)"><a>«</a></li>
      <li [ngClass]="{'active' : i+1 == Page}" *ngFor='let in of counter(getPageSize(CollectionSize, PageSize));let i = index'>
        <a (click)="pageChange(i+1)">{{i+1}}</a></li>
      <li class="PagedList-skipToNext" *ngIf="Page!=getPageSize(CollectionSize, PageSize)" (click)="pageChange(Page + 1)"><a>»</a></li>
    </ul>
  </div>
</div>
<!--
<p class="text-center text-danger fw-bold" *ngIf="CollectionSize===0">無任何資料.</p>
-->
