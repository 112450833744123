import { GetPersonalMsgResponse, GetPersonalMsgArgs, PageParam } from './../../model/member';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/baseComponent';
import { MemberService } from 'src/app/services/member.service';
import { AllowHelper } from 'src/app/helper/allowHelper';

@Component({
  selector: 'app-get-personal-msg',
  templateUrl: './get-personal-msg.component.html',
  styleUrls: ['./get-personal-msg.component.css']
})
export class GetPersonalMsgComponent extends BaseComponent implements OnInit {
  GetPersonalMsgArgs = new GetPersonalMsgArgs();
  PersonalMsgList = [] as GetPersonalMsgResponse[];
  TypeDropdown = 0;
  pageparam = new PageParam();
  constructor(
    protected allow: AllowHelper,
    private _MemberService: MemberService,
  ) {
    super(allow);
  }

  ngOnInit(): void {

    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 15;
    this.pageparam.Page = '個人化通知';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.GetPersonalMsg();
      }
      else {
        return
      }
    })
  }

  GetPersonalMsg() {

    this.GetPersonalMsgArgs.cMsgType = this.TypeDropdown;
    this.GetPersonalMsgArgs.PageIndex = this.pageIndex;
    this.GetPersonalMsgArgs.PageSize = this.pageSize;

    this._MemberService.GetPersonalMsg(this.GetPersonalMsgArgs).subscribe(res => {
      this.PersonalMsgList = res.Entries;
      this.totalRecords = res.TotalItems;
    });
  }

  changeValue(newValue: number) {
    this.TypeDropdown = newValue;
    this.GetPersonalMsg();
  }
}
