import { SafeUrl } from "@angular/platform-browser";

export class Base<T> {
  List = [] as T[];
  Data!: T;
  IsNew = false;
  CanEdit = false;
}

export class GetCaptchaCodeArgs {

}

export class GetCaptchaCodeResponse {
  code = '';
}

export class GetCaptchaImageArgs {
  code = '';
}

export class GetCaptchaImageResponse {
  url : SafeUrl | null = null;;
}

export class JwtPayload {
  LOGIN_DATE_TIME!: Date;
  UserID!: string;
  BF_NO!: number;
  BF_SRNO!: number;
  BF_NAME!: string;
  KYC_RISK_ATTR!: string;
  KYC_RISK_ATTR_NM!: string;
  KYC_STATUS!: string;
  BF_TYPE!: string;
  BF_COUNTRY_X!: string;
  ES_PSW_STATUS!: string;
  EMP_NO!: string;
  LoginType!: number;
  access_token!: string;
  GloubIsVerification!: boolean;
}
