
export class GetRedemFundDataArgs {

  BF_SRNO!: number;
  BF_NO!: number;
}

export class GetAllowRedemDataArgs {
  FUND_ID: string;
  REDEM_UNIT: number;
  FUND_NAME: string;
  REDEM_DATE: Date;
  SWITCH_FUND_ID: string;
}

export class GetSwitchFundListArgs {
  FUND_ID: string;
  FUND_NAME: string;
}

export class GetRedemFundDataResponse {
  InventoryList!: InventoryList[];
}

export class InventoryList {
  FUND_ID!: string;
  FUND_NAME!: string;
  FUND_CURRENCY!: string;
  FUND_CURRENCY_NM!: string;
  AFEE_TYPE: string;
  CRNCY_CD!: string;
  CRNCY_NM!: string;
  MIN_UNIT_SUR!: number;
  BAL_SCRIP_UNIT: number;
  BAL_UNIT!: number;
  R_UNIT!: number;

  RETURN_RATE!: string;

  UNIT_DEC!: number;
  DEC_LEN!: number;

  OfficialManualPath!: string;
  RiskForecastPath!: string;
}

export class GetSwitchFundListResponse {
  SwitchFundList!: SwitchFundList[];
}

export class SwitchFundList {
  SWITCH_FUND_ID!: string;
  SWITCH_FUND_NAME!: string;
  SWITCH_FUND_CURRENCY!: string;
  SWITCH_FUND_CURRENCY_NM!: string;
  SWITCH_FUND_RISK!: string;
  SWITCH_FUND_RISK_NM!: string;
  SWITCH_FUND_DIVIDEND_TYPE_NM!: string;
  DIVIDEND_TYPE!: string;
  DIVIDEND_TYPE_NM!: string;
  DIV_TYPE!: string;
  OfficialManualPath!: string;
  RiskForecastPath!: string;
}

export class GetAllowRedemDataResponse {
  FUND_ID!: string;
  FUND_NAME: string;
  BAL_UNIT!: number;
  R_UNIT!: number;
  BAL_SCRIP_UNIT: number;
  TOTAL_SHORT_UNIT!: number;
  SHORT_FEE_RATE!: number;
  SHORT_FEE!: number;
  REDEM_FEE!: number;
  REDEM_NAV_DATE!: Date;
  REDEM_PAY_DATE!: Date;
  SWITCH_DATE: Date;
  F_NAV!: number;
  NAV_DEC!: number;
  UNIT_DEC!: number;
  DEC_LEN!: number;
  TOTAL!: number;
}

export class CheckTransferSubscriptionArgs {
  FUND_ID: string;
  SWITCH_FUND_ID: string;
  REDEM_UNIT: number;
  REDEM_PAY_DATE: Date;
  TOTAL!: number;
}

export class SaveTransferSubscriptionArgs {
  FUND_ID: string;
  FUND_NAME: string;
  SWITCH_FUND_ID: string;
  SWITCH_FUND_NAME: string;
  REDEM_UNIT: number;
  REDEM_PAY_DATE: Date;
  PASSWORD: string;
  FUND_CURRENCY: string;
  FUND_CURRENCY_NM: string;
  ReadInfo = false; // 已讀相關說明
  FEE: number;
  FEE_NAME = '';
  FEE_CHOICE: string;
  CAMPAIGN_CODE = '';
  SWITCH_DATE: Date;
  REDEM_NAV_DATE: Date;
  TOTAL: number;
  TOTAL_SHORT_UNIT: number;
}

export class SaveTransferSubscriptionResponse {
  REDEM_PAY_DATE: string;
  APPLY_DATE: string;

}
