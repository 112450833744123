import { ChangePswDataArgs, PswTxDataResponse, GetPersonalMsgResponse, GetPersonalMsgArgs, PageParam } from './../model/member';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import {
  EstablishAccountSearchArgs,
  EstablishAccountSearchResponse,
  GetMemberVerificationArgs,
  GetMemberVerificationResponse,
  LoadMemberProfileArgs,
  LoadMemberProfileResponse,
  LoginArgs,
  LoginResponse,
  MemberVerificationArgs,
  PswDataArgs,
  PswDataResponse,
  SaveMemberProfileArgs,
  SaveMemberProfileResponse,
  SaveMemberVerificationArgs,
  SaveMemberVerificationResponse,
} from '../model/member';
import { PersonalizationSettingsForLINE } from '../model/personalization-settings';
import { GetMonthTradeArgs } from '../model/open-data';

@Injectable({
  providedIn: 'root',
})
export class MemberService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/Member`;
  constructor(http: HttpClient) {
    super(http);
  }

  login(request: LoginArgs): Observable<ResponseBase<LoginResponse>> {
    const url = `${this.baseUrl}/MemberLogin`;
    return this.http.post<ResponseBase<LoginResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  reSendMemberVerification(
    request: MemberVerificationArgs
  ): Observable<ResponseBase<LoginResponse>> {
    const url = `${this.baseUrl}/ReSendMemberVerification`;
    return this.http.post<ResponseBase<LoginResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  checkSendMemberVerification(
    request: MemberVerificationArgs
  ): Observable<ResponseBase<LoginResponse>> {
    const url = `${this.baseUrl}/CheckMemberVerification`;
    return this.http.post<ResponseBase<LoginResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  ForgotPassword(
    request: PswDataArgs
  ): Observable<ResponseBase<PswDataResponse>> {
    const url = `${this.baseUrl}/ForgotPassword`;
    return this.http.post<ResponseBase<PswDataResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  ChangePassword(
    request: ChangePswDataArgs
  ): Observable<ResponseBase<PswTxDataResponse>> {
    const url = `${this.baseUrl}/ChangePassword`;
    return this.http.post<ResponseBase<PswTxDataResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetPersonalMsg(
    request: GetPersonalMsgArgs
  ): Observable<ResponseBase<GetPersonalMsgResponse[]>> {
    const url = `${this.baseUrl}/GetPersonalMsg`;
    return this.http.post<ResponseBase<GetPersonalMsgResponse[]>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetMemberVerification(
    request: GetMemberVerificationArgs
  ): Observable<ResponseBase<GetMemberVerificationResponse>> {
    const url = `${this.baseUrl}/GetMemberVerification`;
    return this.http.post<ResponseBase<GetMemberVerificationResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  SaveMemberVerification(
    request: SaveMemberVerificationArgs
  ): Observable<ResponseBase<SaveMemberVerificationResponse>> {
    const url = `${this.baseUrl}/SaveMemberVerification`;
    return this.http.post<ResponseBase<SaveMemberVerificationResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  // Load 受益人基本資料
  LoadMemberProfile(
    request: LoadMemberProfileArgs
  ): Observable<ResponseBase<LoadMemberProfileResponse>> {
    const url = `${this.baseUrl}/GetMemberProfile`;
    return this.http.post<ResponseBase<LoadMemberProfileResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  // Save 受益人基本資料
  SaveMemberProfile(
    request: SaveMemberProfileArgs
  ): Observable<ResponseBase<SaveMemberProfileResponse>> {
    const url = `${this.baseUrl}/SaveMemberProfile`;
    return this.http.post<ResponseBase<SaveMemberProfileResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  //開戶進度查詢
  EstablishAccountSearch(
    request: EstablishAccountSearchArgs
  ): Observable<ResponseBase<EstablishAccountSearchResponse>> {
    const url = `${this.baseUrl}/EstablishAccountSearch`;
    return this.http.post<ResponseBase<EstablishAccountSearchResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  logout(): Observable<number> {
    const url = `${this.baseUrl}/MemberLogout`;

    return this.http.post<number>(
      url,
      this.httpOptions
    );
  }

  WebLog(request: PageParam): Observable<number> {
    const url = `${this.baseUrl}/WebLog`;

    return this.http.post<number>(
      url,
      request,
      this.httpOptions
    );
  }

  GetLine(): Observable<PersonalizationSettingsForLINE> {
    const url = `${this.baseUrl}/GetLine`;

    return this.http.post<PersonalizationSettingsForLINE>(
      url,
      this.httpOptions
    );
  }

  GetMonthTrade(request: GetMonthTradeArgs): Observable<any> {
    const url = `${this.baseUrl}/GetMonthTrade`;
    debugger
    const params = new HttpParams().append('Month', request.Month.toString())
      .append('ID', request.ID.toString())
      .append('BF_NO', request.BF_NO.toString());

    return this.http.get(
      url,
      {
        responseType: 'blob',
        params: params
      });
  }
}
