<!-- 列表 -->
<ng-container *ngIf="showType === 1">
  <div class="Block-content">
    <div class="transaction-hr">交易時間 <app-now></app-now>
    </div>
    <div class="ec-area">
      <table class="scroll-left ec-table">
        <thead>
          <tr>
            <th>基金名稱</th>
            <th>手續費率</th>
            <th>扣款日期</th>
            <th>扣款金額</th>
            <th>第一次扣款日</th>
            <th>申請日期</th>
            <th>異動</th>
            <th>狀態</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of GetRspQueryResponse.RspChgList;">
            <td>
              <a href="javascript:void(0)" (click)="GetRspQGetRegulyBuyDiffuery(item.RSP_NO)" style="color: red;"><u>{{
                  item.FUND_NAME }}</u></a>
            </td>
            <td align="center">{{ item.RSP_FEE_RATE}}%</td>
            <td align="center">{{ item.RSP_ALLOT_DAY }}</td>
            <td align="center">{{ item.RSP_AMT | currency}}</td>
            <td align="center">{{ item.FIRST_SUB_DATE | date : 'yyyy/MM/dd' }}</td>
            <td align="center">{{ item.RCV_DATE | date : 'yyyy/MM/dd'}}</td>
            <td align="center"><a href="javascript:void(0)" rel="modal:open" class="set-up" (click)="OnEdit(item)"
                *ngIf="item.RSPCHG_YN == 'N'"><i class="fas fa-chevron-right"></i>異動</a></td>
            <td align="center">{{ item.STATUS_DESC }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="title-02">異動定期定額契約</div>
    <div class="ec-area">
      <table class="scroll-left ec-table">
        <thead>
          <tr>
            <th>基金名稱</th>
            <th>申請日期</th>
            <th>扣款日期</th>
            <th>扣款金額</th>
            <th>扣款狀態</th>
            <th>扣款銀行</th>
            <th>狀態</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of GetRegulyBuyDiffResponse;">
            <td align="center">{{item.cFundName}}</td>
            <td align="center">{{item.cTransferDate}}</td>
            <td align="center">{{item.cChargeDate}}</td>
            <td align="center">{{item.cAmount_NM}}</td>
            <td align="center">
              {{item.cCHARGESTATE_NM}}
              <!-- <span *ngIf="item.cCHARGESTATE == '3'">
                日期:{{item.cSetEndTime}} ~ {{item.cSetStartTime}}
              </span> -->
            </td>
            <td align="center">{{item.cAccount}}</td>
            <td align="center">{{item.cStatus}}</td>
          </tr>
        </tbody>
      </table>
    </div>


  </div>

</ng-container>


<!-- 編輯 -->
<ng-container *ngIf="showType === 2">

  <div class="Block-content">
    <div class="transaction-hr">交易時間 <app-now></app-now>
    </div>

    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">1</div>
        <div class="p-contact-form__step-label">定期定額異動申購</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">2</div>
        <div class="p-contact-form__step-label">定期定額異動確認</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">3</div>
        <div class="p-contact-form__step-label">定期定額異動完成</div>
      </div>
    </div>

    <div class="basic-data-table">
      <div class="w-100">
        <div
          *ngIf="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')"
          class="basic-left" style="padding: 0px;padding-left: 10px;">
          <br /><span class="text-red"><b>*您的kyc已過期，只能將扣款狀態更新為暫停扣款</b></span><br />
          <a href="kyc"><img alt="riskbtn" src=".././../../assets/images/riskbtn.png" /></a>
        </div>
        <div *ngIf="EditRegulyBuyDiffResponse.RadioButtonList_ChargeState_List[0].Value == '1'" class="basic-left"
          style="padding: 0px;padding-left: 10px;height: 50px;">
          <span class="text-red"><b>*您的扣款狀態目前為暫停扣款，只能將扣款狀態更新為恢復扣款</b>
          </span><br />
        </div>
        <div *ngIf="ShowAlert != ''" class="basic-left"
          style="padding: 0px;padding-left: 10px;height: 50px;">
          <span class="text-red"><b>*{{ShowAlert}}</b>
          </span><br />
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">申請日期</div>
        <div class="basic-right">
          {{EditRegulyBuyDiffResponse.Label_Day | date : 'yyyy/MM/dd'}}
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">基金名稱</div>
        <div class="basic-right">{{EditRegulyBuyDiffResponse.Label_FUND}}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">
          <div class="checkbox-wrap">
            <input type="checkbox" id="CheckBox_SetAmount" *ngIf="EditRegulyBuyDiffResponse.CheckBox_SetAmount_Enabled"
              [disabled]="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')
              || EditRegulyBuyDiffResponse.RadioButtonList_ChargeState_List[0].Value == '1'"
              [(ngModel)]="EditRegulyBuyDiffResponse.CheckBox_SetAmount_Checked" [ngModelOptions]="{standalone: true}">
            <label for="CheckBox_SetAmount"><samp>每期申購金額</samp></label>
          </div>
        </div>
        <div class="basic-right">
          <input [disabled]="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')
            && EditRegulyBuyDiffResponse.RadioButtonList_ChargeState_List[0].Value == '1'"
            *ngIf="EditRegulyBuyDiffResponse.CheckBox_SetAmount_Checked" class="input-25" (blur)="checkAmount()"
            style="margin-right:10px;" [(ngModel)]="EditRegulyBuyDiffResponse.TextBox_CSALECOUNT">
          <ng-container *ngIf="!EditRegulyBuyDiffResponse.CheckBox_SetAmount_Checked">
            {{EditRegulyBuyDiffResponse.Label_SaleCount | number}}</ng-container>
          元
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">
          <div class="checkbox-wrap">
            <input [disabled]="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')
              || EditRegulyBuyDiffResponse.RadioButtonList_ChargeState_List[0].Value == '1'" type="checkbox"
              id="CheckBox_Day" *ngIf="EditRegulyBuyDiffResponse.CheckBox_Day_Enabled"
              [(ngModel)]="EditRegulyBuyDiffResponse.CheckBox_Day_Checked" [ngModelOptions]="{standalone: true}">
            <label for="CheckBox_Day"><samp>扣款日期</samp></label>
          </div>
        </div>
        <div class="basic-right" style="display:flex;padding-top:22px;">
          <ng-container *ngIf="EditRegulyBuyDiffResponse.CheckBox_Day_Checked">
            <div class="clear" *ngFor="let item of GetRspDayListResponse.RspAllotDaysList;">
              <input id="RadioButtonList_Day{{item.DAYS}}" type="radio" name="RadioButtonList_Day" class="form"
                [value]="item.DAYS" [(ngModel)]="EditRegulyBuyDiffResponse.RadioButtonList_Day" /><label
                for="RadioButtonList_Day{{item.DAYS}}" class="form">{{item.DAYS}}</label>
            </div>
          </ng-container>
          <ng-container *ngIf="!EditRegulyBuyDiffResponse.CheckBox_Day_Checked">
            {{EditRegulyBuyDiffResponse.Label_EditDay}}
          </ng-container>
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">
          <div class="checkbox-wrap">
            <input type="checkbox" id="CheckBox_ChargeState"
              *ngIf="EditRegulyBuyDiffResponse.CheckBox_ChargeState_Enabled" [disabled]="(jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3'))
              && EditRegulyBuyDiffResponse.RadioButtonList_ChargeState_List[0].Value != '3'"
              [(ngModel)]="EditRegulyBuyDiffResponse.CheckBox_ChargeState_Checked"
              [ngModelOptions]="{standalone: true}">
            <label for="CheckBox_ChargeState"><samp>扣款狀態</samp></label>
          </div>
        </div>
        <div class="basic-right" style="display:flex;padding-top:22px;">
          <ng-container *ngIf="EditRegulyBuyDiffResponse.CheckBox_ChargeState_Checked">
            <div class="clear" *ngFor="let item of EditRegulyBuyDiffResponse.RadioButtonList_ChargeState_List;">
              <input id="CheckBox_ChargeState{{item.Value}}" type="radio" name="CheckBox_ChargeState" class="form"
                [value]="item.Value" [(ngModel)]="EditRegulyBuyDiffResponse.RadioButtonList_ChargeState" /><label
                for="CheckBox_ChargeState{{item.Value}}" class="form">{{item.Text}}</label>
            </div>
          </ng-container>
          <ng-container *ngIf="!EditRegulyBuyDiffResponse.CheckBox_ChargeState_Checked">
            {{EditRegulyBuyDiffResponse.Label_EditState}}
          </ng-container>
          <br />
          <!-- <div *ngIf="EditRegulyBuyDiffResponse.RadioButtonList_ChargeState == '3'"> -->
          <div *ngIf="false">
            <label>開始日期：</label>
            <input type="date" style="width: 170px;" *ngIf="EditRegulyBuyDiffResponse.CheckBox_ChargeState_Checked"
              [(ngModel)]="EditRegulyBuyDiffResponse.TextBox_StartDate" />
            <label
              *ngIf="!EditRegulyBuyDiffResponse.CheckBox_ChargeState_Checked">{{EditRegulyBuyDiffResponse.Label_EditSdate
              | date: 'yyyy/MM/dd'}}</label>
            <br>
            <label>結束日期：</label>
            <input type="date" style="width: 170px;" *ngIf="EditRegulyBuyDiffResponse.CheckBox_ChargeState_Checked"
              [(ngModel)]="EditRegulyBuyDiffResponse.TextBox_EndDate" />
            <label
              *ngIf="!EditRegulyBuyDiffResponse.CheckBox_ChargeState_Checked">{{EditRegulyBuyDiffResponse.Label_EditEdate
              | date: 'yyyy/MM/dd'}}</label>
            <br>
            <label style="color: Red;"
              *ngIf="EditRegulyBuyDiffResponse.Label_StatusMemo_Visible">(暫停期間內『狀態』功能將無法使用，待期間結束方可啟用)</label>
          </div>

        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">
          <div class="checkbox-wrap">
            <input type="checkbox" id="CheckBox_TBLBANKDEBITS" [disabled]="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')
              || EditRegulyBuyDiffResponse.RadioButtonList_ChargeState_List[0].Value == '1'"
              *ngIf="EditRegulyBuyDiffResponse.CheckBox_TBLBANKDEBITS_Enabled"
              [(ngModel)]="EditRegulyBuyDiffResponse.CheckBox_TBLBANKDEBITS_Checked"
              [ngModelOptions]="{standalone: true}">
            <label for="CheckBox_TBLBANKDEBITS"><samp>扣款帳戶</samp></label>
          </div>
        </div>
        <div class="basic-right">
          <div class="SelectStyle2 w-80" *ngIf="EditRegulyBuyDiffResponse.CheckBox_TBLBANKDEBITS_Checked">
            <select [(ngModel)]="EditRegulyBuyDiffResponse.DropDownList_TBLBANKDEBITS">
              <option value="">請選擇</option>
              <option *ngFor="let item of EditRegulyBuyDiffResponse.BucAccList;" [ngValue]="item.ACCOUNT_NO">
                {{item.BANK_BRH_SHNM}} {{item.ACCOUNT_NO_SHOW}}
              </option>
            </select>
          </div>

          <ng-container *ngIf="!EditRegulyBuyDiffResponse.CheckBox_TBLBANKDEBITS_Checked">
            {{EditRegulyBuyDiffResponse.Label_BankNm}}&nbsp;{{EditRegulyBuyDiffResponse.Label_BankAccount_No_Show}}
          </ng-container>
        </div>
      </div>
    </div>

    <div class="button-box">
      <a href="regular-quota" class="cancel-button">取消</a>
      <a href="javascript:void(0)" class="Main-button" (click)="OnInsert(3)">確認<i class="fas fa-chevron-right"></i></a>
    </div>

  </div>

</ng-container>


<!-- 確認 -->
<ng-container *ngIf="showType === 3">

  <div class="Block-content">

    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">1</div>
        <div class="p-contact-form__step-label">定期定額異動申購</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">2</div>
        <div class="p-contact-form__step-label">定期定額異動確認</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">3</div>
        <div class="p-contact-form__step-label">定期定額異動完成</div>
      </div>
    </div>

    <div class="basic-data-table">
      <div class="w-100">
        <div class="basic-left">申請日期</div>
        <div class="basic-right">{{InsertRegulyBuyDiffResponse.Modify_Label_Day | date : 'yyyy/MM/dd'}}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">基金名稱</div>
        <div class="basic-right">{{InsertRegulyBuyDiffResponse.Modify_Label_FUND}}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">每期申購金額</div>
        <div class="basic-right" [ngClass]="{'text-red' : InsertRegulyBuyDiffResponse.IsModify_Label_SaleCount}">
          {{InsertRegulyBuyDiffResponse.Modify_Label_SaleCount | number}}元</div>
      </div>
      <div class="w-100">
        <div class="basic-left">扣款日期</div>
        <div class="basic-right" [ngClass]="{'text-red' : InsertRegulyBuyDiffResponse.IsModify_Label_EditDay}">
          {{InsertRegulyBuyDiffResponse.Modify_Label_EditDay}}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">扣款狀態</div>
        <div class="basic-right" [ngClass]="{'text-red' : InsertRegulyBuyDiffResponse.IsModify_Label_EditState}">
          {{InsertRegulyBuyDiffResponse.Modify_Label_EditState}}
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">扣款帳戶</div>
        <div class="basic-right" [ngClass]="{'text-red' : InsertRegulyBuyDiffResponse.IsModify_Label_BankDebits}">
          {{InsertRegulyBuyDiffResponse.Modify_Label_BankDebits}}</div>
      </div>
    </div>


    <div class="basic-data-table transaction-password">
      <div class="w-100">
        <div class="basic-left">交易密碼</div>
        <div class="basic-right">
          <input class="input-25" style="float: left" [type]="passwordType"
            [(ngModel)]="EditRegulyBuyDiffResponse.TextBox_Password">
          <div [class]="conceal" (click)="onClickConceal()"></div>
        </div>
      </div>
    </div>


    <div class="button-box">
      <a href="regular-quota" class="cancel-button">取消</a>
      <a href="javascript:void(0)" class="Main-button" (click)="OnInsert(4)" class="Main-button">確認<i
          class="fas fa-chevron-right"></i></a>
    </div>


    <div class="note-box">
      <p class="text-red" style="text-align:center;">*紅字為本次異動項目，其餘為處理項目</p>
    </div>


  </div>


</ng-container>


<ng-container *ngIf="showType === 4">

  <div class="Block-content">
    <!-- <div class="transaction-hr">交易時間 2020/04/14 09:43:33</div> -->

    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">1</div>
        <div class="p-contact-form__step-label">定期定額異動申購</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">2</div>
        <div class="p-contact-form__step-label">定期定額異動確認</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">3</div>
        <div class="p-contact-form__step-label">定期定額異動完成</div>
      </div>
    </div>

    <div class="Details">
      <div class="w-60">
        <p class="txt">
          <i class="fas fa-check-circle"></i> 定期定額異動
          <span class="txt-red">{{InsertRegulyBuyDiffResponse.Modify_Label_FUND}}</span>
          異動完成！
        </p>
      </div>
      <div class="w-60">
        <table border="0" cellpadding="1" cellspacing="0" class="ptable">
          <tbody>
            <tr>
              <th>委託確認時間</th>
            </tr>
            <tr>
              <td>
                {{InsertRegulyBuyDiffResponse.ApplyTime | date : 'yyyy-MM-dd HH:mm:ss'}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="button-box">
      <a href="javascript:void(0)" class="cancel-button" (click)="this.GetRspQuery(GetRspQueryArgs);showType = 1"
        class="Main-button">回定期定額契約<i class="fas fa-chevron-right"></i></a>
    </div>
  </div>
</ng-container>
