<div class="Block-content">
  <div class="SearchDiv">
    <div class="label notice">通知訊息類型</div>
    <div class="SelectStyle2">
      <select id="FundType" [(ngModel)]="TypeDropdown" (ngModelChange)="changeValue($event)">
        <option [ngValue]="0">全部</option>
        <option [ngValue]="1">鎖利通知</option>
        <option [ngValue]="2">交易通知</option>
        <option [ngValue]="3">個人通知</option>
      </select>
    </div>
  </div>
  <div class="news-list notice">
    <li *ngFor="let item of PersonalMsgList">
      <div>
        <div class="news-c" [ngSwitch]="item.cMsgType">
          <span *ngSwitchCase="1" class="Red-label"> {{item.cMsgName}} </span>
          <span *ngSwitchCase="2" class="green-label"> {{item.cMsgName}} </span>
          <span *ngSwitchCase="3" class="blue-label"> {{item.cMsgName}} </span>
          <span *ngSwitchDefault>{{item.cMsgName}}</span>
          <a href="#" target="_blank"> {{item.cMsgParam1}} </a>
        </div>
        <samp> {{ item.cCreateDt| date: 'yyyy.MM.dd' }} </samp>
      </div>
    </li>
  </div>

  <ngx-pagination [(CollectionSize)]="totalRecords" [(Page)]="pageIndex" [(PageSize)]="pageSize"
    (PageChange)="GetPersonalMsg()">
  </ngx-pagination>

  <!-- <div class="pagination">
    <div class="pagination-container">
      <ul class="pagination circle">
        <li class="active"><a>1</a></li>
        <li><a href="/News?Tab=1708&amp;pages=2">2</a></li>
        <li class="PagedList-skipToNext"><a href="/News?Tab=1708&amp;pages=2" rel="next">»</a></li>
      </ul>
    </div>
  </div>
</div> -->
