import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import { GetArticleByUnitArgs, Data, GetNewsListResponse } from '../model/News';
@Injectable({
  providedIn: 'root',
})
export class NewsService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/News`;
  constructor(
    http: HttpClient
  ) {
    super(http);

  }

  GetList(request: GetArticleByUnitArgs): Observable<ResponseBase<GetNewsListResponse>> {
    const url = `${this.baseUrl}/GetNews`;
    return this.http.post<ResponseBase<GetNewsListResponse>>(
      url,
      request,
      this.httpOptions);
  }

  GetNewsByLogin(request: GetArticleByUnitArgs): Observable<ResponseBase<GetNewsListResponse>> {
    const url = `${this.baseUrl}/GetNewsByLogin`;
    return this.http.post<ResponseBase<GetNewsListResponse>>(
      url,
      request,
      this.httpOptions);
  }

}


