import { Component, OnInit, NgModule } from '@angular/core';
import { GetSwitchFundListResponse, InventoryList, GetRedemFundDataResponse, GetRedemFundDataArgs, GetAllowRedemDataArgs, GetSwitchFundListArgs, GetAllowRedemDataResponse } from './../../model/transfersubscription';
import { GetFundListResponse, GetFundListArgs, FundDetail, GetFeeRateArgs, GetFeeRateResponse, GetBucAccArgs, GetBucAccResponse, SingletransactionModel, FeeRateItem, SingletransactionModelVerifyResponse, GetDivAccArgs, GetDivAccResponse, BucAccList } from './../../model/open-data';
import { CheckFundRedemptionArgs, SaveFundRedemptionArgs, SaveFundRedemptionResponse, GetRemitAccountResponse, RemitAccountList, GetRemitAccountArgs } from './../../model/fundredemption';
import { OpenDataService } from 'src/app/services/open-data.service';
import { transfersubscriptionService } from 'src/app/services/transfersubscription.service';
import { fundredemptionService } from 'src/app/services/fundredemption.service';
import { MessageService } from '../../services/message.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { ValidationHelper } from 'src/app/helper/validationHelper';
import { FormsModule } from '@angular/forms';
import { PageParam } from 'src/app/model/member';
import { MemberService } from 'src/app/services/member.service';
@Component({
  selector: 'app-fundredemption',
  templateUrl: './fundredemption.component.html',
  styleUrls: ['./fundredemption.component.css']
})
export class FundredemptionComponent implements OnInit {
  SingletransactionModel = new SingletransactionModel();
  GetRemitAccountResponse = new GetRemitAccountResponse();

  RemitAccountList = new RemitAccountList();
  GetRedemFundDataArgs = new GetRedemFundDataArgs();
  GetAllowRedemDataArgs = new GetAllowRedemDataArgs();
  GetSwitchFundListArgs = new GetSwitchFundListArgs();
  GetRedemFundDataResponse = new GetRedemFundDataResponse();
  GetAllowRedemDataResponse = new GetAllowRedemDataResponse();
  GetSwitchFundListResponse = new GetSwitchFundListResponse();

  CheckFundRedemptionArgs = new CheckFundRedemptionArgs();
  SaveFundRedemptionArgs = new SaveFundRedemptionArgs();
  SaveFundRedemptionResponse = new SaveFundRedemptionResponse();
  InventoryList = new InventoryList();

  selectedFundValue: string = "";
  selectedACCOUNT_NO: string = "";
  PASSWORD: string = "";
  REDEM_UNIT: number = 0;
  pagecontact: number = 1;
  passwordType: string = 'password';
  conceal: string = 'conceal';
  pageparam = new PageParam();

  isSelected: boolean;

  TWDList = [] as RemitAccountList[];//台幣帳戶
  FORList = [] as RemitAccountList[];//外幣帳戶

  constructor(
    private valid: ValidationHelper,
    private message: MessageService,
    private _OpenDataService: OpenDataService,
    private _transfersubscriptionService: transfersubscriptionService,
    private _fundredemptionService: fundredemptionService,
    private _MemberService: MemberService
  ) { }

  ngOnInit(): void {

    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 6;
    this.pageparam.Page = '基金買回';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.getFundList();
      }
      else {
        return
      }
    })
  }


  //持有基金
  getFundList() {
    this._transfersubscriptionService.GetRedemFundData().subscribe(res => {
      this.GetRedemFundDataResponse = res.Entries;
    });
  }

  //持有基金資料
  selectFund(e: any, n: any) {
    this.selectedACCOUNT_NO = '';

    if (e.value == '') {
      this.isSelected = false;
    }
    else {
      this.isSelected = true;
    }

    if (e.value != '') {
      this.InventoryList = this.GetRedemFundDataResponse.InventoryList.filter(x => x.FUND_ID == e.value)[0];
      this.InventoryList.BAL_UNIT = parseFloat(this.InventoryList.BAL_UNIT.toFixed(this.InventoryList.UNIT_DEC));
      this.InventoryList.R_UNIT = parseFloat(this.InventoryList.R_UNIT.toFixed(this.InventoryList.UNIT_DEC));
      this.GetRemitAccount({ CRNCY_CD: this.InventoryList.FUND_CURRENCY });
      //資料
      this.GetAllowRedemDataArgs.FUND_ID = e.value;

      // this._transfersubscriptionService.GetAllowRedemData(this.GetAllowRedemDataArgs).subscribe(res => {
      //   this.GetAllowRedemDataResponse = res.Entries;
      // });


    }
  }
  //選擇的買回帳號
  selecRemitAccount(e: any) {
    this.selectedACCOUNT_NO = e.value;
    this.RemitAccountList = this.GetRemitAccountResponse.RemitAccountList.filter(x => x.ACCOUNT_NO == e.value)[0];

    //console.log("轉換SwitchFundList", this.SwitchFundList);
  }
  //買回的帳號列表
  GetRemitAccount(arg: GetRemitAccountArgs) {
    this._fundredemptionService.GetRemitAccount(arg).subscribe(res => {
      console.log("GetRemitAccount", res);
      this.GetRemitAccountResponse = res.Entries;

      var TWDList = [] as RemitAccountList[];//台幣帳戶
      var FORList = [] as RemitAccountList[];//外幣帳戶

      this.GetRemitAccountResponse.RemitAccountList.forEach(data => {
        switch (data.ACC_NO_TYPE) {
          case '0':
            TWDList.push(data);
            break;
          case '2':
            FORList.push(data);
            break;
        }
      });
      this.TWDList = TWDList;
      this.FORList = FORList;
    });
  }

  //持有基金資料明細
  RedemData() {
    //console.log("this.REDEM_UNIT", this.REDEM_UNIT);
    //console.log("this.GetAllowRedemDataArgs", this.GetAllowRedemDataArgs);
    if (/[^\d|^\.]/.test(this.REDEM_UNIT.toString())) {
      alert('輸入格式不可包含數字及小數點以外的文字');
      return;
    }
    if (this.REDEM_UNIT.toString().length > 10) {
      alert('超出最大贖回位數(10位)，請重新輸入');
    }
    else {
      if (this.REDEM_UNIT != null) {
        if (this.REDEM_UNIT > 0) {
          if (this.REDEM_UNIT <= this.InventoryList.R_UNIT) {
            this.GetAllowRedemDataArgs.REDEM_UNIT = this.REDEM_UNIT;
            this.GetAllowRedemDataArgs.SWITCH_FUND_ID = ' '
            this._transfersubscriptionService.GetAllowRedemData(this.GetAllowRedemDataArgs).subscribe(res => {
              if (res.StatusCode === EnumStatusCode.Success) {
                this.GetAllowRedemDataResponse = res.Entries;
                this.GetAllowRedemDataResponse.REDEM_FEE = parseFloat(this.GetAllowRedemDataResponse.REDEM_FEE.toFixed(this.GetAllowRedemDataResponse.DEC_LEN));
                this.GetAllowRedemDataResponse.F_NAV = parseFloat(this.GetAllowRedemDataResponse.F_NAV.toFixed(this.GetAllowRedemDataResponse.NAV_DEC));
                this.GetAllowRedemDataResponse.SHORT_FEE = parseFloat(this.GetAllowRedemDataResponse.SHORT_FEE.toFixed(this.GetAllowRedemDataResponse.DEC_LEN));
                this.GetAllowRedemDataResponse.TOTAL = parseFloat(this.GetAllowRedemDataResponse.TOTAL.toFixed(this.GetAllowRedemDataResponse.DEC_LEN));
              }
              else {

              }
            });
          }
          else {
            alert('超過可買回單位數');
            this.REDEM_UNIT = 0;
            this.GetAllowRedemDataResponse.TOTAL = 0;
          }
        }
      }
    }
  }

  save1() {
    this.valid.clear();
    if (this.selectedFundValue == '') {
      this.valid.addErrorMessage('請選擇欲贖回基金');
    }
    if (this.selectedACCOUNT_NO == '') {
      this.valid.addErrorMessage('請選擇存入帳號');
    }
    if (this.REDEM_UNIT <= 0) {
      this.message.showSucessMSG('請輸入買回單位數');
    }
    else if (this.REDEM_UNIT > this.InventoryList.R_UNIT) {
      this.message.showSucessMSG('超過可買回單位數');
    }
    if (this.valid.errorMessages.length > 0) {
      this.message.showErrorMSGs(this.valid.errorMessages);
    }
    else {
      this.RedemData();

      this.CheckFundRedemptionArgs.FUND_ID = this.selectedFundValue;
      this.CheckFundRedemptionArgs.ACCOUNT_NO = this.selectedACCOUNT_NO;
      this.CheckFundRedemptionArgs.REDEM_UNIT = this.REDEM_UNIT;
      this.CheckFundRedemptionArgs.REDEM_PAY_DATE = this.GetAllowRedemDataResponse.REDEM_PAY_DATE;
      this.CheckFundRedemptionArgs.TOTAL = this.GetAllowRedemDataResponse.TOTAL;

      this._fundredemptionService.CheckFundRedemption(this.CheckFundRedemptionArgs).subscribe(res => {
        if (res.StatusCode === EnumStatusCode.Success) {
          this.pagecontact = 2;
        }
        else {
          // this.message.showSucessMSG(res.Message);
        }
      });
    }
  }

  save2() {
    this.valid.clear();
    if (this.SaveFundRedemptionArgs.ReadInfo == false) {
      this.valid.addErrorMessage('請勾選我已閱讀公開說明書 風險預告書');
    }
    if (this.PASSWORD == '') {
      this.valid.addErrorMessage('請輸入交易密碼');
    }

    if (this.valid.errorMessages.length > 0) {
      this.message.showErrorMSGs(this.valid.errorMessages);
    }
    else {
      this.SaveFundRedemptionArgs.FUND_CURRENCY = this.InventoryList.FUND_CURRENCY;
      this.SaveFundRedemptionArgs.FUND_CURRENCY_NM = this.InventoryList.FUND_CURRENCY_NM;
      this.SaveFundRedemptionArgs.PASSWORD = this.PASSWORD;
      this.SaveFundRedemptionArgs.FUND_ID = this.selectedFundValue;
      this.SaveFundRedemptionArgs.ACCOUNT_NO = this.selectedACCOUNT_NO;
      this.SaveFundRedemptionArgs.BANK_BRH = this.RemitAccountList.BANK_BRH;
      this.SaveFundRedemptionArgs.BANK_BRH_SHNM = this.RemitAccountList.BANK_BRH_SHNM;
      this.SaveFundRedemptionArgs.REDEM_UNIT = this.REDEM_UNIT;
      this.SaveFundRedemptionArgs.FUND_NAME = this.InventoryList.FUND_NAME
      this.SaveFundRedemptionArgs.REDEM_PAY_DATE = this.GetAllowRedemDataResponse.REDEM_PAY_DATE;
      this.SaveFundRedemptionArgs.REDEM_NAV_DATE = this.GetAllowRedemDataResponse.REDEM_NAV_DATE;
      this.SaveFundRedemptionArgs.SHORT_FEE = this.GetAllowRedemDataResponse.SHORT_FEE;
      this.SaveFundRedemptionArgs.TOTAL_SHORT_UNIT = this.GetAllowRedemDataResponse.TOTAL_SHORT_UNIT;
      this.SaveFundRedemptionArgs.TOTAL = this.GetAllowRedemDataResponse.TOTAL;

      this._fundredemptionService.SaveFundRedemption(this.SaveFundRedemptionArgs).subscribe(res => {
        if (res.StatusCode === EnumStatusCode.Success) {
          this.pagecontact = 3;
          this.SaveFundRedemptionResponse.APPLY_DATE = res.Entries.APPLY_DATE;
          this.SaveFundRedemptionResponse.REDEM_PAY_DATE = res.Entries.REDEM_PAY_DATE;
        }
        else {
          this.message.showSucessMSG(res.Message);
        }
      });
    }
  }

  onClickConceal() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.conceal = 'conceal yincang';
    } else {
      this.passwordType = 'password';
      this.conceal = 'conceal';
    }
  }
}
