import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import {
  EditRegulyBuyDiffArgs,
  EditRegulyBuyDiffResponse,
  GetRegulyBuyDiffArgs,
  GetRegulyBuyDiffResponse,
  GetRspDayListArgs,
  GetRspDayListResponse,
  GetRspQueryArgs,
  GetRspQueryResponse,
  InsertRegulyBuyDiffResponse,
} from '../model/rsp';
import {
  RegularSavingPlanModel,
  SingletransactionModel,
} from '../model/open-data';

@Injectable({
  providedIn: 'root',
})
export class RSPService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/RSP`;
  constructor(http: HttpClient) {
    super(http);
  }

  GetRspDayList(
    request: GetRspDayListArgs
  ): Observable<ResponseBase<GetRspDayListResponse>> {
    const url = `${this.baseUrl}/GetRspDayList`;
    return this.http.post<ResponseBase<GetRspDayListResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetRspQuery(
    request: GetRspQueryArgs
  ): Observable<ResponseBase<GetRspQueryResponse>> {
    const url = `${this.baseUrl}/GetRspQuery`;
    return this.http.post<ResponseBase<GetRspQueryResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetRegulyBuyDiff(
    request: GetRegulyBuyDiffArgs
  ): Observable<ResponseBase<GetRegulyBuyDiffResponse[]>> {
    const url = `${this.baseUrl}/GetRegulyBuyDiff`;
    return this.http.post<ResponseBase<GetRegulyBuyDiffResponse[]>>(
      url,
      request,
      this.httpOptions
    );
  }

  EditRegulyBuyDiff(
    request: EditRegulyBuyDiffArgs
  ): Observable<ResponseBase<EditRegulyBuyDiffResponse>> {
    const url = `${this.baseUrl}/EditRegulyBuyDiff`;
    return this.http.post<ResponseBase<EditRegulyBuyDiffResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  InsertRegulyBuyDiff(
    request: EditRegulyBuyDiffResponse
  ): Observable<ResponseBase<InsertRegulyBuyDiffResponse>> {
    const url = `${this.baseUrl}/InsertRegulyBuyDiff`;
    return this.http.post<ResponseBase<InsertRegulyBuyDiffResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  SendSingletransactionApply(
    request: SingletransactionModel
  ): Observable<ResponseBase<boolean>> {
    const url = `${this.baseUrl}/SendSingletransactionApply`;
    return this.http.post<ResponseBase<boolean>>(
      url,
      request,
      this.httpOptions
    );
  }

  SendRegularSavingPlanApply(
    request: RegularSavingPlanModel
  ): Observable<ResponseBase<boolean>> {
    const url = `${this.baseUrl}/SendSingletransactionApply`;
    return this.http.post<ResponseBase<boolean>>(
      url,
      request,
      this.httpOptions
    );
  }
}
