
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import {CheckFundRedemptionArgs,SaveFundRedemptionArgs,SaveFundRedemptionResponse,GetRemitAccountResponse,RemitAccountList,GetRemitAccountArgs } from '../model/fundredemption';
import {GetSwitchFundListResponse, GetRedemFundDataResponse, GetRedemFundDataArgs, GetAllowRedemDataArgs, GetSwitchFundListArgs,GetAllowRedemDataResponse } from '../model/transfersubscription';
@Injectable({
  providedIn: 'root',
})
export class fundredemptionService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/fundredemption`;
  constructor(
    http: HttpClient
  ) {
    super(http);

  }

  GetRemitAccount(request: GetRemitAccountArgs): Observable<ResponseBase<GetRemitAccountResponse>> {
    const url = `${this.baseUrl}/GetRemitAccount`;
    return this.http.post<ResponseBase<GetRemitAccountResponse>>(
      url,
      request,
      this.httpOptions);
  }

  CheckFundRedemption(request: CheckFundRedemptionArgs): Observable<ResponseBase<GetSwitchFundListResponse>> {
    const url = `${this.baseUrl}/CheckFundRedemption`;
    return this.http.post<ResponseBase<GetSwitchFundListResponse>>(
      url,
      request,
      this.httpOptions);
  }

  SaveFundRedemption(request: SaveFundRedemptionArgs): Observable<ResponseBase<SaveFundRedemptionResponse>> {
    const url = `${this.baseUrl}/SaveFundRedemption`;
    return this.http.post<ResponseBase<SaveFundRedemptionResponse>>(
      url,
      request,
      this.httpOptions);
  }
}


