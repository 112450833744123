import { FormsModule } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { disclaimerService } from 'src/app/services/disclaimer.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { GetKYCQuestionResponse, ChkMemStatusArgs, OpenMemberArgs, OpenMemberResponse, Kyc, KycDetail, GetCountryCDResponse } from './../../model/disclaimer';
import { GetKycQuestionArgs, GetKycQuestionResponse, KYCQuestionDtl, KYCQuestionList, PostKycQuestionArgs, PostKycQuestionResponse } from 'src/app/model/personal';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-open-account-all',
  templateUrl: './open-account-all2.component.html',
  styleUrls: ['./open-account-all2.component.css']
})

export class OpenAccountAllComponent implements OnInit {

  OpenMemberArgs = new OpenMemberArgs();
  ChkMemStatusArgs = new ChkMemStatusArgs();
  OpenMemberResponse = new OpenMemberResponse();

  GetCountryCDResponse = new GetCountryCDResponse();
  GetKYCQuestionResponse = new GetKYCQuestionResponse();
  GetKycQuestionResponse = new GetKycQuestionResponse();
  GetKycQuestionArgs = new GetKycQuestionArgs();

  kyc = new Kyc(); //套印渲染kyc選項用
  tempkyc = new KycDetail() //kyc複選比較

  Brh = '';

  constructor(
    private _disclaimerService: disclaimerService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    this.ChkMemStatusArgs.ID_NO = this.activatedRoute.snapshot.queryParams["Code"];
    this.OpenAccount();
    this.GetCountry();
  }

  ShowCountry(Code: string) {

    var name = this.GetCountryCDResponse.CountryList.find(x => x.COUNTRY_CD == Code);

    if (name != undefined) {
      return name.COUNTRY_NAME;
    }
    else if (Code == '') {
      return '';
    }
    else {
      return '資料錯誤';
    }
  }

  GetCountry() {
    this._disclaimerService.GetCountryCD().subscribe(res => {
      this.GetCountryCDResponse = res.Entries;
    })
  }

  OpenAccount() {
    this._disclaimerService.OpenAccountAll(this.ChkMemStatusArgs).subscribe(res => {
      this.OpenMemberResponse = res.Entries;
      this.temp(res.Message);
      // this.runKyc();
      this.ChooseBrh();
      // debugger
      // if (res.StatusCode === EnumStatusCode.Success) {
      //   this.GetKYCQuestion();
      // }
      // console.log(this.OpenMemberResponse);
    });
  }

  print() {
    window.print()
  }

  GetKYCQuestion() {
    this.GetKycQuestionArgs.BF_COUNTRY_X = "01";
    this._disclaimerService.GetKYCQuestion(this.GetKycQuestionArgs).subscribe(res => {
      this.GetKYCQuestionResponse = res.Entries;
      if (res.StatusCode === EnumStatusCode.Success) {
        setTimeout(() => {
          // this.print()
        }, 1000);
      }
    });
  }

  getKYCQuestionList(QSN_ORDER_TYPE: string) {
    if (this.OpenMemberResponse && this.OpenMemberResponse.KYCQuestionList) {
      return this.OpenMemberResponse.KYCQuestionList.filter(x => x.QSN_ORDER.startsWith(QSN_ORDER_TYPE))
    }
    else {
      return [] as KYCQuestionList[];

    }
  }

  findDtlTxtArray(list: KYCQuestionDtl[]) {
    var text = [] as string[];

    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.LAST_IS_CHK_YN == 'Y') {

        text.push(element.ANS_ITEM);
      }
    }

    return text.join(',');
  }

  strsubstr(v: string, s: number, e: number) {
    return v.substring(s, e);
  };

  strsubstrBRH(v: string, e: string) {
    // v 總行名稱
    // e 分行名稱
    var bank = v.length;
    var brh = e.length;
    return e.substring(bank, brh);
  };

  runKyc() {
    let kycQuestion = this.OpenMemberResponse.CusKycInfo[0].CusKycDetail.filter(x => x.IS_CHK_YN == 'Y');

    for (let i = 0; i < kycQuestion.length; i++) {
      if (kycQuestion[i].IS_CHK_YN == 'Y') {
        var kycDetail = new KycDetail()
        kycDetail.KYC_QUESTION = kycQuestion[i].KYC_QUESTION;
        kycDetail.QSN_SEQNO = kycQuestion[i].QSN_SEQNO;
        kycDetail.ANS_ORDER = kycQuestion[i].ANS_ORDER;
        kycDetail.ANS_TEXT_IN = kycQuestion[i].ANS_TEXT_IN;
        kycDetail.ANS_SCORE = kycQuestion[i].ANS_SCORE;

        if (kycQuestion[i].QSN_SEQNO == 11 || kycQuestion[i].QSN_SEQNO == 12) {
          if (kycQuestion[i].QSN_SEQNO == kycQuestion[i - 1].QSN_SEQNO) {
            this.tempkyc.KYC_QUESTION = kycQuestion[i - 1].KYC_QUESTION;
            this.tempkyc.QSN_SEQNO = kycQuestion[i - 1].QSN_SEQNO;
            this.tempkyc.ANS_ORDER = kycQuestion[i - 1].ANS_ORDER;
            this.tempkyc.ANS_TEXT_IN = kycQuestion[i - 1].ANS_TEXT_IN;
            this.tempkyc.ANS_SCORE = kycQuestion[i - 1].ANS_SCORE;
          }
          // 如果題目相同 然後分數又比暫存kyc高的話 則指定新的
          if (kycDetail.QSN_SEQNO == this.tempkyc.QSN_SEQNO) {
            this.kyc.KycDetail.pop();
            this.kyc.KycDetail.push(kycDetail);
          }
          else {
            this.kyc.KycDetail.push(kycDetail);
          }
        }
        else {
          this.kyc.KycDetail.push(kycDetail);
        }
      }
    }
    setTimeout(() => {
      this.print()
    }, 1000);
  }

  ChooseBrh() {
    if (this.OpenMemberResponse.CusAccount4[0].BANK_BRH.length > 0) {
      this.Brh = this.OpenMemberResponse.CusAccount4[0].BANK_BRH.substring(0, 3);
    }
  }

  GetDate(value: Date, p: number) {
    switch (p) {
      case 1:
        return value.toString().substring(0, 4);
      case 2:
        return value.toString().substring(5, 7);
      default:
        return value.toString().substring(8, 10);
    }
  }

  temp(value: string) {
    const AAA = document.getElementById('AAA') as HTMLElement;
    AAA.innerHTML = value.split('|')[0];

    const BBB = document.getElementById('BBB') as HTMLElement;
    BBB.innerHTML = value.split('|')[1];
    setTimeout(() => {
      this.print()
    }, 1000);
  }
}
