<nb-layout>

  <nb-layout-header fixed>
  <!-- Insert header here -->
  </nb-layout-header>

  <nb-layout-column>
    <router-outlet></router-outlet>
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>

    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-modal/0.9.1/jquery.modal.min.js"></script>
    <!--include plugin js-->
    <!--js run code-->
    <script type="text/javascript">
      function initPage1() {
        var rvnMenu = $("#navbar").rvnm({
          // mode: 'mobile',
          // responsive: false,
          searchable: true,
          theme: 'dark-lesb'
        });
        console.log(rvnMenu);
        //  rvnMenu.setMode('minimal');
        rvnMenu.setTheme('dark-ruby');
      }

      function initPage2() {
        $('.mobiletable').on('click', function () {
          $(this).toggleClass('top10mobHide');
        });
        $('.closeBtn').click(function () {
          $('.cookiePolicy').hide();
        });
        $('.closeTap a').click(function () {
          $('.notificationInnerBlock').slideToggle('slow');
          $('.closeTap a i').toggleClass('rotate');
        });
        $('.tgAction').click(function () {

          if ($(this).hasClass('expanded')) {
            $('.tgActionText').css({ height: '74px' });
            $(this).removeClass('expanded')
          }
          else {
            $('.tgActionText').css({ height: 'auto' });
            $(this).addClass('expanded')
          }

          $('.tgAction i').toggleClass('rotate');
        });
      }

      function initPage3() {
        var $backToTopEle = $('.toTop'), $backToTopFun = function () {
          var st = $(document).scrollTop(), winh = $(window).height();
          (st > 200) ? $backToTopEle.fadeIn('slow') : $backToTopEle.fadeOut('slow');
          //IE6下的定位
          if (!window.XMLHttpRequest) {
            $backToTopEle.css("top", st + winh - 166);
          }
        };
        $('.toTop').click(function () {
          $("html, body").animate({ scrollTop: 0 }, 1200);
        })
        $backToTopEle.hide();
        $backToTopFun();
        $(window).bind("scroll", $backToTopFun);
        $('#catalogWord').click(function () {
          $("#catalog").slideToggle(600);
        })

      }

    </script>

  </nb-layout-column>

  <nb-layout-footer fixed>
  <!-- Insert footer here -->
  </nb-layout-footer>

</nb-layout>
