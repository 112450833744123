<footer>
  <div class="w-1200">
    <div class="footer-top">
        <div class="footer-left">
        <img src="assets/images/footer-logo.svg">
        <p>台北市新生南路一段50號4樓客服專線：(02) 23511707
        </p>
        </div>
        <div class="footer-right"><img src="assets/images/ec-footer-img.png"></div>
    </div>
    <div class="ftTextDown">
        <div class="tgText">
            <div class="tgActionText" [ngClass]="{'show':isShow}">
                <!-- ngRepeat: footerAfter in FooterAfters -->
                <div ng-repeat="footerAfter in FooterAfters" ng-bind-html="footerAfter.cContent" class="ng-binding ng-scope">
                    <p>注意：本基金經金管會核准，惟不表示本基金絕無風險，經理公司以往之績效，亦不保證本基金之最低投資效益，本公司除盡善良管理人之注意義務外，不負責本基金之盈虧，亦不保證最低之收益，投資人申購前，請詳閱基金公開說明書。 依據"洗錢防制法"規定，本公司將針對現金交易五十萬元以上客戶，進行確認客戶身分之程序(請客戶提供身分證明文件)，如造成您的不便，敬請見諒。</p>
                </div>
                <!-- end ngRepeat: footerAfter in FooterAfters -->
             </div>
            <span class="tgAction" (click)="isShow = !isShow">
              完全展開
              <i class="fas fa-angle-down" [ngClass]="{rotate:isShow}"></i>
            </span>
        </div>

    </div>
  </div>
  </footer>
  <div class="footer-red">※本網站內容之所有權歸台中銀投信所有，未經本公司正式書面同意，不得將網站全部或部分內容轉載於任何形式媒體</div>
