<header>
  <div class="logo"><img src="./assets/images/logo.png" /></div>
  <div class="header-right-box">
    <a *ngIf="token" href="javascript:void(0)" class="Logout" (click)="logoutweb()">
      <i class="fas fa-sign-out-alt"></i>
      登出
    </a>
    <a href="https://www.tcbsitc.com.tw" class="Back-web">回官網</a>
    <a
      *ngIf="token"
      href="javascript:void(0)"
      class="Logout"
      (click)="clickToast()">
      <i class="fas fa-bell"></i>
      通知
      <span class="red-cicle">
        {{ToastForCountResponse.PersonMsgCount}}
      </span>
    </a>
    <div [class]="isToastDivShow">
      <div class="toastDiv-body">
        <div class="news-list toast">
          <li *ngFor="let item of ToastResponse">
            <div>
              <div class="news-c">
                <span [class]="toastClassList[item.Cstatusid - 1]">{{
                  item.Cstatusname
                }}</span
                ><a href="#">{{ item.CMsgParam1 }}</a>
              </div>
              <samp>{{ item.CCreateDt }}</samp>
            </div>
          </li>
        </div>
      </div>
    </div>
  </div>
</header>
