import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ChkMemStatusArgs, ChkMemStatusResponse, CountryList, CheckIdArgs, BankMsg } from './../../model/disclaimer';
import { GetCountryCDResponse, GetBankDataArgs, GetBankDataResponse, BankList } from './../../model/disclaimer';
import { GetSubBankDataArgs, GetSubBankDataResponse, SubBankDetails } from './../../model/disclaimer';
import { GetKYCQuestionArgs, GetKYCQuestionResponse, ProductImg, OpenMemberArgs, OpenMemberResponse, CusAccountList, CusKycInfo, CusKycDetail } from './../../model/disclaimer';
import { disclaimerService } from 'src/app/services/disclaimer.service';
import { MessageService } from '../../services/message.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { ValidationHelper } from 'src/app/helper/validationHelper';
import ImageCompressor from 'image-compressor.js'
import { PersonalService } from 'src/app/services/personal';
import { GetKycRiskScoreArgs, GetKycRiskScoreResponse } from 'src/app/model/open-data';
import { GetKycQuestionArgs, GetKycQuestionResponse, KYCQuestionDtl, KYCQuestionList, PostKycQuestionArgs, PostKycQuestionResponse } from 'src/app/model/personal';
import { ViewportScroller } from '@angular/common';
import { disclaimerMsg } from 'src/app/model/disclaimerMsg';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.css']
})
export class DisclaimerComponent implements OnInit {



  GetKycRiskScoreArgs = new GetKycRiskScoreArgs();
  GetKycRiskScoreResponse = new GetKycRiskScoreResponse();

  PostKycQuestionArgs = new PostKycQuestionArgs();
  PostKycQuestionResponse = new PostKycQuestionResponse();

  ChkMemStatusArgs = new ChkMemStatusArgs();
  GetBankDataArgs = new GetBankDataArgs();
  GetSubBankDataArgs = new GetSubBankDataArgs();
  // GetKYCQuestionArgs = new GetKYCQuestionArgs();
  GetKycQuestionArgs = new GetKycQuestionArgs();
  OpenMemberArgs = new OpenMemberArgs();

  ChkMemStatusResponse = new ChkMemStatusResponse();
  GetCountryCDResponse = new GetCountryCDResponse();
  GetBankDataResponse = new GetBankDataResponse();
  GetBankDataResponse2 = new GetBankDataResponse();
  GetBankDataResponse3 = new GetBankDataResponse();
  GetKYCQuestionResponse = new GetKYCQuestionResponse();
  GetKycQuestionResponse = new GetKycQuestionResponse();
  OpenMemberResponse = new OpenMemberResponse();
  GetSubBankDataResponse = new GetSubBankDataResponse();
  GetSubBankDataResponse2 = new GetSubBankDataResponse();
  GetSubBankDataResponse3 = new GetSubBankDataResponse();
  GetSubBankDataResponse4 = new GetSubBankDataResponse();
  GetSubBankDataResponse5 = new GetSubBankDataResponse();
  //檢查兩個法定代理人身分證
  CheckIdArgs = new CheckIdArgs();
  idres = 0;
  age = 0;

  // 檢查必填欄位是否都有填寫
  check: boolean;

  //欄位錯誤提示
  disMsg = new disclaimerMsg();

  //銀行長度顯示
  BankMsg = [{}, {}, {}, {}, {}] as BankMsg[]

  BankList = new BankList();
  CountryList = new CountryList();
  SubBankDetails = new SubBankDetails();
  KYCQuestionList = new KYCQuestionList();
  KYCQuestionDtl = new KYCQuestionDtl();
  CusAccountList = new CusAccountList();
  CusAccountList2 = new CusAccountList();
  CusAccountList3 = new CusAccountList();
  CusAccountList4 = new CusAccountList();
  CusAccountList5 = new CusAccountList();
  CusAccountList6 = new CusAccountList();
  CusAccountList7 = new CusAccountList();
  print = '';
  CusKycInfo = new CusKycInfo();
  CusKycDetailList = new CusKycDetail();


  ReadInfostep1: boolean;
  ReadInfostep2: boolean;
  pagecontact: number = 0;


  imgURL = new ProductImg();
  imgURL2 = new ProductImg();
  imgURL3 = new ProductImg();
  imgURL4 = new ProductImg();
  imgURL5 = new ProductImg();
  imgURL6 = new ProductImg();
  imgURL7 = new ProductImg();
  imgURL8 = new ProductImg();
  imgURL9 = new ProductImg();
  imgURL10 = new ProductImg();
  imgURL11 = new ProductImg();
  imgURL12 = new ProductImg();

  //驗證帳號長度用
  // 0 扣款 1 買回 2 買回2 3 收益 4 申贖
  countList = [0, 0, 0, 0, 0] as number[];

  constructor(
    private _personalService: PersonalService,
    private valid: ValidationHelper,
    private message: MessageService,
    private _disclaimerService: disclaimerService,
    private scroll: ViewportScroller
  ) {



    if (localStorage.getItem('page') != '立即開戶') {
      // debugger
      localStorage.setItem('page', '立即開戶');
      location.reload()
    }

    this.imgURL.ImgType = "1";
    this.imgURL2.ImgType = "2";
    this.imgURL3.ImgType = "3";
    this.imgURL4.ImgType = "4";
    this.imgURL5.ImgType = "5";
    this.imgURL6.ImgType = "6";
    this.imgURL7.ImgType = "7";
    this.imgURL8.ImgType = "8";
    this.imgURL9.ImgType = "9";
    this.imgURL10.ImgType = "10";
    this.imgURL11.ImgType = "11";
    this.imgURL12.ImgType = "12";

    this.OpenMemberArgs.APPLY_DTTM = new Date();

    //預設帳戶Type
    this.CusAccountList.ACC_SUB_ID = "1"; // 扣款帳號
    this.CusAccountList.ACC_NO_TYPE = "0";

    this.CusAccountList2.ACC_SUB_ID = "2"; // 買回(一)
    this.CusAccountList2.ACC_NO_TYPE = "0";

    this.CusAccountList3.ACC_SUB_ID = "2"; // 買回(二)
    this.CusAccountList3.ACC_NO_TYPE = "0";

    this.CusAccountList4.ACC_SUB_ID = "3"; // 收益配息
    this.CusAccountList4.ACC_NO_TYPE = "0";

    this.CusAccountList5.ACC_SUB_ID = "1"; // 外幣綜合
    this.CusAccountList5.ACC_NO_TYPE = "2";

    // this.CusAccountList6.ACC_SUB_ID = "2"; // 外幣綜合
    // this.CusAccountList6.ACC_NO_TYPE = "2";

    // this.CusAccountList7.ACC_SUB_ID = "3"; // 外幣綜合
    // this.CusAccountList7.ACC_NO_TYPE = "2";

    //預設選擇帳號
    this.CusAccountList.BANK_HQ = '-1';
    // this.CusAccountList.BANK_BRH = '-1';
    this.CusAccountList2.BANK_HQ = '-1';
    // this.CusAccountList2.BANK_BRH = '-1';
    this.CusAccountList3.BANK_HQ = '-1';
    // this.CusAccountList3.BANK_BRH = '-1';
    this.CusAccountList4.BANK_HQ = '-1';
    // this.CusAccountList4.BANK_BRH = '-1';
    this.CusAccountList5.BANK_HQ = '-1';
    // this.CusAccountList5.BANK_BRH = '-1';
  }

  ReadInfostep() {
    //console.log("ReadInfostep1", this.ReadInfostep1);
  }

  ngOnInit(): void {
    this.GetCountryCD();
    this.GetBankData();
    this.GetBankData2();
    this.GetBankData3();
    this.GetKYCQuestion();
    // this.CountrySelect(0, 'TW');
    // this.CountrySelect(1, 'TW');
    // this.CountrySelect(2, 'TW');
    // this.CountrySelect(3, 'TW');
  }

  save1(e: number) {
    let bir_date = parseInt(this.OpenMemberArgs.BIR_DATE1 + this.OpenMemberArgs.BIR_DATE2.padStart(2, '0') + this.OpenMemberArgs.BIR_DATE3.padStart(2, '0'));
    // let now_date = parseInt(new Date().toLocaleDateString().replace(/\//g, ''));
    let now = new Date();
    let now_date = parseInt(now.getFullYear().toString() + (now.getMonth() + 1).toString().padStart(2, '0') + now.getDate().toString().padStart(2, '0'));
    this.age = (now_date - bir_date) / 10000;
    this.scroll.scrollToPosition([0, 0]);

    this.valid.clear();

    if (e == 2) {
      this.valid.clear();
      if (this.ReadInfostep1 != true) {
        this.valid.addErrorMessage('請點選同意');
      }

      if (this.valid.errorMessages.length > 0) {
        this.message.showErrorMSGs(this.valid.errorMessages);
      }
      else {
        this.pagecontact = e;
      };
    }

    if (e == 1) {

      if (this.ChkMemStatusArgs.ID_NO == '') {
        this.valid.addErrorMessage('請輸入身分證字號');
      }
      if (this.ReadInfostep2 != true) {
        this.valid.addErrorMessage('請點選同意');
      }
      if (this.valid.errorMessages.length > 0) {
        this.message.showErrorMSGs(this.valid.errorMessages);
      }
      else {
        this.OpenMemberArgs.ID_NO = this.ChkMemStatusArgs.ID_NO;
        this.OpenMemberArgs.BF_COUNTRY_X = "01";
        this.OpenAccount();
      };
      // 測試 跳頁用
      // this.pagecontact = 7;
    }
    if (e == 3) {
      this.CheckId();
    }
    if (e == 4) {
      if (this.imgURL.ImageFile == '') {
        this.valid.addErrorMessage('請上傳身分證正面');
      }
      if (this.imgURL2.ImageFile == '') {
        this.valid.addErrorMessage('請上傳身分證反面');
      }
      if (this.imgURL3.ImageFile == '') {
        this.valid.addErrorMessage('請上傳第二證件正面');
      }

      if (this.age < 18 && this.imgURL5.ImageFile == '') {
        this.valid.addErrorMessage('請上傳第一監護人身份證正面');
      }
      if (this.age < 18 && this.imgURL6.ImageFile == '') {
        this.valid.addErrorMessage('請上傳第一監護人身份證反面');
      }
      if (this.age < 18 && this.imgURL7.ImageFile == '') {
        this.valid.addErrorMessage('請上傳第一監護人第二證件正面');
      }
      // if (this.age < 18 && this.imgURL8.ImageFile == '') {
      //   this.valid.addErrorMessage('請上傳第一監護人第二證件反面');
      // }

      if (this.valid.errorMessages.length > 0) {
        this.message.showErrorMSGs(this.valid.errorMessages);

      }
      else {
        let list = [] as any[];
        list.push(this.imgURL);
        list.push(this.imgURL2);
        list.push(this.imgURL3);
        list.push(this.imgURL4);
        list.push(this.imgURL5);
        list.push(this.imgURL6);
        list.push(this.imgURL7);
        list.push(this.imgURL8);
        list.push(this.imgURL9);
        list.push(this.imgURL10);
        list.push(this.imgURL11);
        list.push(this.imgURL12);
        //console.log("list", list);

        this.OpenMemberArgs.ProductImgArgs = list;
        this.valid.clear();
        this.pagecontact = e;
      }
    }
    if (e == 5) {
      this.getKycRiskScore();
      this.check = true;
      this.postKycQuestion()
      if (this.check) {
        this.pagecontact = e;
      }
    }
    if (e == 6) {
      this.OpenMemberArgs.ID_NO = this.ChkMemStatusArgs.ID_NO;
      this.OpenMemberArgs.BF_COUNTRY_X = "01";
      this._disclaimerService.OpenAccount(this.ChkMemStatusArgs).subscribe(res => {
        this.ChkMemStatusResponse = res.Entries;
        if (this.ChkMemStatusResponse.OPEN_STATUS == '' || this.ChkMemStatusResponse.OPEN_STATUS.length == 0) {
          this.SaveDisclaimer();
        }
        else if (this.ChkMemStatusResponse.OPEN_STATUS = 'Y') {
          this.valid.addErrorMessage('此帳戶已重複開戶，請洽台中銀投信');
          this.message.showErrorMSGs(this.valid.errorMessages);
          this.pagecontact = 0;
        }
        else if (this.ChkMemStatusResponse.OPEN_STATUS = 'N') {
          this.valid.addErrorMessage('此帳戶已重複開戶，請洽台中銀投信');
          this.message.showErrorMSGs(this.valid.errorMessages);
          this.pagecontact = 0;
        }
        //console.log("pagecontact", this.pagecontact);
        //console.log("this.ChkMemStatusResponse.OPEN_STATUS", this.ChkMemStatusResponse.OPEN_STATUS);
      });
    }
    if (e == 7) {
      this.pagecontact = e;
    }
    // console.log("e", e);
  }

  SaveDisclaimer() {
    this.valid.clear();
    this.OpenMemberArgs.BIR_DATE2 = this.OpenMemberArgs.BIR_DATE2.padStart(2, '0');
    this.OpenMemberArgs.BIR_DATE3 = this.OpenMemberArgs.BIR_DATE3.padStart(2, '0')

    this._disclaimerService.SaveDisclaimer(this.OpenMemberArgs).subscribe(res => {
      res.StatusCode;
      if (res.StatusCode == 0) {
        this.valid.addErrorMessage(res.Message);
        this.message.showErrorMSGs(this.valid.errorMessages);
        this.pagecontact = 6;
        this.print = res.Entries;
      }
      else {
        this.valid.addErrorMessage(res.Message);
        this.message.showErrorMSGs(this.valid.errorMessages);
      }
    });
  }


  //帳號
  OpenAccount() {

    this._disclaimerService.OpenAccount(this.ChkMemStatusArgs).subscribe(res => {
      this.ChkMemStatusResponse = res.Entries;
      if (this.ChkMemStatusResponse.OPEN_STATUS == '' || this.ChkMemStatusResponse.OPEN_STATUS.length == 0) {
        this.pagecontact = 1;
      }
      else if (this.ChkMemStatusResponse.OPEN_STATUS == 'Y') {
        // this.valid.addErrorMessage('您已開戶完成。\n無法於此處申請我的e帳戶。\n請至表格下載區及常見問題查詢並下載相關表格填寫郵寄至本公司或您可致電台中銀投信客戶服務專線，或Mail至客服信箱我們將竭誠為您服務！');
        // this.message.showErrorMSGs(this.valid.errorMessages);
      }
      else if (this.ChkMemStatusResponse.OPEN_STATUS == 'N') {
        this.valid.addErrorMessage('未開戶完成');
        this.message.showErrorMSGs(this.valid.errorMessages);
      }
      //console.log("pagecontact", this.pagecontact);
      //console.log("this.ChkMemStatusResponse.OPEN_STATUS", this.ChkMemStatusResponse.OPEN_STATUS);
    });
  }

  //國別
  GetCountryCD() {
    this._disclaimerService.GetCountryCD().subscribe(res => {
      this.GetCountryCDResponse = res.Entries;

      this.OpenMemberArgs.BF_NATIONALITY = "TW";
      this.OpenMemberArgs.AGENT_IN_LAW_NALITY1 = "TW";
      this.OpenMemberArgs.AGENT_IN_LAW_NALITY2 = "TW";
      this.OpenMemberArgs.NATIONALITY_BIRTH = "TW";
    });
  }

  //銀行
  GetBankData() {
    this.GetBankDataArgs.BANK_TYPE = "1";
    this._disclaimerService.GetBankData(this.GetBankDataArgs).subscribe(res => {
      this.GetBankDataResponse = res.Entries;

    });
  }
  GetBankData2() {
    this.GetBankDataArgs.BANK_TYPE = "2";
    this._disclaimerService.GetBankData(this.GetBankDataArgs).subscribe(res => {
      this.GetBankDataResponse2 = res.Entries;

    });
  }

  GetBankData3() {
    this.GetBankDataArgs.BANK_TYPE = "3";
    this._disclaimerService.GetBankData(this.GetBankDataArgs).subscribe(res => {
      this.GetBankDataResponse3 = res.Entries;

    });
  }
  //分行
  GetSubBankInCusAccountList(e: any, n: number) {

    switch (n) {
      case 1:
        this.CusAccountList = e.value;
        break;
      case 2:
        this.CusAccountList2 = e.value;
        break;
      case 3:
        this.CusAccountList3 = e.value;
        break;
      case 4:
        this.CusAccountList4 = e.value;
        break;
      case 5:
        this.CusAccountList5 = e.value;
        break;
    }
  }
  GetSubBankData(e: any, n: number) {
    this.GetSubBankDataArgs.BANK_HQ = e.value;
    this.GetSubBankDataArgs.BANK_TYPE = n == 1 ? '1' :
      n == 5 ? '3' : '2'
    this._disclaimerService.GetSubBankData(this.GetSubBankDataArgs).subscribe(res => {

      switch (n) {
        case 1:
          this.CusAccountList.BANK_BRH = '';
          this.GetSubBankDataResponse = res.Entries;
          break;
        case 2:
          this.CusAccountList2.BANK_BRH = '';
          this.GetSubBankDataResponse2 = res.Entries;
          break;
        case 3:
          this.CusAccountList3.BANK_BRH = '';
          this.GetSubBankDataResponse3 = res.Entries;
          break;
        case 4:
          this.CusAccountList4.BANK_BRH = '';
          this.GetSubBankDataResponse4 = res.Entries;
          break;
        case 5:
          this.CusAccountList5.BANK_BRH = '';
          this.GetSubBankDataResponse5 = res.Entries;
          break;
      }
    });
  }
  //總行連動分行
  selectBankData(e: any, n: number) {
    if (e.value != '-1') {

      this.GetSubBankData(e, n);

      // debugger;
      switch (n) {
        case 1:
          for (let index = 0; index < this.GetBankDataResponse.BankList.length; index++) {
            if (e.value == this.GetBankDataResponse.BankList[index].BANK_HQ) {
              // 依據選擇的欄位 賦予對應的index 對應List -1
              this.countList[n - 1] = index;
              // console.log(`${this.countList[n - 1]} 對應 ${this.GetBankDataResponse.BankList[this.countList[n - 1]].BANK_HQ_NAME}`)
              break;
            }
          }
          break;
        case 2:
        case 3:
        case 4:
          // 找出選擇的銀行在List第幾位
          for (let index = 0; index < this.GetBankDataResponse2.BankList.length; index++) {
            if (e.value == this.GetBankDataResponse2.BankList[index].BANK_HQ) {
              // 依據選擇的欄位 賦予對應的index 對應List -1
              this.countList[n - 1] = index;
              // console.log(`${this.countList[n - 1]} 對應 ${this.GetBankDataResponse2.BankList[this.countList[n - 1]].BANK_HQ_NAME}`)
              break;
            }
          }
          break;
        case 5:
          for (let index = 0; index < this.GetBankDataResponse3.BankList.length; index++) {
            if (e.value == this.GetBankDataResponse3.BankList[index].BANK_HQ) {
              // 依據選擇的欄位 賦予對應的index 對應List -1
              this.countList[n - 1] = index;
              // console.log(`${this.countList[n - 1]} 對應 ${this.GetBankDataResponse3.BankList[this.countList[n - 1]].BANK_HQ_NAME}`)
              break;
            }
          }
          break;
      }

      // 各欄位 帳號長度提示 為0則顯示空字串
      switch (n) {
        case 1:
          this.BankMsg[n - 1].len = this.GetBankDataResponse.BankList[this.countList[n - 1]].DEPOSIT_LENGTH == '0' ? '' : this.GetBankDataResponse.BankList[this.countList[n - 1]].DEPOSIT_LENGTH;
          this.BankMsg[n - 1].len1 = this.GetBankDataResponse.BankList[this.countList[n - 1]].DEPOSIT_LENGTH1 == '0' ? '' : this.GetBankDataResponse.BankList[this.countList[n - 1]].DEPOSIT_LENGTH1;
          this.BankMsg[n - 1].len2 = this.GetBankDataResponse.BankList[this.countList[n - 1]].DEPOSIT_LENGTH2 == '0' ? '' : this.GetBankDataResponse.BankList[this.countList[n - 1]].DEPOSIT_LENGTH2;
          break;
        case 2:
        case 3:
        case 4:
          this.BankMsg[n - 1].len = this.GetBankDataResponse2.BankList[this.countList[n - 1]].DEPOSIT_LENGTH == '0' ? '' : this.GetBankDataResponse2.BankList[this.countList[n - 1]].DEPOSIT_LENGTH;
          this.BankMsg[n - 1].len1 = this.GetBankDataResponse2.BankList[this.countList[n - 1]].DEPOSIT_LENGTH1 == '0' ? '' : this.GetBankDataResponse2.BankList[this.countList[n - 1]].DEPOSIT_LENGTH1;
          this.BankMsg[n - 1].len2 = this.GetBankDataResponse2.BankList[this.countList[n - 1]].DEPOSIT_LENGTH2 == '0' ? '' : this.GetBankDataResponse2.BankList[this.countList[n - 1]].DEPOSIT_LENGTH2;
          break;
        case 5:
          this.BankMsg[n - 1].len = this.GetBankDataResponse3.BankList[this.countList[n - 1]].DEPOSIT_LENGTH == '0' ? '' : this.GetBankDataResponse3.BankList[this.countList[n - 1]].DEPOSIT_LENGTH;
          this.BankMsg[n - 1].len1 = this.GetBankDataResponse3.BankList[this.countList[n - 1]].DEPOSIT_LENGTH1 == '0' ? '' : this.GetBankDataResponse3.BankList[this.countList[n - 1]].DEPOSIT_LENGTH1;
          this.BankMsg[n - 1].len2 = this.GetBankDataResponse3.BankList[this.countList[n - 1]].DEPOSIT_LENGTH2 == '0' ? '' : this.GetBankDataResponse3.BankList[this.countList[n - 1]].DEPOSIT_LENGTH2;
          break;
      }


      //由小排到大
      for (let index = 0; index < this.BankMsg.length - 1; index++) {

        var temp = '';

        if (parseInt(this.BankMsg[index].len) > parseInt(this.BankMsg[index].len1)) {
          temp = this.BankMsg[index].len1;
          this.BankMsg[index].len1 = this.BankMsg[index].len;
          this.BankMsg[index].len = temp;
        }

        if (parseInt(this.BankMsg[index].len1) > parseInt(this.BankMsg[index].len2)) {
          temp = this.BankMsg[index].len2;
          this.BankMsg[index].len2 = this.BankMsg[index].len1;
          this.BankMsg[index].len1 = temp;
        }
      }
    }
  }
  validation(): void {

    this.valid.clear();
    const date = new Date();
    // var datechecker: number;
    const regex_cell = /^09\d{8}$/;
    const regex_area = /^\d{1,4}$/;
    const regex_telfax = /^\d{1,8}$/;
    const regex_email = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    const regex_add = /^([\u4E00-\u9FFF]+[市|縣]){1}/;
    const regex_ext = /^\d{1,5}$/;
    const regex_bank = /\D{1}/;
    const regex_zip = /^\d{3,6}$/;
    const regex_year = /^\d{4}$/;
    const regex_month_day = /^\d{1,2}$/
    const regex_name = /^([\u4E00-\u9FFF]|[a-zA-Z -',])+$/;
    const regex_name_e = /^[a-zA-Z -',]+$/;


    if (this.OpenMemberArgs.BF_NAME == '') {
      this.valid.addErrorMessage('請輸入姓名');
    }
    else if (!regex_name.test(this.OpenMemberArgs.BF_NAME)) {
      this.valid.addErrorMessage('姓名格式不正確');
    }

    debugger;
    if (this.OpenMemberArgs.BIR_DATE1 == '' || this.OpenMemberArgs.BIR_DATE2 == '' || this.OpenMemberArgs.BIR_DATE3 == '') {
      this.valid.addErrorMessage('日期格式不正確');
    }
    else if (!(regex_year.test(this.OpenMemberArgs.BIR_DATE1) && regex_month_day.test(this.OpenMemberArgs.BIR_DATE2) && regex_month_day.test(this.OpenMemberArgs.BIR_DATE3))) {
      this.valid.addErrorMessage('日期格式不正確');
    }
    else if (Date.parse(`${this.OpenMemberArgs.BIR_DATE1}-${this.OpenMemberArgs.BIR_DATE2}-${this.OpenMemberArgs.BIR_DATE3}`) > date.getTime()) {
      this.valid.addErrorMessage('日期格式不正確');
    }
    else if (parseInt(this.OpenMemberArgs.BIR_DATE1) < 1911) {
      this.valid.addErrorMessage('日期格式不正確');
    }
    else if (parseInt(this.OpenMemberArgs.BIR_DATE2) > 12) {
      this.valid.addErrorMessage('日期格式不正確');
    }
    else if (parseInt(this.OpenMemberArgs.BIR_DATE2) <= 7) {
      if (this.OpenMemberArgs.BIR_DATE2 == '2') {

        // 閏年判斷
        if (parseInt(this.OpenMemberArgs.BIR_DATE1) % 400 == 0
          || (parseInt(this.OpenMemberArgs.BIR_DATE1) % 100 != 0
            && parseInt(this.OpenMemberArgs.BIR_DATE1) % 4 == 0)) {
          if (parseInt(this.OpenMemberArgs.BIR_DATE3) > 29) {
            this.valid.addErrorMessage('日期格式不正確');
          }
        }
        else {
          if (parseInt(this.OpenMemberArgs.BIR_DATE3) > 28) {
            this.valid.addErrorMessage('日期格式不正確');
          }
        }
      }
      else {
        var m = parseInt(this.OpenMemberArgs.BIR_DATE2) % 2

        switch (m) {
          case 0:
            if (parseInt(this.OpenMemberArgs.BIR_DATE3) > 30) {
              this.valid.addErrorMessage('日期格式不正確');
            }
            break;
          case 1:
            if (parseInt(this.OpenMemberArgs.BIR_DATE3) > 31) {
              this.valid.addErrorMessage('日期格式不正確');
            }
            break;
        }
      }
    }
    else if (parseInt(this.OpenMemberArgs.BIR_DATE2) >= 8) {
      var m = parseInt(this.OpenMemberArgs.BIR_DATE2) % 2

      switch (m) {
        case 0:
          if (parseInt(this.OpenMemberArgs.BIR_DATE3) > 31) {
            this.valid.addErrorMessage('日期格式不正確');
          }
          break;
        case 1:
          if (parseInt(this.OpenMemberArgs.BIR_DATE3) > 30) {
            this.valid.addErrorMessage('日期格式不正確');
          }
          break;
      }
    }
    // else {

    // datechecker = Date.parse(`${this.OpenMemberArgs.BIR_DATE1}-${this.OpenMemberArgs.BIR_DATE2}-${this.OpenMemberArgs.BIR_DATE3}`)
    // debugger
    // // 用回傳的毫秒數來比對大小
    // if (datechecker > date.getTime()) {
    //   this.valid.addErrorMessage('日期格式不正確');
    // }
    // }

    if (this.OpenMemberArgs.HM_TEL_AREA == '') {
      this.valid.addErrorMessage('請輸入連絡電話');
    }
    else if (!(regex_area.test(this.OpenMemberArgs.HM_TEL_AREA) && regex_telfax.test(this.OpenMemberArgs.HM_TEL))) {
      this.valid.addErrorMessage('連絡電話格式不正確');
    }

    if (this.OpenMemberArgs.OF_TEL_AREA != '') {
      if (!(regex_area.test(this.OpenMemberArgs.OF_TEL_AREA) && regex_telfax.test(this.OpenMemberArgs.OF_TEL))) {
        this.valid.addErrorMessage('公司電話格式不正確');
      }
    }

    if (this.OpenMemberArgs.OF_TEL_EXT != '' && !this.valid.errorMessages.includes('公司電話格式不正確')) {
      if (!regex_ext.test(this.OpenMemberArgs.OF_TEL_EXT)) {
        this.valid.addErrorMessage('公司電話格式不正確');
      }
    }

    if (this.OpenMemberArgs.FAX_TEL_AREA1 != '') {
      if (!(regex_area.test(this.OpenMemberArgs.FAX_TEL_AREA1) && regex_telfax.test(this.OpenMemberArgs.FAX_TEL1))) {
        this.valid.addErrorMessage('傳真電話格式不正確');
      }
    }

    if (this.OpenMemberArgs.CELL_PHONE != '') {
      // this.valid.addErrorMessage('請輸入手機電話');
      if (!regex_cell.test(this.OpenMemberArgs.CELL_PHONE)) {
        this.valid.addErrorMessage('手機電話格式不正確');
      }
    }


    if (this.OpenMemberArgs.MAIL_ZIP == '') {
      this.valid.addErrorMessage('請輸入通訊郵遞區號');
    }
    else if (!regex_zip.test(this.OpenMemberArgs.MAIL_ZIP)) {
      this.valid.addErrorMessage('通訊郵遞區號格式錯誤，請使用純數字輸入標準3+2 / 3+3郵遞區號。');
    }

    if (this.OpenMemberArgs.MAIL_ADDR == '') {
      this.valid.addErrorMessage('請輸入通訊地址');
    }
    else if (!regex_add.test(this.OpenMemberArgs.MAIL_ADDR)) {
      this.valid.addErrorMessage('通訊地址格式錯誤，缺少縣或市。');
    }

    if (this.OpenMemberArgs.ADDR_CODE1 != 'true') {
      // this.valid.addErrorMessage('請輸入通訊地址');

      if (this.OpenMemberArgs.PERM_ZIP == '') {
        this.valid.addErrorMessage('請輸入戶籍郵遞區號');
      }
      else if (!regex_zip.test(this.OpenMemberArgs.PERM_ZIP)) {
        this.valid.addErrorMessage('戶籍郵遞區號格式錯誤，請使用純數字輸入標準3+2 / 3+3郵遞區號。');
      }

      if (this.OpenMemberArgs.PERM_ADDR == '') {
        this.valid.addErrorMessage('請輸入戶籍地址');
      }
      else if (!regex_add.test(this.OpenMemberArgs.PERM_ADDR)) {
        this.valid.addErrorMessage('戶籍地址格式錯誤，缺少縣或市。');
      }
    }

    if (this.OpenMemberArgs.EMAIL1 == '') {
      this.valid.addErrorMessage('請輸入電子信箱');
    }
    else if (!regex_email.test(this.OpenMemberArgs.EMAIL1)) {
      this.valid.addErrorMessage('電子信箱格式不正確');
    }

    if (this.OpenMemberArgs.BF_NATIONALITY == '') {
      this.valid.addErrorMessage('請選擇國籍');
    }

    if (this.OpenMemberArgs.IS_USA == '') {
      this.valid.addErrorMessage('請選擇是否為美國稅務居民');
    }

    // if (this.OpenMemberArgs.MCD_YN == '') {
    //   this.valid.addErrorMessage('請選擇是否接收行銷訊息');
    // }

    if (this.OpenMemberArgs.CNT_PERSON != '') {
      if (!regex_name.test(this.OpenMemberArgs.CNT_PERSON)) {
        this.valid.addErrorMessage('連絡人姓名格式不正確')
      }
    }

    if (this.OpenMemberArgs.CNT_CELL_PHONE != '') {
      if (!regex_cell.test(this.OpenMemberArgs.CNT_CELL_PHONE)) {
        this.valid.addErrorMessage('連絡人手機電話格式不正確');
      }
    }

    if (this.OpenMemberArgs.CNT_OF_TEL_AREA != '') {
      if (!(regex_area.test(this.OpenMemberArgs.CNT_OF_TEL_AREA) && regex_telfax.test(this.OpenMemberArgs.CNT_OF_TEL))) {
        this.valid.addErrorMessage('連絡人公司電話格式不正確');
      }
    }

    // 如果 分機不為空字串 且錯誤清單中 無公司電話格式不正確 則檢查
    if (this.OpenMemberArgs.CNT_OF_TEL_EXT != '' && !this.valid.errorMessages.includes('連絡人公司電話格式不正確')) {
      if (!regex_ext.test(this.OpenMemberArgs.CNT_OF_TEL_EXT)) {
        this.valid.addErrorMessage('連絡人公司電話格式不正確');
      }
    }


    //RL20211109 - 未滿18歲檢查
    if (this.age < 18) {
      if (this.OpenMemberArgs.AGENT_IN_LAW1 == '') {
        this.valid.addErrorMessage('申請人未滿18歲，請填法定代理人(一)姓名');
      }
      if (this.OpenMemberArgs.AGENT_IN_LAW_ID == '') {
        this.valid.addErrorMessage('申請人未滿18歲，請填法定代理人(一)身份證號');
      }
      if (this.OpenMemberArgs.AGENT_IN_LAW_NALITY1 == '') {
        this.valid.addErrorMessage('申請人未滿18歲，請填法定代理人(一)國籍');
      }
    }

    if (this.OpenMemberArgs.AGENT_IN_LAW_ID != '' && this.IDChk(this.OpenMemberArgs.AGENT_IN_LAW_ID) != '0') {
      this.valid.addErrorMessage('法定代理人(一)身分證字號 格式不正確');
    }

    if (this.OpenMemberArgs.AGENT_IN_LAW_ID2 != '' && this.IDChk(this.OpenMemberArgs.AGENT_IN_LAW_ID2) != '0') {
      this.valid.addErrorMessage('法定代理人(二)身分證字號 格式不正確');
    }

    if (this.age < 18 || this.OpenMemberArgs.AGENT_IN_LAW1 != '') {
      if (!regex_name.test(this.OpenMemberArgs.AGENT_IN_LAW1)) {
        this.valid.addErrorMessage('法定代理人姓名(一) 格式不正確');
      }
    }

    if (this.age < 18 || this.OpenMemberArgs.AGENT_IN_LAW2 != '') {
      if (!regex_name.test(this.OpenMemberArgs.AGENT_IN_LAW2)) {
        this.valid.addErrorMessage('法定代理人姓名(二) 格式不正確');
      }

      if (this.idres == 1) {
        this.valid.addErrorMessage('法定代理人(一)身分證字號 格式不正確');
      }
      else if (this.idres == 3) {
        this.valid.addErrorMessage('法定代理人(二)身分證字號 格式不正確');
      }
      else if (this.idres == 4) {
        this.valid.addErrorMessage('法定代理人(一)身分證字號 格式不正確');
        this.valid.addErrorMessage('法定代理人(二)身分證字號 格式不正確');
      }
    }

    let list = [] as any[];
    // debugger;
    // var len1 = this.GetBankDataResponse.BankList.filter(x => x.BANK_HQ == this.CusAccountList.BANK_HQ)[0].DEPOSIT_LENGTH;
    if (this.CusAccountList.BANK_HQ == '-1' || this.CusAccountList.BANK_BRH == '') {
      this.valid.addErrorMessage('請選擇輸入扣款帳號');
    }
    else if (this.CusAccountList.ACCOUNT_NO == '') {
      this.valid.addErrorMessage('請選擇輸入扣款帳號');
    }
    else if (regex_bank.test(this.CusAccountList.ACCOUNT_NO)) {
      this.valid.addErrorMessage('扣款帳號 格式不正確');
    }
    else if (!(
      this.CusAccountList.ACCOUNT_NO.length.toString() == this.GetBankDataResponse.BankList[this.countList[0]].DEPOSIT_LENGTH
      || this.CusAccountList.ACCOUNT_NO.length.toString() == this.GetBankDataResponse.BankList[this.countList[0]].DEPOSIT_LENGTH1
      || this.CusAccountList.ACCOUNT_NO.length.toString() == this.GetBankDataResponse.BankList[this.countList[0]].DEPOSIT_LENGTH2
    )) {
      this.valid.addErrorMessage('扣款帳號 帳號長度不符');
    }
    else {
      list.push(this.CusAccountList);
      this.CusAccountList.BANK_HQ_NAME = this.GetBankDataResponse.BankList.filter(x => x.BANK_HQ == this.CusAccountList.BANK_HQ)[0].BANK_HQ_NAME;
      this.CusAccountList.BANK_BRH_NAME = this.GetSubBankDataResponse.SubBankDetails.filter(x => x.BANK_BRH == this.CusAccountList.BANK_BRH)[0].BANK_BRH_NAME;
    }

    if (this.CusAccountList2.BANK_HQ == '-1' || this.CusAccountList2.BANK_BRH == '') {
      this.valid.addErrorMessage('請選擇輸入約定買回帳戶');
    }
    else if (this.CusAccountList2.ACCOUNT_NO == '') {
      this.valid.addErrorMessage('請選擇輸入約定買回帳戶');
    }
    else if (regex_bank.test(this.CusAccountList2.ACCOUNT_NO)) {
      this.valid.addErrorMessage('買回價款匯入銀行(一) 格式不正確');
    }
    else if (!(
      this.CusAccountList2.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[1]].DEPOSIT_LENGTH
      || this.CusAccountList2.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[1]].DEPOSIT_LENGTH1
      || this.CusAccountList2.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[1]].DEPOSIT_LENGTH2
    )) {
      this.valid.addErrorMessage('買回價款匯入銀行(一) 帳號長度不符')
    }
    else {
      list.push(this.CusAccountList2);
      this.CusAccountList2.BANK_HQ_NAME = this.GetBankDataResponse2.BankList.filter(x => x.BANK_HQ == this.CusAccountList2.BANK_HQ)[0].BANK_HQ_NAME;
      this.CusAccountList2.BANK_BRH_NAME = this.GetSubBankDataResponse2.SubBankDetails.filter(x => x.BANK_BRH == this.CusAccountList2.BANK_BRH)[0].BANK_BRH_NAME;
    }

    if (this.CusAccountList3.BANK_HQ != '-1' || this.CusAccountList3.BANK_BRH != '') {
      if (this.CusAccountList3.ACCOUNT_NO == '') {
        this.valid.addErrorMessage('請選擇輸入約定買回帳戶(二)');
      }
    }

    if (this.CusAccountList3.ACCOUNT_NO != '') {
      if (this.CusAccountList3.BANK_HQ == '-1' || this.CusAccountList3.BANK_BRH == '') {
        this.valid.addErrorMessage('請選擇輸入約定買回帳戶(二)');
      }
      else if (regex_bank.test(this.CusAccountList3.ACCOUNT_NO)) {
        this.valid.addErrorMessage('買回價款匯入銀行(二) 格式不正確');
      }
      else if (!(
        this.CusAccountList3.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[2]].DEPOSIT_LENGTH
        || this.CusAccountList3.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[2]].DEPOSIT_LENGTH1
        || this.CusAccountList3.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[2]].DEPOSIT_LENGTH2
      )) {
        this.valid.addErrorMessage('買回價款匯入銀行(二) 帳號長度不符')
      }
      else {
        list.push(this.CusAccountList3);
        this.CusAccountList3.BANK_HQ_NAME = this.GetBankDataResponse2.BankList.filter(x => x.BANK_HQ == this.CusAccountList3.BANK_HQ)[0].BANK_HQ_NAME;
        this.CusAccountList3.BANK_BRH_NAME = this.GetSubBankDataResponse3.SubBankDetails.filter(x => x.BANK_BRH == this.CusAccountList3.BANK_BRH)[0].BANK_BRH_NAME;
      }
    }

    if (this.CusAccountList4.BANK_HQ == '-1' || this.CusAccountList4.BANK_BRH == '') {
      this.valid.addErrorMessage('請選擇輸入收益配息帳號');
    }
    else if (this.CusAccountList4.ACCOUNT_NO == '') {
      this.valid.addErrorMessage('請選擇輸入收益配息帳號');
    }
    else if (regex_bank.test(this.CusAccountList4.ACCOUNT_NO)) {
      this.valid.addErrorMessage('收益配息帳戶 格式不正確');
    }
    else if (!(
      this.CusAccountList4.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[3]].DEPOSIT_LENGTH
      || this.CusAccountList4.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[3]].DEPOSIT_LENGTH1
      || this.CusAccountList4.ACCOUNT_NO.length.toString() == this.GetBankDataResponse2.BankList[this.countList[3]].DEPOSIT_LENGTH2
    )) {
      this.valid.addErrorMessage('收益配息帳戶 帳號長度不符')
    }
    else {
      list.push(this.CusAccountList4);
      this.CusAccountList4.BANK_HQ_NAME = this.GetBankDataResponse2.BankList.filter(x => x.BANK_HQ == this.CusAccountList4.BANK_HQ)[0].BANK_HQ_NAME;
      this.CusAccountList4.BANK_BRH_NAME = this.GetSubBankDataResponse4.SubBankDetails.filter(x => x.BANK_BRH == this.CusAccountList4.BANK_BRH)[0].BANK_BRH_NAME;
    }

    if (this.CusAccountList5.BANK_HQ != '-1' || this.CusAccountList5.BANK_BRH != '') {
      if (this.CusAccountList5.ACCOUNT_NO == '') {
        this.valid.addErrorMessage('請選擇輸入外幣申贖帳號');
      }
    }

    if (this.CusAccountList5.ACCOUNT_NO != '') {
      if (this.CusAccountList5.BANK_HQ == '-1' || this.CusAccountList5.BANK_BRH == '') {
        this.valid.addErrorMessage('請選擇輸入外幣申贖帳號');
      }
      if (regex_bank.test(this.CusAccountList5.ACCOUNT_NO)) {
        this.valid.addErrorMessage('外幣申贖帳號 格式不正確');
      }
      else if (!(
        this.CusAccountList5.ACCOUNT_NO.length.toString() == this.GetBankDataResponse3.BankList[this.countList[4]].DEPOSIT_LENGTH
        || this.CusAccountList5.ACCOUNT_NO.length.toString() == this.GetBankDataResponse3.BankList[this.countList[4]].DEPOSIT_LENGTH1
        || this.CusAccountList5.ACCOUNT_NO.length.toString() == this.GetBankDataResponse3.BankList[this.countList[4]].DEPOSIT_LENGTH2
      )) {
        this.valid.addErrorMessage('外幣申贖帳號 帳號長度不符')
      }
    }


    if (this.CusAccountList5.ACCOUNT_NO.length > 0 && this.OpenMemberArgs.BF_NAME_E == '') {
      this.valid.addErrorMessage('請輸入英文姓名')
    }
    else if (this.CusAccountList5.ACCOUNT_NO.length > 0 && this.OpenMemberArgs.BF_NAME_E != '') {
      if (!regex_name_e.test(this.OpenMemberArgs.BF_NAME_E)) {
        this.valid.addErrorMessage('英文姓名格式不正確')
      }
      else {
        let CusAccountList6 = new CusAccountList()
        CusAccountList6.ACCOUNT_NO = this.CusAccountList5.ACCOUNT_NO
        CusAccountList6.ACC_SUB_ID = '2';
        CusAccountList6.ACC_NO_TYPE = '2';
        CusAccountList6.BANK_HQ = this.CusAccountList5.BANK_HQ;
        CusAccountList6.BANK_BRH = this.CusAccountList5.BANK_BRH;
        CusAccountList6.CRNCY_CD = ''; // 綜合外幣幣別指定為空字串

        let CusAccountList7 = new CusAccountList()
        CusAccountList7.ACCOUNT_NO = this.CusAccountList5.ACCOUNT_NO
        CusAccountList7.ACC_SUB_ID = '3';
        CusAccountList7.ACC_NO_TYPE = '2';
        CusAccountList7.BANK_HQ = this.CusAccountList5.BANK_HQ;
        CusAccountList7.BANK_BRH = this.CusAccountList5.BANK_BRH;
        CusAccountList7.CRNCY_CD = ''; // 綜合外幣幣別指定為空字串

        this.CusAccountList5.CRNCY_CD = '';

        list.push(this.CusAccountList5);
        list.push(CusAccountList6);
        list.push(CusAccountList7);

        this.CusAccountList5.BANK_HQ_NAME = this.GetBankDataResponse3.BankList.filter(x => x.BANK_HQ == this.CusAccountList5.BANK_HQ)[0].BANK_HQ_NAME;
        this.CusAccountList5.BANK_BRH_NAME = this.GetSubBankDataResponse5.SubBankDetails.filter(x => x.BANK_BRH == this.CusAccountList5.BANK_BRH)[0].BANK_BRH_NAME;
        CusAccountList6.BANK_HQ_NAME = this.CusAccountList5.BANK_HQ_NAME
        CusAccountList6.BANK_BRH_NAME = this.CusAccountList5.BANK_BRH_NAME
        CusAccountList7.BANK_HQ_NAME = this.CusAccountList5.BANK_HQ_NAME
        CusAccountList7.BANK_BRH_NAME = this.CusAccountList5.BANK_BRH_NAME
      }
    }

    //console.log("this.OpenMemberArgs2", this.OpenMemberArgs);

    if (this.valid.errorMessages.length > 0) {
      this.message.showErrorMSGs(this.valid.errorMessages);

      debugger
      var disMsg = new disclaimerMsg();

      this.ShowMsg(disMsg);
    }
    else {
      //轉型態
      this.OpenMemberArgs.ADDR_CODE1 = this.OpenMemberArgs.ADDR_CODE1 == true ? "Y" : "N";
      //console.log("帳號資料1", this.OpenMemberArgs);
      //預設
      this.OpenMemberArgs.CusAccount = list;

      //this.OpenMemberArgs.CusKycInfo.CusKycDetail = [];

      //console.log("帳號資料", this.OpenMemberArgs);
      this._disclaimerService.CheckExgOpenData(this.OpenMemberArgs).subscribe(res => {
        var Entries = res.Entries;
        if (res.StatusCode === EnumStatusCode.Success) {
          this.disMsg = new disclaimerMsg();
          this.pagecontact = 3;
        }
        else {

          var Msg = res.Message.split("\n")

          Msg.forEach(data => {
            if (data != '') {
              this.valid.addErrorMessage(data);
            }
          });

          if (this.valid.errorMessages.length > 0) {
            // this.message.showErrorMSGs(this.valid.errorMessages);

            debugger
            var disMsg = new disclaimerMsg();

            this.ShowMsg(disMsg);
          }
        }
      });
    };
  }



  //圖片
  fileChange(files: any, n: number) {
    // console.log("files", files.target.files);
    if (files.target.files.length === 0) {
      return;
    }
    // console.log("files", files.target.files[0]);
    const imageFile = files.target.files[0];
    //return;
    // 进行图片压缩
    // console.log('原始檔案', imageFile);
    const compressionFile = new ImageCompressor().compress(imageFile, {
      quality: .8,
      maxWidth: 500,
      maxHeight: 300,
      convertSize: 614400, //超过600kb压缩
      success(result) {
      },
      error(e) {
        // console.log('壓縮失敗', e);
        // console.log('壓縮失敗', e.message);
      }
    }).then(ret => {
      // console.log('壓縮成功', ret);
      // console.log('nnnnnnn', n);
      //顯示圖片
      const reader = new FileReader();
      reader.readAsDataURL(ret);
      reader.onload = () => {
        if (reader.result != null) {
          switch (n) {
            case 1:
              this.imgURL.ImageProductImg = reader.result;
              this.imgURL.ImageFile = reader.result.toString();  //傳檔
              break;
            case 2:
              this.imgURL2.ImageProductImg = reader.result;
              this.imgURL2.ImageFile = reader.result.toString();  //傳檔
              break;
            case 3:
              this.imgURL3.ImageProductImg = reader.result;
              this.imgURL3.ImageFile = reader.result.toString();  //傳檔
              break;
            case 4:
              this.imgURL4.ImageProductImg = reader.result;
              this.imgURL4.ImageFile = reader.result.toString();  //傳檔
              break;
            case 5:
              this.imgURL5.ImageProductImg = reader.result;
              this.imgURL5.ImageFile = reader.result.toString();  //傳檔
              break;
            case 6:
              this.imgURL6.ImageProductImg = reader.result;
              this.imgURL6.ImageFile = reader.result.toString();  //傳檔
              break;
            case 7:
              this.imgURL7.ImageProductImg = reader.result;
              this.imgURL7.ImageFile = reader.result.toString();  //傳檔
              break;
            case 8:
              this.imgURL8.ImageProductImg = reader.result;
              this.imgURL8.ImageFile = reader.result.toString();  //傳檔
              break;
            case 9:
              this.imgURL9.ImageProductImg = reader.result;
              this.imgURL9.ImageFile = reader.result.toString();  //傳檔
              break;
            case 10:
              this.imgURL10.ImageProductImg = reader.result;
              this.imgURL10.ImageFile = reader.result.toString();  //傳檔
              break;
            case 11:
              this.imgURL11.ImageProductImg = reader.result;
              this.imgURL11.ImageFile = reader.result.toString();  //傳檔
              break;
            case 12:
              this.imgURL12.ImageProductImg = reader.result;
              this.imgURL12.ImageFile = reader.result.toString();  //傳檔
              break;
          }
        }
        // console.log('imgURL', this.imgURL);
        // console.log('imgURL2', this.imgURL2);
        // console.log('imgURL3', this.imgURL3);
        // console.log('imgURL4', this.imgURL4);
        //傳檔
        //this.product.cproductImg = reader.result.toString();
      };
    })
    //   .catch((err) => {
    //   console.log('壓縮錯誤', err);
    //   console.log('壓縮錯誤', err.message);
    // });
  }

  //#region KYC
  //KYC
  GetKYCQuestion() {
    this.GetKycQuestionArgs.BF_COUNTRY_X = "01";
    this._disclaimerService.GetKYCQuestion(this.GetKycQuestionArgs).subscribe(res => {

      this.GetKYCQuestionResponse = res.Entries;
      for (let index = 0; index < res.Entries.KYCQuestionList.length; index++) {
        const e = res.Entries.KYCQuestionList[index];
        if (e.IS_ALLOW_MULIT == 'N') {

        }

        if (e.IS_ALLOW_MULIT == 'Y') {

        }
      }
      this.GetKycQuestionResponse = res.Entries;

      // console.log("KYC", this.GetKYCQuestionResponse);
    });
  }

  getKYCQuestionList(QSN_ORDER_TYPE: string) {
    return this.GetKycQuestionResponse.KYCQuestionList.filter(x => x.QSN_ORDER.startsWith(QSN_ORDER_TYPE))
  }
  findDtlTxt(list: KYCQuestionDtl[], value: any) {
    var data = list.filter(x => x.ANS_SEQNO == value);
    if (data.length > 0) {
      return data[0].ANS_ITEM;

    }
    else {

      return "";
    }


  }

  findDtlTxtArray(list: KYCQuestionDtl[]) {
    var text = [] as string[];

    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      if (element.ANS_SEQNO_SELECTED) {

        text.push(element.ANS_ITEM);
      }
    }

    return text.join(',');
  }

  verify(): boolean {

    let result = true;

    for (let index = 0; index < this.GetKycQuestionResponse.KYCQuestionList.length; index++) {
      const e = this.GetKycQuestionResponse.KYCQuestionList[index];

      if (e.IS_ALLOW_MULIT == 'N' && !e.KYCQuestionResult) {
        result = false;
        this.message.showErrorMSG(`請選擇 ${e.KYC_QUESTION}`)
        break;
      }

      if (e.IS_ALLOW_MULIT == 'Y') {
        let checkbox = false;

        for (let index2 = 0; index2 < e.KYCQuestionDtl.length; index2++) {
          const e2 = e.KYCQuestionDtl[index2];
          if (e2.ANS_SEQNO_SELECTED) {
            checkbox = true;
          }
        }

        if (checkbox == false) {
          result = false;
          this.message.showErrorMSG(`請選擇 ${e.KYC_QUESTION}`)
          break;
        }
      }

    }

    return result;
  }

  getKycRiskScore() {
    if (this.verify() == false) {
      return;
    }

    let sum = 0;
    //計算分數
    this.GetKycQuestionResponse.KYCQuestionList.forEach(e => {

      let data;
      if (e.IS_ALLOW_MULIT == 'N') {
        //找到detail的分數
        data = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO == e.KYCQuestionResult)
      }

      if (e.IS_ALLOW_MULIT == 'Y') {
        data = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO_SELECTED);
      }

      let ANS_SCORE;

      if (data && data.length > 0) {
        //有選
        ANS_SCORE = Math.max.apply(Math, data.map(function (o) { return o.ANS_SCORE; }))
      }
      else {
        //沒選
        ANS_SCORE = Math.min.apply(Math, e.KYCQuestionDtl.map(function (o) { return o.ANS_SCORE; }))
      }

      sum += ANS_SCORE;
    });

    this.GetKycRiskScoreArgs.ANS_SCORE = sum;
    this.GetKycRiskScoreArgs.VERSION_NO = this.GetKycQuestionResponse.KYCQuestionList[0].VERSION_NO;

    this._disclaimerService.GetKycRiskScore(this.GetKycRiskScoreArgs).subscribe(res => {
      if (res.Entries != null && res.Entries != undefined) {
        this.GetKycRiskScoreResponse = res.Entries;
      }
      else {
        alert('目前獲取不到風險屬性分數，請聯絡台中銀投信人員')
      }
    });

  }

  postKycQuestion() {

    this.CusKycInfo.VERSION_NO = this.GetKycRiskScoreArgs.VERSION_NO;
    this.CusKycInfo.KYC_TOTAL_SCORE = this.GetKycRiskScoreArgs.ANS_SCORE;
    this.CusKycInfo.KYC_RISK_ATTR = this.GetKycRiskScoreResponse.KYC_RISK_ATTR;
    //console.log("CusKycInfo1", this.CusKycInfo);
    //this.OpenMemberArgs.CusKycInfo.CusKycDetail = [];

    var row = 1;
    for (let index = 0; index < this.GetKycQuestionResponse.KYCQuestionList.length; index++) {
      const e = this.GetKycQuestionResponse.KYCQuestionList[index];

      //get checked
      let checkedList = [] as KYCQuestionDtl[];
      if (e.IS_ALLOW_MULIT == 'N') {
        //找到detail的分數
        checkedList = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO == e.KYCQuestionResult)
      }

      if (e.IS_ALLOW_MULIT == 'Y') {
        checkedList = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO_SELECTED);
      }

      let ANS_KEY_IN_ARRAY = [] as any[];
      checkedList.forEach(checked => {
        ANS_KEY_IN_ARRAY.push(checked.ANS_ORDER);
      });



      e.KYCQuestionDtl.forEach(data => {

        let CusKycDetail = {} as any;
        CusKycDetail.QSN_SEQNO = e.QSN_SEQNO;
        CusKycDetail.QSN_ORDER = e.QSN_ORDER;
        CusKycDetail.KYC_QUESTION = e.KYC_QUESTION;
        CusKycDetail.ANS_SEQNO = data.ANS_SEQNO;
        CusKycDetail.ANS_ORDER = data.ANS_ORDER;
        CusKycDetail.ANS_ITEM = data.ANS_ITEM;
        CusKycDetail.ANS_SCORE = data.ANS_SCORE;

        if (e.IS_ALLOW_MULIT == 'N') {
          //找到detail的分數
          if (data.ANS_SEQNO == e.KYCQuestionResult) {
            CusKycDetail.IS_CHK_YN = "Y";
          }
          else {
            CusKycDetail.IS_CHK_YN = "N";
          }
        }

        if (e.IS_ALLOW_MULIT == 'Y') {
          if (data.ANS_SEQNO_SELECTED) {
            CusKycDetail.IS_CHK_YN = "Y";
          }
          else {
            CusKycDetail.IS_CHK_YN = "N";
          }
        }

        CusKycDetail.ANS_KEY_IN = ANS_KEY_IN_ARRAY.join(',');

        // 如果迴圈下 e 問題IS_FREE_INPUT == 'Y' 則進入判斷
        // 改變下拉式選單後會將問題 e 的IS_FREE_INPUT屬性 變更
        if (e.IS_FREE_INPUT == 'Y') {
          // 如果 e 問題可以自由輸入 在塞入各選項答案時檢查 選項的IS_FREE_INPUT 是否可自由輸入 以及input是否有輸入
          if ((e.ANS_TEXT_IN === '' || e.ANS_TEXT_IN === undefined || e.ANS_TEXT_IN === null) && data.IS_FREE_INPUT == 'Y') {
            alert(`請填寫 ${e.KYC_QUESTION}`)
            this.check = false; // 如果有跳出警告 則下面的push不做事 直接跳出迴圈
          }
          else {
            // if (data.IS_FREE_INPUT == 'Y') {
            CusKycDetail.ANS_TEXT_IN = e.ANS_TEXT_IN;
            // }
            // else {
            //   CusKycDetail.ANS_TEXT_IN = '';
            // }
          }
        }
        else {
          CusKycDetail.ANS_TEXT_IN = '';
        }

        CusKycDetail.IS_FREE_INPUT = data.IS_FREE_INPUT;
        CusKycDetail.ROW = row++;
        //console.log("CusKycDetailA", CusKycDetail);
        this.CusKycInfo.CusKycDetail.push(CusKycDetail);
        //console.log("CusKycInfo2", this.CusKycInfo);
      });
    }

    if (this.check) {
      this.OpenMemberArgs.CusKycInfo.push(this.CusKycInfo);
    }
    else {
      return
    }

    //this.OpenMemberArgs.CusKycInfo = this.CusKycInfo;
    //console.log("OpenMemberArgsFOR", this.OpenMemberArgs);
  }
  //#endregion

  SendParper() {
    this.valid.clear();
    this._disclaimerService.SendParper(this.OpenMemberArgs).subscribe(res => {
      this.valid.addErrorMessage(res.Message);
      this.message.showErrorMSGs(this.valid.errorMessages);

    });
  }

  autoPERM_ADDR() {
    if (this.OpenMemberArgs.ADDR_CODE1 == true || this.OpenMemberArgs.ADDR_CODE1 == "Y") {


      this.OpenMemberArgs.PERM_ZIP = this.OpenMemberArgs.MAIL_ZIP;
      this.OpenMemberArgs.PERM_ADDR = this.OpenMemberArgs.MAIL_ADDR;
    }
  }

  CheckId() {
    let promise = new Promise((resolve) => {
      this.CheckIdArgs.AGENT_IN_LAW_ID = this.OpenMemberArgs.AGENT_IN_LAW_ID;
      this.CheckIdArgs.AGENT_IN_LAW_ID2 = this.OpenMemberArgs.AGENT_IN_LAW_ID2;
      this._disclaimerService.CheckId(this.CheckIdArgs).subscribe(res => {
        resolve(res);
      });
    })

    promise.then((value: any) => {
      this.idres = value;
      // console.log(value);
      this.validation();
    })
  }

  ShowMsg(disMsg: disclaimerMsg) {

    if (this.valid.errorMessages.includes('日期格式不正確')) {
      disMsg.BIR_DATE = true;
    }
    else if (this.valid.errorMessages.includes('生日(西元)錯誤')) {
      disMsg.BIR_DATE = true;
    }

    if (this.valid.errorMessages.includes('請輸入姓名')) {
      disMsg.BF_NAME1 = true;
    }
    else if (this.valid.errorMessages.includes('姓名格式不正確')) {
      disMsg.BF_NAME2 = true;
    }

    if (this.valid.errorMessages.includes('請輸入連絡電話')) {
      disMsg.HM_TEL = true;
    }
    else if (this.valid.errorMessages.includes('連絡電話格式不正確')) {
      disMsg.HM_TEL1 = true;
    }

    if (this.valid.errorMessages.includes('公司電話格式不正確')) {
      disMsg.OF_TEL = true;
    }

    if (this.valid.errorMessages.includes('傳真電話格式不正確')) {
      disMsg.FAX_TEL = true;
    }

    if (this.valid.errorMessages.includes('請輸入手機電話')) {
      disMsg.CELL_PHONE = true;
    }
    else if (this.valid.errorMessages.includes('手機電話格式不正確')) {
      disMsg.CELL_PHONE1 = true;
    }

    if (this.valid.errorMessages.includes('請輸入通訊郵遞區號')) {
      disMsg.MAIL_ZIP = true;
    }
    else if (this.valid.errorMessages.includes('通訊郵遞區號格式錯誤，請使用純數字輸入標準3+2 / 3+3郵遞區號。')) {
      disMsg.MAIL_ZIP1 = true;
    }

    if (this.valid.errorMessages.includes('請輸入通訊地址')) {
      disMsg.MAIL_ADDR = true;
    }
    else if (this.valid.errorMessages.includes('通訊地址格式錯誤，缺少縣或市。')) {
      disMsg.MAIL_ADDR1 = true;
    }

    if (this.valid.errorMessages.includes('請輸入戶籍郵遞區號')) {
      disMsg.PERM_ZIP = true;
    }
    else if (this.valid.errorMessages.includes('戶籍郵遞區號格式錯誤，請使用純數字輸入標準3+2 / 3+3郵遞區號。')) {
      disMsg.PERM_ZIP1 = true;
    }

    if (this.valid.errorMessages.includes('請輸入戶籍地址')) {
      disMsg.PERM_ADDR = true;
    }
    else if (this.valid.errorMessages.includes('戶籍地址格式錯誤，缺少縣或市。')) {
      disMsg.PERM_ADDR1 = true;
    }

    if (this.valid.errorMessages.includes('請輸入電子信箱')) {
      disMsg.EMAIL = true;
    }
    else if (this.valid.errorMessages.includes('電子信箱格式不正確')) {
      disMsg.EMAIL1 = true;
    }

    if (this.valid.errorMessages.includes('請選擇是否為美國稅務居民')) {
      disMsg.IS_USA = true;
    }

    // if (this.valid.errorMessages.includes('請選擇是否接收行銷訊息')) {
    //   disMsg.MCD_YN = true;
    // }

    if (this.valid.errorMessages.includes('請輸入連絡人')) {
      disMsg.CNT_PERSON = true;
    }
    else if (this.valid.errorMessages.includes('連絡人姓名格式不正確')) {
      disMsg.CNT_PERSON1 = true;
    }

    if (this.valid.errorMessages.includes('連絡人手機電話格式不正確')) {
      disMsg.CNT_CELL_PHONE1 = true;
    }

    if (this.valid.errorMessages.includes('連絡人公司電話格式不正確')) {
      disMsg.CNT_OF_TEL1 = true;
    }

    if (this.valid.errorMessages.includes('申請人未滿18歲，請填法定代理人(一)姓名')) {
      disMsg.AGENT_IN_LAW = true;
    }

    if (this.valid.errorMessages.includes('申請人未滿18歲，請填法定代理人(一)身份證號')) {
      disMsg.AGENT_IN_LAW_ID = true;
    }

    if (this.valid.errorMessages.includes('法定代理人姓名(一) 格式不正確')) {
      disMsg.AGENT_IN_LAW1 = true;
    }

    if (this.valid.errorMessages.includes('法定代理人姓名(二) 格式不正確')) {
      disMsg.AGENT_IN_LAW2 = true;
    }

    if (this.valid.errorMessages.includes('法定代理人(一)身分證字號 格式不正確')) {
      disMsg.AGENT_IN_LAW_ID1 = true;
    }

    if (this.valid.errorMessages.includes('法定代理人身分證字號(一) 不可與開戶身分證字號相同')) {
      disMsg.AGENT_IN_LAW_ID1_1 = true;
    }

    if (this.valid.errorMessages.includes('法定代理人(二)身分證字號 格式不正確')) {
      disMsg.AGENT_IN_LAW_ID2 = true;
    }

    if (this.valid.errorMessages.includes('法定代理人身分證字號(二) 不可與開戶身分證字號相同')) {
      disMsg.AGENT_IN_LAW_ID2_1 = true;
    }

    if (this.valid.errorMessages.includes('請選擇輸入扣款帳號')) {
      disMsg.CusAccountList = true;
    }
    else if (this.valid.errorMessages.includes('扣款帳號 格式不正確')) {
      disMsg.CusAccountList_1 = true;
    }
    else if (this.valid.errorMessages.includes('扣款帳號 帳號長度不符')) {
      disMsg.CusAccountList_2 = true;
    }

    if (this.valid.errorMessages.includes('請選擇輸入約定買回帳戶')) {
      disMsg.CusAccountList2 = true;
    }
    else if (this.valid.errorMessages.includes('買回價款匯入銀行(一) 格式不正確')) {
      disMsg.CusAccountList2_1 = true;
    }
    else if (this.valid.errorMessages.includes('買回價款匯入銀行(一) 帳號長度不符')) {
      disMsg.CusAccountList2_2 = true;
    }

    if (this.valid.errorMessages.includes('請選擇輸入約定買回帳戶(二)')) {
      disMsg.CusAccountList3 = true;
    }
    else if (this.valid.errorMessages.includes('買回價款匯入銀行(二) 格式不正確')) {
      disMsg.CusAccountList3_1 = true;
    }
    else if (this.valid.errorMessages.includes('買回價款匯入銀行(二) 帳號長度不符')) {
      disMsg.CusAccountList3_2 = true;
    }

    if (this.valid.errorMessages.includes('請選擇輸入收益配息帳號')) {
      disMsg.CusAccountList4 = true;
    }
    else if (this.valid.errorMessages.includes('收益配息帳戶 格式不正確')) {
      disMsg.CusAccountList4_1 = true;
    }
    else if (this.valid.errorMessages.includes('收益配息帳戶 帳號長度不符')) {
      disMsg.CusAccountList4_2 = true;
    }

    if (this.valid.errorMessages.includes('請選擇輸入外幣申贖帳號')) {
      disMsg.CusAccountList5 = true;
    }
    else if (this.valid.errorMessages.includes('外幣申贖帳號 格式不正確')) {
      disMsg.CusAccountList5_1 = true;
    }
    else if (this.valid.errorMessages.includes('外幣申贖帳號 帳號長度不符')) {
      disMsg.CusAccountList5_2 = true;
    }

    if (this.valid.errorMessages.includes('請輸入英文姓名')) {
      disMsg.BF_NAME_E1 = true;
    }
    else if (this.valid.errorMessages.includes('英文姓名格式不正確')) {
      disMsg.BF_NAME_E2 = true;
    }

    debugger
    this.disMsg = disMsg;
  }

  Display(item: KYCQuestionList, value: number) {

    item.IS_FREE_INPUT = 'N'

    for (let index = 0; index < item.KYCQuestionDtl.length; index++) {
      if (item.KYCQuestionDtl[index].ANS_SEQNO == value) {
        item.IS_FREE_INPUT = item.KYCQuestionDtl[index].IS_FREE_INPUT;
        break;
      }
    }

    // item.KYCQuestionDtl.forEach(data => {
    //   if (data.ANS_SEQNO == value) {
    //     item.IS_FREE_INPUT = data.IS_FREE_INPUT;
    //   }
    // });

    if (item.IS_FREE_INPUT == 'Y') {
      item.Display = true;
    }
    else {
      item.Display = false;
    }
  }

  ShowCountry(Code: string) {

    var name = this.GetCountryCDResponse.CountryList.find(x => x.COUNTRY_CD == Code);

    if (name != undefined) {
      return name.COUNTRY_NAME;
    }
    else {
      return '資料錯誤';
    }
  }

  IDChk(vid: string) {

    var FirstEng = ["A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "P", "Q", "R", "S", "T", "U", "V", "X", "Y", "W", "Z", "I", "O"] as string[];
    var aa = vid.toUpperCase();
    var chackFirstEnd = false;
    if (aa.trim().length == 10) {
      var firstNo = parseInt(aa.trim().substring(1, 2));
      if (firstNo > 2 || firstNo < 1) {
        return "2";
      }
      else {
        var x;
        for (x = 0; x < FirstEng.length; x++) {
          if (aa.substring(0, 1) == FirstEng[x]) {
            aa = `${x + 10}${aa.substring(1, 9)}`
            chackFirstEnd = true;
            break;
          }

        }
        if (!chackFirstEnd)
          return "3";

        var i = 1;
        var ss = parseInt(aa.substring(0, 1));
        while (aa.length > i) {
          ss = ss + (parseInt(aa.substring(i, i + 1)) * (10 - i));
          i++;
        }
        aa = ss.toString();
        if (vid.substring(9, 9) == "0") {
          if (aa.substring(aa.length - 1, 1) == "0") {
            return "0";
          }
          else {
            return "4";
          }
        }
        else {
          if (vid.substring(9, 10) == (10 - parseInt(aa.substring(aa.length - 1, aa.length))).toString()) {

            return "0";
          }
          else {
            return "4";
          }
        }
      }
    }
    else {
      return "1";
    }
  }
}
