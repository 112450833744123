<nav id="navbar"
(click)="listController($event)"
[ngClass]="{'rvnm-mobile-expand':showList}">
  <ul>
    <li *ngFor="let menu of menus" [ngClass]="{
      'rvnm-expandable': menu.childMenu.length > 0,
      'rvnm-collapseable': menu.isOpen
    }">
      <a *ngIf="menu.url" routerLink="{{menu.url}}"><i class="{{menu.class}}"></i>{{menu.name}}</a>
      <a *ngIf="!menu.url" href="javascript:void(0)"><i class="{{menu.class}}"></i>{{menu.name}}</a>
      <ul *ngIf="menu.childMenu.length > 0" [ngStyle]="{'display': menu.isOpen ? 'block' : 'auto' }">
        <!-- <li>
          <a href="https://www.tcbsitc.com.tw/" target="_blank">回官網首頁</a>
        </li> -->
        <li (mouseup)="test($event.target)" (click)="check($event.target)" [ngClass]="{'selected': childMenu.checked}"
          *ngFor="let childMenu of menu.childMenu">
          <a routerLink="{{childMenu.url}}">{{childMenu.name}}</a>
        </li>
      </ul>
    </li>
  </ul>
</nav>
