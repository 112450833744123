import { JwtPayload } from '../../model/base';
import { Component, OnInit } from '@angular/core';
import jwt_decode from 'jwt-decode';


@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent implements OnInit {

  jwt = {} as JwtPayload;


  constructor() { }

  ngOnInit(): void {

    this.jwt = jwt_decode(localStorage.getItem('token') || "");

    console.log(this.jwt.BF_SRNO);
  }

  strName(v: string) {
    if (v.length > 2) {
      return v.substring(0, 1) + '*' + v.substring(2);
    }
    else {
      return v.substring(0, 1) + '*';
    }
  };

  strID(v: String) {
    return v.substring(0, 4) + '***' + v.substring(7, 10);
  };
}
