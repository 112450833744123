<div class="Block-content">
  <div class="basic-data-table">
    <div *ngIf="count == 0" class="w-100">
      <div class="basic-left" style="text-align: center;"><span class="text-red">查無對帳單</span></div>
    </div>
    <div class="w-100" *ngFor="let s of this.GetStatementListResponse.StatementList">
      <div *ngIf="s.FileExist" class="basic-left">{{s.FileMonth}}</div>
      <div *ngIf="s.FileExist" class="basic-right">
        <div *ngIf="s.FileExist">
          <a href="javascript:void(0)" (click)="Download(s.FilePath)" class="set-up"><i
              class="fas fa-chevron-right"></i>查看對帳單</a>
        </div>
        <div *ngIf="!s.FileExist">
          <span style="color:red">查無對帳單</span>
        </div>
      </div>
    </div>
  </div>
</div>
