import { formatDate } from '@angular/common';
import { Injectable, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AllowHelper } from '../../helper/allowHelper';
import * as moment from 'moment';

@Injectable()
export class BaseComponent implements OnInit {


  pageIndex = 1;
  pageSize = 10;
  totalRecords = 0;
  pageFirst = 0;

  constructor(
    protected allow: AllowHelper
  ) {

  }

  ngOnInit() {

  }

  isNullOrEmpty(value: string) {
    if (value === undefined) {
      return true;
    }
    if (value == null) {
      return true;
    }
    if (value === '') {
      return true;
    }
    return false;
  }
}
