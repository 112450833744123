import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { BaseComponent } from 'src/app/pages/base/baseComponent';

@Component({
  selector: 'app-logout-layout',
  templateUrl: './logout-layout.component.html',
  styleUrls: ['./logout-layout.component.css']
})
export class LogoutLayoutComponent extends BaseComponent implements OnInit {


  constructor(
    protected allow: AllowHelper,
    private router: Router) {
    super(allow);

    if (localStorage.getItem('token')) {
      this.router.navigateByUrl('home');
      return;
    }

  }


  ngOnInit(): void {

  }

}
