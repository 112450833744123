import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { NewsComponent } from './pages/news/news.component';
import { ManageMemberVerificationComponent } from './pages/manage-member-verification/manage-member-verification.component';
import { SingletransactionComponent } from './pages/singletransaction/singletransaction.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegularQuotaComponent } from './pages/regular-quota/regular-quota.component';
import { TransfersubscriptionComponent } from './pages/transfersubscription/transfersubscription.component';
import { LogoutLayoutComponent } from './_layout/logout-layout/logout-layout.component';
import { LoginLayoutComponent } from './_layout/login-layout/login-layout.component';
import { CancellationtradeComponent } from './pages/cancellationtrade/cancellationtrade.component';
import { FundredemptionComponent } from './pages/fundredemption/fundredemption.component';
import { DisclaimerComponent } from './pages/disclaimer/disclaimer.component';
import { EditMemberProfileComponent } from './pages/edit-member-profile/edit-member-profile.component';
import { RegularSavingPlanComponent } from './pages/regular-saving-plan/regular-saving-plan.component';
import { KycComponent } from './pages/kyc/kyc.component';
import { KycHistoryComponent } from './pages/kyc-history/kyc-history.component';
import { ETradeSearchComponent } from './pages/e-trade-search/e-trade-search.component';
import { PersonalInvestmentComponent } from './pages/personal-investment/personal-investment.component';
import { EstablishAccountSearchComponent } from './pages/establish-account-search/establish-account-search.component';
import { PersonalizationSettingsComponent } from './pages/personalization-settings/personalization-settings.component';
import { PersonalizationLockSettingsComponent } from './pages/personalization-lock-settings/personalization-lock-settings.component';
import { OpenAccountAllComponent } from './pages/open-account-all/open-account-all.component';
import { GetPersonalMsgComponent } from './pages/get-personal-msg/get-personal-msg.component';
import { StatementQueryComponent } from './pages/statement-query/statement-query.component';

const routes: Routes = [
  //已登入的
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'regular-quota', component: RegularQuotaComponent },
      { path: 'singletransaction', component: SingletransactionComponent },
      {
        path: 'ManageMemberVerification',
        component: ManageMemberVerificationComponent,
      },
      {
        path: 'transfersubscription',
        component: TransfersubscriptionComponent,
      },
      { path: 'news', component: NewsComponent },
      { path: 'cancellationtrade', component: CancellationtradeComponent },
      { path: 'fundredemption', component: FundredemptionComponent },
      { path: 'EditMemberProfile', component: EditMemberProfileComponent },
      {
        path: 'RegularSavingPlan',
        component: RegularSavingPlanComponent,
      },
      { path: 'kyc', component: KycComponent },
      { path: 'kyc-history', component: KycHistoryComponent },
      { path: 'ETradeSearch', component: ETradeSearchComponent },
      { path: 'PersonalInvestment', component: PersonalInvestmentComponent },
      {
        path: 'PersonalizationSettings',
        component: PersonalizationSettingsComponent,
      },
      {
        path: 'PersonalizationLockSettings',
        component: PersonalizationLockSettingsComponent,
      },
      { path: 'ChangePassword', component: ChangePasswordComponent },
      { path: 'GetPersonalMsg', component: GetPersonalMsgComponent },
      { path: 'StatementQuery', component: StatementQueryComponent },
    ],
  },

  //未登入的
  {
    path: '',
    component: LogoutLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'disclaimer', component: DisclaimerComponent },
      {
        path: 'EstablishAccountSearch',
        component: EstablishAccountSearchComponent,
      },
      {
        path: 'EstablishAccountSearch',
        component: EstablishAccountSearchComponent,
      },
    ],
  },
  { path: 'OpenAccountAll', component: OpenAccountAllComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
