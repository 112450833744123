import { element } from 'protractor';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { ValidationHelper } from 'src/app/helper/validationHelper';
import {
  LoadMemberProfileArgs,
  LoadMemberProfileResponse,
  PageParam,
  SaveMemberProfileArgs,
} from 'src/app/model/member';
import { MemberService } from 'src/app/services/member.service';
import { MessageService } from 'src/app/services/message.service';
import { BaseComponent } from '../base/baseComponent';

@Component({
  selector: 'app-edit-member-profile',
  templateUrl: './edit-member-profile.component.html',
  styleUrls: ['./edit-member-profile.component.css'],
})
export class EditMemberProfileComponent
  extends BaseComponent
  implements OnInit {
  loadMemberProfileResponse = new LoadMemberProfileResponse();

  private _birthDate: string;
  bdYear: string;
  bdMonth: string;
  bdDay: string;
  canChange = true;

  pageparam = new PageParam();

  constructor(
    protected allow: AllowHelper,
    private valid: ValidationHelper,
    private message: MessageService,
    private _MemberService: MemberService,
    private router: Router
  ) {
    super(allow);
  }

  ngOnInit(): void {
    // debugger
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 11;
    this.pageparam.Page = '修改個人資料';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.LoadMemberProfile({});
      }
      else {
        return
      }
    })
  }

  // Load 受益人基本資料
  LoadMemberProfile(arg: LoadMemberProfileArgs) {
    this._MemberService.LoadMemberProfile(arg).subscribe((res) => {
      this.loadMemberProfileResponse = res.Entries;
      this._birthDate = this.loadMemberProfileResponse.BIR_DATE;
      this.splitBirthDate(this._birthDate, '/');
      debugger
      if (res.Entries.DATA_STATUS != '0') {
        alert(res.Entries.DATA_STATUS_NM);
        this.canChange = false;
      }
    });

  }

  // Save 受益人基本資料
  SaveMemberProfile() {
    this._MemberService
      .SaveMemberProfile(this.loadMemberProfileResponse)
      .subscribe((res) => {
        if (res.StatusCode === EnumStatusCode.Success) {
          this.message.showSucessMSG('執行成功');
        }
      });
  }

  splitBirthDate(stringToSplit: string, separator: string) {
    if (stringToSplit != '') {
      const arrayOfStrings = stringToSplit.split(separator);
      this.bdYear = (parseInt(arrayOfStrings[0], 10) - 1911).toString();
      this.bdMonth = arrayOfStrings[1];
      this.bdDay = arrayOfStrings[2];
    }
  }

  save() {
    this.valid.clear();
    const regex = /^[0-9\s]*$/;
    if (this.loadMemberProfileResponse.HM_TEL == '') {
      this.valid.addErrorMessage('請輸入住家電話！');
    } else if (!regex.test(this.loadMemberProfileResponse.HM_TEL)) {
      this.valid.addErrorMessage('住家電話請輸入數字！');
    }
    if (this.loadMemberProfileResponse.HM_TEL_AREA == '') {
      this.valid.addErrorMessage('請輸入住家電話！');
    } else if (!regex.test(this.loadMemberProfileResponse.HM_TEL_AREA)) {
      this.valid.addErrorMessage('住家電話請輸入數字！');
    }

    if (this.valid.errorMessages.length > 0) {
      this.message.showErrorMSGs(this.valid.errorMessages);
    } else this.SaveMemberProfile();
  }
}
