import { registerLocaleData } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { PageParam } from 'src/app/model/member';
import { GetKycRiskScoreArgs, GetKycRiskScoreResponse } from 'src/app/model/open-data';
import { GetKycQuestionArgs, GetKycQuestionResponse, KycHistoryArgs, KycHistoryResponse, KYCList, KYCQuestionDtl, KYCQuestionList, PostKycQuestionArgs, PostKycQuestionResponse } from 'src/app/model/personal';
import { MemberService } from 'src/app/services/member.service';
import { MessageService } from 'src/app/services/message.service';
import { OpenDataService } from 'src/app/services/open-data.service';
import { PersonalService } from 'src/app/services/personal';
import { BaseComponent } from '../base/baseComponent';

@Component({
  selector: 'app-kyc-history',
  templateUrl: './kyc-history.component.html',
  styleUrls: ['./kyc-history.component.css']
})
export class KycHistoryComponent extends BaseComponent implements OnInit {


  KYCList = new KYCList();

  KycHistoryArgs = new KycHistoryArgs();
  KycHistoryResponse = new KycHistoryResponse();

  GetKycRiskScoreArgs = new GetKycRiskScoreArgs();
  GetKycRiskScoreResponse = new GetKycRiskScoreResponse();

  KYC_ANS_NO = "";

  pageparam = new PageParam();

  constructor(
    private _personalService: PersonalService,
    private _openDataService: OpenDataService,
    protected allow: AllowHelper,
    private message: MessageService,
    private _MemberService: MemberService
  ) {
    super(allow);
  }

  ngOnInit(): void {
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 13;
    this.pageparam.Page = '投資適性分析表版本查詢';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.kycHistory();
      }
      else {
        return
      }
    })
  }

  kycHistory() {
    this._personalService.KycHistory(this.KycHistoryArgs).subscribe(res => {
      this.KycHistoryResponse = res.Entries;

      this.KycHistoryResponse.KYCList.forEach(data => {
        data.KYCQuestionList.forEach(data2 => {
          data2.KYCQuestionDtl.forEach(data3 => {
            // debugger
            if (data3.IS_FREE_INPUT == 'Y' && data3.LAST_ANS_TEXT_IN !== '' && data3.LAST_ANS_TEXT_IN !== undefined && data3.LAST_ANS_TEXT_IN !== null) {
              data2.IS_FREE_INPUT = 'Y';
              data2.ANS_TEXT_IN = data3.LAST_ANS_TEXT_IN;
            }
            else {
              data2.IS_FREE_INPUT = 'N';
            }
          })
        })
      });

      console.log(this.KycHistoryResponse)
    });
  }

  getKYCQuestionList(QSN_ORDER_TYPE: string) {
    if (this.KYCList && this.KYCList.KYCQuestionList) {

      // if (QSN_ORDER_TYPE == 'A') {
      //   var x = this.KYCList.KYCQuestionList.filter(x => x.QSN_ORDER.startsWith(QSN_ORDER_TYPE))
      //   x.forEach(data => {

      //     data.KYCQuestionDtl.forEach(data2 => {
      //       if (data2.IS_FREE_INPUT == 'Y' && data2.LAST_ANS_TEXT_IN != '') {
      //         data.ANS_TEXT_IN = data2.LAST_ANS_TEXT_IN;
      //       }
      //     });
      //   });

      //   return this.KYCList.KYCQuestionList.filter(x => x.QSN_ORDER.startsWith(QSN_ORDER_TYPE));
      // }
      // else {
      return this.KYCList.KYCQuestionList.filter(x => x.QSN_ORDER.startsWith(QSN_ORDER_TYPE))
      // }
    }
    else {
      return [];
    }
  }

  changeValue(event: any) {
    if (event) {
      let data = this.KycHistoryResponse.KYCList.filter(x => x.KYC_ANS_NO == event)[0];

      for (let index = 0; index < data.KYCQuestionList.length; index++) {
        const e = data.KYCQuestionList[index];
        if (e.IS_ALLOW_MULIT == 'N') {
          var filter = e.KYCQuestionDtl.filter(x => x.LAST_IS_CHK_YN == 'Y');
          if (filter.length > 0) {
            e.KYCQuestionResult = filter[0].ANS_SEQNO;
          }
        }

        if (e.IS_ALLOW_MULIT == 'Y') {
          e.KYCQuestionDtl.forEach(e2 => {
            e2.ANS_SEQNO_SELECTED = e2.LAST_IS_CHK_YN == 'Y'
          });
        }
      }
      this.KYCList = data;
      this.getKycRiskScore();
    }
  }

  getKycRiskScore() {
    let sum = 0;
    //計算分數
    this.KYCList.KYCQuestionList.forEach(e => {

      let data;
      if (e.IS_ALLOW_MULIT == 'N') {
        //找到detail的分數
        data = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO == e.KYCQuestionResult)
      }

      if (e.IS_ALLOW_MULIT == 'Y') {
        data = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO_SELECTED);
      }

      let ANS_SCORE;

      if (data && data.length > 0) {
        //有選
        ANS_SCORE = Math.max.apply(Math, data.map(function (o) { return o.ANS_SCORE; }))
      }
      else {
        //沒選
        ANS_SCORE = Math.min.apply(Math, e.KYCQuestionDtl.map(function (o) { return o.ANS_SCORE; }))
      }

      sum += ANS_SCORE;
    });

    this.GetKycRiskScoreArgs.ANS_SCORE = sum;
    this.GetKycRiskScoreArgs.VERSION_NO = this.KYCList.VERSION_NO;

    this._openDataService.GetKycRiskScore(this.GetKycRiskScoreArgs).subscribe(res => {
      this.GetKycRiskScoreResponse = res.Entries;
    });

  }

  Display(string: string): number {

    if (string == '取得投資資訊之來源或方法') {
      // console.log('取得投資資訊之來源或方法');
      return 1;
    }
    else if (string == '投資資金來源') {
      // console.log('投資資金來源')
      return 2
    }
    else {
      return 0;
    }
  }
}
