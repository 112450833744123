<ng-template #dialog let-data let-ref="dialogRef">
    <div id="set-up" class="Purchase" >
        <h3>個人鎖利設定</h3>
        <div class="basic-data-table">
            <div class="w-100">
                <div class="basic-left">基金名稱</div>
                <div class="basic-right">台中銀台灣主流中小基金(原名稱：德信台灣主流中小基金)----積極型(RR5)</div>
            </div>
            <div class="w-100">
                <div class="basic-left">淨值日期</div>
                <div class="basic-right">2021/05/24</div>
            </div>
            <div class="w-100">
                <div class="basic-left">最新淨值</div>
                <div class="basic-right">123</div>
            </div>
            <div class="w-100">
                <div class="basic-left">平均成本</div>
                <div class="basic-right">123</div>
            </div>
            <div class="w-100">
                <div class="basic-left">預估投資報酬率(％)</div>
                <div class="basic-right Personal-f"><input>%</div>
            </div>
            <div class="w-100">
                <div class="basic-left">買進提醒</div>
                <div class="basic-right Personal-f"><input></div>
            </div>
            <div class="w-100">
                <div class="basic-left">賣出提醒</div>
                <div class="basic-right Personal-f"><input></div>
            </div>
            <div class="w-100">
                <div class="basic-left">停損漲跌幅(％)</div>
                <div class="basic-right Personal-f"><input>%</div>
            </div>
            <div class="w-100">
                <div class="basic-left">停利漲跌幅(%)</div>
                <div class="basic-right Personal-f"><input>%</div>
            </div>
        </div>
        <div class="button-box">
            <a (click)="ref.close()" rel="modal:close" class="cancel-button">取消</a>
            <a href="#" class="Main-button">確認<i class="fas fa-chevron-right"></i></a>
        </div>
    </div>
    </ng-template>
