import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import {} from 'src/app/model/e-trade-search';
import { PageParam } from 'src/app/model/member';
import {
  AllFundsList,
  AllotList,
  DivList,
  GetHistoryDataParams,
  GetHistoryDataResponse,
  RedemList,
  SwitchList,
} from 'src/app/model/open-data';
import { MemberService } from 'src/app/services/member.service';
import { OpenDataService } from 'src/app/services/open-data.service';

@Component({
  selector: 'app-e-trade-search',
  templateUrl: './e-trade-search.component.html',
  styleUrls: ['./e-trade-search.component.css'],
})
export class ETradeSearchComponent implements OnInit {
  FormattedBeginDate: string;
  FormattedEndDate: string;
  GetHistoryDataParams = new GetHistoryDataParams();
  GetHistoryDataResponse = new GetHistoryDataResponse();
  SingleSubscription: AllotList[]; // 單筆申購
  RegularSavingPlan: AllotList[]; // 定期定額
  Redemption: RedemList[]; // 贖回
  Switch: SwitchList[]; // 轉申購
  Dividend: DivList[]; // 收益配息
  AllFunds: AllFundsList[] = [];
  pageparam = new PageParam();

  constructor(
    private openDateService: OpenDataService,
    private _MemberService: MemberService
  ) { }

  ngOnInit(): void {
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 18;
    this.pageparam.Page = '歷史交易明細';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.FormattedBeginDate = this.formattingBeginDate(new Date());
        this.FormattedEndDate = this.formattingEndDate(new Date());
        this.setGetHistoryDataParams();
        this.getHistoryData();
      }
      else {
        return
      }
    })
  }


  private formattingBeginDate(date: Date) {
    let timeStamp = Date.parse(date.toString());
    let timeStampPrevious = timeStamp - 7776000000; // 1000 * 60 * 60 * 24 * 90
    let dateObjPrevious = new Date(timeStampPrevious);

    var yyyy = dateObjPrevious.getFullYear().toString();
    var mm = (dateObjPrevious.getMonth() + 1).toString();
    var dd = dateObjPrevious.getDate().toString();

    return (
      yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0])
    );
  }

  private formattingEndDate(d: Date) {
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 1).toString(); // getMonth() is zero-based
    var dd = d.getDate().toString();

    return (
      yyyy + '-' + (mm[1] ? mm : '0' + mm[0]) + '-' + (dd[1] ? dd : '0' + dd[0])
    );
  }

  private setGetHistoryDataParams() {
    this.GetHistoryDataParams.TRADE_TYPE = '0';
    this.GetHistoryDataParams.FUND_ID = 'ALL';
    this.GetHistoryDataParams.BEGIN_DATE = new Date(this.FormattedBeginDate);
    this.GetHistoryDataParams.END_DATE = new Date(this.FormattedEndDate);
  }

  // 取得歷史交易查詢資料
  getHistoryData() {
    this.openDateService
      .GetHistoryData(this.GetHistoryDataParams)
      .subscribe((response) => {
        this.GetHistoryDataResponse = response.Entries;
        if (this.GetHistoryDataResponse != null) {
          if (this.GetHistoryDataResponse.AllotList != null) {
            // 取得單筆申購
            this.SingleSubscription =
              this.GetHistoryDataResponse.AllotList.filter(
                (x) => x.TRADE_TYPE == '1'
              );
            // 取得定期定額
            this.RegularSavingPlan =
              this.GetHistoryDataResponse.AllotList.filter(
                (x) => x.TRADE_TYPE == '4'
              );
            // 挑出單筆申購＆定期定額基金
            this.getAllFunds(this.GetHistoryDataResponse.AllotList);
          }
          if (this.GetHistoryDataResponse.RedemList != null) {
            // 取得贖回
            this.Redemption = this.GetHistoryDataResponse.RedemList;
            // 挑出贖回基金
            this.getAllFunds(this.GetHistoryDataResponse.RedemList);
          }
          if (this.GetHistoryDataResponse.SwitchList != null) {
            // 取得轉申購
            this.Switch = this.GetHistoryDataResponse.SwitchList;
            // 挑出轉申購基金
            this.getAllFunds(this.GetHistoryDataResponse.SwitchList);
          }
          if (this.GetHistoryDataResponse.DivList != null) {
            // 取得收益配息
            this.Dividend = this.GetHistoryDataResponse.DivList;
            // 挑出收益配息基金
            this.getAllFunds(this.GetHistoryDataResponse.SwitchList);
          }
        }
      });
  }

  // 取得所有基金
  private getAllFunds(
    list: AllotList[] | RedemList[] | SwitchList[] | DivList[]
  ) {
    for (let item of list) {
      const pickedList = this.AllFunds.filter((x) => x.FUND_ID == item.FUND_ID);
      if (pickedList.length <= 0) {
        const fund: AllFundsList = new AllFundsList();
        fund.FUND_ID = item.FUND_ID;
        fund.FUND_NAME = item.FUND_NAME;
        this.AllFunds.push(fund);
      }
    }
  }

  // 開始搜尋基金
  searchFunds() {
    if (this.FormattedBeginDate == '') {
      this.formattingBeginDate(new Date());
    } else {
      const date = new Date(this.FormattedBeginDate).toString();
      if (date == 'Invalid Date') {
        this.formattingBeginDate(new Date());
      }
    }
    if (this.FormattedEndDate == '') {
      this.formattingEndDate(new Date());
    } else {
      const date = new Date(this.FormattedEndDate).toString();
      if (date == 'Invalid Date') {
        this.formattingEndDate(new Date());
      }
    }

    this.GetHistoryDataParams.BEGIN_DATE = new Date(this.FormattedBeginDate);
    this.GetHistoryDataParams.END_DATE = new Date(this.FormattedEndDate);

    this.getHistoryData();
  }
}
