import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ngx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Output() PageChange = new EventEmitter();
  @Output() PageSizeChange = new EventEmitter();
  @Output() CollectionSizeChange = new EventEmitter();
  @Input() Page: number;
  @Input() PageSize: number;
  @Input() CollectionSize: number;

  constructor() { }

  ngOnInit(): void {
  }

  counter(i: number) {
    return new Array(i);
  }

  getPageSize(CollectionSize: number, PageSize: number) {
    return Math.ceil(CollectionSize / PageSize);
  }

  pageChange(page: number) {
    this.Page = page;
    this.PageChange.emit(this.Page);
  }

  pageSizeChange() {
    this.PageSizeChange.emit(this.PageSize);
  }

  collectionSizeChange() {
    this.CollectionSizeChange.emit(this.CollectionSize);
  }


}
