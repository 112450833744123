import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PersonalizationSettingsForLINETokenParams,
  PersonalizationSettingsForLINETokenResponse,
  PersonalizationSettingsForRetrieveParams,
  PersonalizationSettingsForRetrieveResponse,
  PersonalizationSettingsForSubmitParams,
  PersonalizationSettingsForSubmitResponse,
} from '../model/personalization-settings';

import { ResponseBase } from '../model/ResponseBase';
import { ServiceBase } from './service-base';

@Injectable({
  providedIn: 'root',
})
export class PersonalizationSettingsService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/Member`;

  constructor(http: HttpClient) {
    super(http);
  }

  RetrievePersonalizationSettings(
    request: PersonalizationSettingsForRetrieveParams
  ): Observable<ResponseBase<PersonalizationSettingsForRetrieveResponse>> {
    const url = `${this.baseUrl}/GetMemberNotify`;

    return this.http.post<
      ResponseBase<PersonalizationSettingsForRetrieveResponse>
    >(url, request, this.httpOptions);
  }

  SubmitPersonalizationSettings(
    request: PersonalizationSettingsForSubmitParams
  ): Observable<ResponseBase<PersonalizationSettingsForSubmitResponse>> {
    const url = `${this.baseUrl}/SaveMemberNotify`;

    return this.http.post<
      ResponseBase<PersonalizationSettingsForSubmitResponse>
    >(url, request, this.httpOptions);
  }

  RetrieveLINEToken(
    request: PersonalizationSettingsForLINETokenParams
  ): Observable<ResponseBase<PersonalizationSettingsForLINETokenResponse>> {
    const url = `${this.baseUrl}/GetLineID`;

    return this.http.post<
      ResponseBase<PersonalizationSettingsForLINETokenResponse>
    >(url, request, this.httpOptions);
  }
}
