import { filter } from 'rxjs/operators';
import { formatDate } from '@angular/common';
// tslint:disable-next-line: max-line-length
import { GetFundListResponse, GetFundListArgs, FundDetail, GetFeeRateArgs, GetFeeRateResponse, GetBucAccArgs, GetBucAccResponse, SingletransactionModel, FeeRateItem, SingletransactionModelVerifyResponse, GetDivAccArgs, GetDivAccResponse, BucAccList, DivAccList } from './../../model/open-data';
import { Component, OnInit } from '@angular/core';
import { OpenDataService } from 'src/app/services/open-data.service';
import { JwtPayload } from 'src/app/model/base';
import jwt_decode from 'jwt-decode';
import { devOnlyGuardedExpression } from '@angular/compiler';
import { ValidationHelper } from 'src/app/helper/validationHelper';
import { MessageService } from 'src/app/services/message.service';
import { VerifyService } from 'src/app/services/verify.service';
import { RSPService } from 'src/app/services/rsp.service';
import { MemberService } from 'src/app/services/member.service';
import { PageParam } from 'src/app/model/member';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { Router } from '@angular/router';

@Component({
  selector: 'app-singletransaction',
  // selector: 'app-content',
  templateUrl: './singletransaction.component.html',
  styleUrls: ['./singletransaction.component.css']
})
export class SingletransactionComponent implements OnInit {
  jwt = {} as JwtPayload;
  GetFundListArgs = new GetFundListArgs();
  GetFundListResponse = new GetFundListResponse();
  GetFeeRateArgs = new GetFeeRateArgs();
  GetFeeRateResponse = new GetFeeRateResponse();
  GetBucAccResponse = new GetBucAccResponse();
  GetDivAccResponse = new GetDivAccResponse();
  SingletransactionModel = new SingletransactionModel();
  SingletransactionModelVerifyResponse = new SingletransactionModelVerifyResponse();
  selectedFund: FundDetail;
  selectedFundValue = '';
  passwordType: string = 'password';
  conceal: string = 'conceal';
  pageparam = new PageParam();
  isSelected: boolean = false;
  canBuy: boolean = false;
  HasDivAcc: boolean = true;

  TWDList = [] as BucAccList[];//台幣帳戶
  FORList = [] as BucAccList[];//外幣帳戶

  AmountCheck: number;//檢查小數後幾位
  ShowAlert = ''; //紅字警告
  numbercheck: RegExpExecArray;
  AccAlert = ''; //帳號警告
  AccAlert2 = ''; //配息帳號警告
  constructor(
    private _MemberService: MemberService,
    private openDataService: OpenDataService,
    private valid: ValidationHelper,
    private message: MessageService,
    private router: Router,
    private verifyService: VerifyService,
    private rspService: RSPService
  ) { }

  ngOnInit(): void {
    this.jwt = jwt_decode(localStorage.getItem('token') || '');
    // if (this.jwt.KYC_STATUS != 'Y') {
    //   if (confirm('您的kyc已過期，無法進行交易，是否導頁到kyc頁填寫?')) {
    //     this.router.navigateByUrl('kyc');
    //   }
    // }
    this.Weblog();
  }

  getFundList() {
    this.GetFundListArgs.FUND_ID = 'ALL';
    this.GetFundListArgs.FUND_TYPE = 1;

    this.openDataService.GetFundList(this.GetFundListArgs).subscribe(res => {
      this.GetFundListResponse = res.Entries;
    });
  }

  Weblog() {
    this.pageparam.Value = 3;
    this.pageparam.Page = '單筆申購';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.getFundList();
      }
      else {
        return
      }
    })
  }

  // 選擇基金
  selectFund(e: any) {

    this.selectedFund = this.GetFundListResponse.FundList.filter(x => x.FUND_ID === e.value)[0];

    // 指定此筆申購基金可輸入的小數位數
    this.AmountCheck = this.selectedFund.DEC_LEN;

    if (e.value == '') {
      this.isSelected = false;
    }
    else {
      this.isSelected = true;
    }

    if (this.selectedFund.OfficialManualPath !== null) {
      const tempArr = this.selectedFund.OfficialManualPath.split('/');
      const index = tempArr.length;
      this.selectedFund.OfficialManualFileName = tempArr[index - 1];
    }

    if (this.selectedFund.RiskForecastPath !== null) {
      const tempArr = this.selectedFund.RiskForecastPath.split('/');
      const index = tempArr.length;
      this.selectedFund.RiskForecastFileName = tempArr[index - 1];
    }

    this.GetBucAcc({ CRNCY_CD: this.selectedFund.FUND_CURRENCY });
    this.GetDivAcc({ CRNCY_CD: this.selectedFund.FUND_CURRENCY, FUND_ID: this.selectedFund.FUND_ID });
    this.checkTypeOfRisk(
      this.jwt.KYC_RISK_ATTR,
      parseInt(this.selectedFund.FUND_RISK, 10)
    );

    //配息方式
    switch (this.selectedFund.DIV_TYPE) {
      case "1":
        this.selectedFund.DIV_TYPE_NM = "月配息";
        break;
      case "2":
        this.selectedFund.DIV_TYPE_NM = "季配息";
        break;
      case "3":
        this.selectedFund.DIV_TYPE_NM = "年配息";
        break;
      default:
        this.selectedFund.DIV_TYPE_NM = "不分配";
        break;
    }

    //手續費收取方式
    switch (this.selectedFund.AFEE_TYPE) {
      case "1":
        this.selectedFund.AFEE_TYPE_NM = "前收型";
        break;
      case "2":
        this.selectedFund.AFEE_TYPE_NM = "後收型";
        break;
      default:
        this.selectedFund.AFEE_TYPE_NM = "無";
        break;
    }
    // 清空資料
    this.SingletransactionModel = new SingletransactionModel();
  }

  // 檢核申購基金是否符合個人風險屬性
  checkTypeOfRisk(kycRisk: string, fundRiskInt: number) {
    const kycRiskInt = parseInt(kycRisk, 10);
    switch (kycRisk) {
      case '1': // 保守型
        this.canBuy = kycRiskInt >= fundRiskInt;
        break;
      case '2': // 穩健型
        this.canBuy = kycRiskInt + 1 >= fundRiskInt;
        break;
      case '3': // 積極型
        this.canBuy = kycRiskInt + 2 >= fundRiskInt;
        break;
    }
  }

  getFeeRate(request: any) {
    debugger
    if (typeof (request) == 'object') {
      request = request.value
    }

    // this.AmountCheck = 2;
    // debugger
    // 判斷有無小數點
    //
      this.numbercheck = /^([\d]+)\.?([\d]*)$/.exec(request) as RegExpExecArray;
    /*
        1.先抓出輸入是否只有數字沒有特殊符號
        2.判斷基金可否接受輸入小數，如果可 可接受幾位數
        3.可輸入小數的基金，沒有輸入小數點後的數字
        4.只可輸入整數的基金，如果輸入了小數點則幫他進位
    */

    //1.
    if (this.numbercheck == null) {
      this.ShowAlert = `輸入數字錯誤，請重新輸入`;
      this.SingletransactionModel.ApplyAmount = 0;
      return;
    }
    //2.
    if (this.numbercheck[2].length > this.AmountCheck) {
      if (this.AmountCheck == 0) {
        this.ShowAlert = `此基金僅可申購整數位`;
        return;
      }
      else {
        this.ShowAlert = `此基金只可申購至小數點後${this.AmountCheck}位`;
        return;
      }
    }
    //3.
    if (this.numbercheck[2].length <= this.AmountCheck) {
      if (this.AmountCheck == 0 && request.indexOf('.') > -1) {
        this.ShowAlert = `僅可申購整數位之基金不可輸入小數點`;
        // request = parseInt(request).toString();
        return;
      }
      else if (this.AmountCheck > 0 && request.indexOf('.') == request.length - 1) {
        this.ShowAlert = `如輸入小數點，則小數點後不可沒有輸入數字`;
        // request = parseFloat(request).toString();
        return;
      }
    }
    //4. else {
    this.ShowAlert = '';
    const dateTime = new Date();
    this.GetFeeRateArgs.TRADE_TYPE = '1';
    this.GetFeeRateArgs.FUND_ID = this.selectedFund.FUND_ID;
    this.GetFeeRateArgs.CRNCY_CD = this.selectedFund.FUND_CURRENCY;
    if (this.AmountCheck == 0) {
      // request = parseInt(request).toString();
      this.GetFeeRateArgs.TRADE_AMT = parseInt(request);
      this.SingletransactionModel.ApplyAmount = parseInt(request);
    }
    else {
      // request = parseFloat(request).toString();
      this.GetFeeRateArgs.TRADE_AMT = parseFloat(request);
      this.SingletransactionModel.ApplyAmount = parseFloat(request)
    }
    this.GetFeeRateArgs.TRADE_DATE = dateTime;

    this.openDataService.GetFeeRate(this.GetFeeRateArgs).subscribe(res => {
      if (res.StatusCode === 0) {
        this.GetFeeRateResponse = res.Entries;

        const rateArr = this.GetFeeRateResponse.FeeRateList.map(x => x.FEE_RATE);
        const minRate = Math.min.apply(null, rateArr);

        const minData = this.GetFeeRateResponse.FeeRateList.filter(x => x.FEE_RATE === minRate);
        const sort = new Array('2', '3', '5', '4', '1');
        // 牌價
        const listRate = this.GetFeeRateResponse.FeeRateList.filter(x => x.FEE_CHOICE === '1')[0].FEE_RATE;
        this.SingletransactionModel.ListPrice = parseFloat((listRate * this.GetFeeRateArgs.TRADE_AMT).toFixed(this.selectedFund.DEC_LEN));
        this.SingletransactionModel.OnlyListPrice = this.GetFeeRateResponse.FeeRateList.filter(x => x.FEE_CHOICE != '1').length === 0;

        let targetRate = new FeeRateItem();
        // 同價位按排序擇優
        sort.some(function (v) {
          const temp = minData.filter(x => x.FEE_CHOICE === v);
          if (temp.length > 0) {
            targetRate = temp[0];
            return temp[0];
          }
          else {
            return false;
          }
        });

        this.SingletransactionModel.FEE_NAME = targetRate.FEE_NAME;
        this.SingletransactionModel.ApplyFee = parseFloat((request * targetRate.FEE_RATE).toFixed(this.selectedFund.DEC_LEN));
        this.SingletransactionModel.CAMPAIGN_CODE = targetRate.CAMPAIGN_CODE;
        this.SingletransactionModel.FEE_CHOICE = targetRate.FEE_CHOICE;
        this.SingletransactionModel.CAMPAIGN_SHNM = targetRate.CAMPAIGN_SHNM;
        this.SingletransactionModel.ApplyFeeRate = targetRate.FEE_RATE;
        if (this.SingletransactionModel.FEE_CHOICE == '1') {
          this.SingletransactionModel.OnlyListPrice = true;
        }
        else {
          this.SingletransactionModel.OnlyListPrice = false;
        }
      }
      else {
        this.SingletransactionModel.OnlyListPrice = true;
        return;
      }
    });
    // }
  }

  GetBucAcc(arg: GetBucAccArgs) {
    this.openDataService.GetBucAcc(arg).subscribe(res => {
      this.GetBucAccResponse = res.Entries;

      var TWDList = [] as BucAccList[];//台幣帳戶
      var FORList = [] as BucAccList[];//外幣帳戶

      this.GetBucAccResponse.BucAccList.forEach(data => {
        if (data.SEAL_TYPE == '3') {
          switch (data.ACC_NO_TYPE) {
            case '0':
              TWDList.push(data);
              break;
            case '2':
              FORList.push(data);
              break;
          }
        }
      });
      this.TWDList = TWDList;
      this.FORList = FORList;

      if (arg.CRNCY_CD == 'TWD') {
        if (TWDList.length == 0) {
          this.HasDivAcc = false;
          alert('無台幣扣款帳號，暫時無法進行外幣交易。');
        }
      }
      else {
        if (FORList.length == 0) {
          this.HasDivAcc = false;
          alert('無外幣扣款帳號，暫時無法進行外幣交易。');
        }
      }
    });
  }

  GetDivAcc(arg: GetDivAccArgs) {

    this.openDataService.GetDivAcc2(arg).subscribe(res => {
      if (res.Entries.DivAccList.length > 0) {
        this.GetDivAccResponse = res.Entries;
        this.HasDivAcc = true;
      }
      else {
        if (this.selectedFund.DIVIDEND_TYPE == '4') {
          this.HasDivAcc = true;
        }
        else {
          this.HasDivAcc = false;
        }

        if (!this.HasDivAcc && this.selectedFund.DIVIDEND_TYPE != '4') {
          alert('您目前無配息帳號，請至臨櫃申請')
        }
      }
    });
  }

  ClearData(): void {
    this.SingletransactionModel = new SingletransactionModel();
    this.selectedFundValue = '';
  }

  GoStep1(): void {
    this.SingletransactionModel.Step = 1;
  }

  GoStep2(): void {
    this.validation();

    if (this.valid.errorMessages.length > 0) {
      // this.message.showErrorMSGs(this.valid.errorMessages);
      this.valid.clear();
      return;
    }
    // debugger
    this.SingletransactionModel.FUND_ID = this.selectedFund.FUND_ID;
    this.SingletransactionModel.FUND_NAME = this.selectedFund.FUND_NAME;
    this.SingletransactionModel.FUND_CURRENCY = this.selectedFund.FUND_CURRENCY;
    this.SingletransactionModel.FUND_CURRENCY_NM = this.selectedFund.FUND_CURRENCY_NM;
    this.SingletransactionModel.DIVIDEND_TYPE = this.selectedFund.DIVIDEND_TYPE;
    this.SingletransactionModel.DIVIDEND_TYPE_NM = this.selectedFund.DIVIDEND_TYPE_NM;
    this.SingletransactionModel.ApplyTime = new Date();
    this.SingletransactionModel.BF_NO = this.jwt.BF_NO.toString();

    const deduction = this.GetBucAccResponse.BucAccList.filter(x => x.ACCOUNT_NO === this.SingletransactionModel.ACCOUNT_NO)[0];
    this.SingletransactionModel.ACCOUNT_NO_SHOW = deduction.ACCOUNT_NO_SHOW;
    this.SingletransactionModel.BANK_BRH_SHNM = deduction.BANK_BRH_SHNM;
    this.SingletransactionModel.SUB_BANK_BRH = deduction.BANK_BRH;

    if (this.SingletransactionModel.DIVIDEND_TYPE != '4') {
      const divAcc = this.GetDivAccResponse.DivAccList.filter(x => x.ACCOUNT_NO === this.SingletransactionModel.ACCOUNT_DIV_NO)[0];
      this.SingletransactionModel.ACCOUNT_DIV_NO_SHOW = deduction.ACCOUNT_NO_SHOW;
      this.SingletransactionModel.BANK_BRH_DIV_SHNM = deduction.BANK_BRH_SHNM;
      this.SingletransactionModel.SUB_BANK_BRH_DIV = deduction.BANK_BRH;
    }

// debugger

    this.SingletransactionModel.ApplyTotalAmount = this.SingletransactionModel.ApplyFee + this.SingletransactionModel.ApplyAmount;

    this.valid.clear();
    this.verifyService.GetSingletransactionVerify(this.SingletransactionModel).subscribe(res => {
      this.SingletransactionModelVerifyResponse = res.Entries;
      this.SingletransactionModel.CommissionDate = res.Entries.CommissionDate;
      this.SingletransactionModelVerifyResponse.VerifyMsg.forEach(element => {
        this.valid.addErrorMessage(element);
      });

      if (this.valid.errorMessages.length > 0) {
        // this.message.showErrorMSGs(this.valid.errorMessages);
        this.ShowAlert = this.valid.errorMessages.join('\n');
        return;
      }
      else {
        this.SingletransactionModel.Step = 2;
      }
    });
  }

  GoStep3(): void {
    this.SingletransactionModel.ApplyTime = new Date();
    this.validation2();
  }

  validation(): void {
    this.getFeeRate(this.SingletransactionModel.ApplyAmount.toString());
    // debugger
    if (this.ShowAlert != '') {
      this.valid.addErrorMessage(this.ShowAlert);
    }
    this.valid.clear();
    // if (this.SingletransactionModel.ListPrice === 0) {
    //   this.valid.addErrorMessage('交易失敗');
    //   return;
    // }

    if (this.selectedFund == null) {
      this.valid.addErrorMessage('請選擇基金');
    }

    if (this.SingletransactionModel.ApplyAmount === 0) {
      this.valid.addErrorMessage('申購金額不可輸入零');
      this.ShowAlert = '申購金額不可輸入零';
    }

    if (this.SingletransactionModel.ApplyAmount < 0) {
      this.valid.addErrorMessage('申購金額不可輸入零以下的數字');
      this.ShowAlert = '申購金額不可輸入零以下的數字';
    }

    // 檢查小數位數
    // var nVertify = this.SingletransactionModel.ApplyAmount.toString();
    // var pos = nVertify.length - (nVertify.indexOf('.') + 1); //字串長度減掉小數點在的位置+1 = 小數點後面有幾位數
    // if (pos != nVertify.length) {
    //   if (this.selectedFund.DEC_LEN < pos) {
    //     // ex: this.selectedFund.DEC_LEN = 3
    //     // in: 1234.1234 超出位數
    //     this.valid.addErrorMessage(`申購金額不可超過小數點 ${this.selectedFund.DEC_LEN} 位數`);
    //   }
    // }
    // else if (pos == 0) {
    //   this.valid.addErrorMessage(`申購金額格式錯誤`); // ex:123. 後面無輸入小數 卻輸入小數點
    // }
    // else {
    //   this.valid.addErrorMessage(`申購金額不可有小數位數`); // ex: this.selectedFund.DEC_LEN = 0
    // }

    if (this.selectedFund.DIVIDEND_TYPE != '4') {
      if (this.SingletransactionModel.ACCOUNT_DIV_NO == '') {
        this.valid.addErrorMessage('請選擇配息帳號');
        this.AccAlert2 = '請選擇配息帳號';
      }
    }

    if (this.SingletransactionModel.ACCOUNT_NO === '') {
      this.valid.addErrorMessage('請選擇扣款帳號');
      this.AccAlert = '請選擇扣款帳號';
    }

    // true 無優惠手續費 false 有優惠手續費
    // 計算方式不同
    // ApplyAmount 申購金額; ListPrice 原價手續費;
    // this.target.rate 優惠手續費率
    // if (this.SingletransactionModel.OnlyListPrice) {
    //   if (this.SingletransactionModel.ApplyAmount + this.SingletransactionModel.ListPrice > 5000000) {
    //     this.valid.addErrorMessage('單筆申購限額新臺幣伍百萬元(申購金額+手續費)');
    //     this.ShowAlert = '單筆申購限額新臺幣伍百萬元(申購金額+手續費)';
    //   }
    // }
    // else {
    //   if (this.SingletransactionModel.ApplyAmount + this.SingletransactionModel.ApplyFee > 5000000) {
    //     this.valid.addErrorMessage('單筆申購限額新臺幣伍百萬元(申購金額+手續費)');
    //     this.ShowAlert = '單筆申購限額新臺幣伍百萬元(申購金額+手續費)';
    //   }
    // }
  }

  validation2(): void {
    // debugger;
    this.valid.clear();
    if (this.SingletransactionModel.Password === '') {
      this.valid.addErrorMessage('請輸入密碼');
    }
    if (!this.SingletransactionModel.ReadInfo) {
      this.valid.addErrorMessage('請勾選我已閱讀公開說明書及風險預告書');
    }

    if (this.valid.errorMessages.length > 0) {
      this.message.showErrorMSGs(this.valid.errorMessages);
      this.valid.clear();
      return;
    }

    this.verifyService.GetSingletransactionVerify2(this.SingletransactionModel).subscribe(res => {
      this.SingletransactionModelVerifyResponse = res.Entries;
      this.SingletransactionModelVerifyResponse.VerifyMsg.forEach(element => {
        this.valid.addErrorMessage(element);
      });

      if (this.valid.errorMessages.length > 0) {
        this.message.showErrorMSGs(this.valid.errorMessages);
      }
      else {
        this.rspService.SendSingletransactionApply(this.SingletransactionModel).subscribe(res => {
          if (res.StatusCode === 0) {
            this.SingletransactionModel.Step = 3;
          }
        });
      }
    });
  }

  onClickConceal() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.conceal = 'conceal yincang';
    } else {
      this.passwordType = 'password';
      this.conceal = 'conceal';
    }
  }

  sendtype(value: string) {
    var list = this.GetDivAccResponse.DivAccList;

    for (let i = 0; i < list.length; i++) {
      if (value == list[i].ACCOUNT_NO) {
        this.SingletransactionModel.BANK_BRH_DIV_SHNM = list[i].BANK_BRH_SHNM;
        this.SingletransactionModel.SUB_BANK_BRH_DIV = list[i].BANK_BRH;
        this.SingletransactionModel.cType = list[i].cType;
        this.SingletransactionModel.ACCOUNT_DIV_NO_SHOW = list[i].ACCOUNT_NO_SHOW
      }
    }

    console.log(this.SingletransactionModel.cType)
  }
}
