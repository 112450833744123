import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { BaseComponent } from 'src/app/pages/base/baseComponent';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.css']
})
export class LoginLayoutComponent extends BaseComponent implements OnInit {

  constructor(
    protected allow: AllowHelper,
    private router: Router) {
    super(allow);

    if (!localStorage.getItem('token')) {
      this.router.navigateByUrl('login');
      return;
    }

  }

  ngOnInit(): void {
  }

}
