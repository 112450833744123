<div class="Block-content">

  <div class="SearchDiv Fund-screening">
    <div class="label notice">基金篩選</div>
    <div class="SelectStyle2">
      <select (change)="selectFundType($event.target)" id="FundType">
        <option value="0">--庫存/非庫存基金--</option>
        <option value="1">--庫存基金--</option>
        <option value="2">--非庫存基金--</option>
      </select>
    </div>
    <a style="display: none;" href="#ex1" rel="modal:open" class="Main-button">確認<i
        class="fas fa-chevron-right"></i></a>
  </div>
  <div *ngFor="let fund of FundShowList">
    <div class="title-02">{{fund.FUND_NAME}}----{{fund.FUND_RISK_NM}}</div>
    <div class="ec-area">
      <table class="ec-table">
        <thead>
          <tr>
            <th>淨值日期</th>
            <th>最新淨值</th>
            <th>買進提醒</th>
            <th>賣出提醒</th>
            <th>目前報酬率(%)</th>
            <th>平均單位成本</th>
            <th>停利報酬率(%)</th>
            <th>停損報酬率(%)</th>
            <th>鎖利設定</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-th="淨值日期" align="center">
              <div>{{fund.NAV_DATE}}</div>
            </td>
            <td data-th="最新淨值" align="center">
              <div>{{str(fund.NAV_B,fund.NAV_DEC)}}</div>
            </td>
            <td data-th="買進提醒" align="center">
              <div>{{fund.MIN_VAL}}</div>
            </td>
            <td data-th="賣出提醒" align="center">
              <div>{{fund.MAX_VAL}}</div>
            </td>
            <td data-th="目前報酬率(%)" align="center">
              <div>{{fund.RETURN_RATE || 0}}%</div>
            </td>
            <td data-th="平均單位成本" align="center">
              <div>{{fund.AVG_COST || 0}}</div>
            </td>
            <td data-th="停利報酬率(%)" align="center">
              <div>＋{{fund.INCREASE}}%</div>
            </td>
            <td data-th="停損報酬率(%)" align="center">
              <div>－{{fund.DECLINE}}%</div>
            </td>
            <td data-th="鎖利設定" align="center">
              <div>
                <a style="cursor: pointer;" (click)="onEdit(fund)" rel="modal:open" class="set-up">
                  <!-- <a style="cursor: pointer;" (click)="onEdit(fund, dialog)" rel="modal:open" class="set-up"> -->
                  <i class="fas fa-chevron-right"></i>設定
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- <ng-template #dialog let-data let-ref="dialogRef"> -->
  <!-- <div id="set-up" class="Purchase" style="max-width: 30%;"> -->
  <dialog id="set-up" class="Purchase" style="border: none; max-width: 30%; padding: unset" *ngIf="ThisFund">
    <h3 style="text-align: center;padding: 25px 0;border-bottom: 1px solid #f0eeee;">個人鎖利設定</h3>
    <div class="basic-data-table">
      <div class="w-100">
        <div class="basic-left">基金名稱</div>
        <div class="basic-right">{{ThisFund.FUND_NAME}}----{{ThisFund.FUND_RISK_NM}}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">淨值日期</div>
        <div class="basic-right">{{ThisFund.NAV_DATE}}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">最新淨值</div>
        <div class="basic-right">{{ThisFund.NAV_B}}</div>
      </div>
      <div class="w-100">
        <div class="basic-left">買進提醒</div>
        <div class="basic-right Personal-f"><input [(ngModel)]="ThisFund.MIN_VAL"></div>
      </div>
      <div class="w-100">
        <div class="basic-left">賣出提醒</div>
        <div class="basic-right Personal-f"><input [(ngModel)]="ThisFund.MAX_VAL"></div>
      </div>
      <div class="w-100" *ngIf="ThisFund.IS_BALANCE">
        <div class="basic-left">目前投資報酬率(％)</div>
        <div class="basic-right Personal-f">{{ThisFund.RETURN_RATE}}%</div>
      </div>
      <div class="w-100" *ngIf="ThisFund.IS_BALANCE">
        <div class="basic-left">平均單位成本</div>
        <div class="basic-right">{{ThisFund.AVG_COST}}</div>
      </div>
      <div class="w-100" *ngIf="ThisFund.IS_BALANCE">
        <div class="basic-left">停利報酬率(%)</div>
        <div class="basic-right Personal-f"><input [(ngModel)]="ThisFund.INCREASE">%</div>
      </div>
      <div class="w-100" *ngIf="ThisFund.IS_BALANCE">
        <div class="basic-left">停損報酬率(％)</div>
        <div class="basic-right Personal-f"><input [(ngModel)]="ThisFund.DECLINE">%</div>
      </div>
    </div>
    <div class="button-box">
      <a href="javascript:void(0);" (click)="closeSetUpDialog()" rel="modal:close" class="cancel-button">取消</a>
      <!-- <a (click)="ref.close()" rel="modal:close" class="cancel-button">取消</a> -->
      <a href="javascript:void(0);" (click)="saveFundWarning()" class="Main-button">確認<i
          class="fas fa-chevron-right"></i></a>
    </div>
  </dialog>
  <!-- </div> -->
  <!-- </ng-template> -->

</div>
