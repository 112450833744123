<div *ngIf="this.SingletransactionModel.Step == 1">
  <div class="Block-content">
    <div class="transaction-hr">交易時間 <app-now></app-now>
    </div>
    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">1</div>
        <div class="p-contact-form__step-label">單筆申購</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">2</div>
        <div class="p-contact-form__step-label">申購確認</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">3</div>
        <div class="p-contact-form__step-label">申購完成</div>
      </div>
    </div>

    <div class="basic-data-table">
      <div class="w-100">
        <div class="basic-left">
          <div class="fund-in">
            <div class="SelectStyle2 w-100">
              <select [(ngModel)]="selectedFundValue" (change)="selectFund($event.target)"
                [disabled]="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')">
                <option value="">- 請選擇與申購基金 -</option>
                <option *ngFor="let fund of this.GetFundListResponse.FundList" [value]="fund.FUND_ID">
                  {{fund.FUND_NAME}}---({{fund.FUND_RISK_NM}})
                </option>
              </select>
            </div>
          </div>
          <div
            *ngIf="jwt.KYC_STATUS != 'Y' || !(jwt.KYC_RISK_ATTR == '1' || jwt.KYC_RISK_ATTR == '2' || jwt.KYC_RISK_ATTR == '3')">
            <br /><span class="text-red"><b>*您的kyc已過期，無法進行交易</b></span><br />
            <a href="kyc"><img alt="riskbtn" src=".././../../assets/images/riskbtn.png" /></a>
          </div>
          <div *ngIf="selectedFundValue != '' && canBuy == false">
            <br /><b>您留存之風險屬性為{{this.jwt.KYC_RISK_ATTR_NM}}，未達此商品之風險等級</b><br />
            <a href="kyc"><img alt="riskbtn" src=".././../../assets/images/riskbtn.png" /></a>
          </div>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">計價幣別</div>
        <div class="basic-right">
          <span *ngIf="selectedFundValue != ''">
            {{ selectedFund.FUND_CURRENCY_NM }}
          </span>
        </div>
      </div>

      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">配息方式</div>
        <div
          [ngClass]="{'basic-right-c4': selectedFund?.DIVIDEND_TYPE != '4','basic-right': selectedFund?.DIVIDEND_TYPE == '4'}">
          <span *ngIf="selectedFundValue != ''" style="font-size: 18px;">
            {{selectedFund?.DIV_TYPE_NM}}
          </span>
        </div>
        <div class="basic-left" *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE != '4'">
          配息收益約定帳戶
        </div>
        <div class="basic-right" *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE != '4'">
          <div class="SelectStyle2 w-100">
            <select (change)="sendtype(SingletransactionModel.ACCOUNT_DIV_NO)"
              [(ngModel)]="SingletransactionModel.ACCOUNT_DIV_NO"
              [disabled]="!canBuy || !(selectedFund.DIVIDEND_TYPE != '4' && HasDivAcc)">
              <option value="">請選擇</option>
              <option *ngFor="let account of GetDivAccResponse.DivAccList" value="{{ account.ACCOUNT_NO }}">
                {{ account.BANK_BRH_SHNM }} {{ account.ACCOUNT_NO_SHOW }}
              </option>
            </select>
          </div>
          <p class="text-red" style="margin-top: 10px;" *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE != '4' && AccAlert2 != ''">
            *{{AccAlert2}}
          </p>
        </div>
      </div>

      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">申購金額</div>
        <div class="basic-right">
          <div *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE == '4'" class="unit-box">
            <input [(ngModel)]="SingletransactionModel.ApplyAmount" type="text" class="input-25" step="1" min="0"
              [disabled]="!canBuy" (blur)="getFeeRate($event.target)" />
            <p>元</p>
          </div>
          <p style="margin-top: 10px;"
            *ngIf="ShowAlert != '' && selectedFundValue != '' && selectedFund.DIVIDEND_TYPE == '4'" class="text-red">
            *{{ShowAlert}}
          </p>
          <div *ngIf="selectedFundValue != '' && selectedFund.DIVIDEND_TYPE != '4'" class="unit-box">
            <input [(ngModel)]="SingletransactionModel.ApplyAmount" type="text" class="input-25" step="1" min="0"
              [disabled]="!canBuy || !(selectedFund.DIVIDEND_TYPE != '4' && HasDivAcc)"
              (blur)="getFeeRate($event.target)" />
            <p>元</p>
          </div>
          <p style="margin-top: 10px;"
            *ngIf="ShowAlert != '' && selectedFundValue != '' && selectedFund.DIVIDEND_TYPE != '4'" class="text-red">
            *{{ShowAlert}}
          </p>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">手續費收取方式</div>
        <div class="basic-right-c4">
          <span style="font-size: 18px;">{{selectedFund?.AFEE_TYPE_NM}}</span>
        </div>
        <div class="basic-left">手續費</div>
        <div class="basic-right">
          <div *ngIf="selectedFundValue != '' && this.SingletransactionModel.OnlyListPrice" class="unit-box">
            <span class="r-10">
              {{ this.SingletransactionModel.ListPrice | number }}</span>
            <span class="r-60">元</span>
          </div>
          <div *ngIf="selectedFundValue != '' && !this.SingletransactionModel.OnlyListPrice" class="unit-box">
            <span class="r-10" style="text-decoration: line-through">
              {{ this.SingletransactionModel.ListPrice | number }}</span>
            <span class="r-10">元</span>
            <span>
              享
              <span style="color: #ff0000">
                {{ this.SingletransactionModel.FEE_NAME }}</span>
              手續費：
              <span style="color: #ff0000; padding: 0px">
                {{ this.SingletransactionModel.ApplyFee | number }}</span>
              元
            </span>
          </div>
        </div>
      </div>
      <div class="w-100" *ngIf="isSelected">
        <div class="basic-left">扣款帳號</div>
        <div class="basic-right">
          <!--style="background-color: #f0eeee" -->
          <div *ngIf="selectedFundValue != '' && selectedFund.FUND_CURRENCY_NM == '新臺幣'" class="SelectStyle2 w-100">
            <select [(ngModel)]="SingletransactionModel.ACCOUNT_NO" [disabled]="!canBuy || !HasDivAcc">
              <option value="">請選擇</option>
              <option *ngFor="let account of TWDList" value="{{ account.ACCOUNT_NO }}">
                {{ account.BANK_BRH_SHNM }} {{ account.ACCOUNT_NO_SHOW }}
              </option>
            </select>
          </div>
          <div *ngIf="selectedFundValue != '' && selectedFund.FUND_CURRENCY_NM == '新臺幣' && AccAlert != ''" style="margin-top: 10px;"
            class="text-red">*{{AccAlert}}
          </div>
          <div *ngIf="selectedFundValue != '' && selectedFund.FUND_CURRENCY_NM != '新臺幣'" class="SelectStyle2 w-100">
            <select [(ngModel)]="SingletransactionModel.ACCOUNT_NO" [disabled]="!canBuy || !HasDivAcc">
              <option value="">請選擇</option>
              <option *ngFor="let account of FORList" value="{{ account.ACCOUNT_NO }}">
                {{ account.BANK_BRH_SHNM }} {{ account.ACCOUNT_NO_SHOW }}
              </option>
            </select>
          </div>
          <div *ngIf="selectedFundValue != '' && selectedFund.FUND_CURRENCY_NM != '新臺幣' && AccAlert != ''" style="margin-top: 10px;"
            class="text-red">*{{AccAlert}}
          </div>
        </div>
      </div>
    </div>

    <div class="button-box basic-data-table-bottom-line">
      <a *ngIf="isSelected" href="#" class="cancel-button">取消</a>
      <a *ngIf="isSelected && canBuy" href="javascript:void(0)" (click)="GoStep2()" class="Main-button">下一步<i
          class="fas fa-chevron-right"></i></a>
    </div>

    <div class="basic-note-div">
      <h1>注意事項：</h1>
      如需取消委託交易，請於交易時間截止前，於【取消交易】中點選該筆交易，超過交易時間該筆委託將無法進行取消。<br />
      凡進行各項交易申請，申請成功後，系統皆會寄發確認信至受益人約定之電子郵件信箱。 委託成功不代表交易成功，請於交易截止時間後，至電子交易平台點選【歷史交易明細】功能，查詢交易是否成功。
      交易委託時間以本系統實際完成收件為準，如委託交易營業日與完成委託營業日不同時，系統無法接受該筆委託，請重新下單。各營業日截止時間前完成之委託視為當日申請之交易；非營業日或逾各基金交易截止時間之委託視為次一營業日之申請。<br>
      如因扣款機構之電腦轉帳系統故障或其他不可抗力事由，未能於指定之日期進行轉帳扣款作業時，本公司得順延至扣款機構之電腦轉帳系統恢復正常之營業日進行轉帳扣款作業並以該日為基金申購日。
      交易所授權之扣款帳戶將在同一天內自同一帳戶執行數筆扣款交易。若授權之帳戶餘額不足支付自動轉帳申購之價金時，指定銀行有權自行決定各筆扣款之先後順序或不予進行扣款轉帳作業。
      若因重大事件導致基金暫停計價，致使各項交易日順延，實際各項交易日期以「交易確認單」或「投資對帳單」為準。<br>
      本公司網路交易申請係透過財金股份有限公司全國性繳費（稅）機制進行扣款作業，依該機構規定單筆最高轉帳扣繳限額為等值新臺幣伍百萬元，每日最高轉帳扣繳限額為等值新臺幣參仟萬元，但前述金額經調整者，依調整後金額定之。各扣款銀行或有不同限額限制，申購人申購前應自行與該扣款銀行確認相關業務之額度限制，並依該額度限制來申購基金，以避免額度限制造成扣款失敗。
      當本公司所發行之受益權單位數達金管會所核准之最高發行基金規模或有其他事由時，本公司有權拒絕受益人之申購申請，並無息返還申購價金。<br>
      台中銀投信所發行之基金因其受益憑證以無實體方式發行，未開放證券商保管劃撥帳戶功能，故無法提供投資人要求可透過證券商保管劃撥帳戶辦理申購贖回及登載於證券商之保管戶劃撥帳戶之服務且受益人不得申請領回實體受益憑證。<br>
      為避免因受益人短線交易頻繁，造成基金管理及交易成本，增加進而損及基金長期持有之受益人權益，並稀釋基金之權利，故不歡迎受益人進行短線交易。若受益人進行短線交易，經理公司將另洽收買回費用。基金受益權單位數之買回限制規定，買回淨值計算日及買回價金給付日請詳閱各基金公開說明書/投資說明書。<br>
      受益人同意經理公司得依個人資料保護法及相關法令規定，對其個人資料為搜集、處理、利用或國際傳遞。受益人並同意經理公司基於客戶服務、資訊提供、或為受益人之利益等目的或依法令規定，得將其個人開戶及交易等資料，提供予受經理公司委託辦理相關業務之機構。<br>
      辦理基金銷售業務時，對於明知已屬明顯弱勢族群之投資人，包括年齡為70歲以上，教育程度為國中畢業以下或有全民健康保險重大傷病證明等，不主動介紹屬高風險之基金產品。<br>
      投資人進行高收益債券基金交易前，應審慎評估本身之財務狀況與風險承受能力是否適合此種交易，並充分瞭解下列事項：投資人投資高收益債券基金不宜占其投資組合過高之比重。由於高收益債券之信用評等未達投資等級或未經信用評等，且對利率變動的敏感度甚高，故基金可能會因利率上升、市場流動性下降，或債券發行機構違約不支付本金、利息或破產而蒙受虧損。基金不適合無法承擔相關風險之投資人。<br>
      台端所申購之基金有不同級別，台端應於申購前充分瞭解該檔基金各級別之不同，如為不同計價幣別、配息或不配息、手續費為前收或後收等，以投資合適之級別。不同級別之費用率與報酬率或有差異，現提供該檔基金各級別近五年度之費用率與報酬率資訊，請詳閱本公司提供該檔基金各級別近五年度之費用率與報酬率資訊。<br>
      本公司基金經金管會核准或同意生效，或經金管會申報備查，惟不表示基金絕無風險。本公司以往之經理績效，不保證本基金之最低投資效益；本公司除盡善良管理人之注意義務外，不負責本基金之盈虧，亦不保證最低之收益，投資人申購前應詳閱本基金公開/投資說明書。公開說明書備索，或至本公司網址，或至公開資訊觀測站查詢下載。<br>
      投資人若欲瞭解本公司銷售機構報酬揭露相關情形，可至本公司網站之「銷售機構報酬揭露專區」
    </div>
  </div>
</div>

<div *ngIf="this.SingletransactionModel.Step == 2">
  <div class="Block-content">
    <div class="transaction-hr">交易時間 <app-now></app-now>
    </div>
    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">1</div>
        <div class="p-contact-form__step-label">單筆申購</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">2</div>
        <div class="p-contact-form__step-label">申購確認</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle">3</div>
        <div class="p-contact-form__step-label">申購完成</div>
      </div>
    </div>

    <div class="basic-data-table">
      <div class="w-100">
        <div class="basic-left">基金名稱</div>
        <div class="basic-right">
          {{ this.SingletransactionModel.FUND_NAME }}
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">計價幣別</div>
        <div class="basic-right">
          {{ this.SingletransactionModel.FUND_CURRENCY_NM }}
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">配息方式</div>
        <div class="basic-right">
          {{ this.SingletransactionModel.DIVIDEND_TYPE_NM }}
        </div>
      </div>
      <div class="w-100" *ngIf="SingletransactionModel.DIVIDEND_TYPE != '4'">
        <div class="basic-left">配息收益約定帳戶</div>
        <div class="basic-right">
          {{ this.SingletransactionModel.BANK_BRH_DIV_SHNM }}
          {{ this.SingletransactionModel.ACCOUNT_DIV_NO }}
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">申購金額</div>
        <div class="basic-right">
          {{ this.SingletransactionModel.ApplyAmount | number }} 元
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">優惠手續費</div>
        <div class="basic-right">
          {{ this.SingletransactionModel.ApplyFee | number }} 元
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">申購總金額</div>
        <div class="basic-right">
          {{ this.SingletransactionModel.ApplyTotalAmount | number }} 元
        </div>
      </div>
      <div class="w-100">
        <div class="basic-left">扣款帳號</div>
        <div class="basic-right">
          {{ this.SingletransactionModel.BANK_BRH_SHNM }}
          {{ this.SingletransactionModel.ACCOUNT_NO }}
        </div>
      </div>
    </div>

    <div class="basic-data-table transaction-password">
      <div class="w-100">
        <div class="basic-left">交易密碼</div>
        <div class="basic-right" *ngIf="selectedFundValue != ''">
          <input [(ngModel)]="SingletransactionModel.Password" style="float: left" [type]="passwordType"
            class="input-25" id="password" />
          <div [class]="conceal" (click)="onClickConceal()"></div>
        </div>
      </div>
    </div>

    <div class="i-have-read">
      <a href="{{ selectedFund.OfficialManualPath }}" target="_blank"
        download="{{selectedFund.OfficialManualFileName}}"><i class="fas fa-file-pdf"></i>
        公開說明書</a>
      <a href="{{ selectedFund.RiskForecastPath }}" target="_blank" download="{{selectedFund.RiskForecastFileName}}"><i
          class="fas fa-file-pdf"></i>
        風險預告書</a>
      <div class="checkbox-wrap">
        <input type="checkbox" [(ngModel)]="SingletransactionModel.ReadInfo" value="guangpan" name="investment"
          id="f1" />
        <label for="f1"></label><samp>我已閱讀公開說明書 風險預告書</samp>
      </div>
    </div>

    <div class="button-box">
      <a href="singletransaction" class="cancel-button">取消</a>
      <a href="javascript:void(0)" (click)="GoStep3()" class="Main-button">確認<i class="fas fa-chevron-right"></i></a>
    </div>
  </div>
</div>
<div *ngIf="this.SingletransactionModel.Step == 3">
  <div class="Block-content">
    <div class="transaction-hr">交易時間 <app-now></app-now>
    </div>
    <div class="p-contact-form__step">
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">1</div>
        <div class="p-contact-form__step-label">單筆申購</div>
      </div>
      <div class="p-contact-form__step-bar-gray"></div>
      <div class="p-contact-form__step-item Gray-word">
        <div class="p-contact-form__step-circle-finish">2</div>
        <div class="p-contact-form__step-label">申購確認</div>
      </div>
      <div class="p-contact-form__step-bar"></div>
      <div class="p-contact-form__step-item is-current">
        <div class="p-contact-form__step-circle-current">3</div>
        <div class="p-contact-form__step-label">申購完成</div>
      </div>
    </div>

    <div class="Details">
      <div class="">
        <p class="txt">
          <i class="fas fa-check-circle"></i> 單筆申購
          <span class="txt-red">{{
            this.SingletransactionModel.FUND_NAME
            }}</span>
          完成！
        </p>
        <p class="txt2">
          {{
          this.SingletransactionModel.ApplyTime | date: "yyyy/MM/dd HH:mm:ss"
          }}
        </p>
      </div>
      <div class="">
        <table border="0" cellpadding="1" cellspacing="0" class="ptable">
          <tbody>
            <tr>
              <th>收件日期</th>
              <th>預計申購日期</th>
            </tr>
            <tr>
              <td>
                {{ this.SingletransactionModel.ApplyTime | date: "yyyy/MM/dd" }}
              </td>
              <td>
                {{
                this.SingletransactionModel.CommissionDate
                | date: "yyyy/MM/dd"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="button-box">
      <a href="singletransaction" class="Main-button">回申購頁<i class="fas fa-chevron-right"></i></a>
    </div>
  </div>
</div>
