<div class="m-hdr1-sub"><span>填寫開戶資料</span></div>
<div class="Block-content" *ngIf="pagecontact==1">
  <div class="p-contact-form__step-100">
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-current">1</div>
      <div class="p-contact-form__step-label">開戶規範及個資同意書</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">2</div>
      <div class="p-contact-form__step-label">輸入基本資料</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">3</div>
      <div class="p-contact-form__step-label">填寫風險承受度及投資屬性</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">4</div>
      <div class="p-contact-form__step-label">資料確認</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">5</div>
      <div class="p-contact-form__step-label">開戶文件下載</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">6</div>
      <div class="p-contact-form__step-label">申請完成</div>
    </div>
  </div>


  <div class="Description">
    <h4>個人資料保護法告知事項同意書</h4>
    <div class="book-box">
      <p>
        台中銀證券投資信託股份有限公司(以下簡稱本公司)將遵守個人資料保護法之相關規範進行個人資料之蒐集、處理、利用或國際傳輸，並依據個人資料保護法第8條之規定，向台端告知下列事項，請台端詳閱：
      </p>
      <ol>
        <li>資料蒐集目的：
          為辦理證券投資信託、全權委託投資業務及其他合於本公司之營業登記項目或主管機關所核准之業務，以及基於營業目的、風險控管、稽核、客戶服務、管理、資訊提供、行銷活動推廣、洗錢防制、為台端之利益或依法令規定，或因各相關主管機關、法院要求等。
        </li>
        <li>個人資料之類別： 姓名、出生年月日、身分證統一編號、護照號碼、婚姻、家庭、教育、職業、聯絡方式、財務情況、社會活動、或後續其他合於本公司營業項目之特定目的所須蒐集之個人資料等。</li>
        <li>個人資料利用之期間、對象、地區及方式：
          <ul>
            <li><span>(1)</span>
              <p>期間：依相關法令或契約約定之保存期限，或本公司因執行業務所必須之保存期間。</p>
            </li>
            <li><span>(2)</span>
              <p>對象：本公司、本公司所屬分支機構、本公司之共同行銷或合作推廣對象、其他與本公司有業務往來之機構、依法定義務所必要提供之第三方，或經台端書面同意之對象。</p>
            </li>
            <li><span>(3)</span>
              <p>地區：前揭所稱對象之所在地。</p>
            </li>
            <li><span>(4)</span>
              <p>方式：電子文件、書面、電話、傳真、以自動化機器或其他非自動化之利用方式。</p>
            </li>
          </ul>
        </li>
        <li>台端就本公司保有台端之個人資料得行使之權利及方式：
          <ul>
            <li><span>(1)</span>
              <p>得向本公司查詢、請求閱覽或請求製給複製本，而本公司依法得酌收必要成本費用。</p>
            </li>
            <li><span>(2)</span>
              <p>得向本公司請求補充或更正，惟依法台端應為適當之釋明。</p>
            </li>
            <li><span>(3)</span>
              <p>得向本公司請求停止蒐集、處理或利用，及請求刪除，惟依法本公司因執行業務所必須者，得不依台端請求為之。</p>
            </li>
          </ul>
        </li>
        <li>台端知悉並瞭解，如未將申請業務或服務所需之個人資料提供予本公司，本公司將無法提供台端相關服務。</li>
        <li>
          如台端未完整提供本公司遵循美國海外帳戶稅收遵循法及相關跨政府協議執行美國帳戶調查所需個人資料或不同意本公司申報美國帳戶資料予美國聯邦政府或中華民國政府者，本公司依法須將台端於本公司之帳戶列為不合作帳戶(Recalcitrant
          Account)，針對符合法定條件的款項(包含但不限於美國來源所得)予以扣繳百分之三十的美國扣繳稅款予美國聯邦政府；如經合理期間台端仍不完整提供或同意者，本公司依法可能須關閉台端的帳戶。</li>
      </ol>
    </div>
  </div>

  <div style="display:flex;margin:0 auto;width:180px;">
    <div class="clear" style="margin:0 40px 0 0;">
      <input id="01" type="radio" name="yes" class="form" ng-control="yes" [value]='true'
        [(ngModel)]="ReadInfostep1"><label for="01" class="form">同意</label>
    </div>
    <div class="clear" style="margin:0;padding:0;">
      <input id="02" type="radio" name="yes" class="form" ng-control="yes" [value]='false'
        [(ngModel)]="ReadInfostep1"><label for="02" class="form">不同意</label>
    </div>
  </div>
  <div class="button-box">
    <a href="login" class="cancel-button">放棄</a>
    <a href="javascript:void(0)" (click)="save1(2)" class="Main-button">下一步<i class="fas fa-chevron-right"></i></a>
  </div>

</div>


<div class="Block-content" *ngIf="pagecontact==0">
  <div class="p-contact-form__step-100">
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-current">1</div>
      <div class="p-contact-form__step-label">開戶規範及個資同意書</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">2</div>
      <div class="p-contact-form__step-label">輸入基本資料</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">3</div>
      <div class="p-contact-form__step-label">填寫風險承受度及投資屬性</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">4</div>
      <div class="p-contact-form__step-label">資料確認</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">5</div>
      <div class="p-contact-form__step-label">開戶文件下載</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">6</div>
      <div class="p-contact-form__step-label">申請完成</div>
    </div>
  </div>

  <div class="Sign-box-line">
    <div class="Sign-box">
      <div class="Sign-list">
        <div class="Sign-title">帳號</div>
        <div class="Sign-in">
          <input class="input-25" [maxLength]=10 [(ngModel)]="this.ChkMemStatusArgs.ID_NO" placeholder="身份證字號"
            (blur)="IDChk(ChkMemStatusArgs.ID_NO)">
          <p class="text-red">* 法人請洽台中銀投信業務員 02 02351-1707</p>
        </div>
      </div>
    </div>
  </div>

  <div class="Description">
    <h4>交易規範:約定及聲明事項：</h4>
    <div class="book-box">
      <table>
        <tr>
          <td class="td">一、</td>
          <td class="td">
            本人(以下稱甲方)同意簽署及依照台中銀投信「多元理財帳戶開戶申請書」所約定之內容及條款，向台中銀證券投資信託股份有限公司（以下稱乙方）辦理基金申購、買回、轉申購事宜，並遵守下列契約條款：
          </td>
        </tr>
        <tr>
          <td class="td">二、</td>
          <td class="td">
            甲方為櫃台、電子交易及傳真交易或其他由乙方開發且經相關法令核可之新種業務交易方式時，同意依照相關法令、交易標的之信託契約、最新的公開/投資說明書、「中華民國證券投資信託暨顧問商業同業公會國內證券投資信託基金電子交易作業準則」、本約定書及乙方所公布之最新作業流程等規定辦理。
          </td>
        </tr>
        <tr>
          <td class="td">三、</td>
          <td class="td">
            甲方須提供現行洗錢防制法要求之證明，以證明甲方的身分。若乙方或其指定通路未能收到滿意之證明，則可要求提供進一步資料。
          </td>
        </tr>
        <tr>
          <td class="td">四、</td>
          <td class="td">
            本約定書所稱「營業日」依交易標的之信託契約定義之，交易遇到非營業日，遞延至次一營業日辦理。
          </td>
        </tr>
        <tr>
          <td class="td">五、</td>
          <td class="td">
            開戶手續：
            <ol>
              <li>甲方憑身分證明文件及簽章辦理開戶，並簽署台中銀投信「多元理財帳戶開戶申請書」，本開戶手續以開戶約定書正本送達乙方後生效。</li>
              <li>甲方若欲採取銀行指定帳戶扣款轉帳，應填寫銀行代扣款授權書，待該授權書經其指定銀行確認簽章無誤後，寄達乙方並經輸入系統後，始能開始交易。本項限申請電子交易／定時定額投資使用。</li>
              <li>甲方於辦理開戶時，須事先以書面指定以其本人名義開立之銀行帳戶以作為進行電子交易委託之往來銀行帳戶，將來請求買回時亦僅得就所指定之帳戶中作選擇或以甲方本人為受款人之記名劃線禁止背書轉讓票據支付買回價金。
              </li>
              <li>如甲方指定之交易帳戶有變更時，甲方應事前以書面加蓋原留簽章後通知乙方，乙方於接獲交易帳戶變更申請之通知前，就已執行交易之扣款或付款仍以原指定帳戶為準。</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td class="td">六、</td>
          <td class="td">
            基金之申購／買回／轉申購交易：
            <ol>
              <li>
                有關「多元理財帳戶」之流程及應注意事項係依據中華民國證券投資信託暨顧問商業同業公會制定之「中華民國證券投資信託暨顧問商業同業公會證券投資信託基金募集、發行、銷售及申購買回作業流程」、「國內證券投資信託基金電子交易作業準則」制定，有關細節請參考本公司網站提供之網路交易操作使用說明，交易應依各基金之證券投資信託契約、公開/投資說明書規定及台中銀投信最新作業流程辦理。
              </li>
              <li>甲方應以申購文件齊備及申購價款經銷售機構收訖無誤或匯達基金專戶日為申購日，並依申購手續完成日之基金淨值計算申購之受益權單位數，甲方如未依約定繳足申購金額及相關費用，則乙方有權認定該筆申購交易不成立。
              </li>
              <li>甲方應齊備相關買回文件並詳細填妥申請書，到達乙方或代理買回機構之營業日為買回申請日。甲方若持有受益憑證，應先將憑證送達乙方，方可辦理買回。</li>
              <li>買回價金限匯入以甲方名義開立之同名匯款帳戶，除透過電子交易買回或傳真交易外，非經書面申請並加蓋原留簽章之買回申請書正本送達乙方核對無誤，不得匯入其他帳戶。</li>
              <li>轉申購係以買回價金匯入轉申購基金專戶日為轉申購基金生效日。</li>
              <li>甲方若為領取受益憑證之受益人，必須先將受益憑證送達乙方方能透過電子交易或傳真申請買回。</li>
              <li>基金之申購/買回/轉申購交易截止時間請詳閱各基金之公開/投資說明書，如逾交易時間或適逢例假日，則視為次ㄧ營業日之交易指示。</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td class="td">七、</td>
          <td class="td">
            傳真交易：
            <ol>
              <li>
                若申請以傳真方式進行交易，甲方茲授權乙方得接受、信賴甲方或甲方指定之被授權人之指示並依該指示執行基金之申購/買回/轉申購交易。甲方瞭解乙方無義務對發出指示之人予以查證。（但乙方仍有權依其判斷對任一指示為進一步之查證）
              </li>
              <li>
                甲方以傳真方式向乙方辦理所發行基金之申購/買回/轉申購等相關交易時，應先填妥相關申請書，並將其傳真至乙方，同時必須以電話確認該交易。若因未確認導致任一申請書漏失，以致無法順利作業者，乙方得依該傳真文件逕行辦理相關交易或拒絕此項交易，無需對甲方因不實或不清之傳真指示所遭致之損害負任何賠償責任，甲方並同意放棄先訴抗辯權。
              </li>
              <li>甲方瞭解並同意自行承擔因錯誤及由非被授權人員所為交易指示之任何風險，乙方並無需負責因此所生之任何損失。</li>
              <li>
                甲方瞭解並同意若未依照本約定書之約定，交付相關交易所需之文件或未依乙方正常作業或往來交易所需，交付相關文件者，乙方得隨時（且無需事先通知）拒絕為甲方進行任何交易，甲方絕無任何異議，並將不對乙方主張任何權利或請求。
              </li>
              <li>傳真之文件因電話線路、機器故障或其他任何因素所顯示之文件內容或印鑑不清楚或無法辨識時，茲同意於甲方未另行傳真清楚足以辨認其內容及印鑑之文件予乙方收受前，乙方得拒絕接受甲方以傳真方式之交易。</li>
              <li>
                為確保買回價金匯入甲方帳戶，當甲方以傳真方式申請買回受益權單位時，乙方將買回價金匯入本申請書之甲方約定帳戶，如甲方傳真申請買回之匯入帳戶非本申請書之約定帳戶者，乙方得拒絕以傳真交易方式申請買回之指示。
              </li>
            </ol>
          </td>
        </tr>
        <tr>
          <td class="td">八、</td>
          <td class="td">電子交易使用方式、限制與規範：
            <ol>
              <li>
                「電子交易服務」指乙方依本約定書所定之方式，經乙方電子交易系統所提供甲方交易委託、相關資訊及相關應用程式之服務。「電子交易委託」指透過電子交易型態進行申購（包含單筆及定期定額交易）、轉申購、買回乙方基金或相關交易之任何委託。「密碼」:指為執行交易及查詢個人交易之相關資訊，使用於乙方交易服務系統之使用者識別方式。「電子交易流程」指乙方隨時公告或修正之電子交易相關作業流程。「交易帳戶」指甲方依本約定書第五條第二項所開立並維持之銀行帳戶。「營業日」：指乙方配合主管機關所訂定之交易有效工作日。
              </li>
              <li>甲方申請電子交易服務，於取得使用權限後，須本人親自使用專屬密碼，通過身分驗證後始得進行交易指示。</li>
              <li>密碼經甲方設定並確認後，連同甲方之帳戶號碼，係甲方專屬使用；每次交易皆須鍵入帳戶號碼與密碼並經確認無誤始予受理。</li>
              <li>基金交易於交易時間截止前，甲方得依系統指示進行修改或取消交易。</li>
              <li>甲方瞭解基金交易經乙方人員自網路系統下載交易資料後，雖可經由查詢交易進度之功能表，查詢交易處理狀況，但無法再做任何修改或取消。</li>
              <li>
                甲方同意所有使用其密碼經由乙方網站交易服務系統傳輸之電子交易交易委託，均為甲方之有效指示，甲方不得以任何理由否認交易之有效性，乙方並得據以執行。甲方亦確實瞭解網路交易委託之法律效力與於櫃台或透過郵寄所為之效力相同。
              </li>
              <li>甲方同意於使用網路交易前，應先詳閱並遵守乙方最新網站之電子交易流程相關流程、通知、公告及系統狀況，並於交易執行完成後，應主動於乙方網站查詢交易結果及交易帳戶內之有效受益權單位數。</li>
              <li>
                除法令變更外，甲方每日電子交易之申購或買回金額均各以相當於新台幣三千萬元或等值外幣為上限，其中買回限額之計算，係以甲方為電子交易委託前最近公告之境外基金淨值及匯率為準。如甲方違反前述金額限制，乙方將不予受理。
              </li>
              <li>甲方保證未經授權不得竄改、修改或以任何方式變更乙方電子交易系統之任何部分，或進入乙方電子交易系統未經許可之部分。</li>
              <li>乙方應盡力維護電子交易系統傳輸訊息之安全，防止他人非法進入系統、竊取、竄改或毀損交易紀錄及資料。</li>
              <li>
                甲方瞭解及同意其本人為帳戶號碼與交易密碼之唯一授權使用者，甲方應妥善保管及使用，並對於使用交易密碼經由乙方電子交易系統所作之一切交易及資料變更等行為負責，但乙方有故意或重大過失致甲方之密碼外洩為第三人所冒用者，不在此限。
              </li>
              <li>乙方對於其處理甲方從事電子式交易之相關設備軟硬體，應盡善良管理人之注意義務。</li>
              <li>
                甲方同意電子式交易型態在資料傳輸上有不穩定之風險，甲方同意如電子交易系統傳輸，因通訊斷線、斷電、網路壅塞或發生天然災害等不可抗力事由，致電子交易之時間遲延、執行時之價格與指示當時之價格不同或乙方無法接收或傳送時，如該事由非因乙方之故意或重大過失所致而影響交易之結果，甲方須自負因此所生之風險。甲方如於乙方執行電子交易前欲更改原交易之內容，惟因通訊斷線、斷電、網路壅塞或發生天然災害等不可抗力事由，致乙方無法接收、傳送或即時更改者，乙方無須負責；且原電子交易之內容，對甲方仍發生效力。
              </li>
              <li>非可歸責於乙方之事由，包括但不限於因法令或主管機關之限制、交易市場規則、停止交易、戰爭、天災等，所致執行遲延或無法執行而造成甲方之損害，乙方及其代理人、代表人、受僱人無須負責。</li>
              <li>甲方同意於使用電子交易系統時，如有任何連線上之問題，應主動嘗試以其他方式與乙方聯繫並將所面臨之問題立即通知乙方。</li>
              <li>甲方同意於使用乙方電子交易服務系統時，如有下述情形，應立即通知乙方，並配合辦理相關措施：<br>
                　　(１) 經二十四小時未收到該電子交易委託回報或已執行之確認通知；<br>
                　　(２) 甲方已收到該電子交易委託回報或已執行之確認通知，但非甲方所作之指示或彼此歧異；<br>
                　　(３) 甲方得知其使用者密碼被他人盜用等情形；<br>
                　　(４) 其他有關電子交易委託所生之問題。
              </li>
              <li>如因任何甲方電子交易之交易指示將導致乙方有違反相關法令、遭受訴訟或損害之虞時，乙方得拒絕接受甲方之指示，其因此造成甲方之任何損失，乙方不需負責；惟乙方需在可能之時限內，通知甲方拒絕受理指示之事實。
              </li>
              <li>甲方應妥善保管及使用個人帳號及交易密碼，並應對於所有使用個人帳號及密碼經由台中銀投信之網路服務完成之網路委託，負完全責任。乙方對於甲方或第三人因網路委託所受之一切損失，不負任何責任。</li>
              <li>甲方同意為保障雙方權益，乙方得有權(但並非必須)自動監測或紀錄甲方與乙方間網路聯繫之內容，並得記錄甲方所有電子交易委託之內容。</li>
              <li>甲方應自行決定所有電子交易委託之內容，乙方或其受任何人所提供之資訊或建議，僅供參考，不論該建議是否基於甲方之要求而提供，乙方均不負任何責任。</li>
              <li>甲方瞭解乙方得調整或變更最新之電子交易交易流程，對於甲方使用乙方電子交易網路及傳真交易服務之相關事宜，具有法律拘束力。</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td class="td">九、</td>
          <td class="td">
            資訊提供：乙方所提供之任何市場資訊、分析報告及交易資訊，僅供甲方參考之用，甲方任何投資決策概由其自行決定。甲方完全瞭解並同意不得以乙方所提供之資訊請求任何賠償，乙方及其受僱人對於甲方所為之任何交易，並不負任何責任。
          </td>
        </tr>
        <tr>
          <td class="td">十、</td>
          <td class="td">
            約定事項：
            <ol>
              <li>本約定書任何條文如經法院認定為無效，僅止於該條文無效或無執行力，其他條文效力不受影響，並於履行本約定書時，該無效或無約束力條文視為不存在。</li>
              <li>甲方不得將本約定書之權利義務轉讓於任何人。</li>
              <li>
                甲方同意乙方得依電腦處理個人資料保護法及相關法令規定，依其營業及為甲方提供服務之目的之範圍內對甲方之相關個人資料為蒐集、利用、國際傳遞及電腦處理。並同意乙方得基於風險控管、稽核、客戶服務、管理、資訊提供、或為甲方之利益或依法令規定、或因主管機關、法院之要求將之提供與受乙方委託處理基金相關事務之人。
              </li>
              <li>
                所有通知事項（包括但不限於交易確認），經由郵寄、專人送達、傳真、電子郵件及簡訊等方式送達乙方受益人名簿記載之甲方或其他甲方指定之地址(包括但不限於電子郵件地址)，乙方原則上應於通知文件上簽署，但經由乙方網站寄送之通知（包括但不限於網路委託回報或已執行之確認通知）將視為已經乙方簽署。乙方所寄送之通知資料若有出入，甲方同意應以乙方之正確帳載為準。
              </li>
              <li>前項通知之送達日如下：經由郵寄方式者，以郵寄日之次一營業日為送達日；經由專人送達、電報、電話、傳真或電子郵件方式者，以通知日為送達日。</li>
              <li>於本約定書第十條所列各項資料有所變更時，甲方應立即通知乙方並依本約定書第十條之規定向乙方申請變更，就甲方疏於通知及申請變更所致之任何損失，乙方不負賠償責任。</li>
              <li>
                本約定書取代甲方與乙方間於本約定書簽署前就電子交易及傳真交易服務所作出之任何口頭或書面之一切溝通意見、陳述及訂立之任何合約。如本約定書與雙方就基金申購、買回、轉申購事宜所為其他約定不一致或有所抵觸時，甲乙雙方同意以本約定書優先適用。
              </li>
              <li>
                甲方留存簽章或授權之有權簽章倘有偽造或變造情事，經乙方盡善良管理人注意義務，仍無法以肉眼辨認而發生之任何損失，乙方毋須負任何賠償責任。且甲方暸解並同意若未依照相關規定交付交易所需之文件或未依乙方正常作業或往來交易所需，交付相關文件者，乙方得拒絕甲方進行任何交易，甲方絕無任何異議，並將不對乙方主張任何權利或請求。
              </li>
              <li>
                本約定書如有未盡事宜悉依證券投資信託及顧問法、證券投資信託基金管理辦法、各基金證券投資信託契約、受益憑證事務處理準則、中華民國證券投資信託暨顧問商業同業公會國內證券投資信託基金電子交易作業準則及中華民國相關法令、函釋之規定辦理，前述法令及契約有修訂者，依修訂後之規定辦理，就修訂部分本約定書視為亦已修訂，不須重新簽署。
              </li>
              <li>任一方得隨時以書面通知他方終止本約定書，該終止通知，對於接獲該通知前已執行之電子交易委託及雙方之權利義務，均不受影響。</li>
            </ol>
          </td>
        </tr>
        <tr>
          <td class="td">十一、</td>
          <td class="td">
            準據法與管轄法院：本約定書以中華民國法律為準據法，並以台灣台北地方法院為第一審管轄法院。
          </td>
        </tr>
        <tr>
          <td class="td">十二、</td>
          <td class="td">防制洗錢及打擊資恐聲明：
            <ol>
              <li>甲方聲明已瞭解且必確實遵守本國之洗錢防制法之相關規定。</li>
              <li>甲方確認及保證所從事的業務 職務及其他活動不容易涉及不法情事。</li>
              <li>甲方保證所交付給台中銀投信的金錢或其他金融工具(例如票據)均不涉及可被懷疑之犯罪所得，或台中銀投信所提供的產品/服務並未利用作為犯罪用途的嫌疑。</li>
              <li>甲方確認並未有正面臨清算 破產之情形。</li>
            </ol>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div style="display:flex;margin:0 auto;width:180px;">
    <div class="clear" style="margin:0 40px 0 0;">
      <input id="03" type="radio" name="yes1" class="form" ng-control="yes1" [value]='true'
        [(ngModel)]="ReadInfostep2"><label for="03" class="form">同意</label>
    </div>
    <div class="clear" style="margin:0;padding:0;">
      <input id="04" type="radio" name="yes1" class="form" ng-control="yes1" [value]='false'
        [(ngModel)]="ReadInfostep2"><label for="04" class="form">不同意</label>
    </div>
  </div>


  <div class="button-box">
    <a href="login" class="cancel-button">放棄</a>
    <a href="javascript:void(0)" (click)="save1(1)" class="Main-button">下一步<i class="fas fa-chevron-right"></i></a>
  </div>
</div>


<div class="Block-content" *ngIf="pagecontact==2">
  <div class="p-contact-form__step-100">
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">1</div>
      <div class="p-contact-form__step-label">開戶規範及個資同意書</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-current">2</div>
      <div class="p-contact-form__step-label">輸入基本資料</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">3</div>
      <div class="p-contact-form__step-label">填寫風險承受度及投資屬性</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">4</div>
      <div class="p-contact-form__step-label">資料確認</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">5</div>
      <div class="p-contact-form__step-label">開戶文件下載</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">6</div>
      <div class="p-contact-form__step-label">申請完成</div>
    </div>
  </div>

  <div class="title-02">
    <div style="display: inline-block;width: 50%;">基本資料</div>
    <div style="display: inline-block;width: 50%;text-align: right;">申請日期：{{this.OpenMemberArgs.APPLY_DTTM|
      date:'yyyy/MM/dd'}}</div>
  </div>
  <div class="basic-data-table">
    <div class="w-50">
      <div class="basic-left">姓名<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <input [maxLength]=30 [(ngModel)]="this.OpenMemberArgs.BF_NAME" class=" w-80">
        <div *ngIf="disMsg.BF_NAME1" class="text-red" style="margin-top: 8px;">* 請輸入姓名</div>
        <div *ngIf="disMsg.BF_NAME2" class="text-red" style="margin-top: 8px;">* 姓名格式不正確</div>
      </div>
      <div class="basic-left">
        <div>英文姓名</div>
        <div>(請與匯款英文名稱相符)</div>
      </div>
      <div class="basic-right">
        <input [maxLength]=30 [(ngModel)]="this.OpenMemberArgs.BF_NAME_E" class=" w-80">
        <div *ngIf="disMsg.BF_NAME_E1" class="text-red" style="margin-top: 8px;">* 請輸入英文姓名</div>
        <div *ngIf="disMsg.BF_NAME_E2" class="text-red" style="margin-top: 8px;">* 英文姓名格式不正確</div>
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">生日(西元)<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <div class="Enter-box w-80">
          <input class="input-20" [maxLength]=4 [(ngModel)]="this.OpenMemberArgs.BIR_DATE1">
          <p>年</p>
          <input class="input-20" [maxLength]=2 [(ngModel)]="this.OpenMemberArgs.BIR_DATE2">
          <p>月</p>
          <input class="input-20" [maxLength]=2 [(ngModel)]="this.OpenMemberArgs.BIR_DATE3">
          <p>日</p>
        </div>
        <div *ngIf="disMsg.BIR_DATE" class="text-red" style="margin-top: 8px;">* 日期格式不正確</div>
      </div>
      <div class="basic-left">身份證字號<samp class="text-red">*</samp></div>
      <div class="basic-right">{{this.OpenMemberArgs.ID_NO}}</div>
    </div>
    <div class="w-50">
      <div class="basic-left">連絡電話<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <div class="Enter-box w-80">
          <input [maxLength]=4 [(ngModel)]="this.OpenMemberArgs.HM_TEL_AREA" class="input-20">
          <input [maxLength]=9 [(ngModel)]="this.OpenMemberArgs.HM_TEL" class="input-70">
        </div>
        <div *ngIf="disMsg.HM_TEL" class="text-red" style="margin-top: 8px;">* 請輸入連絡電話</div>
        <div *ngIf="disMsg.HM_TEL1" class="text-red" style="margin-top: 8px;">* 連絡電話格式不正確</div>
      </div>
      <div class="basic-left">公司電話</div>
      <div class="basic-right">
        <div class="Enter-box">
          <div class="w-80 Enter-box">
            <input [maxLength]=4 [(ngModel)]="this.OpenMemberArgs.OF_TEL_AREA" class="input-20">
            <input [maxLength]=10 [(ngModel)]="this.OpenMemberArgs.OF_TEL" class="input-60">
          </div>
          <p>#</p><input [maxLength]=10 [(ngModel)]="this.OpenMemberArgs.OF_TEL_EXT" class="input-15">
        </div>
        <div *ngIf="disMsg.OF_TEL" class="text-red" style="margin-top: 8px;">* 公司電話格式不正確</div>
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">傳真電話</div>
      <div class="basic-right">
        <div class="Enter-box w-80">
          <input [maxLength]=4 [(ngModel)]="this.OpenMemberArgs.FAX_TEL_AREA1" class="input-20">
          <input [maxLength]=10 [(ngModel)]="this.OpenMemberArgs.FAX_TEL1" class="input-60">
        </div>
        <div *ngIf="disMsg.FAX_TEL" class="text-red" style="margin-top: 8px;">* 傳真電話格式不正確</div>
      </div>
      <div class="basic-left">行動電話</div>
      <div class="basic-right">
        <input [maxLength]=10 [(ngModel)]="this.OpenMemberArgs.CELL_PHONE" class=" w-80">
        <!-- <div *ngIf="disMsg.CELL_PHONE" class="text-red" style="margin-top: 8px;">* 請輸入手機電話</div> -->
        <div *ngIf="disMsg.CELL_PHONE1" class="text-red" style="margin-top: 8px;">* 手機電話格式不正確</div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">通訊地址<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <div class="post-box">
          <p>郵遞區號</p>
          <input [minlength]="3" [maxlength]="5" [(ngModel)]="this.OpenMemberArgs.MAIL_ZIP" class="input-15">
          <div *ngIf="disMsg.MAIL_ZIP" class="text-red input-50" style="margin-top: 8px;margin-left: 8px;">* 請輸入通訊郵遞區號
          </div>
          <div *ngIf="disMsg.MAIL_ZIP1" class="text-red input-50" style="margin-top: 8px;margin-left: 8px;">*
            通訊郵遞區號格式錯誤，請使用純數字輸入標準3+2 / 3+3郵遞區號。</div>
        </div>
        <div class="address-box">
          <input [(ngModel)]="this.OpenMemberArgs.MAIL_ADDR" class="input-100">
        </div>
        <div *ngIf="disMsg.MAIL_ADDR" class="text-red input-50" style="margin-top: 8px;">* 請輸入通訊地址</div>
        <div *ngIf="disMsg.MAIL_ADDR1" class="text-red input-50" style="margin-top: 8px;">* 通訊地址格式錯誤，缺少縣或市。</div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">戶籍地址<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <div class="post-box">
          <div class="checkbox-wrap">
            <input type="checkbox" [(ngModel)]="this.OpenMemberArgs.ADDR_CODE1" (change)="autoPERM_ADDR()" value="true"
              name="investment" id="ADDR_CODE1">
            <label for="ADDR_CODE1"><samp>同通訊地</samp></label>
          </div>
          <p>郵遞區號</p>
          <input [minlength]="3" [maxlength]="5" [(ngModel)]="this.OpenMemberArgs.PERM_ZIP" class="input-15">
          <div *ngIf="disMsg.PERM_ZIP" class="text-red input-50" style="margin-top: 8px;margin-left: 8px;">* 請輸入戶籍郵遞區號
          </div>
          <div *ngIf="disMsg.PERM_ZIP1" class="text-red input-50" style="margin-top: 8px;margin-left: 8px;">*
            戶籍郵遞區號格式錯誤，請使用純數字輸入標準3+2 / 3+3郵遞區號。</div>
        </div>
        <div class="address-box">
          <input [maxLength]=100 [(ngModel)]="this.OpenMemberArgs.PERM_ADDR" class="input-100">
        </div>
        <div *ngIf="disMsg.PERM_ADDR" class="text-red input-50" style="margin-top: 8px;">* 請輸入戶籍地址</div>
        <div *ngIf="disMsg.PERM_ADDR1" class="text-red input-50" style="margin-top: 8px;">* 戶籍地址格式錯誤，缺少縣或市。</div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">電子信箱<samp class="text-red">*</samp></div>
      <div class="basic-right">
        <div class="address-box">
          <input [maxLength]=30 [(ngModel)]="this.OpenMemberArgs.EMAIL1">
        </div>
        <div *ngIf="disMsg.EMAIL" class="text-red" style="margin-top: 8px;">* 請輸入電子信箱</div>
        <div *ngIf="disMsg.EMAIL1" class="text-red" style="margin-top: 8px;">* 電子信箱格式不正確</div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">國籍</div>
      <div class="basic-right">
        <div class="SelectStyle2 input-30">
          <select [(ngModel)]="this.OpenMemberArgs.BF_NATIONALITY">
            <option value="">-請選擇國籍-</option>
            <option *ngFor="let Country of this.GetCountryCDResponse.CountryList" [value]='Country.COUNTRY_CD'>
              {{Country.COUNTRY_NAME}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">居住地</div>
      <div class="basic-right">
        <div class="SelectStyle2 input-30">
          <select [(ngModel)]="this.OpenMemberArgs.NATIONALITY_BIRTH">
            <option value="">-請選擇居住地-</option>
            <option *ngFor="let Country of this.GetCountryCDResponse.CountryList" [value]='Country.COUNTRY_CD'>
              {{Country.COUNTRY_NAME}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">身份類別</div>
      <div class="basic-right">
        <div class="address-box">
          <div class="basic-right" style="display:flex;padding:10px 0;">
            <div class="clear">
              <input id="05" type="radio" ng-control="Identity" name="Identity" class="form" value="N"
                [(ngModel)]="this.OpenMemberArgs.IS_USA"><label for="05" class="form">非美國稅務居民</label>
            </div>
            <div class="clear">
              <input id="06" type="radio" ng-control="Identity" name="Identity" class="form" value="Y"
                [(ngModel)]="this.OpenMemberArgs.IS_USA"><label for="06" class="form">美國稅務居民</label>
            </div>
            <div *ngIf="disMsg.IS_USA" class="text-red clear">* 請選擇是否為美國稅務居民</div>
          </div>
        </div>
        <div class="Identity">
          <h4>美國稅務居民</h4>
          <ul>
            <li><span>(一)</span>
              <p>本人為美國公民、且具美國永久居留權（包含但不限於綠卡持有人）。</p>
            </li>
            <li><span>(二)</span>
              <p>
                本人符合下述可遭認定為美國稅務居民的情形，即未持有A、F、G、J、M、Q等型簽證，仍於當年度停留於美國(含本土、哥倫比亞特區、關島、美屬波多黎各)天數累計達183天，或當年度未達183天但超過31天以上，而當年度停留美國天數全數、前一年度停留美國天數之三分之一，以及前二年度停留美國天數之六分之一合計達183天以上。
              </p>
            </li>
          </ul>
          <h4>非美國稅務居民</h4>
          <ul>
            <li><span>(一)</span>
              <p>本人非屬美國公民、不具美國永久居留權（包含但不限於非綠卡持有人）。</p>
            </li>
            <li><span>(二)</span>
              <p>
                本人不符合下述可遭認定為美國稅務居民的情形：即未持有A、F、G、J、M、Q等型簽證，仍於當年度停留於美國(含本土、哥倫比亞特區、關島、美屬波多黎各)天數累計達183天，或當年度未達183天但超過31天以上，而當年度停留美國天數全數、前一年度停留美國天數之三分之一，以及前二年度停留美國天數之六分之一合計達183天以上。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">連絡人姓名
        <!--<samp class="text-red">*</samp>-->
      </div>
      <div class="basic-right">
        <input [(ngModel)]="this.OpenMemberArgs.CNT_PERSON" class="input-30">
        <!-- <div *ngIf="disMsg.CNT_PERSON" class="text-red" style="margin-top: 8px;">* 請輸入連絡人</div> -->
        <div *ngIf="disMsg.CNT_PERSON1" class="text-red" style="margin-top: 8px;">* 連絡人格式不正確</div>
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">連絡人公司電話</div>
      <div class="basic-right">
        <div class="Enter-box">
          <div class="w-80 Enter-box">
            <input [maxLength]=4 [(ngModel)]="this.OpenMemberArgs.CNT_OF_TEL_AREA" class="input-20">
            <input [maxLength]=10 [(ngModel)]="this.OpenMemberArgs.CNT_OF_TEL" class="input-60">
          </div>
          <p>#</p><input [maxLength]=10 [(ngModel)]="this.OpenMemberArgs.CNT_OF_TEL_EXT" class="input-15">
        </div>
        <!-- <div *ngIf="disMsg.CNT_OF_TEL" class="text-red" style="margin-top: 8px;">* 請輸入連絡人公司電話</div> -->
        <div *ngIf="disMsg.CNT_OF_TEL1" class="text-red" style="margin-top: 8px;">* 連絡人公司電話格式不正確</div>
      </div>

      <div class="basic-left">連絡人手機電話</div>
      <div class="basic-right">
        <input maxLength=10 [(ngModel)]="this.OpenMemberArgs.CNT_CELL_PHONE" class=" w-80">
        <!-- <div *ngIf="disMsg.CNT_CELL_PHONE" class="text-red" style="margin-top: 8px;">* 請輸入連絡人手機電話</div> -->
        <div *ngIf="disMsg.CNT_CELL_PHONE1" class="text-red" style="margin-top: 8px;">* 連絡人手機電話格式不正確</div>
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">法定代理人姓名(一)</div>
      <div class="basic-right">
        <input [(ngModel)]="this.OpenMemberArgs.AGENT_IN_LAW1" class="w-80">
        <div *ngIf="disMsg.AGENT_IN_LAW" class="text-red" style="margin-top: 8px;">* 請輸入法定代理人姓名(一)</div>
        <div *ngIf="disMsg.AGENT_IN_LAW1" class="text-red" style="margin-top: 8px;">* 法定代理人姓名(一) 格式不正確</div>
      </div>
      <div class="basic-left">法定代理人(一)身份證字號</div>
      <div class="basic-right">
        <input [(ngModel)]="this.OpenMemberArgs.AGENT_IN_LAW_ID" class="w-80">
        <div *ngIf="disMsg.AGENT_IN_LAW_ID" class="text-red" style="margin-top: 8px;">* 請輸入法定代理人姓名(一)身分證</div>
        <div *ngIf="disMsg.AGENT_IN_LAW_ID1" class="text-red" style="margin-top: 8px;">* 法定代理人(一) 身分證格式不正確</div>
        <div *ngIf="disMsg.AGENT_IN_LAW_ID1_1" class="text-red" style="margin-top: 8px;">* 法定代理人身分證字號(一) 不可與開戶身分證字號相同
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">國籍</div>
      <div class="basic-right">
        <div class="SelectStyle2 input-30">
          <select [(ngModel)]="this.OpenMemberArgs.AGENT_IN_LAW_NALITY1">
            <option value="">-請選擇國籍-</option>
            <option *ngFor="let Country of this.GetCountryCDResponse.CountryList" [value]='Country.COUNTRY_CD'>
              {{Country.COUNTRY_NAME}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">法定代理人姓名(二)</div>
      <div class="basic-right">
        <input [(ngModel)]="this.OpenMemberArgs.AGENT_IN_LAW2" class="w-80">
        <div *ngIf="disMsg.AGENT_IN_LAW2" class="text-red" style="margin-top: 8px;">* 法定代理人姓名(二) 格式不正確</div>
      </div>
      <div class="basic-left">法定代理人(二)身份證字號</div>
      <div class="basic-right">
        <input [(ngModel)]="this.OpenMemberArgs.AGENT_IN_LAW_ID2" class="w-80">
        <div *ngIf="disMsg.AGENT_IN_LAW_ID2" class="text-red" style="margin-top: 8px;">* 法定代理人(二) 身分證格式不正確</div>
        <div *ngIf="disMsg.AGENT_IN_LAW_ID2_1" class="text-red" style="margin-top: 8px;">* 法定代理人身分證字號(二) 不可與開戶身分證字號相同
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">國籍</div>
      <div class="basic-right">
        <div class="SelectStyle2 input-30">
          <select [(ngModel)]="this.OpenMemberArgs.AGENT_IN_LAW_NALITY2">
            <option value="">-請選擇國籍-</option>
            <option *ngFor="let Country of this.GetCountryCDResponse.CountryList" [value]='Country.COUNTRY_CD'>
              {{Country.COUNTRY_NAME}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <!-- <div class="w-100">
      <div class="basic-left">是否接收行銷訊息</div>
      <div class="basic-right" style="display:flex;padding-top:22px;">
        <div class="clear">
          <input id="07" [(ngModel)]="this.OpenMemberArgs.MCD_YN" ng-control="MCD_YN" type="radio" value="Y"
            name="MCD_YN" class="form"><label for="07" class="form">是</label>
        </div>
        <div class="clear">
          <input id="08" [(ngModel)]="this.OpenMemberArgs.MCD_YN" ng-control="MCD_YN" type="radio" value="N"
            name="MCD_YN" class="form"><label for="08" class="form">否</label>
        </div>
        <div *ngIf="disMsg.MCD_YN" class="text-red">* 請選擇是否接收行銷訊息</div>
      </div>
    </div> -->
  </div>

  <div class="note-box">
    <h4 class="text-red">注意事項(需台中銀投信確認)</h4>
    <ol class="note-list">
      <li>依民法規定，未成年子女之權利義務由父母共同行使或負擔之。若為未成年子女辦理開 戶者，煩請確實填寫父母 雙方資料內容並檢附相關證明文件，以確認父母共同行使對於 未成年子女之權利義務，否則將無法完成網路預約
        開戶。若您因個別原因無法填具並提 供兩方資料，則必須另以書面辦理開戶事宜。書面辦理時請出具同意書，以 授權一方並 僅留存一方印鑑辦理相關事宜，或另提供其他相關證明文件。</li>
      <li>如果您不方便自行列印開戶資料，台中銀投信會協助您列印出完整的開戶文件並以 『限時專送』郵遞至您的通訊 地址。</li>
    </ol>
  </div>

  <div class="title-02">台幣扣款帳號</div>

  <div class="basic-data-table">
    <div class="w-100">
      <div class="basic-left"><samp class="text-red">*</samp>扣款帳號</div>
      <div class="basic-right">
        <div class="address-box">
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList.BANK_HQ" (change)="selectBankData($event.target,1)">
              <option value="-1">-請選擇銀行-</option>
              <option *ngFor="let BANK_HQ of this.GetBankDataResponse.BankList" [value]='BANK_HQ.BANK_HQ'>
                {{BANK_HQ.BANK_HQ_NAME}}
              </option>
            </select>
          </div>
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList.BANK_BRH">
              <option value="">-請選擇分行-</option>
              <option *ngFor="let BANK_HQ of this.GetSubBankDataResponse.SubBankDetails" [value]='BANK_HQ.BANK_BRH'>
                {{BANK_HQ.BANK_BRH_NAME}}
              </option>
            </select>
          </div>
          <input [(ngModel)]="this.CusAccountList.ACCOUNT_NO" class="input-40 mobile-mt10" placeholder="帳號">
        </div>
        <p style="margin-top:10px;">授權扣款帳戶需為申購人(受益人)本人帳戶，且須該扣款行核印完成後，才可進行交易。</p>
        <div *ngIf="disMsg.CusAccountList" class="text-red" style="margin-top: 10px;">* 請選擇輸入扣款帳號</div>
        <div *ngIf="disMsg.CusAccountList_1" class="text-red" style="margin-top: 10px;">* 格式不正確</div>
        <div *ngIf="disMsg.CusAccountList_2" class="text-red" style="margin-top: 10px;">* 帳號長度不符 需填
          {{this.BankMsg[0].len}} {{this.BankMsg[0].len1}} {{this.BankMsg[0].len2}} 碼</div>
      </div>
    </div>
  </div>

  <div class="title-02">台幣約定買回帳戶 僅限申購人之本人帳號，至少需填寫一家銀行</div>

  <div class="basic-data-table">
    <div class="w-100">
      <!-- 買回價款匯入銀行 -->
      <div class="basic-left"><samp class="text-red">*</samp>買回價款匯入銀行(一)</div>
      <div class="basic-right">
        <div class="address-box">
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList2.BANK_HQ" (change)="selectBankData($event.target,2)">
              <option value="-1">-請選擇銀行-</option>
              <option *ngFor="let BANK_HQ of this.GetBankDataResponse2.BankList" [value]='BANK_HQ.BANK_HQ'>
                {{BANK_HQ.BANK_HQ_NAME}}
              </option>
            </select>
          </div>
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList2.BANK_BRH">
              <option value="">-請選擇分行-</option>
              <option *ngFor="let BANK_HQ of this.GetSubBankDataResponse2.SubBankDetails" [value]='BANK_HQ.BANK_BRH'>
                {{BANK_HQ.BANK_BRH_NAME}}
              </option>
            </select>
          </div>
          <input [(ngModel)]="this.CusAccountList2.ACCOUNT_NO" class="input-40 mobile-mt10" placeholder="帳號">
          <div *ngIf="disMsg.CusAccountList2" class="text-red" style="margin-top: 8px;">* 請選擇輸入約定買回帳戶</div>
          <div *ngIf="disMsg.CusAccountList2_1" class="text-red" style="margin-top: 8px;">* 格式不正確</div>
          <div *ngIf="disMsg.CusAccountList2_2" class="text-red" style="margin-top: 10px;">* 帳號長度不符 需填
            {{this.BankMsg[1].len}}
            {{this.BankMsg[1].len1}} {{this.BankMsg[1].len2}} 碼</div>
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">買回價款匯入銀行(二)</div>
      <div class="basic-right">
        <div class="address-box">
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList3.BANK_HQ" (change)="selectBankData($event.target,3)">
              <option value="-1">-請選擇銀行-</option>
              <option *ngFor="let BANK_HQ of this.GetBankDataResponse2.BankList" [value]='BANK_HQ.BANK_HQ'>
                {{BANK_HQ.BANK_HQ_NAME}}
              </option>
            </select>
          </div>
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList3.BANK_BRH">
              <option value="">-請選擇分行-</option>
              <option *ngFor="let BANK_HQ of this.GetSubBankDataResponse3.SubBankDetails" [value]='BANK_HQ.BANK_BRH'>
                {{BANK_HQ.BANK_BRH_NAME}}
              </option>
            </select>
          </div>
          <input [(ngModel)]="this.CusAccountList3.ACCOUNT_NO" class="input-40 mobile-mt10" placeholder="帳號">
          <div *ngIf="disMsg.CusAccountList3" class="text-red" style="margin-top: 8px;">* 請選擇輸入約定買回帳戶(二)</div>
          <div *ngIf="disMsg.CusAccountList3_1" class="text-red" style="margin-top: 8px;">* 格式不正確</div>
          <div *ngIf="disMsg.CusAccountList3_2" class="text-red" style="margin-top: 10px;">* 帳號長度不符 需填
            {{this.BankMsg[2].len}}
            {{this.BankMsg[2].len1}} {{this.BankMsg[2].len2}} 碼</div>
        </div>
      </div>
    </div>
  </div>
  <!-- 買回價款匯入銀行 -->
  <div class="title-02">台幣收益配息帳號 僅限申購人本人之帳號</div>

  <div class="basic-data-table">
    <div class="w-100">
      <div class="basic-left"><samp class="text-red">*</samp>收益配息帳號</div>
      <div class="basic-right">
        <div class="address-box">
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList4.BANK_HQ" (change)="selectBankData($event.target,4)">
              <option value="-1">-請選擇銀行-</option>
              <option *ngFor="let BANK_HQ of this.GetBankDataResponse2.BankList" [value]='BANK_HQ.BANK_HQ'>
                {{BANK_HQ.BANK_HQ_NAME}}
              </option>
            </select>
          </div>
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList4.BANK_BRH">
              <option value="">-請選擇分行-</option>
              <option *ngFor="let BANK_HQ of this.GetSubBankDataResponse4.SubBankDetails" [value]='BANK_HQ.BANK_BRH'>
                {{BANK_HQ.BANK_BRH_NAME}}
              </option>
            </select>
          </div>
          <input [(ngModel)]="this.CusAccountList4.ACCOUNT_NO" class="input-40 mobile-mt10" placeholder="帳號">
          <div *ngIf="disMsg.CusAccountList4" class="text-red" style="margin-top: 8px;">* 請選擇輸入收益配息帳號</div>
          <div *ngIf="disMsg.CusAccountList4_1" class="text-red" style="margin-top: 8px;">* 格式不正確</div>
          <div *ngIf="disMsg.CusAccountList4_2" class="text-red" style="margin-top: 10px;">* 帳號長度不符 需填
            {{this.BankMsg[3].len}}
            {{this.BankMsg[3].len1}} {{this.BankMsg[3].len2}} 碼</div>
        </div>
        <!-- <p style="margin-top:10px;">授權扣款帳戶需為申購人(受益人)本人帳戶，且須該扣款行核印完成後，才可進行交易。</p> -->
      </div>
    </div>
  </div>

  <div class="title-02">外幣申購、買回及收益配息帳號</div>

  <div class="basic-data-table">
    <div class="w-100">
      <div class="basic-left">
        <div>外幣帳號</div>
        <div>(外幣綜合帳號)</div>
      </div>
      <div class="basic-right">
        <div class="address-box">
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList5.BANK_HQ" (change)="selectBankData($event.target,5)">
              <option value="-1">-請選擇銀行-</option>
              <option *ngFor="let BANK_HQ of this.GetBankDataResponse3.BankList" [value]='BANK_HQ.BANK_HQ'>
                {{BANK_HQ.BANK_HQ_NAME}}
              </option>
            </select>
          </div>
          <div class="SelectStyle2 input-29">
            <select [(ngModel)]="this.CusAccountList5.BANK_BRH">
              <option value="">-請選擇分行-</option>
              <option *ngFor="let BANK_HQ of this.GetSubBankDataResponse5.SubBankDetails" [value]='BANK_HQ.BANK_BRH'>
                {{BANK_HQ.BANK_BRH_NAME}}
              </option>
            </select>
          </div>
          <input [(ngModel)]="this.CusAccountList5.ACCOUNT_NO" class="input-40 mobile-mt10" placeholder="帳號">
        </div>
        <p style="margin-top:10px;">授權扣款帳戶需為申購人(受益人)本人帳戶，且須該扣款行核印完成後，才可進行交易。</p>
        <div *ngIf="disMsg.CusAccountList5" class="text-red" style="margin-top: 8px;">* 請選擇輸入外幣申贖帳號</div>
        <div *ngIf="disMsg.CusAccountList5_1" class="text-red" style="margin-top: 8px;">* 格式不正確</div>
        <div *ngIf="disMsg.CusAccountList5_2" class="text-red" style="margin-top: 10px;">* 帳號長度不符 需填
          {{this.BankMsg[4].len}}
          {{this.BankMsg[4].len1}} {{this.BankMsg[4].len2}} 碼</div>
      </div>
    </div>
  </div>

  <div class="button-box">
    <a href="javascript:void(0)" (click)="save1(1)" class="cancel-button">上一步</a>
    <a href="javascript:void(0)" (click)="save1(3)" class="Main-button">下一步<i class="fas fa-chevron-right"></i></a>
  </div>
</div>

<div class="Block-content" *ngIf="pagecontact==3">

  <div class="p-contact-form__step-100">
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">1</div>
      <div class="p-contact-form__step-label">開戶規範及個資同意書</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-current">2</div>
      <div class="p-contact-form__step-label">輸入基本資料</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">3</div>
      <div class="p-contact-form__step-label">填寫風險承受度及投資屬性</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">4</div>
      <div class="p-contact-form__step-label">資料確認</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">5</div>
      <div class="p-contact-form__step-label">開戶文件下載</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">6</div>
      <div class="p-contact-form__step-label">申請完成</div>
    </div>
  </div>


  <div class="title-02">上傳雙證件正、反面</div>
  <div class="basic-data-line">
    <div class="id-box">
      <input id="file1" type="file" accept="image/*" (change)="fileChange($event,1)" style="display: none" />
      <button type="file1" onclick="file1.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL.ImageProductImg == null?'assets/images/photo-icon.png':imgURL.ImageProductImg">
        <p>點此上傳身分證正面</p>
      </button>
      <input id="file2" type="file" accept="image/*" (change)="fileChange($event,2)" style="display: none" />
      <button type="file2" onclick="file2.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL2.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL2.ImageProductImg">
        <p>點此上傳身分證反面</p>
      </button>
      <input id="file3" type="file" accept="image/*" (change)="fileChange($event,3)" style="display: none" />
      <button type="file3" onclick="file3.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL3.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL3.ImageProductImg">
        <p>點此上傳第二證件正面</p>
      </button>
      <input id="file4" type="file" accept="image/*" (change)="fileChange($event,4)" style="display: none" />
      <button type="file4" onclick="file4.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL4.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL4.ImageProductImg">
        <p>點此上傳第二證件反面</p>
      </button>
    </div>
  </div>

  <div class="title-02">上傳第一監護人雙證件正、反面</div>
  <div class="basic-data-line">
    <div class="id-box">
      <input id="file5" type="file" accept="image/*" (change)="fileChange($event,5)" style="display: none" />
      <button type="file5" onclick="file5.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL5.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL5.ImageProductImg">
        <p>點此上傳身分證正面</p>
      </button>
      <input id="file6" type="file" accept="image/*" (change)="fileChange($event,6)" style="display: none" />
      <button type="file6" onclick="file6.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL6.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL6.ImageProductImg">
        <p>點此上傳身分證反面</p>
      </button>
      <input id="file7" type="file" accept="image/*" (change)="fileChange($event,7)" style="display: none" />
      <button type="file7" onclick="file7.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL7.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL7.ImageProductImg">
        <p>點此上傳第二證件正面</p>
      </button>
      <input id="file8" type="file" accept="image/*" (change)="fileChange($event,8)" style="display: none" />
      <button type="file8" onclick="file8.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL8.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL8.ImageProductImg">
        <p>點此上傳第二證件反面</p>
      </button>
    </div>
  </div>

  <div class="title-02">上傳第二監護人雙證件正、反面</div>
  <div class="basic-data-line">
    <div class="id-box">
      <input id="file9" type="file" accept="image/*" (change)="fileChange($event,9)" style="display: none" />
      <button type="file9" onclick="file9.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL9.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL9.ImageProductImg">
        <p>點此上傳身分證正面</p>
      </button>
      <input id="file10" type="file" accept="image/*" (change)="fileChange($event,10)" style="display: none" />
      <button type="file10" onclick="file10.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL10.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL10.ImageProductImg">
        <p>點此上傳身分證反面</p>
      </button>
      <input id="file11" type="file" accept="image/*" (change)="fileChange($event,11)" style="display: none" />
      <button type="file11" onclick="file11.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL11.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL11.ImageProductImg">
        <p>點此上傳第二證件正面</p>
      </button>
      <input id="file12" type="file" accept="image/*" (change)="fileChange($event,12)" style="display: none" />
      <button type="file12" onclick="file12.click()" class="id-link" style='cursor: pointer;'>
        <img [src]="imgURL12.ImageProductImg ==null?'assets/images/photo-icon.png':imgURL12.ImageProductImg">
        <p>點此上傳第二證件反面</p>
      </button>
    </div>
  </div>


  <div class="basic-data-line"></div>

  <div class="button-box">
    <a href="javascript:void(0)" (click)="save1(2)" class="cancel-button">上一步</a>
    <a href="javascript:void(0)" (click)="save1(4)" class="Main-button">下一步<i class="fas fa-chevron-right"></i></a>
  </div>
</div>

<div class="Block-content" *ngIf="pagecontact==4">
  <div class="p-contact-form__step-100">
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">1</div>
      <div class="p-contact-form__step-label">開戶規範及個資同意書</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">2</div>
      <div class="p-contact-form__step-label">輸入基本資料</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-current">3</div>
      <div class="p-contact-form__step-label">填寫風險承受度及投資屬性</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">4</div>
      <div class="p-contact-form__step-label">資料確認</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">5</div>
      <div class="p-contact-form__step-label">開戶文件下載</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">6</div>
      <div class="p-contact-form__step-label">申請完成</div>
    </div>
  </div>

  <!--A區-->
  <div class="title-02">基本資料</div>
  <div class="basic-data-table">
    <div class="w-50" style="width: 50%;" *ngFor="let item of getKYCQuestionList('A'); index as i; first as isFirst">
      <div class="basic-left">{{item.KYC_QUESTION}}</div>
      <div class="basic-right">
        <div class="SelectStyle2 w-80">
          <select (change)="Display(item, item.KYCQuestionResult)" [(ngModel)]="item.KYCQuestionResult">
            <option [ngValue]="undefined">請選擇</option>
            <option *ngFor="let dtl of item.KYCQuestionDtl" [ngValue]="dtl.ANS_SEQNO">
              {{dtl.ANS_ITEM}}
            </option>
          </select>
        </div>
        <div class="w-80" *ngIf="item.Display">
          <br>
          <input nbInput [maxlength]="100" [(ngModel)]="item.ANS_TEXT_IN">
        </div>
        <div *ngIf="item.Display" class="text-red" style="margin-top: 10px;">* 此欄位為必填</div>
      </div>
    </div>
  </div>

  <!--B區-->
  <div class="title-02">風險評估</div>
  <div class="basic-data-table kyc">
    <div class="w-100" *ngFor="let item of getKYCQuestionList('B'); index as i; first as isFirst">
      <div class="basic-left">{{i+1}}.{{item.KYC_QUESTION}}</div>
      <div class="basic-right">


        <div class="kyc-box">
          <!--多選-->
          <ng-container *ngIf="item.IS_ALLOW_MULIT=='Y'">
            <div class="checkbox-wrap" *ngFor="let dtl of item.KYCQuestionDtl">
              <input id="{{dtl.ANS_SEQNO}}" type="checkbox" name="{{item.QSN_SEQNO}}" [value]="dtl.ANS_SEQNO"
                [(ngModel)]="dtl.ANS_SEQNO_SELECTED">
              <label for="{{dtl.ANS_SEQNO}}"><samp>{{dtl.ANS_ITEM}}</samp></label>
            </div>
          </ng-container>

          <!--單選-->
          <ng-container *ngIf="item.IS_ALLOW_MULIT=='N'">
            <div class="clear" *ngFor="let dtl of item.KYCQuestionDtl">
              <input id="{{dtl.ANS_SEQNO}}" type="radio" name="{{item.QSN_SEQNO}}" [value]="dtl.ANS_SEQNO" class="form"
                [(ngModel)]="item.KYCQuestionResult">
              <label for="{{dtl.ANS_SEQNO}}" class="form">{{dtl.ANS_ITEM}}</label>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="button-box">
    <a href="javascript:void(0)" (click)="getKycRiskScore()" class="Main-button">計算分數<i
        class="fas fa-chevron-right"></i></a>
  </div>


  <ng-container *ngIf="GetKycRiskScoreResponse.KYC_RISK_ATTR">
    <div class="Risk-score">
      <ul>
        <li>
          <p>得分</p><samp>{{GetKycRiskScoreArgs.ANS_SCORE}}</samp>
        </li>
        <li>
          <p>風險屬性類型為</p><span>{{GetKycRiskScoreResponse.KYC_RISK_ATTR_NM}}</span>
        </li>
      </ul>
    </div>

    <div class="Scoring-method">
      <p>※計分方式：若有未填選項者，以｢較低分數選項｣計分，若為複選、單選重複勾選者，則以｢最高分數選項｣計分。</p>
      <ul>
        <li><span>21-30分</span>適合積極型基金</li>
        <li><span>11-20分</span>適合穩健型基金</li>
        <li><span>1-10分</span>適合保守型基金</li>
        <li><span>0分</span>適合不適用基金</li>
      </ul>
    </div>




    <div class="button-box">
      <a href="javascript:void(0)" (click)="save1(3)" class="cancel-button">上一步</a>
      <a href="javascript:void(0)" (click)="save1(5)" class="Main-button">下一步<i class="fas fa-chevron-right"></i></a>
    </div>
  </ng-container>

  <div class="note-box">
    <h4 class="text-red">重要聲明</h4>
    <ol class="note-list">
      <li>風險屬性分析之評估結果，係依開戶當時狀況填寫相關資料得知，該評估結果之風險屬性類型將做為您未來每次投資本公司系列基金之參考依據。</li>
      <li>若您不認同上述風險屬性評估之結果，請重作風險屬性評估。</li>
    </ol>
  </div>

</div>

<div class="Block-content" *ngIf="pagecontact==5">

  <div class="p-contact-form__step-100">
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">1</div>
      <div class="p-contact-form__step-label">開戶規範及個資同意書</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">2</div>
      <div class="p-contact-form__step-label">輸入基本資料</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">3</div>
      <div class="p-contact-form__step-label">填寫風險承受度及投資屬性</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-current">4</div>
      <div class="p-contact-form__step-label">資料確認</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">5</div>
      <div class="p-contact-form__step-label">開戶文件下載</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">6</div>
      <div class="p-contact-form__step-label">申請完成</div>
    </div>
  </div>

  <div class="basic-data-table" style="margin-top:50px;">
    <div class="w-50">
      <div class="basic-left">身份證字號</div>
      <div class="basic-right">{{this.OpenMemberArgs.ID_NO}}</div>
      <div class="basic-left">生日(西元)</div>
      <div class="basic-right">
        {{this.OpenMemberArgs.BIR_DATE1}}年{{this.OpenMemberArgs.BIR_DATE2}}月{{this.OpenMemberArgs.BIR_DATE3}}日
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">受益人姓名</div>
      <div class="basic-right">{{this.OpenMemberArgs.BF_NAME}}</div>
      <div class="basic-left">英文姓名</div>
      <div class="basic-right">
        {{this.OpenMemberArgs.BF_NAME_E}}
      </div>
    </div>
    <div class="w-50">
      <div class="basic-left">住家電話</div>
      <div class="basic-right">{{this.OpenMemberArgs.HM_TEL_AREA}}-{{this.OpenMemberArgs.HM_TEL}}</div>
      <div class="basic-left">公司電話</div>
      <div class="basic-right">
        {{this.OpenMemberArgs.OF_TEL_AREA}}-{{this.OpenMemberArgs.OF_TEL}}#{{this.OpenMemberArgs.OF_TEL_EXT}}</div>
    </div>
    <div class="w-50">
      <div class="basic-left">行動電話</div>
      <div class="basic-right">{{this.OpenMemberArgs.CELL_PHONE}}</div>
      <div class="basic-left">傳真電話</div>
      <div class="basic-right">{{this.OpenMemberArgs.FAX_TEL_AREA1}}-{{this.OpenMemberArgs.FAX_TEL1}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">電子信箱</div>
      <div class="basic-right">{{this.OpenMemberArgs.EMAIL1}}</div>
    </div>
    <div class="w-50">
      <div class="basic-left">國籍</div>
      <div class="basic-right">{{ShowCountry(this.OpenMemberArgs.BF_NATIONALITY)}}</div>
      <div class="basic-left">居住地</div>
      <div class="basic-right">{{ShowCountry(this.OpenMemberArgs.NATIONALITY_BIRTH)}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">通訊地址</div>
      <div class="basic-right">{{this.OpenMemberArgs.MAIL_ZIP}}{{this.OpenMemberArgs.MAIL_ADDR}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">戶籍地址</div>
      <div class="basic-right">{{this.OpenMemberArgs.PERM_ZIP}}{{this.OpenMemberArgs.PERM_ADDR}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">身份類別</div>
      <div class="basic-right">{{this.OpenMemberArgs.IS_USA=="Y"?"美國稅務居民":"非美國稅務居民"}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">連絡人</div>
      <div class="basic-right">{{this.OpenMemberArgs.CNT_PERSON}}</div>
    </div>
    <div class="w-50">
      <div class="basic-left">法定代理人(一)姓名</div>
      <div class="basic-right">{{this.OpenMemberArgs.AGENT_IN_LAW1}}</div>
      <div class="basic-left">法定代理人(一)國籍</div>
      <div class="basic-right">{{ShowCountry(this.OpenMemberArgs.AGENT_IN_LAW_NALITY1)}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">法定代理人(一)身份證字號</div>
      <div class="basic-right">{{this.OpenMemberArgs.AGENT_IN_LAW_ID}}</div>
    </div>
    <div class="w-50">
      <div class="basic-left">法定代理人(二)姓名</div>
      <div class="basic-right">{{this.OpenMemberArgs.AGENT_IN_LAW2}}</div>
      <div class="basic-left">法定代理人(二)國籍</div>
      <div class="basic-right">{{ShowCountry(this.OpenMemberArgs.AGENT_IN_LAW_NALITY2)}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">法定代理人(二)身份證字號</div>
      <div class="basic-right">{{this.OpenMemberArgs.AGENT_IN_LAW_ID2}}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">台幣扣款帳號</div>
      <div class="basic-right">{{this.CusAccountList.BANK_BRH_NAME }} {{this.CusAccountList.ACCOUNT_NO }}</div>
    </div>
    <div class="w-100">
      <div class="basic-left">台幣約定買回帳戶(一)</div>
      <div class="basic-right"> {{this.CusAccountList2.BANK_BRH_NAME }} {{this.CusAccountList2.ACCOUNT_NO }} </div>
    </div>
    <div class="w-100">
      <div class="basic-left">台幣約定買回帳戶(二)</div>
      <div class="basic-right"> {{this.CusAccountList3.BANK_BRH_NAME }} {{this.CusAccountList3.ACCOUNT_NO }} </div>
    </div>
    <div class="w-100">
      <div class="basic-left">台幣收益配息帳號</div>
      <div class="basic-right"> {{this.CusAccountList4.BANK_BRH_NAME }} {{this.CusAccountList4.ACCOUNT_NO }} </div>
    </div>
    <div class="w-100">
      <div class="basic-left" style="font-size: 15px;">外幣申購、買回及收益配息帳號</div>
      <div class="basic-right"> {{this.CusAccountList5.BANK_BRH_NAME }} {{this.CusAccountList5.ACCOUNT_NO }} </div>
    </div>
    <!-- <div class="w-100">
      <div class="basic-left">是否接收行銷訊息</div>
      <div class="basic-right">{{this.OpenMemberArgs.MCD_YN=="N"?"否":"是"}}</div>
    </div> -->
  </div>

  <!--A區-->
  <div class="title-02">基本資料</div>
  <div class="basic-data-table">
    <div class="w-50" style="width: 50%;" *ngFor="let item of getKYCQuestionList('A'); index as i; first as isFirst">
      <div class="basic-left">{{item.KYC_QUESTION}}</div>
      <div class="basic-right">
        <div class="w-80">

          <!--ans-->
          {{findDtlTxt(item.KYCQuestionDtl,item.KYCQuestionResult)}}

        </div>
      </div>
    </div>
  </div>


  <div class="title-02">風險評估</div>
  <div class="basic-data-table">
    <div class="w-100" *ngFor="let item of getKYCQuestionList('B'); index as i; first as isFirst">
      <div class="basic-left">{{i+1}}.{{item.KYC_QUESTION}}</div>
      <div class="basic-right">


        <div class="kyc-box">
          <!--多選-->
          <ng-container *ngIf="item.IS_ALLOW_MULIT=='Y'">
            {{findDtlTxtArray(item.KYCQuestionDtl)}}
          </ng-container>

          <!--單選-->
          <ng-container *ngIf="item.IS_ALLOW_MULIT=='N'">
            {{findDtlTxt(item.KYCQuestionDtl,item.KYCQuestionResult)}}
          </ng-container>
        </div>
      </div>
    </div>
  </div>


  <div class="button-box">
    <a href="javascript:void(0)" (click)="save1(4)" class="cancel-button">上一步</a>
    <a href="javascript:void(0)" (click)="save1(6)" class="Main-button">下一步<i class="fas fa-chevron-right"></i></a>
  </div>

</div>

<div class="Block-content" *ngIf="pagecontact==6">
  <div class="p-contact-form__step-100">
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">1</div>
      <div class="p-contact-form__step-label">開戶規範及個資同意書</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">2</div>
      <div class="p-contact-form__step-label">輸入基本資料</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">3</div>
      <div class="p-contact-form__step-label">填寫風險承受度及投資屬性</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">4</div>
      <div class="p-contact-form__step-label">資料確認</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-current">5</div>
      <div class="p-contact-form__step-label">開戶文件下載</div>
    </div>
    <div class="p-contact-form__step-bar-gray"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle">6</div>
      <div class="p-contact-form__step-label">申請完成</div>
    </div>
  </div>

  <div class="complete">
    <h2><i class="far fa-check-circle"></i>選擇帳戶文件取得方式</h2>
    <p>您的帳戶已設定完畢<br>您可選擇以下不同方式取得您的開戶文件</p>
  </div>
  <h2 *ngIf="this.OpenMemberArgs.IS_USA=='Y'" style="color: red;text-align: center;">身份類別為美國稅務居民,請至客服中心之表格下載區加填W-9表格。
  </h2>

  <div class="Details print-box">

    <div><img src="assets/images/print.png"><a href={{this.print}} target="_blank" class="cancel-button">立即列印</a>
    </div>
    <div><img src="assets/images/mail.png"><a href="javascript:void(0)" (click)="SendParper()"
        class="Main-button">郵寄至帳號通訊地</a></div>
  </div>

  <div class="button-box">

    <a href="javascript:void(0)" (click)="save1(7)" class="Main-button">下一步<i class="fas fa-chevron-right"></i></a>
  </div>

</div>

<div class="Block-content" *ngIf="pagecontact==7">

  <div class="p-contact-form__step-100">
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">1</div>
      <div class="p-contact-form__step-label">開戶規範及個資同意書</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">2</div>
      <div class="p-contact-form__step-label">輸入基本資料</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">3</div>
      <div class="p-contact-form__step-label">填寫風險承受度及投資屬性</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">4</div>
      <div class="p-contact-form__step-label">資料確認</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item is-current">
      <div class="p-contact-form__step-circle-finish">5</div>
      <div class="p-contact-form__step-label">開戶文件下載</div>
    </div>
    <div class="p-contact-form__step-bar"></div>
    <div class="p-contact-form__step-item Gray-word">
      <div class="p-contact-form__step-circle-current">6</div>
      <div class="p-contact-form__step-label">申請完成</div>
    </div>
  </div>

  <div style="max-width:600px;margin:60px 25%;">
    <img style="max-width:900px;max-height: 900px;" src="assets/images/new.svg">
  </div>


  <div class="button-box">
    <a href="login" class="Main-button">回登入頁<i class="fas fa-chevron-right"></i></a>
  </div>



</div>
