export class ToastForCountParams {}
export class ToastForCountResponse {
  PersonMsgCount: number; // 未讀數
}
export class ToastParams {}
export class ToastResponse {
  CMsgParam1: string; // 通知內容
  CCreateDt: Date; // 時間
  Cstatusid: number; // 通知類別 ID
  Cstatusname: string; // 通知類別
}
