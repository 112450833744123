import { Component, OnInit } from '@angular/core';
import { OpenDataService } from 'src/app/services/open-data.service';
import { RSPService } from 'src/app/services/rsp.service';
import { EditRegulyBuyDiffArgs, EditRegulyBuyDiffResponse, GetRegulyBuyDiffArgs, GetRegulyBuyDiffResponse, GetRspDayListArgs, GetRspDayListResponse, GetRspQueryArgs, GetRspQueryResponse, InsertRegulyBuyDiffResponse, RspChgList } from 'src/app/model/rsp';
import { GetBucAccArgs, GetBucAccResponse } from 'src/app/model/open-data';
import { BaseComponent } from '../base/baseComponent';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { JwtPayload } from 'src/app/model/base';
import { ServiceBase } from 'src/app/services/service-base';
import jwt_decode from "jwt-decode";
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { PageParam } from 'src/app/model/member';
import { MemberService } from 'src/app/services/member.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-regular-quota',
  templateUrl: './regular-quota.component.html',
  styleUrls: ['./regular-quota.component.css']
})
export class RegularQuotaComponent extends BaseComponent implements OnInit {

  //1.list 2.edit 3.editCheck
  showType = 1;
  jwt = {} as JwtPayload;

  JwtPayload = new JwtPayload();

  GetRspQueryArgs = new GetRspQueryArgs();
  GetRspQueryResponse = new GetRspQueryResponse();

  GetRspDayListResponse = new GetRspDayListResponse();
  GetBucAccResponse = new GetBucAccResponse();

  GetRegulyBuyDiffResponse = [] as GetRegulyBuyDiffResponse[];

  ShowAlert: string = '';

  EditRegulyBuyDiffArgs = new EditRegulyBuyDiffArgs();
  EditRegulyBuyDiffResponse = new EditRegulyBuyDiffResponse();

  InsertRegulyBuyDiffResponse = new InsertRegulyBuyDiffResponse();

  kycblock: number;

  conceal: string = 'conceal';
  passwordType: string = 'password';

  RspChgList = new RspChgList();

  pageparam = new PageParam();

  constructor(
    private _OpenDataService: OpenDataService,
    private _RSPService: RSPService,
    protected allow: AllowHelper,
    private router: Router,
    private _MemberService: MemberService,
    public datepipe: DatePipe,
  ) {
    super(allow);
  }

  ngOnInit(): void {
    this.jwt = jwt_decode(localStorage.getItem('token') || '');
    // if (this.jwt.KYC_STATUS != 'Y') {
    //   if (confirm('您的kyc已過期，無法進行交易，是否導頁到kyc頁填寫?')) {
    //     this.router.navigateByUrl('kyc');
    //   }
    // }
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 5;
    this.pageparam.Page = '異動定期定額契約';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.GetRspQuery(this.GetRspQueryArgs);
        this.GetRspDayList({});
        this.JwtPayload = this.getJwtPayload();
      }
      else {
        return
      }
    })
  }

  getJwtPayload() {
    var token = localStorage.getItem('cdp_token') || "";
    const jwt = jwt_decode(token);
    return (jwt as JwtPayload);
  }

  GetRspDayList(arg: GetRspDayListArgs) {
    this._RSPService.GetRspDayList(arg).subscribe(res => {
      this.GetRspDayListResponse = res.Entries;
    });
  }

  GetRspQuery(arg: GetRspQueryArgs) {
    this._RSPService.GetRspQuery(arg).subscribe(res => {
      this.GetRspQueryResponse = res.Entries;
    });
  }

  GetBucAcc(arg: GetBucAccArgs) {
    debugger;
    this._OpenDataService.GetBucAcc(arg).subscribe(res => {
      this.GetBucAccResponse = res.Entries;
    });
  }

  GetRspQGetRegulyBuyDiffuery(Id: string) {
    var arg = new GetRegulyBuyDiffArgs();
    arg.RegularlyBuyId = Number(Id);
    this._RSPService.GetRegulyBuyDiff(arg).subscribe(res => {
      this.GetRegulyBuyDiffResponse = res.Entries;
      console.log(this.GetRegulyBuyDiffResponse);

    });
  }


  OnEdit(arg: RspChgList) {
    var request = new EditRegulyBuyDiffArgs();
    request.RegularlyBuyId = Number(arg.RSP_NO);
    request.FUND_ID = arg.FUND_ID;
    request.RSP_SRNO = arg.RSP_SRNO;
    this._RSPService.EditRegulyBuyDiff(request).subscribe(res => {
      this.EditRegulyBuyDiffResponse = res.Entries;
      if (res.StatusCode === EnumStatusCode.Success) {
        this.showType = 2;
      }
    });
  }

  OnInsert(InsertTypeId: number) {
    // debugger
    this.ShowAlert = '';
    if (this.EditRegulyBuyDiffResponse.TextBox_CSALECOUNT != null && this.EditRegulyBuyDiffResponse.TextBox_CSALECOUNT.length > 10) {
      alert('超出最大申購位數(10位)，請重新輸入');
    }
    else {
      var date = this.datepipe.transform(new Date(), 'yyyy/MM/dd') as string

      this.EditRegulyBuyDiffResponse.TextBox_StartDate = date;
      this.EditRegulyBuyDiffResponse.TextBox_EndDate = date;
      // debugger
      this.EditRegulyBuyDiffResponse.InsertTypeId = InsertTypeId;
      this._RSPService.InsertRegulyBuyDiff(this.EditRegulyBuyDiffResponse).subscribe(res => {
        if (res.StatusCode === EnumStatusCode.Success) {
          this.showType = InsertTypeId;
          this.InsertRegulyBuyDiffResponse = res.Entries;
        }
        else {
          this.ShowAlert = res.Message;
        }
      });
    }
  }

  checkAmount() {
    if (this.EditRegulyBuyDiffResponse.TextBox_CSALECOUNT.length > 10) {
      alert('超出最大申購位數(10位)，請重新輸入');
    }
  }

  BindCheckBox_SetAmount() {

  }

  BindCheckBox_Day() {


  }

  BindCheckBox_ChargeState() {


  }

  BindTBLBANKDEBITS() {


  }

  onClickConceal() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.conceal = 'conceal yincang';
    } else {
      this.passwordType = 'password';
      this.conceal = 'conceal';
    }
  }



}
