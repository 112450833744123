
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import { GetDashboardInfoArgs, GetDashboardInfoResponse } from '../model/my-account';

@Injectable({
  providedIn: 'root',
})
export class MyAccountService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/MyAccount`;
  constructor(
    http: HttpClient
  ) {
    super(http);

  }

  GetDashboardInfo(request: GetDashboardInfoArgs): Observable<ResponseBase<GetDashboardInfoResponse>> {
    const url = `${this.baseUrl}/GetDashboardInfo`;
    return this.http.post<ResponseBase<GetDashboardInfoResponse>>(
      url,
      request,
      this.httpOptions);
  }
}
