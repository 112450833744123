import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';

import { CancellationTradeArgs, GetCancellationTradeResponse  } from '../model/cancellationtrade';
import { GetBuyResponse, GetTBLREGULARLYBUYResponse, GetRegularlyTransactionResponse, GetSaleResponse, GetTsResponse  } from '../model/cancellationtrade';
@Injectable({
  providedIn: 'root',
})
export class CancellationtradeService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/CancellationTrade`;
  constructor(
    http: HttpClient
  ) {
    super(http);

  }
  GetList(): Observable<ResponseBase<GetCancellationTradeResponse>> {
    const url = `${this.baseUrl}/GetCancellationTrade`;
    return this.http.post<ResponseBase<GetCancellationTradeResponse>>(
      url,
      this.httpOptions);
  }

  CancellationTrade(request: CancellationTradeArgs): Observable<ResponseBase<GetCancellationTradeResponse>> {
    const url = `${this.baseUrl}/CancellationTrade`;
    return this.http.post<ResponseBase<GetCancellationTradeResponse>>(
      url,
      request,
      this.httpOptions);
  }

}


