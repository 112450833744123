export class MenuModel {
  name!: string;
  class!: string;
  url!: string;
  isOpen: boolean = false;
  checked: boolean;
  childMenu!: MenuModel[];
}

export class MenuAllow {
  CPageUrl!: string;
  CCompetenceType!: number;
}
