import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import { GetCaptchaCodeArgs, GetCaptchaCodeResponse, GetCaptchaImageArgs, GetCaptchaImageResponse } from '../model/base';

@Injectable({
  providedIn: 'root',
})
export class BaseService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/Base`;
  constructor(
    http: HttpClient
  ) {
    super(http);

  }


getCaptchaCode(request: GetCaptchaCodeArgs): Observable<ResponseBase<GetCaptchaCodeResponse>> {
  const url = `${this.baseUrl}/GetCaptchaCode`;
  return this.http.post<ResponseBase<GetCaptchaCodeResponse>>(
    url,
    request,
    this.httpOptions);
}

getCaptchaImage(request: GetCaptchaImageArgs): Observable<any>{
  const requestOptions: Object = {
    responseType: 'blob'
  }
  const url = `${this.baseUrl}/GetCaptchaImage`;
  return this.http.post<any>(
    url,
    request,
    requestOptions);
}
}


