export class GetRspDayListArgs { }

export class GetRspDayListResponse {
  RspAllotDaysList!: RspAllotDaysList[]; // 每月扣款日清單
}

export class RspAllotDaysList {
  DAYS!: string; // 扣款日
}

export class GetRspQueryArgs {
  FUND_ID!: string;
}

export class GetRspQueryResponse {
  RspChgList!: RspChgList[];
}

export class RspChgList {
  RSP_NO!: string;
  RSP_SRNO!: string;
  FUND_ID!: string;
  FUND_NAME!: string;
  RCV_DATE!: Date;
  CRNCY_CD!: string;
  CRNCY_NM!: string;
  CAMPAIGN_CODE!: string;
  CAMPAIGN_SHNM!: string;
  RSP_FEE_RATE!: number;
  RSP_ALLOT_DAY!: string;
  RSP_AMT!: number;
  FIRST_SUB_DATE!: Date;
  ACCOUNT_TEXT!: string;
  STATUS!: string;
  STATUS_DESC!: string;
  SYSTEM_ID!: string;
  SYSTEM_ID_DESC!: string;
  RSPCHG_YN!: string;
  DEC_LEN!: number;
}

export class GetRegulyBuyDiffArgs {
  RegularlyBuyId!: number;
}

export class GetRegulyBuyDiffResponse {
  cFundName!: string;
  cAmount!: number;
  cChargeDate?: any;
  cCHARGESTATE!: string;
  cAccount?: any;
  cCodeName?: any;
  cTransferDate?: string;
  cSetStartTime?: string;
  cSetEndTime?: string;
  cStatus: string;
  cCHARGESTATE_NM: string;
  cAmount_NM: string;
}


export class EditRegulyBuyDiffArgs {
  RegularlyBuyId: number;
  RSP_SRNO: string;
  FUND_ID: string;
}

export class RadioButtonListChargeStateList {
  Value: string;
  Text: string;
}

export class BucAccList {
  DATA_SEQ: number;
  BANK_BRH: string;
  BANK_BRH_SHNM: string;
  ACCOUNT_NO: string;
  ACCOUNT_NO_SHOW: string;
  SEAL_TYPE: string;
  ACC_NO_TYPE: string;
}

export class EditRegulyBuyDiffResponse {
  InsertTypeId: number;
  Label_Day: Date;
  FUND_ID: string;
  Label_FUND: string;
  CheckBox_SetAmount_Enabled: boolean;
  CheckBox_SetAmount_Checked: boolean;
  Label_SaleCount: number;
  TextBox_CSALECOUNT: string;
  TextBox_CSALECOUNT_Visible: boolean;
  CheckBox_Day_Enabled: boolean;
  CheckBox_Day_Checked: boolean;
  Label_EditDay: string;
  RadioButtonList_Day: string;
  RadioButtonList_Day_Visible: boolean;
  CheckBox_ChargeState_Enabled: boolean;
  CheckBox_ChargeState_Checked: boolean;
  Label_EditState: string;
  RadioButtonList_ChargeState: string;
  RadioButtonList_ChargeState_Visible: boolean;
  Panel_SetDate_Visible: boolean;
  TextBox_StartDate: string;
  Label_SText_Visible: boolean;
  Label_EditSdate: Date;
  TextBox_EndDate: string;
  Label_EText_Visible: boolean;
  Label_EditEdate: Date;
  CheckBox_TBLBANKDEBITS_Enabled: boolean;
  CheckBox_TBLBANKDEBITS_Checked: boolean;
  Label_BankCode: string;
  Label_BankNm: string;
  Label_BankAccount: string;
  Label_BankAccount_No_Show: string;
  SEAL_TYPE: string;
  DropDownList_TBLBANKDEBITS: string;
  DropDownList_TBLBANKDEBITS_Visible: boolean;
  Label_StatusMemo_Visible: boolean;
  TextBox_Password: string;
  RadioButtonList_ChargeState_List: RadioButtonListChargeStateList[];
  BucAccList: BucAccList[];
}

export class InsertRegulyBuyDiffResponse {
  Modify_Label_Day: Date;
  Modify_Label_FUND: string;
  Modify_Label_SaleCount: string;
  IsModify_Label_SaleCount: boolean;
  Modify_Label_EditDay: string;
  IsModify_Label_EditDay: boolean;
  Modify_Label_EditState: string;
  IsModify_Label_EditState: boolean;
  Modify_Label_BankDebits: string;
  IsModify_Label_BankDebits: boolean;
  ApplyTime: Date;
}
