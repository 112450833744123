import {
  GetFundListArgs,
  GetFundListResponse,
  GetFeeRateArgs,
  GetFeeRateResponse,
  GetDivAccArgs,
  GetDivAccResponse,
  GetKycRiskScoreArgs,
  GetKycRiskScoreResponse,
  GetHistoryDataParams,
  GetHistoryDataResponse,
  GetDashboardInfoParams,
  GetDashboardInfoResponse,
  GetFundWarningListArgs,
  GetFundWarningListResponse,
  SaveFundWarningArgs,
  SaveFundWarningResponse,
  GetStatementListResponse
} from './../model/open-data';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import { GetBucAccArgs, GetBucAccResponse } from '../model/open-data';
import { GetRspDayListArgs, GetRspDayListResponse } from '../model/rsp';

@Injectable({
  providedIn: 'root',
})
export class OpenDataService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/OpenData`;
  constructor(http: HttpClient) {
    super(http);
  }

  GetBucAcc(
    request: GetBucAccArgs
  ): Observable<ResponseBase<GetBucAccResponse>> {
    const url = `${this.baseUrl}/GetBucAcc`;
    return this.http.post<ResponseBase<GetBucAccResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetDivAcc(
    request: GetDivAccArgs
  ): Observable<ResponseBase<GetDivAccResponse>> {
    const url = `${this.baseUrl}/GetDivAcc`;
    return this.http.post<ResponseBase<GetDivAccResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetDivAcc2(
    request: GetDivAccArgs
  ): Observable<ResponseBase<GetDivAccResponse>> {
    const url = `${this.baseUrl}/GetDivAcc2`;
    return this.http.post<ResponseBase<GetDivAccResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetFeeRate(
    request: GetFeeRateArgs
  ): Observable<ResponseBase<GetFeeRateResponse>> {
    const url = `${this.baseUrl}/GetFeeRate`;
    return this.http.post<ResponseBase<GetFeeRateResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetFundList(
    request: GetFundListArgs
  ): Observable<ResponseBase<GetFundListResponse>> {
    const url = `${this.baseUrl}/GetFundList`;
    return this.http.post<ResponseBase<GetFundListResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetRspDayList(
    request: GetRspDayListArgs
  ): Observable<ResponseBase<GetRspDayListResponse>> {
    const url = `${this.baseUrl}/GetRspDayList`;
    return this.http.post<ResponseBase<GetRspDayListResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetBestFeeRate(
    request: GetFeeRateArgs
  ): Observable<ResponseBase<GetFeeRateResponse>> {
    const url = `${this.baseUrl}/GetBestFeeRate`;
    return this.http.post<ResponseBase<GetFeeRateResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetKycRiskScore(
    request: GetKycRiskScoreArgs
  ): Observable<ResponseBase<GetKycRiskScoreResponse>> {
    const url = `${this.baseUrl}/GetKycRiskScore`;
    return this.http.post<ResponseBase<GetKycRiskScoreResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetHistoryData(
    request: GetHistoryDataParams
  ): Observable<ResponseBase<GetHistoryDataResponse>> {
    const url = `${this.baseUrl}/GetHistoryData`;
    return this.http.post<ResponseBase<GetHistoryDataResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetDashboardInfo(
    request: GetDashboardInfoParams
  ): Observable<ResponseBase<GetDashboardInfoResponse>> {
    const url = `${this.baseUrl}/GetDashboardInfo`;
    return this.http.post<ResponseBase<GetDashboardInfoResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  GetFundWarningList(
    request: GetFundWarningListArgs
  ): Observable<ResponseBase<GetFundWarningListResponse>> {
    const url = `${this.baseUrl}/GetFundWarningList`;
    return this.http.post<ResponseBase<GetFundWarningListResponse>>(
      url,
      request,
      this.httpOptions
    );
  }

  SaveFundWarning(request: SaveFundWarningArgs): Observable<ResponseBase<SaveFundWarningResponse>> {
    const url = `${this.baseUrl}/SaveFundWarning`;
    return this.http.post<ResponseBase<SaveFundWarningResponse>>(
      url,
      request,
      this.httpOptions);
  }

  GetStatement(): Observable<ResponseBase<GetStatementListResponse>> {
    const url = `${this.baseUrl}/GetStatement`;
    return this.http.post<ResponseBase<GetStatementListResponse>>(
      url,
      this.httpOptions);
  }

}
