import { Component, OnInit } from '@angular/core';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { EstablishAccountSearchArgs, EstablishAccountSearchResponse } from 'src/app/model/member';
import { MemberService } from 'src/app/services/member.service';
import { MessageService } from 'src/app/services/message.service';
import { BaseComponent } from '../base/baseComponent';

@Component({
  selector: 'app-establish-account-search',
  templateUrl: './establish-account-search.component.html',
  styleUrls: ['./establish-account-search.component.css']
})
export class EstablishAccountSearchComponent extends BaseComponent implements OnInit {


  EstablishAccountSearchArgs = new EstablishAccountSearchArgs();
  EstablishAccountSearchResponse = new EstablishAccountSearchResponse();


  isSearched = false;

  constructor(
    private _MemberService: MemberService,
    protected allow: AllowHelper,
    private message: MessageService,
  ) {
    super(allow);

    if (localStorage.getItem('page') != '開戶進度查詢') {
      // debugger
      localStorage.setItem('page', '開戶進度查詢');
      location.reload()
    }
  }

  ngOnInit(): void {

  }

  establishAccountSearch() {
    this._MemberService.EstablishAccountSearch(this.EstablishAccountSearchArgs).subscribe(res => {
      this.EstablishAccountSearchResponse = res.Entries;
      if (res.StatusCode === EnumStatusCode.Success) {
        this.isSearched = true;
      }
      else {
        this.EstablishAccountSearchArgs.AccountId = '';
        this.EstablishAccountSearchArgs.SearchPass = '';
      }
    });
  }

  strName(v: string) {
    if (v.length > 2) {
      return v.substring(0, 1) + '*' + v.substring(2);
    }
    else {
      return v.substring(0, 1) + '*';
    }
  };

  strID(v: String) {
    return v.substring(0, 4) + '***' + v.substring(7, 10);
  };
}
