import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { PageParam } from 'src/app/model/member';
import { GetKycRiskScoreArgs, GetKycRiskScoreResponse } from 'src/app/model/open-data';
import { GetKycQuestionArgs, GetKycQuestionResponse, KYCQuestionDtl, KYCQuestionList, PostKycQuestionArgs, PostKycQuestionResponse } from 'src/app/model/personal';
import { MemberService } from 'src/app/services/member.service';
import { MessageService } from 'src/app/services/message.service';
import { OpenDataService } from 'src/app/services/open-data.service';
import { PersonalService } from 'src/app/services/personal';
import { BaseComponent } from '../base/baseComponent';
import { SharedObservable } from '../shared/shared.observable';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})
export class KycComponent extends BaseComponent implements OnInit {

  GetKycQuestionArgs = new GetKycQuestionArgs();
  GetKycQuestionResponse = new GetKycQuestionResponse();

  GetKycRiskScoreArgs = new GetKycRiskScoreArgs();
  GetKycRiskScoreResponse = new GetKycRiskScoreResponse();

  PostKycQuestionArgs = new PostKycQuestionArgs();
  PostKycQuestionResponse = new PostKycQuestionResponse();
  conceal: string = 'conceal';
  passwordType: string = 'password';

  pageparam = new PageParam();

  constructor(
    private _personalService: PersonalService,
    private _openDataService: OpenDataService,
    protected allow: AllowHelper,
    private message: MessageService,
    private _MemberService: MemberService,
    private share: SharedObservable,
    private router: Router
  ) {
    super(allow);
  }

  ngOnInit(): void {
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 12;
    this.pageparam.Page = '投資適性分析表';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.getKycQuestion();
      }
      else {
        return
      }
    })
  }

  getKycQuestion() {
    this._personalService.GetKycQuestion(this.GetKycQuestionArgs).subscribe(res => {

      for (let index = 0; index < res.Entries.KYCQuestionList.length; index++) {
        const e = res.Entries.KYCQuestionList[index];
        if (e.IS_ALLOW_MULIT == 'N') {
          var filter = e.KYCQuestionDtl.filter(x => x.LAST_IS_CHK_YN == 'Y');
          if (filter.length > 0) {
            e.KYCQuestionResult = filter[0].ANS_SEQNO;
          }
        }

        if (e.IS_ALLOW_MULIT == 'Y') {
          e.KYCQuestionDtl.forEach(e2 => {
            e2.ANS_SEQNO_SELECTED = e2.LAST_IS_CHK_YN == 'Y'
          });
        }
      }

      this.GetKycQuestionResponse = res.Entries;

      //2021/11/30新增 讓Display至少執行一次
      var x = this.GetKycQuestionResponse.KYCQuestionList.filter(x => x.QSN_ORDER.startsWith('A'));

      // 如果題目的IS_FREE_INPUT有被指定成'Y'則把res LAST_ANS_TEXT_IN 透過迴圈 尋找對應的問題 指定給 ANS_TEXT_IN
      x.forEach(data => {

        this.Display(data, data.KYCQuestionResult);

        data.KYCQuestionDtl.forEach(data2 => {
          if (data2.IS_FREE_INPUT == 'Y' && data2.LAST_ANS_TEXT_IN !== null && data2.LAST_ANS_TEXT_IN !== undefined && data2.LAST_ANS_TEXT_IN !== '') {
            data.IS_FREE_INPUT = 'Y';
            data.ANS_TEXT_IN = data2.LAST_ANS_TEXT_IN;
            console.log(data.ANS_TEXT_IN);
          }
          else {
            data.IS_FREE_INPUT = 'N';
          }
        });
      });

      for (let index = 0; index < x.length; index++) {
        this.GetKycQuestionResponse.KYCQuestionList[index] = x[index]
      }

      // console.log(this.GetKycQuestionResponse.KYCQuestionList)
    });
  }

  getKYCQuestionList(QSN_ORDER_TYPE: string) {
    if (this.GetKycQuestionResponse && this.GetKycQuestionResponse.KYCQuestionList) {

      return this.GetKycQuestionResponse.KYCQuestionList.filter(x => x.QSN_ORDER.startsWith(QSN_ORDER_TYPE))
    }
    else {
      return [] as KYCQuestionList[];
    }
  }

  verify(): boolean {

    let result = true;

    for (let index = 0; index < this.GetKycQuestionResponse.KYCQuestionList.length; index++) {
      const e = this.GetKycQuestionResponse.KYCQuestionList[index];

      if (e.IS_ALLOW_MULIT == 'N' && !e.KYCQuestionResult) {
        result = false;
        this.message.showErrorMSG(`請選擇 ${e.KYC_QUESTION}`)
        break;
      }

      if (e.IS_ALLOW_MULIT == 'Y') {
        let checkbox = false;

        for (let index2 = 0; index2 < e.KYCQuestionDtl.length; index2++) {
          const e2 = e.KYCQuestionDtl[index2];
          if (e2.ANS_SEQNO_SELECTED) {
            checkbox = true;
          }
        }

        if (checkbox == false) {
          result = false;
          this.message.showErrorMSG(`請選擇 ${e.KYC_QUESTION}`)
          break;
        }
      }
    }

    return result;
  }

  getKycRiskScore() {
    if (this.verify() == false) {
      return;
    }

    let sum = 0;
    //計算分數
    this.GetKycQuestionResponse.KYCQuestionList.forEach(e => {

      let data;
      if (e.IS_ALLOW_MULIT == 'N') {
        //找到detail的分數
        data = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO == e.KYCQuestionResult)
      }

      if (e.IS_ALLOW_MULIT == 'Y') {
        data = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO_SELECTED);
      }

      let ANS_SCORE;

      if (data && data.length > 0) {
        //有選
        ANS_SCORE = Math.max.apply(Math, data.map(function (o) { return o.ANS_SCORE; }))
      }
      else {
        //沒選
        ANS_SCORE = Math.min.apply(Math, e.KYCQuestionDtl.map(function (o) { return o.ANS_SCORE; }))
      }

      sum += ANS_SCORE;
    });

    this.GetKycRiskScoreArgs.ANS_SCORE = sum;
    this.GetKycRiskScoreArgs.VERSION_NO = this.GetKycQuestionResponse.KYCQuestionList[0].VERSION_NO;

    this._openDataService.GetKycRiskScore(this.GetKycRiskScoreArgs).subscribe(res => {
      this.GetKycRiskScoreResponse = res.Entries;
    });

  }

  postKycQuestion() {
    this.PostKycQuestionArgs.VERSION_NO = this.GetKycRiskScoreArgs.VERSION_NO;
    this.PostKycQuestionArgs.KYC_TOTAL_SCORE = this.GetKycRiskScoreArgs.ANS_SCORE;
    this.PostKycQuestionArgs.KYC_RISK_ATTR = this.GetKycRiskScoreResponse.KYC_RISK_ATTR;

    this.PostKycQuestionArgs.CusKycDetail = [];

    // 檢查必填欄位是否都有填寫
    var check = true;

    var row = 1;
    for (let index = 0; index < this.GetKycQuestionResponse.KYCQuestionList.length; index++) {
      const e = this.GetKycQuestionResponse.KYCQuestionList[index];


      //get checked
      let checkedList = [] as KYCQuestionDtl[];
      if (e.IS_ALLOW_MULIT == 'N') {
        //找到detail的分數
        checkedList = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO == e.KYCQuestionResult)
      }

      if (e.IS_ALLOW_MULIT == 'Y') {
        checkedList = e.KYCQuestionDtl.filter(x => x.ANS_SEQNO_SELECTED);
      }

      let ANS_KEY_IN_ARRAY = [] as any[];
      checkedList.forEach(checked => {
        ANS_KEY_IN_ARRAY.push(checked.ANS_ORDER);
      });



      e.KYCQuestionDtl.forEach(data => {

        let CusKycDetail = {} as any;
        CusKycDetail.QSN_SEQNO = e.QSN_SEQNO;
        CusKycDetail.QSN_ORDER = e.QSN_ORDER;
        CusKycDetail.KYC_QUESTION = e.KYC_QUESTION;
        CusKycDetail.ANS_SEQNO = data.ANS_SEQNO;
        CusKycDetail.ANS_ORDER = data.ANS_ORDER;
        CusKycDetail.ANS_ITEM = data.ANS_ITEM;
        CusKycDetail.ANS_SCORE = data.ANS_SCORE;

        if (e.IS_ALLOW_MULIT == 'N') {
          //找到detail的分數
          if (data.ANS_SEQNO == e.KYCQuestionResult) {
            CusKycDetail.IS_CHK_YN = "Y";
          }
          else {
            CusKycDetail.IS_CHK_YN = "N";
          }
        }

        if (e.IS_ALLOW_MULIT == 'Y') {
          if (data.ANS_SEQNO_SELECTED) {
            CusKycDetail.IS_CHK_YN = "Y";
          }
          else {
            CusKycDetail.IS_CHK_YN = "N";
          }
        }

        CusKycDetail.ANS_KEY_IN = ANS_KEY_IN_ARRAY.join(',');

        if (e.IS_FREE_INPUT == 'Y') {
          if ((e.ANS_TEXT_IN === '' || e.ANS_TEXT_IN === undefined || e.ANS_TEXT_IN === null) && data.IS_FREE_INPUT == 'Y') {
            alert(`請填寫 ${e.KYC_QUESTION}`)
            check = false;
          }
          else {
            // if (data.IS_FREE_INPUT == 'Y') {
              CusKycDetail.ANS_TEXT_IN = e.ANS_TEXT_IN;
            // }
            // else {
            //   CusKycDetail.ANS_TEXT_IN = '';
            // }
          }
        }
        else {
          CusKycDetail.ANS_TEXT_IN = '';
        }

        // CusKycDetail.ANS_TEXT_IN = '';
        CusKycDetail.IS_FREE_INPUT = data.IS_FREE_INPUT;
        CusKycDetail.ROW = row++;
        this.PostKycQuestionArgs.CusKycDetail.push(CusKycDetail);
      });
    }

    if (check) {
      this._personalService.PostKycQuestion(this.PostKycQuestionArgs).subscribe(res => {
        this.PostKycQuestionResponse = res.Entries;
        if (res.StatusCode === EnumStatusCode.Success) {
          this.message.showSucessMSG(res.Message);
          localStorage.setItem('token', res.Entries.token);
          this.share.SetToken(res.Entries.token);
          location.reload();
        }
      });
    } else {
      return;
    }

  }
  onClickConceal() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.conceal = 'conceal yincang';
    } else {
      this.passwordType = 'password';
      this.conceal = 'conceal';
    }
  }

  Display(item: KYCQuestionList, value: number) {

    item.IS_FREE_INPUT = 'N'

    item.KYCQuestionDtl.forEach(data => {
      if (data.ANS_SEQNO == value) {
        item.IS_FREE_INPUT = data.IS_FREE_INPUT;
      }
    });

    if (item.IS_FREE_INPUT == 'Y') {
      item.Display = true;
    }
    else {
      item.Display = false;
    }
  }
}
