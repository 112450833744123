import {
  GetMemberVerificationResponse,
  SaveMemberVerificationArgs,
  SaveMemberVerificationResponse,
} from './../../model/member';
import { Component, OnInit } from '@angular/core';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { GetMemberVerificationArgs } from 'src/app/model/member';
import { MemberService } from 'src/app/services/member.service';
import { MessageService } from 'src/app/services/message.service';
import { BaseComponent } from '../base/baseComponent';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';

@Component({
  selector: 'app-manage-member-verification',
  templateUrl: './manage-member-verification.component.html',
  styleUrls: ['./manage-member-verification.component.css'],
})
export class ManageMemberVerificationComponent
  extends BaseComponent
  implements OnInit
{
  GetMemberVerificationResponse = new GetMemberVerificationResponse();
  SaveMemberVerificationResponse = new SaveMemberVerificationResponse();

  constructor(
    protected allow: AllowHelper,
    private _MemberService: MemberService,
    private message: MessageService
  ) {
    super(allow);
  }

  ngOnInit(): void {
    this.GetMemberVerification({});
  }

  GetMemberVerification(arg: GetMemberVerificationArgs) {
    this._MemberService.GetMemberVerification(arg).subscribe((res) => {
      this.GetMemberVerificationResponse = res.Entries;
    });
  }

  SaveMemberVerification() {
    var arg = new SaveMemberVerificationArgs();
    arg.IsVerification = this.GetMemberVerificationResponse.IsVerification;
    this._MemberService.SaveMemberVerification(arg).subscribe((res) => {
      if (res.StatusCode === EnumStatusCode.Success) {
        this.message.showSucessMSG('執行成功');
      }
    });
  }
}
