<div class="Block-content">

  <!--A區-->
  <div class="title-02">基本資料</div>
  <div class="basic-data-table">
    <div class="" style="width: 50%;" *ngFor="let item of getKYCQuestionList('A'); index as i; first as isFirst">
      <!-- 上面不要用 .w-50 flex會自適應 -->
      <div class="basic-left" style="height: 70px;">{{item.KYC_QUESTION}}</div>
      <div class="basic-right">
        <div class="SelectStyle2 w-80">
          <select (change)="Display(item, item.KYCQuestionResult)" [(ngModel)]="item.KYCQuestionResult">
            <option [ngValue]="undefined">請選擇</option>
            <option *ngFor="let dtl of item.KYCQuestionDtl" [ngValue]="dtl.ANS_SEQNO">
              {{dtl.ANS_ITEM}}
            </option>
          </select>
        </div>
        <div class="w-80" *ngIf="item.IS_FREE_INPUT == 'Y'">
          <br>
          <input nbInput [maxlength]="100" [(ngModel)]="item.ANS_TEXT_IN">
        </div>
        <div *ngIf="item.Display" class="text-red" style="margin-top: 10px;">* 此欄位為必填</div>
      </div>
    </div>
  </div>

  <!--B區-->
  <div class="title-02">風險評估</div>
  <div class="basic-data-table kyc">
    <div class="w-100" *ngFor="let item of getKYCQuestionList('B'); index as i; first as isFirst">
      <div class="basic-left">{{i+1}}.{{item.KYC_QUESTION}}</div>
      <div class="basic-right">


        <div class="kyc-box">
          <!--多選-->
          <ng-container *ngIf="item.IS_ALLOW_MULIT=='Y'">
            <div class="checkbox-wrap" *ngFor="let dtl of item.KYCQuestionDtl">
              <input id="{{dtl.ANS_SEQNO}}" type="checkbox" name="{{item.QSN_SEQNO}}" [value]="dtl.ANS_SEQNO"
                [(ngModel)]="dtl.ANS_SEQNO_SELECTED">
              <label for="{{dtl.ANS_SEQNO}}"></label><samp>{{dtl.ANS_ITEM}}</samp>
            </div>
          </ng-container>

          <!--單選-->
          <ng-container *ngIf="item.IS_ALLOW_MULIT=='N'">
            <div class="clear" *ngFor="let dtl of item.KYCQuestionDtl">
              <input id="{{dtl.ANS_SEQNO}}" type="radio" name="{{item.QSN_SEQNO}}" [value]="dtl.ANS_SEQNO" class="form"
                [(ngModel)]="item.KYCQuestionResult">
              <label for="{{dtl.ANS_SEQNO}}" class="form">{{dtl.ANS_ITEM}}</label>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="button-box">
    <a href="javascript:void(0)" (click)="getKycRiskScore()" class="Main-button">風險評估<i
        class="fas fa-chevron-right"></i></a>
  </div>


  <ng-container *ngIf="GetKycRiskScoreResponse.KYC_RISK_ATTR">
    <div class="Risk-score">
      <ul>
        <li>
          <p>得分</p><samp>{{GetKycRiskScoreArgs.ANS_SCORE}}</samp>
        </li>
        <li>
          <p>風險屬性類型為</p><span>{{GetKycRiskScoreResponse.KYC_RISK_ATTR_NM}}</span>
        </li>
      </ul>
    </div>

    <div class="Scoring-method">
      <p>※計分方式：若有未填選項者，以｢較低分數選項｣計分，若為複選、單選重複勾選者，則以｢最高分數選項｣計分。</p>
      <ul>
        <li><span>21-30分</span>適合積極型基金</li>
        <li><span>11-20分</span>適合穩健型基金</li>
        <li><span>1-10分</span>適合保守型基金</li>
        <li><span>0分</span>適合不適用基金</li>
      </ul>
    </div>

    <div class="basic-data-table transaction-password">
      <div class="w-100">
        <div class="basic-left">交易密碼</div>
        <div class="basic-right">
          <input style="float: left" class="input-25" [type]="passwordType" [(ngModel)]="PostKycQuestionArgs.Password">
          <div [class]="conceal" (click)="onClickConceal()"></div>
        </div>
      </div>
    </div>


    <div class="button-box">
      <a href="javascript:void(0)" (click)="postKycQuestion()" class="Main-button">確認<i
          class="fas fa-chevron-right"></i></a>
    </div>
  </ng-container>

  <div class="note-box">
    <h4 class="text-red">重要聲明</h4>
    <ol class="note-list">
      <li>風險屬性分析之評估結果，係依開戶當時狀況填寫相關資料得知，該評估結果之風險屬性類型將做為您未來每次投資本公司系列基金之參考依據。</li>
      <li>若您不認同上述風險屬性評估之結果，請重作風險屬性評估。</li>
    </ol>
  </div>

</div>
