export class GetDashboardInfoArgs {
  BAL_DATE: Date; // 結餘截止日期
}

export class TABalanceDetailList {
  FUND_ID: string;
  FUND_NAME: string;
  TRADE_TYPE: string;
  BAL_UNIT: number;
  BAL_COST: number;
  AVG_COST: number;
  NAV_DATE: Date;
  NAV_B: number;
  MARKET_AMT: number;
  UNRL_AMT: number;
  RETURN_RATE: number;
  NAV_DEC: number;
  UNIT_DEC: number;
  DEC_LEN: number;
}

export class BalanceSumList {
  FUND_ID: string;
  FUND_NAME: string;
  NAV_DATE: Date;
  NAV_B: number;
  BAL_UNIT: number;
  BAL_COST: number;
  AVG_COST: number;
  MARKET_AMT: number;
  UNRL_AMT: number;
  RETURN_RATE: number;
  NAV_DEC: number;
  UNIT_DEC: number;
  DEC_LEN: number;
}

export class FundDataList {
  FUND_ID: string;
  FUND_NAME: string;
  FUND_VAL: number;
}

export class GetDashboardInfoResponse {
  TABalanceDetailList: TABalanceDetailList[];
  BalanceSumList: BalanceSumList[];
  FundDataList: FundDataList[];
}
