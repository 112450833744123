import { Component, OnInit } from '@angular/core';
import { SaveTransferSubscriptionResponse, SaveTransferSubscriptionArgs, CheckTransferSubscriptionArgs, GetSwitchFundListResponse, InventoryList, SwitchFundList, GetRedemFundDataResponse, GetRedemFundDataArgs, GetAllowRedemDataArgs, GetSwitchFundListArgs, GetAllowRedemDataResponse } from './../../model/transfersubscription';
import { OpenDataService } from 'src/app/services/open-data.service';
import { JwtPayload } from 'src/app/model/base';
import { transfersubscriptionService } from 'src/app/services/transfersubscription.service';
import { MessageService } from '../../services/message.service';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { ValidationHelper } from 'src/app/helper/validationHelper';
import { MemberService } from 'src/app/services/member.service';
import { PageParam } from 'src/app/model/member';
import { FundDetail, GetDivAccArgs, GetDivAccResponse, GetFeeRateArgs, GetFeeRateResponse, RegularSavingPlanModel } from 'src/app/model/open-data';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transfersubscription',
  templateUrl: './transfersubscription.component.html',
  styleUrls: ['./transfersubscription.component.css']
})
export class TransfersubscriptionComponent implements OnInit {
  jwt = {} as JwtPayload;
  GetRedemFundDataArgs = new GetRedemFundDataArgs();
  GetAllowRedemDataArgs = new GetAllowRedemDataArgs();
  GetSwitchFundListArgs = new GetSwitchFundListArgs();
  GetRedemFundDataResponse = new GetRedemFundDataResponse();
  GetAllowRedemDataResponse = new GetAllowRedemDataResponse();
  GetSwitchFundListResponse = new GetSwitchFundListResponse();
  RegularSavingPlanModel = new RegularSavingPlanModel();
  GetDivAccResponse = new GetDivAccResponse();

  SaveTransferSubscriptionResponse = new SaveTransferSubscriptionResponse();
  SaveTransferSubscriptionArgs = new SaveTransferSubscriptionArgs();
  CheckTransferSubscriptionArgs = new CheckTransferSubscriptionArgs();
  GetFeeRateArgs = new GetFeeRateArgs();
  GetFeeRateResponse = new GetFeeRateResponse();

  InventoryList = new InventoryList();
  SwitchFundList = new SwitchFundList();

  isSelected: boolean;

  selectedFund: FundDetail;
  selectedFundValue: string = "";
  selectedFundSwitchValue: string = "";
  PASSWORD: string = "";

  HasDivAcc: boolean = true;
  REDEM_UNIT: number = 0;
  pagecontact: number = 1;
  feeNAME: string = '';
  isBestFee: boolean = false;
  feerate: number;

  passwordType: string = 'password';
  conceal: string = 'conceal';

  AFEE_TYPE = '1';

  pageparam = new PageParam();
  canBuy: boolean = true;

  constructor(
    private valid: ValidationHelper,
    private message: MessageService,
    private _OpenDataService: OpenDataService,
    private router: Router,
    private _transfersubscriptionService: transfersubscriptionService,
    private _MemberService: MemberService,
    private openDataService: OpenDataService
  ) { }

  ngOnInit(): void {
    this.jwt = jwt_decode(localStorage.getItem('token') || '');
    // if (this.jwt.KYC_STATUS != 'Y') {
    //   if (confirm('您的kyc已過期，無法進行交易，是否導頁到kyc頁填寫?')) {
    //     this.router.navigateByUrl('kyc');
    //   }
    // }
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 7;
    this.pageparam.Page = '基金轉申購';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });
    })

    promise.then((value: any) => {
      if (value == 0) {
        this.getFundList();
        // this.jwt = jwt_decode(localStorage.getItem('token') || '');
      }
      else {
        return
      }
    })
  }

  // 檢核申購基金是否符合個人風險屬性
  checkTypeOfRisk(kycRisk: string, fundRiskInt: number) {
    const kycRiskInt = parseInt(kycRisk, 10);
    switch (kycRisk) {
      case '1': // 保守型
        this.canBuy = kycRiskInt >= fundRiskInt;
        break;
      case '2': // 穩健型
        this.canBuy = kycRiskInt + 1 >= fundRiskInt;
        break;
      case '3': // 積極型
        this.canBuy = kycRiskInt + 2 >= fundRiskInt;
        break;
    }
  }

  //持有基金
  getFundList() {
    this._transfersubscriptionService.GetRedemFundData().subscribe(res => {
      this.GetRedemFundDataResponse = res.Entries;
    });
  }

  //持有基金資料
  selectFund(e: any, n: any) {
    this.selectedFundSwitchValue = '';

    if (e.value == '') {
      this.isSelected = false;
    }
    else {
      this.isSelected = true;
    }

    if (e.value != '') {
      this.InventoryList = this.GetRedemFundDataResponse.InventoryList.filter(x => x.FUND_ID == e.value)[0];
      this.InventoryList.BAL_UNIT = parseFloat(this.InventoryList.BAL_UNIT.toFixed(this.InventoryList.UNIT_DEC));
      this.InventoryList.R_UNIT = parseFloat(this.InventoryList.R_UNIT.toFixed(this.InventoryList.UNIT_DEC));
      this.GetSwitchFundListArgs.FUND_ID = e.value;
      this.GetSwitchFundListArgs.FUND_NAME = this.InventoryList.FUND_NAME;
      this.AFEE_TYPE = this.InventoryList.AFEE_TYPE;

      this._transfersubscriptionService.GetSwitchFundList(this.GetSwitchFundListArgs).subscribe(res => {
        this.GetSwitchFundListResponse = res.Entries;
        this.GetSwitchFundListResponse.SwitchFundList
          = this.GetSwitchFundListResponse.SwitchFundList.filter(x => x.SWITCH_FUND_ID !== this.InventoryList.FUND_ID);
        //資料
        this.GetAllowRedemDataArgs.FUND_ID = e.value;

        // this._transfersubscriptionService.GetAllowRedemData(this.GetAllowRedemDataArgs).subscribe(res => {
        //   this.GetAllowRedemDataResponse = res.Entries;
        // });
      });

      this.GetDivAcc({ CRNCY_CD: this.InventoryList.FUND_CURRENCY, FUND_ID: this.InventoryList.FUND_ID });
    }

  }

  //可轉換的基金
  selecFundSwitch(e: any) {
    if (this.REDEM_UNIT == 0) {
      // debugger
      e.selectedIndex = 0;
      // console.log(e)
      alert('請輸入買回單位數');
    }
    if (/[^\d|^\.]/.test(this.REDEM_UNIT.toString())) {
      alert('輸入格式不可包含數字及小數點以外的文字');
    }
    else {
      this.selectedFundSwitchValue = e.value;

      this.SwitchFundList = this.GetSwitchFundListResponse.SwitchFundList.filter(x => x.SWITCH_FUND_ID == e.value)[0];
      //console.log("轉換SwitchFundList", this.SwitchFundList);

      if (this.SwitchFundList.DIVIDEND_TYPE != '4') {
        if (!this.HasDivAcc) {
          // debugger
          alert('您目前無配息帳號，請至臨櫃申請');
          this.selectedFundSwitchValue = '';
          return;
        }
      }

      this.checkTypeOfRisk(
        this.jwt.KYC_RISK_ATTR,
        parseInt(this.SwitchFundList.SWITCH_FUND_RISK, 10)
      );

      //這邊取 this.SwitchFundList 向api發出getFeeList or GetBestfee 回傳的值*100 後顯示在 手續費率上
      //要請柏輝開欄位 讓我們把手續費率也一併拋過去 ##資料庫 tblregularlytransaction 要新增手續費率欄位
      this.RedemData();
    }
  }

  //持有基金資料明細
  RedemData() {
    // debugger
    if (this.REDEM_UNIT.toString().length > 10) {
      alert('超出最大贖回位數(10位)，請重新輸入');
    }
    else {
      if (this.REDEM_UNIT != null) {
        if (this.REDEM_UNIT > 0) {
          if (this.REDEM_UNIT <= this.InventoryList.R_UNIT) {
            this.GetAllowRedemDataArgs.REDEM_UNIT = this.REDEM_UNIT;
            this.GetAllowRedemDataArgs.SWITCH_FUND_ID = this.SwitchFundList.SWITCH_FUND_ID ?? ' ';

            this._transfersubscriptionService.GetAllowRedemData(this.GetAllowRedemDataArgs).subscribe(res => {
              this.GetAllowRedemDataResponse = res.Entries;
              this.GetAllowRedemDataResponse.REDEM_FEE = parseFloat(this.GetAllowRedemDataResponse.REDEM_FEE.toFixed(this.GetAllowRedemDataResponse.DEC_LEN));
              this.GetAllowRedemDataResponse.F_NAV = parseFloat(this.GetAllowRedemDataResponse.F_NAV.toFixed(this.GetAllowRedemDataResponse.NAV_DEC));
              this.GetAllowRedemDataResponse.SHORT_FEE = parseFloat(this.GetAllowRedemDataResponse.SHORT_FEE.toFixed(this.GetAllowRedemDataResponse.DEC_LEN));
              this.GetAllowRedemDataResponse.TOTAL = parseFloat(this.GetAllowRedemDataResponse.TOTAL.toFixed(this.GetAllowRedemDataResponse.DEC_LEN));
              if (this.GetAllowRedemDataArgs.SWITCH_FUND_ID != ' ') {
                this.getBestFeeRate();
              }
            });
          }
          else {
            alert('超過可買回單位數');
            this.REDEM_UNIT = 0;
            this.RegularSavingPlanModel.FeeRate = 0;
            this.RegularSavingPlanModel.BestFeeRate = 0;
          }
        }
      }
    }
  }

  save1() {
    this.valid.clear();
    if (this.selectedFundValue == '') {
      this.valid.addErrorMessage('請選擇欲贖回基金');
    }

    if (this.selectedFundSwitchValue == '') {
      this.valid.addErrorMessage('請選擇欲轉申購基金');
    }

    if (this.REDEM_UNIT <= 0) {
      this.valid.addErrorMessage('請輸入買回單位數');
    }
    else if (this.REDEM_UNIT > this.InventoryList.R_UNIT) {
      this.valid.addErrorMessage('超過可買回單位數');
    }

    if (this.SwitchFundList.DIVIDEND_TYPE != '4') {
      if (!this.HasDivAcc) {
        // debugger
        alert('您目前無配息帳號，請至臨櫃申請');
        this.selectedFundSwitchValue = '';
        return;
      }
    }
    if (!this.canBuy) {
      this.valid.addErrorMessage('請修改投資適性分析表');
    }
    if (this.valid.errorMessages.length > 0) {
      this.message.showErrorMSGs(this.valid.errorMessages);
    }
    else {
      this.RedemData();
      this.CheckTransferSubscriptionArgs.FUND_ID = this.GetSwitchFundListArgs.FUND_ID;
      this.CheckTransferSubscriptionArgs.SWITCH_FUND_ID = this.selectedFundSwitchValue;
      this.CheckTransferSubscriptionArgs.REDEM_UNIT = this.REDEM_UNIT;
      this.CheckTransferSubscriptionArgs.REDEM_PAY_DATE = this.GetAllowRedemDataResponse.REDEM_PAY_DATE;
      this.CheckTransferSubscriptionArgs.TOTAL = this.GetAllowRedemDataResponse.TOTAL;

      this._transfersubscriptionService.CheckTransferSubscription(this.CheckTransferSubscriptionArgs).subscribe(res => {
        if (res.StatusCode === EnumStatusCode.Success) {
          this.pagecontact = 2;
        }
        else {
          // this.message.showSucessMSG(res.Message);
        }
      });
    }
  }

  save2() {
    this.valid.clear();
    if (this.SaveTransferSubscriptionArgs.ReadInfo == false) {
      this.valid.addErrorMessage('請勾選我已閱讀公開說明書 風險預告書');
    }
    if (this.PASSWORD == '') {
      this.valid.addErrorMessage('請輸入交易密碼');
    }

    if (this.valid.errorMessages.length > 0) {
      this.message.showErrorMSGs(this.valid.errorMessages);
    }
    else {
      this.SaveTransferSubscriptionArgs.PASSWORD = this.PASSWORD;
      this.SaveTransferSubscriptionArgs.FUND_ID = this.selectedFundValue;
      this.SaveTransferSubscriptionArgs.SWITCH_FUND_ID = this.selectedFundSwitchValue;
      this.SaveTransferSubscriptionArgs.REDEM_UNIT = this.REDEM_UNIT;
      this.SaveTransferSubscriptionArgs.FUND_NAME = this.InventoryList.FUND_NAME;
      this.SaveTransferSubscriptionArgs.FUND_CURRENCY = this.InventoryList.FUND_CURRENCY;
      this.SaveTransferSubscriptionArgs.FUND_CURRENCY_NM = this.InventoryList.FUND_CURRENCY_NM;
      this.SaveTransferSubscriptionArgs.SWITCH_FUND_NAME = this.SwitchFundList.SWITCH_FUND_NAME;
      this.SaveTransferSubscriptionArgs.FEE = this.AFEE_TYPE != '2' ? this.feerate : 0; // 送出的手續費率
      this.SaveTransferSubscriptionArgs.FEE_NAME = this.feeNAME; //送出的優惠手續費率名稱
      this.SaveTransferSubscriptionArgs.FEE_CHOICE = this.RegularSavingPlanModel.FEE_CHOICE != '1' ? this.RegularSavingPlanModel.FEE_CHOICE : '1';
      this.SaveTransferSubscriptionArgs.CAMPAIGN_CODE = this.RegularSavingPlanModel.CAMPAIGN_CODE != '' ? this.RegularSavingPlanModel.CAMPAIGN_CODE : '';
      this.SaveTransferSubscriptionArgs.SWITCH_DATE = this.GetAllowRedemDataResponse.SWITCH_DATE;
      this.SaveTransferSubscriptionArgs.REDEM_NAV_DATE = this.GetAllowRedemDataResponse.REDEM_NAV_DATE;
      this.SaveTransferSubscriptionArgs.TOTAL = this.GetAllowRedemDataResponse.TOTAL;
      this.SaveTransferSubscriptionArgs.TOTAL_SHORT_UNIT = this.GetAllowRedemDataResponse.TOTAL_SHORT_UNIT;
      this.SaveTransferSubscriptionArgs.REDEM_PAY_DATE = this.GetAllowRedemDataResponse.REDEM_PAY_DATE;

      this._transfersubscriptionService.SaveTransferSubscription(this.SaveTransferSubscriptionArgs).subscribe(res => {
        if (res.StatusCode === EnumStatusCode.Success) {
          this.pagecontact = 3;
          this.SaveTransferSubscriptionResponse.APPLY_DATE = res.Entries.APPLY_DATE;
          this.SaveTransferSubscriptionResponse.REDEM_PAY_DATE = res.Entries.REDEM_PAY_DATE;
        }
        else {
          // this.message.showSucessMSG(res.Message);
        }
      });
    }
  }

  onClickConceal() {
    if (this.passwordType == 'password') {
      this.passwordType = 'text';
      this.conceal = 'conceal yincang';
    } else {
      this.passwordType = 'password';
      this.conceal = 'conceal';
    }
  }

  // 取得優惠手續費
  getBestFeeRate() {
    this.GetFeeRateArgs.TRADE_TYPE = '3'; // 交易種類, 3：轉申購
    this.GetFeeRateArgs.FUND_ID = this.InventoryList.FUND_ID; // 贖回基金代碼
    this.GetFeeRateArgs.CRNCY_CD = this.SwitchFundList.SWITCH_FUND_CURRENCY; // 交易幣別代碼
    this.GetFeeRateArgs.TRADE_AMT = this.GetAllowRedemDataResponse.TOTAL; // 交易金額
    this.GetFeeRateArgs.TRADE_DATE = this.GetAllowRedemDataResponse.SWITCH_DATE; // 交易日期
    this.GetFeeRateArgs.SWITCH_FUND_ID = this.SwitchFundList.SWITCH_FUND_ID; //轉申購基金代碼

    this.openDataService
      .GetBestFeeRate(this.GetFeeRateArgs)
      .subscribe((res) => {
        if (res.StatusCode === 1) {
          return;
        }
        this.GetFeeRateResponse = res.Entries;

        // 取得牌告費率
        const feeRate = this.GetFeeRateResponse.FeeRateList.filter(
          (x) => x.FEE_CHOICE == '1'
        );
        this.RegularSavingPlanModel.FeeRate = feeRate[0].FEE_RATE;

        this.feerate = feeRate[0].FEE_RATE;

        this.RegularSavingPlanModel.Fee = Number.parseFloat(
          (
            this.RegularSavingPlanModel.ApplyAmount * feeRate[0].FEE_RATE
          ).toFixed(this.InventoryList.DEC_LEN)
        );

        this.RegularSavingPlanModel.FEE_CHOICE = feeRate[0].FEE_CHOICE;
        this.RegularSavingPlanModel.CAMPAIGN_CODE = feeRate[0].CAMPAIGN_CODE;

        this.RegularSavingPlanModel.ApplyFee = this.RegularSavingPlanModel.Fee;

        // 取得最優惠的費率
        const bestFeeRate = this.GetFeeRateResponse.FeeRateList.filter(
          (x) => x.FEE_CHOICE != '1'
        );

        if (bestFeeRate != null && bestFeeRate.length > 0) {
          this.RegularSavingPlanModel.BestFeeRate = bestFeeRate[0].FEE_RATE;

          this.feerate = bestFeeRate[0].FEE_RATE;

          this.RegularSavingPlanModel.BestFee = Number.parseFloat(
            (
              this.RegularSavingPlanModel.ApplyAmount * bestFeeRate[0].FEE_RATE
            ).toFixed(this.InventoryList.DEC_LEN)
          );

          this.RegularSavingPlanModel.ApplyFee =
            this.RegularSavingPlanModel.BestFee;

          this.feeNAME = bestFeeRate[0].FEE_NAME;

          this.RegularSavingPlanModel.CAMPAIGN_CODE =
            bestFeeRate[0].CAMPAIGN_CODE;

          this.RegularSavingPlanModel.FEE_CHOICE = bestFeeRate[0].FEE_CHOICE;

          this.isBestFee = true;
        }
      });
  }

  GetDivAcc(arg: GetDivAccArgs) {

    this.openDataService.GetDivAcc2(arg).subscribe(res => {
      if (res.Entries.DivAccList.length > 0) {
        this.GetDivAccResponse = res.Entries;
        this.HasDivAcc = true;
      }
      else {
        this.HasDivAcc = false;
      }
    });
  }
}
