import { Component, OnInit } from '@angular/core';
import { JwtPayload } from 'src/app/model/base';
import { MenuModel } from 'src/app/model/menu';
import jwt_decode from 'jwt-decode';

declare function loginInitPage1(): any; // just change here from arun answer.
declare function loginInitPage2(): any; // just change here from arun answer.
declare function loginInitPage3(): any; // just change here from arun answer.

@Component({
  selector: 'app-login-navbar',
  templateUrl: './login-navbar.component.html',
  styleUrls: ['./login-navbar.component.css'],
})
export class LoginNavbarComponent implements OnInit {
  showList = false; // nick
  menus = [
    {
      name: '個人帳戶管理',
      class: 'fas fa-user-circle',
      url: '',
      childMenu: [
        {
          name: '變更密碼',
          class: '',
          url: 'ChangePassword',
          checked: false
        },
        {
          name: '修改個人資料',
          class: '',
          url: 'EditMemberProfile',
          checked: false
        },
        {
          name: '投資適性分析表',
          class: '',
          url: 'kyc',
          checked: false
        },
        {
          name: '投資適性分析表歷史紀錄',
          class: '',
          url: 'kyc-history',
          checked: false
        },
        {
          name: '雙因子認證',
          class: '',
          url: 'ManageMemberVerification',
          checked: false
        },
        {
          name: '最新消息',
          class: '',
          url: 'news',
          checked: false
        },
        {
          name: '個人化通知',
          class: '',
          url: 'GetPersonalMsg',
          checked: false
        },
        {
          name: '個人化設定',
          class: '',
          url: 'PersonalizationSettings',
          checked: false
        },
        {
          name: '個人化鎖利設定',
          class: '',
          url: 'PersonalizationLockSettings',
          checked: false
        },
      ],
    },
    {
      name: '基金交易',
      class: 'fas fa-poll',
      url: '',
      childMenu: [
        {
          name: '單筆申購',
          class: '',
          url: 'singletransaction',
          checked: false
        },
        {
          name: '定期定額申購',
          class: '',
          url: 'RegularSavingPlan',
          checked: false
        },
        {
          name: '異動定期定額契約',
          class: '',
          url: 'regular-quota',
          checked: false
        },
        {
          name: '基金買回',
          class: '',
          url: 'fundredemption',
          checked: false
        },
        {
          name: '基金轉申購',
          class: '',
          url: 'transfersubscription',
          checked: false
        },
        {
          name: '取消交易',
          class: '',
          url: 'cancellationtrade',
          checked: false
        },
        {
          name: '電子對帳單查詢',
          class: '',
          url: 'StatementQuery',
          checked: false
        },
      ],
    },
    {
      name: '交易明細查詢',
      class: 'fas fa-money-check-alt',
      url: '',
      childMenu: [
        {
          name: '歷史交易明細',
          class: '',
          url: 'ETradeSearch',
          checked: false
        },
      ],
    },
    {
      name: '個人投資現況',
      class: 'fas fa-hand-holding-usd',
      url: '',
      childMenu: [
        {
          name: '個人投資現況',
          class: '',
          url: 'PersonalInvestment',
          checked: false
        },
      ],
    },
  ] as MenuModel[];

  childList = new MenuModel();

  jwt = {} as JwtPayload;

  constructor() { }

  ngOnInit(): void {

    this.jwt = jwt_decode(localStorage.getItem('token') || "");

    var remove: any;
    if (this.jwt.GloubIsVerification == false) {
      let deleteIndex1 = -1;
      let deleteIndex2 = -1;


      for (let index1 = 0; index1 < this.menus.length; index1++) {
        const element = this.menus[index1];
        for (let index2 = 0; index2 < element.childMenu.length; index2++) {
          const element2 = element.childMenu[index2];
          if (element2.url == 'ManageMemberVerification') {
            deleteIndex1 = index1;
            deleteIndex2 = index2;
          }
        }
      }

      if (deleteIndex1 != -1 && deleteIndex2 != -1) {

        this.menus[deleteIndex1].childMenu.splice(deleteIndex2, 1);
      }
    }
    loginInitPage1();
    loginInitPage2();
    loginInitPage3();
  }

  check(data: any) {
    console.log(data.innerText);
    switch (data.innerText) {
      case '個人帳戶管理':
        this.childList = this.menus[0];
        break;
      case '基金交易':
        this.childList = this.menus[1];
        break;
      case '交易明細查詢':
        this.childList = this.menus[2];
        break;
      case '個人投資現況':
        this.childList = this.menus[3];
        break;
      default:
        return;
    }
  }

  check2(data: any) {
    this.menus.forEach(item => {
      item.childMenu.forEach(item2 => {
        if (item2.checked == true) {
          item2.checked = false;
        }
      })
    })

    this.childList.childMenu.forEach(item => {
      // if (item.checked == true) {
      //   item.checked = false;
      // }
      if (item.name == data.innerText) {
        item.checked = true;
      }
    });
  }

  // nick
  listController(data: any){
    if(data.target.href === 'javascript:void(0)'){
      return;
    }
    this.showList = !this.showList;
  }
}
