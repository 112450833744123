import { Component, DoCheck, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { AllowHelper } from 'src/app/helper/allowHelper';
import { PageParam } from 'src/app/model/member';
import {
  PersonalizationSettingsForLINE,
  PersonalizationSettingsForLINETokenParams,
  PersonalizationSettingsForLINETokenResponse,
  PersonalizationSettingsForRetrieveParams,
  PersonalizationSettingsForRetrieveResponse,
  PersonalizationSettingsForSubmitParams,
} from 'src/app/model/personalization-settings';
import { MemberService } from 'src/app/services/member.service';
import { MessageService } from 'src/app/services/message.service';
import { PersonalizationSettingsService } from 'src/app/services/personalization-settings.service';

@Component({
  selector: 'app-personalization-settings',
  templateUrl: './personalization-settings.component.html',
  styleUrls: ['./personalization-settings.component.css'],
})
export class PersonalizationSettingsComponent implements OnInit {
  [x: string]: any;
  PersonalizationSettingsForRetrieveResponse =
    new PersonalizationSettingsForRetrieveResponse();
  PersonalizationSettingsForSubmitParams =
    new PersonalizationSettingsForSubmitParams();
  PersonalizationSettingsForLINE = new PersonalizationSettingsForLINE();
  PersonalizationSettingsForLINETokenParams =
    new PersonalizationSettingsForLINETokenParams();
  PersonalizationSettingsForLINETokenResponse =
    new PersonalizationSettingsForLINETokenResponse();

  lineLoginUrl: string;

  code = '';
  state = '';
  error = '';
  error_description = '';

  pageparam = new PageParam();

  constructor(
    private personalizationSettingsService: PersonalizationSettingsService,
    private routeInfo: ActivatedRoute,
    protected messageService: MessageService,
    private _MemberService: MemberService
  ) { }

  ngOnInit(): void {
    this.Weblog();
  }

  Weblog() {
    this.pageparam.Value = 16;
    this.pageparam.Page = '個人化設定';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.routeInfo.queryParams.subscribe((params: Params) => {
          this.code = params['code'];
          this.retrievePersonalizationSettings();
          this.GetLine();

          //如果已經有先從資料庫拿到Line ID則不再去向Line API要資料
          if (this.code != undefined) {
            if (this.code != '') {
              this.retrieveLINEToken();
            }
          }
        });
        // this.retrievePersonalizationSettings();
      }
      else {
        return
      }
    })
  }

  GetLine() {
    this._MemberService.GetLine().subscribe(res => {
      this.PersonalizationSettingsForLINE.redirectUri = res.redirectUri;
      this.PersonalizationSettingsForLINE.clientId = res.clientId;
      this.PersonalizationSettingsForLINE.clientSecret = res.clientSecret;
      this.PersonalizationSettingsForLINE.accessUrl = res.accessUrl;
      this.PersonalizationSettingsForLINE.scope = res.scope;
      this.PersonalizationSettingsForLINE.state = res.state;
      this.PersonalizationSettingsForLINE.tokenUrl = res.tokenUrl;

      this.lineLoginUrl =
        this.PersonalizationSettingsForLINE.accessUrl +
        'client_id=' +
        this.PersonalizationSettingsForLINE.clientId +
        '&redirect_uri=' +
        this.PersonalizationSettingsForLINE.redirectUri +
        '&state=' +
        this.PersonalizationSettingsForLINE.state +
        '&scope=' +
        this.PersonalizationSettingsForLINE.scope

    });
  }


  retrievePersonalizationSettings() {
    this.personalizationSettingsService
      .RetrievePersonalizationSettings(
        new PersonalizationSettingsForRetrieveParams()
      )
      .subscribe((response) => {
        this.PersonalizationSettingsForRetrieveResponse = response.Entries;

        this.PersonalizationSettingsForSubmitParams.CBfNo =
          this.PersonalizationSettingsForRetrieveResponse.CBfNo;

        this.PersonalizationSettingsForSubmitParams.CChangeDate =
          this.PersonalizationSettingsForRetrieveResponse.CChangeDate;

        this.PersonalizationSettingsForSubmitParams.CLineBinding =
          this.PersonalizationSettingsForRetrieveResponse.CLineBinding;

        this.PersonalizationSettingsForSubmitParams.CLineId =
          this.PersonalizationSettingsForRetrieveResponse.CLineId;

        this.PersonalizationSettingsForSubmitParams.CMemberNotify =
          this.PersonalizationSettingsForRetrieveResponse.CMemberNotify;

        this.PersonalizationSettingsForSubmitParams.CNewEmail =
          this.PersonalizationSettingsForRetrieveResponse.CNewEmail;

        this.PersonalizationSettingsForSubmitParams.CNewLine =
          this.PersonalizationSettingsForRetrieveResponse.CNewLine;

        this.PersonalizationSettingsForSubmitParams.CPersonalEmail =
          this.PersonalizationSettingsForRetrieveResponse.CPersonalEmail;

        this.PersonalizationSettingsForSubmitParams.CPersonalLine =
          this.PersonalizationSettingsForRetrieveResponse.CPersonalLine;

        this.PersonalizationSettingsForSubmitParams.CTradeEmail =
          this.PersonalizationSettingsForRetrieveResponse.CTradeEmail;

        this.PersonalizationSettingsForSubmitParams.CTradeLine =
          this.PersonalizationSettingsForRetrieveResponse.CTradeLine;

        this.PersonalizationSettingsForSubmitParams.CWarningEmail =
          this.PersonalizationSettingsForRetrieveResponse.CWarningEmail;

        this.PersonalizationSettingsForSubmitParams.CWarningLine =
          this.PersonalizationSettingsForRetrieveResponse.CWarningLine;

        this.PersonalizationSettingsForSubmitParams.CAccessToken =
          this.PersonalizationSettingsForRetrieveResponse.CAccessToken;

        console.log(this.PersonalizationSettingsForSubmitParams.CLineId)
      });
  }

  retrieveLINEToken() {
    this.PersonalizationSettingsForLINETokenParams.code = this.code;
    this.personalizationSettingsService
      .RetrieveLINEToken(this.PersonalizationSettingsForLINETokenParams)
      .subscribe((response) => {
        if (response.Entries.lineid != '') {
          this.PersonalizationSettingsForLINETokenResponse = response.Entries;
          this.PersonalizationSettingsForSubmitParams.CLineId =
            this.PersonalizationSettingsForLINETokenResponse.lineid;
          this.PersonalizationSettingsForSubmitParams.CAccessToken =
            this.PersonalizationSettingsForLINETokenResponse.access_token;
          this.PersonalizationSettingsForSubmitParams.CLineBinding = true;

          console.log(
            'Line ID: ' + this.PersonalizationSettingsForSubmitParams.CLineId
          );
          console.log(
            'Line ID: ' + this.PersonalizationSettingsForLINETokenResponse.lineid
          );
          console.log(
            'Line Name: ' + this.PersonalizationSettingsForLINETokenResponse.linename
          );
          console.log(
            'Line Token: ' + this.PersonalizationSettingsForLINETokenResponse.access_token
          );
        }
      });
  }

  submitPersonalizationSettings() {
    this.personalizationSettingsService
      .SubmitPersonalizationSettings(
        this.PersonalizationSettingsForSubmitParams
      )
      .subscribe((response) => {
        if (response.StatusCode === EnumStatusCode.Success) {
          this.messageService.showSucessMSG('執行成功');
        }
      });
  }

  RemoveLine() {
    this.PersonalizationSettingsForSubmitParams.CLineBinding = false;
  }
}
