<div class="m-hdr1-sub"><span>雙因子認證</span></div>

<div class="Block-content">

  <div class="basic-data-table">

    <div class="w-100">
      <div class="basic-left">E-Mail</div>
      <div class="basic-right">
        {{GetMemberVerificationResponse.Mail}}
      </div>
    </div>
    <div class="w-100">
      <div class="basic-left">E-Mail認證</div>
      <div class="basic-right">
        <div class="unit-box">
          <div class="clear">
            <input id="VerificationEnable" type="radio" name="Verification" class="form" [(ngModel)]="GetMemberVerificationResponse.IsVerification" [value]="true" >
            <label for="VerificationEnable" class="form">
              啟用
            </label>
          </div>
          <div class="clear">
            <input id="VerificationDisable" type="radio" name="Verification" class="form" [(ngModel)]="GetMemberVerificationResponse.IsVerification" [value]="false">
            <label for="VerificationDisable" class="form">
              不啟用</label>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="button-box">
    <a href="javascript:void(0)" class="Main-button" (click)="SaveMemberVerification()">儲存送出</a>
  </div>

</div>
