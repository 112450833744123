<!-- =============================================多元理財開戶申請書============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title"><b>多　元　理　財　開　戶　申　請　書【自然人】</b></div>
  <div class="td">
    <table class="noBreak">
      <tr>
        <td colspan="6" class="td td-title">
          <u><b>本人填寫前已詳閱開戶說明、基金風險預告書、本開戶書之約定及聲明事項，並同意遵守本開戶書之各項約定。</b></u>
          <br>
          請受益人正確且完整填寫申請書，若有塗改請加蓋原留印鑑；印鑑欄留下受益人原留簽章樣式，不可塗改，用印有誤請重新填寫。填妥此申請書請寄回台中銀投信，本公司恕不受理感光紙辦理。
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">
          受益人名稱
          <br>
          (中文名稱)
        </td>
        <td colspan="2" class="td td-right td-text">{{OpenMemberResponse.BF_NAME}}</td>
        <td colspan="1" class="td td-left td-text">身分證字號</td>
        <td colspan="2" class="td td-right td-text">{{OpenMemberResponse.ID_NO}}</td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">國籍</td>
        <td colspan="2" class="td td-text">
          &nbsp;
          <span *ngIf="OpenMemberResponse.BF_NATIONALITY != 'TW'" class="check">□</span>
          <span *ngIf="OpenMemberResponse.BF_NATIONALITY == 'TW'" class="check">■</span>
          本國人
          <span *ngIf="OpenMemberResponse.BF_NATIONALITY == 'TW'" class="check">□</span>
          <span *ngIf="OpenMemberResponse.BF_NATIONALITY != 'TW'" class="check">■</span>
          外國人:
          <u>&nbsp;&nbsp;&nbsp;&nbsp;
            <span
              *ngIf="OpenMemberResponse.BF_NATIONALITY != 'TW'">{{ShowCountry(OpenMemberResponse.BF_NATIONALITY)}}</span>
            <span *ngIf="OpenMemberResponse.BF_NATIONALITY == 'TW'">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
          </u>(請填寫國家名稱)
        </td>
        <td colspan="1" class="td td-left td-text">出生年月日</td>
        <td colspan="2" class="td td-right td-text">
          {{OpenMemberResponse.BIR_DATE1}}/{{OpenMemberResponse.BIR_DATE2}}/{{OpenMemberResponse.BIR_DATE3}}
        </td>
        <!-- <td colspan="2" class="td td-right td-text">1997/09/05</td> -->
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">法定代理人(1)</td>
        <td colspan="2" class="td td-right td-text">{{OpenMemberResponse.AGENT_IN_LAW1}}</td>
        <td colspan="1" class="td td-left td-text">法定代理人(1)<br>身分證字號</td>
        <td colspan="2" class="td td-right td-text">{{OpenMemberResponse.AGENT_IN_LAW_ID}}
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">法定代理人(2)</td>
        <td colspan="2" class="td td-right td-text">{{OpenMemberResponse.AGENT_IN_LAW2}}</td>
        <td colspan="1" class="td td-left td-text">法定代理人(2)<br>身分證字號</td>
        <td colspan="2" class="td td-right td-text">{{OpenMemberResponse.AGENT_IN_LAW_ID2}}</td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">連絡電話</td>
        <td colspan="5" class="td td-text" style="height:40px;font-size: 18px;padding-left: 6px;">
          <div style="display: inline-block;width: 28%;">
            公司：{{OpenMemberResponse.OF_TEL_AREA}}-{{OpenMemberResponse.OF_TEL}}#{{OpenMemberResponse.OF_TEL_EXT}}&nbsp;
          </div>
          <div style="display: inline-block;width: 22%;">
            住家：{{OpenMemberResponse.HM_TEL_AREA}}-{{OpenMemberResponse.HM_TEL}}&nbsp;</div>
          <div style="display: inline-block;width: 22%;">手機：{{OpenMemberResponse.CELL_PHONE}}&nbsp;</div>
          <div style="display: inline-block;width: 22%;">
            傳真：{{OpenMemberResponse.FAX_TEL_AREA1}}-{{OpenMemberResponse.FAX_TEL1}}&nbsp;</div>
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">通訊地址</td>
        <td colspan="5" class="td td-text" style="height:40px;font-size: 20px;padding-left: 10px;">
          {{OpenMemberResponse.MAIL_ZIP}}&nbsp;
          {{OpenMemberResponse.MAIL_ADDR}}
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">戶籍地址</td>
        <td colspan="5" class="td td-text" style="height:40px;font-size: 20px;padding-left: 10px;">
          {{OpenMemberResponse.PERM_ZIP}}&nbsp;
          {{OpenMemberResponse.PERM_ADDR}}
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">電子郵件信箱</td>
        <td colspan="2" class="td td-right td-text">{{OpenMemberResponse.EMAIL1}}</td>
        <td colspan="1" class="td td-left td-text">對帳單/確認單交付方式</td>
        <td colspan="2" class="td td-right td-text">
          <span class="check">■</span>E-MAIL
          或
          <span class="check">□</span>郵寄至通訊地(請擇一勾選)
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">基金交易方式</td>
        <td colspan="5" class="td td-text" style="height:40px;padding-left: 10px;">
          <span class="check">□</span>傳真交易 或
          <span class="check">□</span>傳真交易及自動轉帳扣款(限臺幣計價基金)，請加填『銀行代扣款授權書』。<br>
          <span class="check">■</span>網路交易。◆網路交易含傳真及電子交易功能，請加填電子郵件信箱及『銀行代扣款授權書』。
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text">買回及收益<br>分配約定帳號</td>
        <td colspan="5" class="td td-text" style="height:40px;padding-left: 10px;">
          1.為確保個人權益，限填受益人本人之帳號且日後僅得就約定帳號選擇辦理基金買回或收益分配。<br>
          2.受益人英文戶名須與銀行外幣帳戶英文戶名相同，並檢附護照影本或其他英文名稱相關證明文件如:存摺帳號頁。<br>
          3.若需要收益分配帳號請勾選□，收益分配帳號台幣、外幣各只限留存一筆。
        </td>
      </tr>
    </table>
    <table class="noBreak" style="width: 100%;">
      <tr>
        <td colspan="1" class="td td-left td-text td2-title" style="width: 5%;height: 20px;">幣別</td>
        <td colspan="1" class="td td-right td-text td2-title" style="width: 25%;height: 20px;">銀行/郵局</td>
        <td colspan="1" class="td td-right td-text td2-title" style="width: 25%;height: 20px;">分行/支局</td>
        <td colspan="1" class="td td-right td-text td2-title" style="width: 30%;height: 20px;">帳戶</td>
        <td colspan="1" class="td td-right td-text td2-title" style="width: 10%;height: 20px;">收益分配帳號</td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text" style="height: 40px;">台幣</td>
        <td colspan="1" class="td td-right td-text">{{OpenMemberResponse.CusAccount2[0].BANK_HQ_NAME}}</td>
        <td colspan="1" class="td td-right td-text">
          {{strsubstrBRH(OpenMemberResponse.CusAccount2[0].BANK_HQ_NAME,OpenMemberResponse.CusAccount2[0].BANK_BRH_NAME)}}
        </td>
        <td colspan="1" class="td td-right td-text">{{OpenMemberResponse.CusAccount2[0].ACCOUNT_NO}}</td>
        <td colspan="1" class="td td-right td-text">
          <span class="check">□</span>
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text" style="height: 40px;">台幣</td>
        <td colspan="1" class="td td-right td-text">{{OpenMemberResponse.CusAccount2[1].BANK_HQ_NAME}}</td>
        <td colspan="1" class="td td-right td-text">
          {{strsubstrBRH(OpenMemberResponse.CusAccount2[1].BANK_HQ_NAME,OpenMemberResponse.CusAccount2[1].BANK_BRH_NAME)}}
        </td>
        <td colspan="1" class="td td-right td-text">{{OpenMemberResponse.CusAccount2[1].ACCOUNT_NO}}</td>
        <td colspan="1" class="td td-right td-text">
          <span class="check">□</span>
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text" style="height: 40px;">台幣</td>
        <td colspan="1" class="td td-right td-text">{{OpenMemberResponse.CusAccount3[0].BANK_HQ_NAME}}</td>
        <td colspan="1" class="td td-right td-text">
          {{strsubstrBRH(OpenMemberResponse.CusAccount3[0].BANK_HQ_NAME,OpenMemberResponse.CusAccount3[0].BANK_BRH_NAME)}}
        </td>
        <td colspan="1" class="td td-right td-text">{{OpenMemberResponse.CusAccount3[0].ACCOUNT_NO}}</td>
        <td colspan="1" class="td td-right td-text">
          <span *ngIf="OpenMemberResponse.CusAccount3[0] == undefined" class="check">□</span>
          <span *ngIf="OpenMemberResponse.CusAccount3[0] != undefined" class="check">■</span>
        </td>
      </tr>
      <tr>
        <td rowspan="4" colspan="1" class="td td-left td-text" style="height: 20px;">外幣</td>
        <td colspan="1" class="td td-right td-text td2-title" style="height: 20px;">銀行</td>
        <td colspan="1" class="td td-right td-text td2-title" style="height: 20px;">分行</td>
        <td colspan="1" class="td td-right td-text td2-title" style="height: 20px;">英文戶名</td>
        <td colspan="1" class="td td-right td-text td2-title" style="height: 20px;">
          收益分配帳號
        </td>
      </tr>
      <tr>
        <td rowspan="3" colspan="1" class="td td-right td-text" style="height: 20px;">
          {{OpenMemberResponse.CusAccount4[0].BANK_HQ_NAME}}
        </td>
        <td rowspan="3" colspan="1" class="td td-right td-text" style="height: 20px;">
          {{strsubstrBRH(OpenMemberResponse.CusAccount4[0].BANK_HQ_NAME,OpenMemberResponse.CusAccount4[0].BANK_BRH_NAME)}}
        </td>
        <td colspan="1" class="td td-right td-text" style="height: 40px;">{{OpenMemberResponse.BF_NAME_E}}</td>
        <td rowspan="3" colspan="1" class="td td-right td-text" style="height: 20px;">
          <span *ngIf="OpenMemberResponse.CusAccount4[0].ACCOUNT_NO == ''" class="check">□</span>
          <span *ngIf="OpenMemberResponse.CusAccount4[0].ACCOUNT_NO != ''" class="check">■</span>
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-right td-text td2-title" style="height: 20px;">綜合外幣帳戶</td>
      </tr>
      <tr>
        <td colspan="1" class="td td-right td-text" style="height: 40px;">
          {{OpenMemberResponse.CusAccount4[0].ACCOUNT_NO}}
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td-text" style="width: 2%;height: 220px;">
          受<br> 益<br>人<br>留<br>存<br>印<br>鑑</td>
        <td colspan="4" class="td td-text" style="width: 7%;vertical-align: top;">
          <div>
            1.未成年或輔助宣告之受益人請加蓋法定代理人雙方印鑑。
            <br>
            2.印鑑：以下<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>式憑<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>式有效
          </div>
        </td>
      </tr>
      <tr>
        <td rowspan="4" colspan="1" class="td td-left td-text" style="width: 2%;height: 220px;">
          被<br> 授<br>權<br>樣<br>章
        </td>
        <td rowspan="4" colspan="3" class="td td-text" style="width: 5%;vertical-align: top;">
          <div>
            1.未成年或輔助宣告之受益人請加蓋法定代理人雙方印鑑。
            <br>
            2.印鑑：以下<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>式憑<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>式有效
          </div>
        </td>
        <td colspan="1" class="td td-right td-text" style="width: 5%;vertical-align: top;text-align: left;">
          銷售機構:
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-right td-text" style="width: 5%;vertical-align: top;text-align: left;">
          通路代碼:
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-right td-text" style="width: 5%;vertical-align: top;text-align: left;">
          員工代號:
        </td>
      </tr>
      <tr>
        <td colspan="1" class="td td-right td-text" style="width: 5%;vertical-align: top;text-align: left;">
          推薦人代碼:
        </td>
      </tr>
      <tr>
        <td colspan="4" class="td td-right td-text" style="text-align: left;">
          <div style="display: inline-block;width: 25%;">覆核:</div>
          <div style="display: inline-block;width: 24%;">經辦:</div>
          <div style="display: inline-block;width: 23%;">收件:</div>
          <div style="display: inline-block;width: 23%;">收件日:</div>
        </td>
        <td colspan="1" class="td td-right td-text"
          style="width: 5%;vertical-align: top;text-align: left;height: 44px;">
          部門主管覆核:
        </td>
      </tr>
    </table>
  </div>
  <div style="height: 15px;"></div>
  <div style="height:45px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a href="">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<!-- <div style="page-break-before: always;"></div> -->
<!-- =============================================多元理財開戶申請書============================================= -->
<!-- =============================================客戶投資適性分析表============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title"><b>客　戶　投　資　適　性　分　析　表</b></div>
  <div style="font-family: 標楷體"><b>※本表為開戶及交易之必要文件，請務必填寫完整才能完成基金開戶交易。</b></div>
  <div style="font-family: 標楷體"><b>※表格如有塗改，請於塗改處加蓋留存於本公司之原留印鑑；請勿使用傳真感熱紙。</b></div>
  <div class="td">
    <table class="noBreak" style="width: 100%;">
      <tr>
        <td colspan="1" class="td td-left td2-title td-title" style="width:10%">受 益 人 名 稱</td>
        <td colspan="1" class="td td-right td-title">{{OpenMemberResponse.BF_NAME}}</td>
        <td colspan="1" class="td td-left td2-title td-title" style="width:7%"> 身 份 別</td>
        <td colspan="1" class="td td-right td-title">自然人</td>
      </tr>
      <tr>
        <td colspan="1" class="td td-left td2-title td-title" style="width:10%">身分證字號/統一編號</td>
        <td colspan="1" class="td td-right td-title">{{OpenMemberResponse.ID_NO}}</td>
        <td colspan="1" class="td td-left td2-title td-title" style="width:7%">出生年月日 </td>
        <td colspan="1" class="td td-right td-title">
          {{OpenMemberResponse.BIR_DATE1}}/{{OpenMemberResponse.BIR_DATE2}}/{{OpenMemberResponse.BIR_DATE3}}
        </td>
      </tr>
      <tr>
        <td colspan="2" class="td td-title td2-title"> <b> 一、基本資料(法人請填6-8項)</b></td>
        <td colspan="1" class="td td-title td-left td2-title">連絡電話</td>
        <td colspan="1" class="td td-title td-right">{{OpenMemberResponse.CELL_PHONE}}</td>
      </tr>
    </table>
    <table id="AAA" class="noBreak" style="width: 100%;">
    </table>
    <table id="BBB" class="noBreak" style="width: 100%;">
    </table>
  </div>
  <div style="height: 50px;"></div>
  <div style="height:45px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<div style="page-break-before:left;"></div>
<!-- =============================================客戶投資適性分析表============================================= -->
<!-- =============================================美國海外帳戶FATCA身分聲明書暨個人資料保護法告知事項同意書【個人版】附錄一============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title" style="font-size: 24px;">
    <b>
      美國海外帳戶FATCA身分聲明書<br>
      暨個人資料保護法告知事項同意書<br>
      【個人版】
    </b>
  </div>
  <br>
  <div class="td-title" style="width: 100%;font-size: 24px;">
    本人<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{OpenMemberResponse.BF_NAME}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>聲明以下內容為實：
    <br>
    <br>
    請視個人之身分類別，勾選適用之選項一或選項二:
  </div>
  <br>
  <div class="td-title" style="font-size: 22px;"><b>一、
      <span *ngIf="OpenMemberResponse.IS_USA == 'Y'" class="check">■</span>
      <span *ngIf="OpenMemberResponse.IS_USA == 'N'" class="check">□</span>
      本人為美國稅務居民(即符合下述之定義)，且願意提供W-9以茲證明FATCA身分</b><br>
    <div>
      <div class="pg3-left">
        (一)<br>
        (二)
      </div>
      <div class="pg3-right">
        人為美國公民、且具美國永久居留權（包含但不限於綠卡持有人）。<br>
        本人符合下述可遭認定為美國稅務居民的情形，即未持有A、F、G、J、M、Q等型簽證，仍於當年度停留於美國(含本土、哥倫比亞特區、關島、美屬波多黎各)天數累計達183天，
        或當年度未達183天但超過31天以上，而當年度停留美國天數全數、前一年度停留美國天數之三分之一，以及前二年度停留美國天數之六分之一合計達183天以上。
      </div>
    </div>
  </div>
  <br>
  <div class="td-title" style="font-size: 22px;"><b>二、
      <span *ngIf="OpenMemberResponse.IS_USA == 'N'" class="check">■</span>
      <span *ngIf="OpenMemberResponse.IS_USA == 'Y'" class="check">□</span>
      本人非屬美國稅務居民(即符合下述之定義)</b><br>
    <div>
      <div class="pg3-left">
        (一)<br>
        (二)
      </div>
      <div class="pg3-right">
        本人非屬美國公民、不具美國永久居留權（包含但不限於非綠卡持有人）。<br>
        本人不符合下述可遭認定為美國稅務居民的情形：即未持有A、F、G、J、M、Q等型簽證，仍於當年度停留於美國(含本土、哥倫比亞特區、關島、美屬波多黎各)天數累計達183天，
        或當年度未達183天但超過31天以上，而當年度停留美國天數全數、前一年度停留美國天數之三分之一，以及前二年度停留美國天數之六分之一合計達183天以上。
      </div>
    </div>
  </div>
  <br>
  <div class="td-title">
    <div>
      <div class="pg3-left">
        <!-- 對齊格式留空 -->
        <br>
        <!-- 對齊格式留空 -->
      </div>
      <div class="pg3-right">
        　　本人了解並同意台中銀證券投資信託股份有限公司(以下簡稱
        貴公司)為證實上開聲明內容得於必要時向本人索取相關身分證明文件；得代理本人向美國稅法的扣繳義務人出示本聲明書或交付本聲明書之複本以協助本人聲明非屬美國納稅義務人。
        本人已詳細閱讀【附錄一】美國海外帳戶稅收遵循法條款及【附錄二】個人資料保護法告知事項同意書，了解並同意其規定與要求，並特此同意　貴公司蒐集、處理、利用或國際傳輸本人個人資料之目的及用途。
      </div>
    </div>
  </div>
  <br>
  <div class="td-title">
    <div>
      <div class="pg3-left">
        <!-- 對齊格式留空 -->
        <br>
        <!-- 對齊格式留空 -->
      </div>
      <div class="pg3-right">
        　　若上述聲明內容及其他開戶相關身分證明文件之資訊產生變動，而造成本開戶文件不正確或不完整時，本人至遲應於變更日起30天內主動告知 貴公司。本人了解並同意
        貴公司有權合理認定上開聲明內容之真偽或變更情形而對本人帳戶權利為必要的處置行為，包含但不限於辦理美國稅扣繳或終止帳戶服務。
      </div>
    </div>
  </div>
  <br>
  <div class="td-title" style="font-size: 22px;"> <b>【附錄一】美國海外帳戶稅收遵循法條款</b>
    <div>
      <div class="pg3-left">
        <b>第一條</b>
        <br>
        <!-- 對齊格式留空 -->
      </div>
      <div class="pg3-right">
        受益人(即申購人)茲受告知並同意配合
        貴公司遵循國內外稅務法令(包含但不限於美國海外帳戶稅收遵循法及中華民國相關法令)、條約或國際協議的必要措施，包含調查受益人之國籍與稅籍稅務資料，
        將稅籍資料及帳戶資訊揭露予國內外政府機關(包含中華民國政府及美國聯邦政府)，並於調查結果顯示受益人與貴公司間的關係符合國內外稅務法令、
        條約或國際協議的特定條件(包含但不限於受益人未能協助提供前揭調查所需的資料、未能據實出具本聲明書，
        或受益人不同意貴公司向中華民國政府及美國聯邦政府為前揭揭露等情形)時，為受益人辦理稅款扣繳之結算或終止本聲明書。
      </div>
    </div>
    <!-- <div style="height: 10px;"></div> -->
    <div style="height:45px;border-top: 5px black double;">
      <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
        100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
          href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
        【11011開戶申請書-自然人】
      </div>
    </div>
  </div>
  <!-- <div style="page-break-before: always;"></div> -->
  <div class="td-title" style="font-size: 22px;">
    <div>
      <div class="pg3-left">
        <b>第二條</b>
        <br>
        <!-- 對齊格式留空 -->
      </div>
      <div class="pg3-right">
        本附錄第一條相關名詞參考美國海外帳戶稅收遵循法說明如下，本說明僅供參考，相關定義以美國海外帳戶稅收遵循法之有權解釋為準：<br>
      </div>
    </div>
  </div>
  <div class="td-title" style="font-size: 22px;">
    <div>
      <div style="display: inline-block;width: 7%;vertical-align: top;text-align: right;font-size: 20px;">
        <!-- 對齊格式留空 -->
        <br>
        <!-- 對齊格式留空 -->
      </div>
      <div style="display: inline-block;width: 91%;font-size: 20px;">
        <div class="td-title" style="font-size: 22px;">
          <!-- 一、內部縮排 -->
          <div>
            <div class="pg3-left-2">
              一、
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-2">
              美國海外帳戶稅收遵循法:指美國Foreign Account Tax Compliance Act即26 USC §1471~
              §1474，或稱美國內地稅法第四章(Internal Revenue Code Chapter
              4)，並包含美國國稅局(Internal Revenue Service)發布的相關行政命令(包含但不限於26 CFR Parts 1及301)、指引及申辦表單等。
            </div>
          </div>
          <!-- 一、內部縮排 -->
          <!-- 二、內部縮排 -->
          <div>
            <div class="pg3-left-2">
              二、
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-2">
              條約或國際協議：包含但不限於中華民國政府與美國政府或雙方政府之代表人或代表機構間簽訂關於美國海外帳戶稅收遵循法執行的跨政府協議(Intergovernmental
              Agreement)。
            </div>
          </div>
          <!-- 二、內部縮排 -->
          <!-- 三、內部縮排 -->
          <div>
            <div class="pg3-left-2">
              三、
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-2">
              受益人：包含但不限於受益人指定自動或定期轉帳轉入帳戶持有人；受益人如為非自然人之法律實體時，對受益人直接或間接擁有股權性利益、合夥利益、投資利益、信託利益之人，以及其他依美國海外帳戶稅收遵循法可認定雖非直接持有帳戶，但實質享有帳戶利益之人。
            </div>
          </div>
          <!-- 三、內部縮排 -->
          <!-- 四、內部縮排 -->
          <div>
            <div class="pg3-left-2">
              四、
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-2">
              國籍與稅籍稅務資料：包含但不限於國籍、雙重國籍或永久居留權身分；納稅義務人稅籍編號(Taxpayer Identification
              Number)、全球中介機構識識碼(Global Intermediary Identification
              Number)；美國稅務Form W-8、Form W-9或其他替代文件，以及其他依美國海外帳戶稅收遵循法指定金融機構必須調查或取得的帳戶相關資料。
            </div>
          </div>
          <!-- 四、內部縮排 -->
          <!-- 五、內部縮排 -->
          <div>
            <div class="pg3-left-2">
              五、
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-2">
              其他相關名詞：
            </div>
          </div>
          <div>
            <!-- 五、其他相關名詞： 內容-->
            <div class="pg3-left-3">
              (一)
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-3">
              美國內地稅法第四章身分(Internal Revenue Code Chapter 4 Status):包含美國人(U.S. Person)、特定美國人(Specified
              U.S. Person)、除外之非金融機構非美國法人
              (excepted NFFE)、或非實質營運之非金融機構非美國法人(Passive
              NFFE)等自然人或非自然人之法律實體之身分類別，及其他同於美國內地稅法第四章所規定之身分類別。
            </div>
            <div class="pg3-left-3">
              (二)
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-3">
              美國人(U.S. Person)及特定美國人(Specified U.S. Person)：美國人係指26 USC
              §7701(a)30所規定之美國人，包含美國公民、具美國永久居留權之人、美國境內的合夥組織、公司或遺產財團、或美國法院對之有管轄權或美國人對之有控制權的信託財產。特定美國人係指26
              USC
              §1473(3)所規定任何不具下列性質之美國人:1.任何股票於證券交易市場經常性交易之公司、2.任何同屬於前述1.公司集團之公司、3.任何屬26 USC
              §501(a)所指之免稅組織或自然人退休計畫、4.美國(政府)或政府所有之機構或投資工具、5.任何美國聯邦州、哥倫比亞特區、美國(政府)財產、其分支、其所有之機構或投資工具、6.任何銀行、7.任何不動產投資信託、8.任何受監督的投資公司、9.任何共同信託基金、10.任何適用26
              USC §664(c)之免稅規定或符合26 USC §4947(a)(1)的信託。
            </div>
            <div class="pg3-left-3">
              (三)
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-3">
              非美國金融機構(Foreign Financial Institution; FFI)及非金融機構非美國法人(Non-financial Foreign Entity;
              NFFE)：非美國金融機構係指26 U.S.C.
              §1471(d)(4)定義之非美國的金融機構，金融機構則是指26 USC
              §1471(d)(5)所定義辦理存款業務的銀行、以從事投資、轉投資、或有價證券、合夥利益、商品期貨或任何對有價證券、合夥利益、商品期貨的利益(包含期貨、遠期合約或選擇權)的交易為主業的機構等。非金融機構非美國法人則是指26
              USC
              §1472(d) 所定義任何不屬於金融機構的非美國機構。
            </div>
            <div class="pg3-left-3">
              (四)
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-3">
              除外之非金融機構非美國法人(Excepted NFFE)：指26 CFR
              §1.1472-1(c)(1)所定義符合下列條件之一的非金融機構非美國法人：1.屬於股份有限公司且一定比例公司股票於正式的證券交易市場(established
              securities
              market)中經常交易者。2.前述股份有限公司的關係企業。3.美國海外領土居民所完全持有控制的非金融機構非美國法人。4.實質營運之非金融機構非美國法人(Active
              NFFE)。5.豁免型非金融機構，包含26 CFR
              §1.1471-5(e)(5)所指的非金融集團的控股公司、財政管理中心、自保型財務公司、新設公司、清算或破產更生公司或非營利組織等。其中實質營運之非金融機構非美國法人(Active
              NFFE)係指26 CFR
              §1.1472-1(c)(1)(iv)所定義符合下列條件的非金融機構非美國法人：1.前一年度被動收入(passive
              income)未滿毛收入的百分之五十，且2.該機構直接或間接產生被動收入之資產加權平均價值所占百分比未滿百分之五十；其中被動收入(passive
              income)係指未經相關法令排除適用之股利、利息、相當於利息的收入、租金或權利金收入、年金、處分產出被動收入資產的盈餘、特定商品期貨交易的盈餘、Section 988
              Transaction的盈餘、26 CFR
              1.446-3(c)(1)所定義Notional Principal Contract的淨收入、來自現金價值保險契約的收入、保險公司關於保險及年金契約準備金所賺取的收入等。
            </div>
            <div class="pg3-left-3">
              (五)
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-3">
              非實質營運之非金融機構非美國法人(Passive NFFE)：不屬於除外之非金融機構非美國法人(Excepted NFFE)之非金融機構非美國法人(NFFE)。
            </div>
            <div class="pg3-left-3">
              (六)
              <br>
              <!-- 對齊格式留空 -->
            </div>
            <div class="pg3-right-3">
              實質美國股東(Substantial United States owner):指26 USC
              §1473(2)所定義對任何公司直接或間接持有超過百分之十股權(依投票權比例或面值比例定之)之「特定美國人」(specified
              U.S.
              person)、對任何合夥直接或間接持有超過百分之十的分紅或資本利得權利之特定美國人、對任何信託委託授予財產之特定美國人、對任何信託直接或間接持有超過百分之十受益權之特定美國人。10%之計算除姻親關係(in-laws)或繼子女與繼父母或類似關係(step
              relationship)之親屬外，應包含配偶、直系親屬與旁系親屬對該法人客戶之持股。該美國人股東毋須揭露親屬之持股比例，而是將加總的持股比例全數計入該美國人股東之持股。
            </div>
            <!-- 五、其他相關名詞： 內容-->
          </div>
          <!-- 五、內部縮排 -->
        </div>
      </div>
    </div>
  </div>
  <div style="height: 15px;"></div>
  <div style="height:45px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<!-- =============================================美國海外帳戶FATCA身分聲明書暨個人資料保護法告知事項同意書【個人版】附錄一============================================= -->
<!-- =============================================美國海外帳戶FATCA身分聲明書暨個人資料保護法告知事項同意書【個人版】附錄二============================================= -->
<!-- <div style="page-break-before: always;"></div> -->
<div class="table">
  <table>
    <tr>
      <td colspan="2" class="td-title" style="font-size: 20px;vertical-align: top;">
        <b> 【附錄二】個人資料保護法告知事項同意書</b> <br>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td-blank">
        <!-- 中間留空 -->
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td">
        台中銀證券投資信託股份有限公司(以下簡稱本公司)將遵守個人資料保護法之相關規範進行個人資料之蒐集、處理、利用或國際傳輸，並依據個人資料保護法第8條之規定，向台端告知下列事項，請台端詳閱：<br>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td-blank">
        <!-- 中間留空 -->
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td">
        1. 資料蒐集目的：為辦理證券投資信託、全權委託投資業務及其他合於本公司之營業登記項目或主管機關所核准之業務，以及基於營業目的
        、風險控管、稽核、客戶服務、管理、資訊提供、行銷活動推廣、洗錢防制、為台端之利益或依國內外法令規定(包含美國海外帳戶稅收遵循法及相關跨政府協議等)，或因各相關主管機關、法院要求等。<br>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td-blank">
        <!-- 中間留空 -->
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td">
        2. 個人資料之類別：姓名、出生年月日、身分證字號或統一編號、護照號碼、美國稅籍身分及編號
        婚姻、家庭、教育、職業、聯絡方式、財務情況、社會活動或後續其他合於本公司營業項目之特定目的所須蒐集之個人資料等。<br>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td-blank">
        <!-- 中間留空 -->
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td">
        3. 個人資料利用之期間、對象、地區及方式：<br>
        (1)期間：依相關法令或契約約定之保存期限，或本公司因執行業務所必須之保存期間。<br>
        (2)對象：本公司、本公司所屬分支機構、本公司之共同行銷或合作推廣對象、其他與本公司有業務往來之機構、依法定義務所必要提供之第三方，或經台端書面同意之對象。<br>
        (3)地區：前揭所稱對象之所在地。<br>
        (4)方式：電子文件、書面、電話、傳真、以自動化機器或其他非自動化之利用方式。<br>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td-blank">
        <!-- 中間留空 -->
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td">
        4. 台端就本公司保有台端之個人資料得行使之權利及方式：<br>
        (1) 得向本公司查詢、請求閱覽或請求製給複製本，而本公司依法得酌收必要成本費用。<br>
        (2) 得向本公司請求補充或更正，惟依法台端應為適當之釋明。<br>
        (3) 得向本公司請求停止蒐集、處理或利用，及請求刪除，惟依法本公司因執行業務所必須者，得不依台端請求為之。<br>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td-blank">
        <!-- 中間留空 -->
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td">
        5. 台端知悉並瞭解，如未將申請業務或服務所需之個人資料提供予本公司，本公司將無法提供台端相關服務。<br>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td-blank">
        <!-- 中間留空 -->
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title pg4-td">
        6.
        如台端未完整提供本公司遵循美國海外帳戶稅收遵循法及相關跨政府協議執行美國帳戶調查所需個人資料或不同意本公司申報美國帳戶資料予美國聯邦政府或中華民國政府者，本公司依法須將台端於本公司之帳戶列為不合作帳戶(Recalcitrant
        Account)，針對符合法定條件的款項(包含但不限於美國來源所得)予以扣繳百分之三十的美國扣繳稅款予美國聯邦政府；如經合理期間台端仍不完整提供或同意者，本公司依法可能須關閉台端的帳戶。<br>
      </td>
    </tr>
    <tr>
      <td class="pg4-td-sign">
        <div style="margin: 10px;border-bottom: 1px solid;"></div>
        <div style="margin: 10px;">聲明人簽名 (受益人留存印鑑)</div>
      </td>
      <td class="pg4-td-sign">
        <div style="margin: 10px;border-bottom: 1px solid;"></div>
        <div style="margin: 10px;">日 期</div>
      </td>
    </tr>
  </table>
  <!-- <div style="height: 15px;"></div> -->
  <div style="height:45px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<!-- <div style="page-break-before: always;"></div> -->
<!-- =============================================美國海外帳戶FATCA身分聲明書暨個人資料保護法告知事項同意書【個人版】附錄二============================================= -->
<!-- =============================================自我證明表-個人============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title">
    <b> 自我證明表-個人 </b>
  </div>
  <div class="td-text" style="width: 99%;border: 1px black solid;font-size: 16px;padding-left: 10px;">
    重要提示：<br>
    <div class="pg5-left">1.</div>
    <div class="pg5-right">
      金融機構執行共同申報及盡職審查作業辦法(下稱本辦法)規定，金融機構應蒐集及申報有關帳戶持有人稅務居住者身分之特定資訊。本辦法係依稅捐稽徵法第5條之1第6項訂定，其內容參考經濟合作暨發展組織發布之共同申報及盡職審查準則(下稱共同申報準則)。
    </div>
    <div class="pg5-left">2.</div>
    <div class="pg5-right">
      金融機構依本辦法規定取得帳戶持有人之自我證明文件，以辨識帳戶持有人為稅務居住者之國家/地區。金融機構依法可能將本表及該帳戶其他資訊提供中華民國稅捐稽徵機關，經由政府間協定進行稅務目的金融帳戶資訊交換，提供他方國家/地區稅捐稽徵機關。
    </div>
    <div class="pg5-left">3.</div>
    <div class="pg5-right">
      標有星號（*）欄位或部分為必填資訊。
    </div>
    <div class="pg5-left">4.</div>
    <div class="pg5-right">
      本表將持續有效，倘狀態變動（例如帳戶持有人之稅務居住者身分變動）致所填資訊不正確或不完整，帳戶持有人應通知金融機構，並更新本表。
    </div>
    <div class="pg5-left">5.</div>
    <div class="pg5-right">
      本表相關用詞(如帳戶持有人、稅務識別碼、金融帳戶等)，請詳本辦法。
    </div>
  </div>
  <table class="td" style="width: 100%;">
    <tr>
      <td class="td td-text td2-title" style="width: 10%;text-align: center;">
        受 益 人
        <br>
        (立聲明書人)
      </td>
      <td class="td td-text" style="width: 30%;text-align: center;">{{OpenMemberResponse.BF_NAME}}</td>
      <td class="td td-text td2-title" style="width: 10%;text-align: center;">身份證字號</td>
      <td class="td td-text" style="width: 20%;text-align: center;">{{OpenMemberResponse.ID_NO}}</td>
      <td class="td td-text td2-title" style="width: 10%;text-align: center;">簽署日期</td>
      <td class="td td-text" style="width: 20%;text-align: center;">
        {{GetDate(OpenMemberResponse.APPLY_DTTM,1)}}/{{GetDate(OpenMemberResponse.APPLY_DTTM,2)}}/{{GetDate(OpenMemberResponse.APPLY_DTTM,3)}}
      </td>
    </tr>
    <tr>
      <td colspan="6" class="td td-text" style="padding-left: 10px;line-height: 25px;">
        <span *ngIf="OpenMemberResponse.BF_NATIONALITY != 'TW'" class="check">□</span>
        <span *ngIf="OpenMemberResponse.BF_NATIONALITY == 'TW'" class="check">■</span>
        本帳戶持有人聲明<b><u>僅具有中華民國稅務居住者身分</u></b>，有關本帳戶持有人身分資料請詳開戶申請書。(勾選此項，請完成第三部分。)
        <br>
        <span *ngIf="OpenMemberResponse.BF_NATIONALITY != 'TW'" class="check">■</span>
        <span *ngIf="OpenMemberResponse.BF_NATIONALITY == 'TW'" class="check">□</span>
        本帳戶持有人聲明因具有其他國家或地區稅務居住者身分(勾選此項，請完成第一部分至第三部分內容。)
      </td>
    </tr>
  </table>
  <div>
    <div class="td-title" style="font-size: 18px;">
      <b>第一部分：個人帳戶持有人身分辨識資料</b>
    </div>
    <div class="td-text">
      本帳戶持有人聲明因具有其他國家或地區稅務居住者身分，應以英文填寫相關資料若帳戶持有人對稅務居住者身份有所疑問，將自行洽詢律師或會計師，或至OECD網站查詢各國稅務居住者身分認定要件(http://www.oecd.org/tax/automatic-exchange/crs-implementation-and-assistance/tax-residency/)，以確保自身權益。
    </div>
  </div>
  <table class="table td" style="width: 100%;">
    <tr>
      <td class="td" rowspan="3" style="width: 15%;line-height: 18px;font-size: 14px;">
        帳戶持有人姓名* <br>
        Name of Account Holder
      </td>
      <td colspan="2" class="td"
        style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        姓氏* Last Name or Surname(s)
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td"
        style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        名字* First or Given Name
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td"
        style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        中間名*Middle Name(s)
      </td>
    </tr>
    <tr>
      <td rowspan="3" class="td" style="line-height: 18px;font-size: 14px;">
        現行居住地址*<br>
        Current Residence Address
      </td>
      <td colspan="2" class="td"
        style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        （如有室、 樓層、 大樓、 街道、 地區等）* (e.g. Suite, Floor, Building, Street, District, if any)
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td"
        style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        （如有鎮、 市、 省、 縣、 州等）*(e.g. Town/City/Province/County/State)
      </td>
    </tr>
    <tr>
      <td class="td"
        style="line-height: 18px;padding-left: 6px;width: 42%;font-size: 14px;height: 40px;vertical-align: top;">
        國家/地區* Country/Jurisdiction
      </td>
      <td class="td"
        style="line-height: 18px;padding-left: 6px;width: 42%;font-size: 14px;height: 40px;vertical-align: top;">
        郵政編碼/郵遞區號* Post Code/ZIP Code (if any)
      </td>
    </tr>
    <tr>
      <td rowspan="3" class="td" style="line-height: 18px;font-size: 14px;">
        通訊地址* <br>
        Mailing Address<br>
        (與現行居住地址不同時， 填寫此欄)<br>
        (Complete if different to the Current Residence Address)
      </td>
      <td colspan="2" class="td"
        style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        （如有室、 樓層、 大樓、 街道、 地區等）* (e.g. Suite, Floor, Building, Street, District, if any)
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td"
        style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        （如有鎮、 市、 省、 縣、 州等）* (e.g. Town/City/Province/County/State)
      </td>
    </tr>
    <tr>
      <td class="td"
        style="line-height: 18px;padding-left: 6px;width: 42%;font-size: 14px;height: 40px;vertical-align: top;">
        國家/地區* Country/Jurisdiction
      </td>
      <td class="td"
        style="line-height: 18px;padding-left: 6px;width: 42%;font-size: 14px;height: 40px;vertical-align: top;">
        郵政編碼/郵遞區號* Post Code/ZIP Code (if any)
      </td>
    </tr>
    <tr>
      <td class="td" style="line-height: 18px;font-size: 14px;vertical-align: top;">
        出生日期*Date of Birth
      </td>
      <td colspan="2" class="td"
        style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        (西元日/月/年) (dd/mm/yyyy)
      </td>
    </tr>
    <tr>
      <td class="td" style="line-height: 18px;font-size: 14px;">
        出生地*Place of Birth
      </td>
      <td class="td" style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        出生城市 *Town or City of Birth
      </td>
      <td class="td" style="line-height: 18px;padding-left: 6px;font-size: 14px;height: 40px;vertical-align: top;">
        出生國家/地區* Country/Jurisdiction
      </td>
    </tr>
  </table>
  <div>
    <div class="td-title" style="font-size: 18px;">
      <b>第二部分：稅務居住者之國家/地區及其稅務識別碼或具相當功能之辨識碼（”稅務識別碼”）*</b>
    </div>
    <div class="td-text">
      請於下表填寫(a)帳戶持有人為稅務居住者之國家/地區，及(b)於該國家/地區稅務識別碼。<br>
      帳戶持有人如同時為2個以上國家/地區稅務居住者，請填寫所有其為稅務居住者之國家/地區。<br>
      如帳戶持有人為中華民國稅務居住者，填列稅務識別碼如下：<br>
      1.具身分證字號者為身分證字號(10碼，由內政部戶政司編配)。<br>
      2.具統一證號者為統一證號(10碼，由內政部移民署編配)。<br>
      3.個人無身分證字號或統一證號者，以現行稅務識別碼(大陸地區人民為9＋西元出生年後2碼及出生月日4碼；其餘情形為西元出生年月日8碼＋護照顯示英文姓名前2字母2碼)方式編配。<br>
      如無法提供稅務識別碼，於下列欄位填寫適用之理由A、B或C：<br>
      理由A – 帳戶持有人為稅務居住者之國家/地區未核發稅務識別碼。<br>
      理由B – 帳戶持有人無法取得稅務識別碼。(請說明帳戶持有人無法取得稅務識別碼原因)<br>
      理由C – 帳戶持有人毋須提供稅務識別碼。(限於該帳戶持有人為稅務居住者之國家/地區國內法未要求蒐集稅務識別碼)
    </div>
  </div>
  <table class="table td" style="width: 100%;">
    <tr>
      <td class="td td-text" style="text-align: center;">
        稅務居住者之國家/地區
        Country/Jurisdiction of tax residence
      </td>
      <td class="td td-text" style="text-align: center;">
        稅務識別碼
        TIN
      </td>
      <td class="td td-text" style="text-align: center;">
        若無法提供稅務識別碼，填寫理由A、B或C
        Enter reason A, B or C if no TIN available
      </td>
      <td class="td td-text" style="text-align: center;">
        如選取理由B，說明帳戶持有人無法取得稅務識別碼之原因Explain why the Account Holder is unable to obtain a TIN if you have
        selected reason B
      </td>
    </tr>
    <tr>
      <td class="td" style="height: 20px;"></td>
      <td class="td"></td>
      <td class="td"></td>
      <td class="td"></td>
    </tr>
    <tr>
      <td class="td" style="height: 20px;"></td>
      <td class="td"></td>
      <td class="td"></td>
      <td class="td"></td>
    </tr>
    <tr>
      <td class="td" style="height: 20px;"></td>
      <td class="td"></td>
      <td class="td"></td>
      <td class="td"></td>
    </tr>
  </table>
  <div>
    <div class="td-title" style="font-size: 18px;">
      <b>第三部分：聲明及簽署</b>
    </div>
    <div class="td-text">
      本人知悉，本表所含資訊、相關帳戶持有人及任何應申報帳戶資訊，將可能提供中華民國稅捐稽徵機關，經由政府間協定進行稅務目的金融帳戶資訊交換，提供帳戶持有人為稅務居住者之國家/地區稅捐稽徵機關。<br>
      <u>本人知悉，依稅捐稽徵法第46條之1，若本人規避、妨礙或拒絶財政部或其授權之機關調查或備詢，或未應要求或未配合提供有關資訊，將受相關罰則。</u><br>
      本人證明，與本表相關之所有帳戶，本人為帳戶持有人(或本人業經帳戶持有人授權簽署本表)。<br>
      本人聲明，就本人所知所信，於本自我證明所為之陳述均為正確且完整。<br>
      本人承諾，如狀態變動致影響本表第一部分所述之個人稅務居住者身分，或所載資料不正確或不完整，本人會通知台中銀證券投資信託股份有限公司，並在狀態變動後30日內提供台中銀證券投資信託股份有限公司一份經適當更新之自我證明表
    </div>
  </div>
  <div style="width: 98%;height: 80px;">
    <!-- <div class="td-text" style="display:inline-block;font-size: 24px;padding-top: 16px;"><b>受益人留存印鑑:</b></div>
    <div class="td-text" style="width: 78%;display:inline-block;font-size: 24px;padding-top: 16px;border-bottom: 2px black solid;"></div> -->
    <table style="width:98%;height: 80px;">
      <tr>
        <td class="td-text" style="width: 5%;font-size: 24px;"><b>受益人留存印鑑:</b></td>
        <td class="td-text" style="width: 20%;border-bottom: 2px black solid;"></td>
      </tr>
    </table>
  </div>
</div>
<!-- <div style="page-break-before: always;"></div> -->
<!-- =============================================自我證明表-個人============================================= -->
<!-- =============================================聲   明   書============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title" style="margin-top: 20px;"><b>聲&nbsp;&nbsp;&nbsp;明&nbsp;&nbsp;&nbsp;書</b></div>

  <div class="td-title" style="margin-top: 20px;margin-bottom: 20px;font-size: 18px;">
    本人<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{OpenMemberResponse.BF_NAME}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
    身份證字號：<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{OpenMemberResponse.ID_NO}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>確認所提示之身份證明文件影本與正本相符，如有虛偽、隱匿情事，本人願負相關法律責任。
  </div>
  <div class="td-title" style="font-size: 18px;padding-left: 1em;">此致</div>
  <div class="td-title" style="font-size: 18px;padding-left: 4em;">台中銀證券投資信託股份有限公司</div>
  <div class="td-title" style="font-size: 18px;padding-left: 35em;">原留印鑑：________________________________</div>
  <div class="td-title" style="font-size: 18px;height:200px;">
    檢附文件：<br>
    1.本國人請附身份證正反面影本（未滿14歲得以戶口名簿影本替代）;外國人提供護照或居留証影本。<br>
    2.第二證件影本。<br>
    3.未成年人或受輔助宣告之人請加附法定代理人（父母雙方）或輔助人之身份證正反面影本、第二證件影本及聲明書。<br>
    4.受益人申請外幣帳戶請檢附存摺帳號頁、護照影本或其他地方英文名稱相關證明文件
  </div>

  <div class="td" style="width: 99%;height: 1000px;">
    <div class="td-title" style="text-align: center;font-size: 24px;"><b>檢附文件黏貼處</b></div>
    <div style="text-align: left;">
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[0].ImageFile" alt="">
      &nbsp;
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[1].ImageFile" alt="">
    </div>
    <div style="text-align: left;">
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[2].ImageFile" alt="">
      &nbsp;
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[3].ImageFile" alt="">
    </div>
  </div>
  <div style="height: 15px;"></div>
  <div style="height:30px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<div style="page-break-before: left;"></div>
<!-- =============================================聲   明   書============================================= -->
<!-- =============================================聲   明   書 【法代一】============================================= -->
<div class="table" *ngIf="OpenMemberResponse.AGENT_IN_LAW_ID != ''">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title" style="margin-top: 20px;"><b>聲&nbsp;&nbsp;&nbsp;明&nbsp;&nbsp;&nbsp;書</b></div>

  <div class="td-title" style="margin-top: 20px;margin-bottom: 20px;font-size: 18px;">
    本人<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{OpenMemberResponse.AGENT_IN_LAW1}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
    身份證字號：<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{OpenMemberResponse.AGENT_IN_LAW_ID}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>確認所提示之身份證明文件影本與正本相符，如有虛偽、隱匿情事，本人願負相關法律責任。
  </div>
  <div class="td-title" style="font-size: 18px;padding-left: 1em;">此致</div>
  <div class="td-title" style="font-size: 18px;padding-left: 4em;">台中銀證券投資信託股份有限公司</div>
  <div class="td-title" style="font-size: 18px;padding-left: 35em;">原留印鑑：________________________________</div>
  <div class="td-title" style="font-size: 18px;height:200px;">
    檢附文件：<br>
    1.本國人請附身份證正反面影本（未滿14歲得以戶口名簿影本替代）;外國人提供護照或居留証影本。<br>
    2.第二證件影本。<br>
    3.未成年人或受輔助宣告之人請加附法定代理人（父母雙方）或輔助人之身份證正反面影本、第二證件影本及聲明書。<br>
    4.受益人申請外幣帳戶請檢附存摺帳號頁、護照影本或其他地方英文名稱相關證明文件
  </div>

  <div class="td" style="width: 99%;height: 1000px;">
    <div class="td-title" style="text-align: center;font-size: 24px;"><b>檢附文件黏貼處</b></div>
    <div style="text-align: left;">
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[4].ImageFile" alt="">
      &nbsp;
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[5].ImageFile" alt="">
    </div>
    <div style="text-align: left;">
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[6].ImageFile" alt="">
      &nbsp;
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[7].ImageFile" alt="">
    </div>
  </div>
  <div style="height: 15px;"></div>
  <div style="height:30px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<div style="page-break-before: left;"></div>
<!-- =============================================聲   明   書 【法代一】============================================= -->
<!-- =============================================聲   明   書 【法代二】============================================= -->
<div class="table" *ngIf="OpenMemberResponse.AGENT_IN_LAW_ID2 != ''">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title" style="margin-top: 20px;"><b>聲&nbsp;&nbsp;&nbsp;明&nbsp;&nbsp;&nbsp;書</b></div>

  <div class="td-title" style="margin-top: 20px;margin-bottom: 20px;font-size: 18px;">
    本人<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{OpenMemberResponse.AGENT_IN_LAW2}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
    身份證字號：<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{OpenMemberResponse.AGENT_IN_LAW_ID2}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>確認所提示之身份證明文件影本與正本相符，如有虛偽、隱匿情事，本人願負相關法律責任。
  </div>
  <div class="td-title" style="font-size: 18px;padding-left: 1em;">此致</div>
  <div class="td-title" style="font-size: 18px;padding-left: 4em;">台中銀證券投資信託股份有限公司</div>
  <div class="td-title" style="font-size: 18px;padding-left: 35em;">原留印鑑：________________________________</div>
  <div class="td-title" style="font-size: 18px;height:200px;">
    檢附文件：<br>
    1.本國人請附身份證正反面影本（未滿14歲得以戶口名簿影本替代）;外國人提供護照或居留証影本。<br>
    2.第二證件影本。<br>
    3.未成年人或受輔助宣告之人請加附法定代理人（父母雙方）或輔助人之身份證正反面影本、第二證件影本及聲明書。<br>
    4.受益人申請外幣帳戶請檢附存摺帳號頁、護照影本或其他地方英文名稱相關證明文件
  </div>

  <div class="td" style="width: 99%;height: 1000px;">
    <div class="td-title" style="text-align: center;font-size: 24px;"><b>檢附文件黏貼處</b></div>
    <div style="text-align: left;">
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[8].ImageFile" alt="">
      &nbsp;
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[9].ImageFile" alt="">
    </div>
    <div style="text-align: left;">
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[10].ImageFile" alt="">
      &nbsp;
      <img style="width: 500px;height: auto;max-width: 500px;max-height: 300px;"
        [src]="OpenMemberResponse.ProductImgList[11].ImageFile" alt="">
    </div>
  </div>
  <div style="height: 15px;"></div>
  <div style="height:30px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<div style="page-break-before: left;"></div>
<!-- =============================================聲   明   書 【法代二】============================================= -->
<!-- =============================================交易規範約定及聲明事項 第一頁============================================= -->
<div>
  <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
</div>
<table class="table td">
  <tr>
    <td class="td td-title" style="background-color: darkred;color: white;">
      交易規範-約定及聲明事項
    </td>
  </tr>
  <tr>
    <td class="td td-text" style="font-size: 16px;">
      一、 本人(以下稱甲方)同意簽署及依照台中銀投信「多元理財帳戶開戶申請書」所約定之內容及條款，向台中銀證券投資信託股份有限公司（以下稱乙方）辦理基金申購、買回、轉申購事宜，並遵守下列契約條款：<br>
      二、 甲方為櫃台、電子交易及傳真交易或其他由乙方開發且經相關法令核可之新種業務交易方式時，同意依照相關法令、交易標的之信託契約、最新的公開/投資說明書、
      「中華民國證券投資信託暨顧問商業同業公會國內證券投資信託基金電子交易作業準則」、本約定書及乙方所公布之最新作業流程等規定辦理。<br>
      三、 甲方須提供現行洗錢防制法要求之證明，以證明甲方的身分。若乙方或其指定通路未能收到滿意之證明，則可要求提供進一步資料。<br>
      四、 本約定書所稱「營業日」依交易標的之信託契約定義之，交易遇到非營業日，遞延至次一營業日辦理。<br>
      五、 開戶手續：<br>
      1. 甲方憑身分證明文件及簽章辦理開戶，並簽署台中銀投信「多元理財帳戶開戶申請書」，本開戶手續以開戶約定書正本送達乙方後生效。<br>
      2. 甲方若欲採取銀行指定帳戶扣款轉帳，應填寫銀行代扣款授權書，待該授權書經其指定銀行確認簽章無誤後，寄達乙方並經輸入系統後，始能開始交易。本項限申請電子交易／定時定額投資使用。<br>
      3. 甲方於辦理開戶時，須事先以書面指定以其本人名義開立之銀行帳戶以作為進行電子交易委託之往來銀行帳戶
      ，將來請求買回時亦僅得就所指定之帳戶中作選擇或以甲方本人為受款人之記名劃線禁止背書轉讓票據支付買回價金。<br>
      4. 如甲方指定之交易帳戶有變更時，甲方應事前以書面加蓋原留簽章後通知乙方，乙方於接獲交易帳戶變更申請之通知前，就已執行交易之扣款或付款仍以原指定帳戶為準。<br>
      六、 基金之申購／買回／轉申購交易：<br>
      1. 有關「多元理財帳戶」之流程及應注意事項係依據中華民國證券投資信託暨顧問商業同業公會制定之「中華民國證券投資信託暨顧問商業同業公會證券投資信託基金募集
      、發行、銷售及申購買回作業流程」、「國內證券投資信託基金電子交易作業準則」制定，有關細節請參考本公司網站提供之網路交易操作使用說明，
      交易應依各基金之證券投資信託契約、公開/投資說明書規定及台中銀投信最新作業流程辦理。<br>
      2.
      甲方應以申購文件齊備及申購價款經銷售機構收訖無誤或匯達基金專戶日為申購日，並依申購手續完成日之基金淨值計算申購之受益權單位數，甲方如未依約定繳足申購金額及相關費用，則乙方有權認定該筆申購交易不成立。<br>
      3. 甲方應齊備相關買回文件並詳細填妥申請書，到達乙方或代理買回機構之營業日為買回申請日。甲方若持有受益憑證，應先將憑證送達乙方，方可辦理買回。<br>
      4. 買回價金限匯入以甲方名義開立之同名匯款帳戶，除透過電子交易買回或傳真交易外，非經書面申請並加蓋原留簽章之買回申請書正本送達乙方核對無誤，不得匯入其他帳戶。<br>
      5. 轉申購係以買回價金匯入轉申購基金專戶日為轉申購基金生效日。<br>
      6. 甲方若為領取受益憑證之受益人，必須先將受益憑證送達乙方方能透過電子交易或傳真申請買回。<br>
      7. 基金之申購/買回/轉申購交易截止時間請詳閱各基金之公開/投資說明書，如逾交易時間或適逢例假日，則視為次ㄧ營業日之交易指示。<br>
      七、 傳真交易：<br>
      1. 若申請以傳真方式進行交易，甲方茲授權乙方得接受、信賴甲方或甲方指定之被授權人之指示並依該指示執行基金之申購/買回/轉申購交易。甲方瞭解乙方無義務對發出指示之人予以查證。
      （但乙方仍有權依其判斷對任一指示為進一步之查證）<br>
      2. 甲方以傳真方式向乙方辦理所發行基金之申購/買回/轉申購等相關交易時，應先填妥相關申請書，並將其傳真至乙方，同時必須以電話確認該交易。若因未確認導致任一申請書漏失
      ，以致無法順利作業者，乙方得依該傳真文件逕行辦理相關交易或拒絕此項交易，無需對甲方因不實或不清之傳真指示所遭致之損害負任何賠償責任，甲方並同意放棄先訴抗辯權。<br>
      3. 甲方瞭解並同意自行承擔因錯誤及由非被授權人員所為交易指示之任何風險，乙方並無需負責因此所生之任何損失。<br>
      4. 甲方瞭解並同意若未依照本約定書之約定，交付相關交易所需之文件或未依乙方正常作業或往來交易所需，交付相關文件者，乙方得隨時
      （且無需事先通知）拒絕為甲方進行任何交易，甲方絕無任何異議，並將不對乙方主張任何權利或請求。<br>
      5. 傳真之文件因電話線路、機器故障或其他任何因素所顯示之文件內容或印鑑不清楚或無法辨識時，茲同意於甲方未另行傳真清楚足以辨認其內容及印鑑之文件予乙方收受前，乙方得拒絕接受甲方以傳真方式之交易。<br>
      6. 為確保買回價金匯入甲方帳戶，當甲方以傳真方式申請買回受益權單位時，乙方將買回價金匯入本申請書之甲方約定帳戶
      ，如甲方傳真申請買回之匯入帳戶非本申請書之約定帳戶者，乙方得拒絕以傳真交易方式申請買回之指示。<br>
      八、 電子交易使用方式、限制與規範：<br>
      1. 「電子交易服務」指乙方依本約定書所定之方式，經乙方電子交易系統所提供甲方交易委託、相關資訊及相關應用程式之服務。「電子交易委託」指透過電子交易型態進行申購
      （包含單筆及定期定額交易）、轉申購、買回乙方基金或相關交易之任何委託。「密碼」:指為執行交易及查詢個人交易之相關資訊，使用於乙方交易服務系統之使用者識別方式。
      「電子交易流程」指乙方隨時公告或修正之電子交易相關作業流程。「交易帳戶」指甲方依本約定書第五條第二項所開立並維持之銀行帳戶。「營業日」：指乙方配合主管機關所訂定之交易有效工作日。<br>
      2. 甲方申請電子交易服務，於取得使用權限後，須本人親自使用專屬密碼，通過身分驗證後始得進行交易指示。<br>
      3. 密碼經甲方設定並確認後，連同甲方之帳戶號碼，係甲方專屬使用；每次交易皆須鍵入帳戶號碼與密碼並經確認無誤始予受理。<br>
      4. 基金交易於交易時間截止前，甲方得依系統指示進行修改或取消交易。<br>
      5. 甲方瞭解基金交易經乙方人員自網路系統下載交易資料後，雖可經由查詢交易進度之功能表，查詢交易處理狀況，但無法再做任何修改或取消。<br>
      6. 甲方同意所有使用其密碼經由乙方網站交易服務系統傳輸之電子交易交易委託，均為甲方之有效指示，甲方不得以任何理由否認交易之有效性，
      乙方並得據以執行。甲方亦確實瞭解網路交易委託之法律效力與於櫃台或透過郵寄所為之效力相同。<br>
      7. 甲方同意於使用網路交易前，應先詳閱並遵守乙方最新網站之電子交易流程相關流程、通知、公告及系統狀況，並於交易執行完成後，
      應主動於乙方網站查詢交易結果及交易帳戶內之有效受益權單位數。<br>
      8. 除法令變更外，甲方每日電子交易之申購或買回金額均各以相當於新台幣三千萬元或等值外幣為上限，其中買回限額之計算，
      係以甲方為電子交易委託前最近公告之境外基金淨值及匯率為準。如甲方違反前述金額限制，乙方將不予受理。<br>
      9. 甲方保證未經授權不得竄改、修改或以任何方式變更乙方電子交易系統之任何部分，或進入乙方電子交易系統未經許可之部分。<br>
      10. 乙方應盡力維護電子交易系統傳輸訊息之安全，防止他人非法進入系統、竊取、竄改或毀損交易紀錄及資料。<br>
      11. 甲方瞭解及同意其本人為帳戶號碼與交易密碼之唯一授權使用者，甲方應妥善保管及使用，並對於使用交易密碼經由乙方電子交易系統所作之一切交易及資料變更等行為負責，
      但乙方有故意或重大過失致甲方之密碼外洩為第三人所冒用者，不在此限。<br>
      12. 乙方對於其處理甲方從事電子式交易之相關設備軟硬體，應盡善良管理人之注意義務。<br>
      13. 甲方同意電子式交易型態在資料傳輸上有不穩定之風險，甲方同意如電子交易系統傳輸，因通訊斷線、斷電、網路壅塞或發生天然災害等不可抗力事由，
      致電子交易之時間遲延、執行時之價格與指示當時之價格不同或乙方無法接收或傳送時，如該事由非因乙方之故意或重大過失所致而影響交易之結果，
      甲方須自負因此所生之風險。甲方如於乙方執行電子交易前欲更改原交易之內容，惟因通訊斷線、斷電、網路壅塞或發生天然災害等不可抗力事由，
      致乙方無法接收、傳送或即時更改者，乙方無須負責；且原電子交易之內容，對甲方仍發生效力。<br>
      14. 非可歸責於乙方之事由，包括但不限於因法令或主管機關之限制、交易市場規則、停止交易、戰爭、天災等，所致執行遲延或無法執行而造成甲方之損害，
      乙方及其代理人、代表人、受僱人無須負責。<br>
      15. 甲方同意於使用電子交易系統時，如有任何連線上之問題，應主動嘗試以其他方式與乙方聯繫並將所面臨之問題立即通知乙方。<br>
      16. 甲方同意於使用乙方電子交易服務系統時，如有下述情形，應立即通知乙方，並配合辦理相關措施：<br>
      (１) 經二十四小時未收到該電子交易委託回報或已執行之確認通知；<br>
      (２) 甲方已收到該電子交易委託回報或已執行之確認通知，但非甲方所作之指示或彼此歧異；<br>
      (３) 甲方得知其使用者密碼被他人盜用等情形；<br>
      (４) 其他有關電子交易委託所生之問題。
    </td>
  </tr>
</table>
<div style="height: 15px;"></div>
<div style="height:45px;border-top: 5px black double;">
  <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
    100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
      href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
    【11011開戶申請書-自然人】
  </div>
</div>
<!-- <div style="page-break-before: always;"></div> -->
<!-- =============================================交易規範約定及聲明事項 第一頁============================================= -->
<!-- =============================================交易規範約定及聲明事項 第二頁============================================= -->
<div>
  <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
</div>
<table class="table td">
  <tr>
    <td colspan="2" class="td td-text" style="font-size: 16px;">
      17. 如因任何甲方電子交易之交易指示將導致乙方有違反相關法令、遭受訴訟或損害之虞時，乙方得拒絕接受甲方之指示，
      其因此造成甲方之任何損失，乙方不需負責；惟乙方需在可能之時限內，通知甲方拒絕受理指示之事實。<br>
      18. 甲方應妥善保管及使用個人帳號及交易密碼，並應對於所有使用個人帳號及密碼經由台中銀投信之網路服務完成之網路委託，
      負完全責任。乙方對於甲方或第三人因網路委託所受之一切損失，不負任何責任。<br>
      19. 甲方同意為保障雙方權益，乙方得有權(但並非必須)自動監測或紀錄甲方與乙方間網路聯繫之內容，並得記錄甲方所有電子交易委託之內容。<br>
      20. 甲方應自行決定所有電子交易委託之內容，乙方或其受任何人所提供之資訊或建議，僅供參考，不論該建議是否基於甲方之要求而提供，乙方均不負任何責任。<br>
      21. 甲方瞭解乙方得調整或變更最新之電子交易交易流程，對於甲方使用乙方電子交易網路及傳真交易服務之相關事宜，具有法律拘束力。<br>
      九、 資訊提供：乙方所提供之任何市場資訊、分析報告及交易資訊，僅供甲方參考之用，甲方任何投資決策概由其自行決定。甲方完全瞭解並同意不得以乙方所提供之資訊請求任何賠償，
      乙方及其受僱人對於甲方所為之任何交易，並不負任何責任。<br>
      十、約定事項：<br>
      1. 本約定書任何條文如經法院認定為無效，僅止於該條文無效或無執行力，其他條文效力不受影響，並於履行本約定書時，該無效或無約束力條文視為不存在。<br>
      2. 甲方不得將本約定書之權利義務轉讓於任何人。<br>
      3. 甲方同意乙方得依電腦處理個人資料保護法及相關法令規定，依其營業及為甲方提供服務之目的之範圍內對甲方之相關個人資料為蒐集、利用、國際傳遞及電腦處理。並同意乙方得基於風險控管、
      稽核、客戶服務、管理、資訊提供、或為甲方之利益或依法令規定、或因主管機關、法院之要求將之提供與受乙方委託處理基金相關事務之人。<br>
      4. 所有通知事項（包括但不限於交易確認），經由郵寄、專人送達、傳真、電子郵件及簡訊等方式送達乙方受益人名簿記載之甲方或其他甲方指定之地址(包括但不限於電子郵件地址)，
      乙方原則上應於通知文件上簽署，但經由乙方網站寄送之通知（包括但不限於網路委託回報或已執行之確認通知）將視為已經乙方簽署。乙方所寄送之通知資料若有出入，甲方同意應以乙方之正確帳載為準。<br>
      5. 前項通知之送達日如下：經由郵寄方式者，以郵寄日之次一營業日為送達日；經由專人送達、電報、電話、傳真或電子郵件方式者，以通知日為送達日。<br>
      6. 於本約定書第十條所列各項資料有所變更時，甲方應立即通知乙方並依本約定書第十條之規定向乙方申請變更，就甲方疏於通知及申請變更所致之任何損失，乙方不負賠償責任。<br>
      7. 本約定書取代甲方與乙方間於本約定書簽署前就電子交易及傳真交易服務所作出之任何口頭或書面之一切溝通意見、陳述及訂立之任何合約。
      如本約定書與雙方就基金申購、買回、轉申購事宜所為其他約定不一致或有所抵觸時，甲乙雙方同意以本約定書優先適用。<br>
      8. 甲方留存簽章或授權之有權簽章倘有偽造或變造情事，經乙方盡善良管理人注意義務，仍無法以肉眼辨認而發生之任何損失，
      乙方毋須負任何賠償責任。且甲方暸解並同意若未依照相關規定交付交易所需之文件或未依乙方正常作業或往來交易所需，
      交付相關文件者，乙方得拒絕甲方進行任何交易，甲方絕無任何異議，並將不對乙方主張任何權利或請求。
      9. 本約定書如有未盡事宜悉依證券投資信託及顧問法、證券投資信託基金管理辦法、各基金證券投資信託契約、受益憑證事務處理準則、
      中華民國證券投資信託暨顧問商業同業公會國內證券投資信託基金電子交易作業準則及中華民國相關法令、函釋之規定辦理，
      前述法令及契約有修訂者，依修訂後之規定辦理，就修訂部分本約定書視為亦已修訂，不須重新簽署。<br>
      10. 任一方得隨時以書面通知他方終止本約定書，該終止通知，對於接獲該通知前已執行之電子交易委託及雙方之權利義務，均不受影響。<br>
      十一、準據法與管轄法院：本約定書以中華民國法律為準據法，並以台灣台北地方法院為第一審管轄法院。<br>
      十二、防制洗錢及打擊資恐聲明：<br>
      1. 甲方聲明已瞭解且必確實遵守本國之洗錢防制法之相關規定。<br>
      2. 甲方確認及保證所從事的業務 職務及其他活動不容易涉及不法情事。<br>
      3. 甲方保證所交付給台中銀投信的金錢或其他金融工具(例如票據)均不涉及可被懷疑之犯罪所得，或台中銀投信所提供的產品/服務並未利用作為犯罪用途的嫌疑。<br>
      4. 甲方確認並未有正面臨清算 破產之情形。<br><br>

      <b><u>基金風險預告書</u></b><br><br>

      本風險預告書係依中華民國證券投資信託暨顧問商業同業公會「證券投資信託基金募集發行銷售及申購或買回作業程序」第十七條規定訂定之。 基金之交易特性與存款、股票及其他投資工具不同，
      台端於開戶及決定交易前，應審慎評估本身之財務狀況與風險承受能力是否適合此種交易，並充分瞭解下列事項：一、基金之買賣係以自己之判斷為之，<b>台端應瞭解並承擔交易可能產生之損益，且最大可能損失達原始投資金額。</b>
      二、基金經金管會核准，惟不表示絕無風險，本公司以往之經理績效不保證基金之最低投資收益，本公司除盡善良管理人之注意義務外，不負責基金之盈虧，亦不保證最低之收益，台端申購前應詳閱基金公開/投資說明書。三、基金交易應考量之風險因素如下：<b>（一）投資標的及投資地區可能產生之風險：市場（政治、經濟、社會變動、匯率、利率、股價、指數或其他標的資產之價格波動）風險、流動性風險、信用風險、產業景氣循環變動、證券相關商品交易、法令、貨幣、流動性不足等風險。</b>（二）因前述風險、受益人大量買回或基金暫停計算買回價格等因素，或有延遲給付買回價金之可能。四、基金交易係以長期投資為目的，不宜期待於短期內獲取高收益。任何基金單位之價格及其收益均可能漲或跌，故不一定能取回全部之投資金額。<b>五、基金不受存款保險、保險安定基金或其他相關保障機制。六、如因基金交易所生紛爭，
        台端可向中華民國證券投資信託暨顧問商業同業公會或財團法人金融消費評議中心提出申訴。金融消費者應先向金融服務業提出申訴，金融服務業應於收受申訴之日起三十日內為適當之處理，並將處理結果回覆提出申訴之金融消費者；金融消費者不接受處理結果者或金融服務業逾上述期限不為處理者，金融消費者得於收受處理結果或期限屆滿之日起六十日內，向爭議處理機構申請評議。</b>本風險預告書之預告事項僅列舉大端，對於所有基金投資之風險及影響市場行情之因素無法一一詳述，
      台端於投資前除須對本風險預告書詳加研讀外，尚應審慎詳讀各基金公開/投資說明書，對其他可能之影響因素亦有所警覺，並確實作好財務規劃與風險評估，以免因貿然投資而遭到難以承受之損失。
      若您對上述有任何疑問或欲索取本公司基金公開/投資說明書，歡迎致電0800-069-555。
    </td>
  </tr>
  <tr>
    <td class="td img" style="height: 370px;width: 50%;vertical-align: top;">
      <div class="td-title" style="text-align: center;"><b>身份證黏貼欄</b> </div>
      <div style="text-align: center;">
        <img style="width: 500px;height: auto;max-width: 500px;max-height: 330px;"
          [src]="OpenMemberResponse.ProductImgList[0].ImageFile" alt="">
      </div>
    </td>
    <td class="td img" style="height: 370px;width: 50%;vertical-align: top;">
      <div class="td-title" style="text-align: center;"><b>身份證黏貼欄</b> </div>
      <div style="text-align: center;">
        <img style="width: 500px;height: auto;max-width: 500px;max-height: 330px;"
          [src]="OpenMemberResponse.ProductImgList[1].ImageFile" alt="">
      </div>
    </td>
  </tr>
</table>
<div style="height: 5px;"></div>
<div style="height:45px;border-top: 5px black double;">
  <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
    100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
      href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
    【11011開戶申請書-自然人】
  </div>
</div>
<!-- <div style="page-break-before: always;"></div> -->
<!-- =============================================交易規範約定及聲明事項 第二頁============================================= -->
<!-- =============================================銀 行 代 扣 款 授 權 書 【新台幣】 扣款銀留存============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title">
    <b> 銀　行　代　扣　款　授　權　書 </b>
  </div>
  <br>
  <table style="width: 100%;">
    <tr>
      <td style="width: 33%;"></td>
      <td class="td-title" style="width: 33%;font-size: 24px;text-align: center;"><b>【新臺幣專用】</b></td>
      <td class="td-title" style="width: 33%;font-size: 24px;text-align: right;"><b>申請日期：
          {{OpenMemberResponse.APPLY_DTTM | date:'yyyy'}}年
          {{OpenMemberResponse.APPLY_DTTM | date:'M'}}月
          {{OpenMemberResponse.APPLY_DTTM | date:'d'}}日</b>
      </td>
    </tr>
  </table>
  <table class="td" style="width: 100%;letter-spacing: 3px;line-height: 25px;">
    <tr>
      <td colspan="2" class="td-title"><span><b>※注意事項：</b></span></td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">1.</td>
      <td class="td-title">
        請受益人正確且完整填寫申請書，若有塗改請加蓋原留印鑑。填妥此申請書請寄回台中銀投信，本公司恕不受理感光紙辦理。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">2.</td>
      <td class="td-title">
        本授權書之簽訂，代表受益人已詳閱並同意下列授權扣款各項事宜;並遵守台中銀投信『多元理財帳戶開戶申請書』之交易規範-約定及聲明事項。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">3.</td>
      <td class="td-title">
        受益人填寫本授權書表示同意未來與台中銀投信往來之扣款作業得使用所列帳戶扣款，限受益人本人開立之銀行帳戶；若因銀行功能限制無法配合授權扣款，須待銀行限制取消後始可適用。
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title" style="border-top:1px black solid;">
        <b>立申請人（即扣款人）茲向　貴行（即表列之扣款銀行）申請委託，以申請人下列約定之扣款帳戶逕行轉帳扣繳下表申請人，應支付予委託單位(台中銀投信)之款項，並遵守下列約定事項：</b>
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">1.</td>
      <td class="td-title">
        申請人同意本件委託扣繳作業悉依財金資訊股份有限公司「全國性繳費（稅）系統」或依扣款機構與台中銀投信簽訂之相關業務規定辦理，並於該業務各相關作業完妥後始生效力。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">2.</td>
      <td class="td-title">
        申請人同意由　貴行依據台中銀投信提供之清單、明細表或電子媒體資料（含扣款日期、金額等），逕自申請人於　貴行開立之帳戶進行扣款轉帳作業，並將該筆款項撥入台中銀投信所指定之款項收付專戶，倘若台中銀投信所編製之清單、明細表或電子媒體有錯誤，願由申請人自行與台中銀投信處理，概與
        貴行無涉。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">3.</td>
      <td class="td-title">
        如申請人存款金額不足、帳戶遭法院、檢察署或其他機關扣押或存款帳戶結清時，貴行得不辦理扣款轉帳作業，並將結果通知台中銀投信；倘因　貴行電腦轉帳系統或全國性繳費（稅）業務系統故障、電信中斷等因素致無法交易者，貴行得順延至系統恢復正常始予扣款；其因上開事由所致之損失及責任，由申請人自行負擔。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">4.</td>
      <td class="td-title">
        貴行於同一日需自指定扣款帳戶執行多筆扣款轉帳作業而申請人存款不足時，申請人同意由　貴行自行決定扣款順序。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">5.</td>
      <td class="td-title">
        本申請書經　貴行核對帳戶留存印鑑無誤並台中銀投信通知申請人後，始得辦理扣款轉帳作業；如有更動扣款資料，由申請人自行與台中銀投信接洽。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">6.</td>
      <td class="td-title">
        申請人同意辦理本件「全國性繳費（稅）系統」業務時，委託單位得將本件資料交付予帳務代理銀行轉交
        貴行辦理；另目前<b>「全國性繳費（稅）系統」扣款金額每筆上限為新臺幣伍佰萬元整及每日最高轉帳扣款金額上限為新臺幣參仟萬元整</b>，若有變更，請依台中銀投信網站公告為準。
      </td>
    </tr>
  </table>
  <table class="td" style="width: 100%;letter-spacing: 3px;line-height: 25px;">
    <tr>
      <td class="td td2-title td-title" style="width: 15%;text-align: center;letter-spacing: 0px;font-size: 16px;">
        <b>扣款銀行</b><br>
        (限受益人本人帳戶)
      </td>
      <td class="td td-title">
        臺灣銀行、土地銀行、合作金庫、第一銀行、華南銀行、彰化銀行、上海商銀、台北富邦銀行、<br>
        國泰世華銀行、高雄銀行、兆豐商銀、台灣中小企銀、渣打銀行、台中商銀、京城銀行、<br>
        瑞興商業銀行、華泰商銀、新光銀行、陽信銀行、板信銀行、聯邦銀行、遠東國際商銀、<br>
        元大商銀、永豐銀行、玉山商銀、凱基商銀、星展銀行、台新商銀、日盛國際商銀、<br>
        安泰商銀
        (上述銀行請擇一填寫於下方表格)
      </td>
    </tr>
  </table>
  <br>
  <div class="td-title" style="text-align: center;font-size: 24px;"><b>全國性繳費業務暨申購授權轉帳繳款申請書　(台中銀投信留存聯)</b> </div>
  <table class="td" style="width: 100%;height: 400px;letter-spacing: 3px;line-height: 25px;">
    <tr>
      <td colspan="2" class="td td-title" style="width: 10%;text-align: center;">申請人姓名<br>
        (受益人)
      </td>
      <td colspan="10" class="td td-title" style="width: 60%;text-align: center;">
        <b>{{OpenMemberResponse.BF_NAME}}</b>
      </td>
      <td rowspan="6" class="td td-text" style="width: 30%;text-align: center;vertical-align: top;">
        <b> 請簽蓋扣款帳戶留存印鑑<br>
          <span style="letter-spacing: 0px;">(印鑑式樣必須與銀行之留存印鑑一致)</span>
        </b>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td td-title" style="text-align: center;">身分證字號</td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,0,1)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,1,2)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,2,3)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,3,4)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,4,5)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,5,6)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,6,7)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,7,8)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,8,9)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,9,10)}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td td-title" style="width: 15%;text-align: center;">銀行</td>
      <td colspan="4" class="td td-title" style="width: 15%;text-align: center;">
        <b>{{OpenMemberResponse.CusAccount[0].BANK_HQ_NAME}}</b>
      </td>
      <td colspan="2" class="td td-title" style="width: 20%;text-align: center;">分行</td>
      <td colspan="4" class="td td-title" style="width: 20%;text-align: center;">
        <b>{{strsubstrBRH(OpenMemberResponse.CusAccount[0].BANK_HQ_NAME,OpenMemberResponse.CusAccount[0].BANK_BRH_NAME)}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td td-title" style="width: 15%;text-align: center;">帳號</td>
      <td colspan="10" class="td td-title" style="width: 15%;text-align: center;">
        <b>{{OpenMemberResponse.CusAccount[0].ACCOUNT_NO}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="12" class="td td-title" style="height: 100px;vertical-align: middle;">
        <div class="td-title" style="text-align: center;">全國性繳費（稅）業務帳務代理銀行：兆豐國際商業銀行世貿分行</div>
        <table style="width: 100%;text-align: center;">
          <tr>
            <td colspan="2" class="td" style="width: 20%;">費用類別</td>
            <td colspan="2" class="td" style="width: 20%;">委託單位</td>
            <td colspan="5" class="td" style="width: 60%;">扣款銀行填載</td>
          </tr>
          <tr>
            <td class="td">名稱</td>
            <td class="td">代碼</td>
            <td class="td">名稱</td>
            <td class="td">代碼</td>
            <td class="td">銀行</td>
            <td class="td">分行</td>
            <td class="td">經辦</td>
            <td class="td">主管</td>
            <td class="td">日期</td>
          </tr>
          <tr>
            <td class="td" style="text-align:center"> 基金<br> 扣款</td>
            <td class="td" style="text-align:center">00001</td>
            <td class="td" style="text-align:center">台中銀投信</td>
            <td class="td" style="text-align:center">10002031</td>
            <td class="td"></td>
            <td class="td"></td>
            <td class="td"></td>
            <td class="td"></td>
            <td class="td"></td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <div style="height: 40px;"></div>
  <div style="height:45px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<div style="page-break-before:left;"></div>
<!-- =============================================銀 行 代 扣 款 授 權 書 【新台幣】 扣款銀留存============================================= -->
<!-- =============================================銀 行 代 扣 款 授 權 書 【新台幣】 台中銀留存============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title">
    <b> 銀　行　代　扣　款　授　權　書 </b>
  </div>
  <br>
  <table style="width: 100%;">
    <tr>
      <td style="width: 33%;"></td>
      <td class="td-title" style="width: 33%;font-size: 24px;text-align: center;"><b>【新臺幣專用】</b></td>
      <td class="td-title" style="width: 33%;font-size: 24px;text-align: right;"><b>申請日期：
          {{OpenMemberResponse.APPLY_DTTM | date:'yyyy'}}年
          {{OpenMemberResponse.APPLY_DTTM | date:'M'}}月
          {{OpenMemberResponse.APPLY_DTTM | date:'d'}}日</b></td>
    </tr>
  </table>
  <table class="td" style="width: 100%;letter-spacing: 3px;line-height: 25px;">
    <tr>
      <td colspan="2" class="td-title"><span><b>※注意事項：</b></span></td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">1.</td>
      <td class="td-title">
        請受益人正確且完整填寫申請書，若有塗改請加蓋原留印鑑。填妥此申請書請寄回台中銀投信，本公司恕不受理感光紙辦理。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">2.</td>
      <td class="td-title">
        本授權書之簽訂，代表受益人已詳閱並同意下列授權扣款各項事宜;並遵守台中銀投信『多元理財帳戶開戶申請書』之交易規範-約定及聲明事項。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">3.</td>
      <td class="td-title">
        受益人填寫本授權書表示同意未來與台中銀投信往來之扣款作業得使用所列帳戶扣款，限受益人本人開立之銀行帳戶；若因銀行功能限制無法配合授權扣款，須待銀行限制取消後始可適用。
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title" style="border-top:1px black solid;">
        <b>立申請人（即扣款人）茲向　貴行（即表列之扣款銀行）申請委託，以申請人下列約定之扣款帳戶逕行轉帳扣繳下表申請人，應支付予委託單位(台中銀投信)之款項，並遵守下列約定事項：</b>
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">1.</td>
      <td class="td-title">
        申請人同意本件委託扣繳作業悉依財金資訊股份有限公司「全國性繳費（稅）系統」或依扣款機構與台中銀投信簽訂之相關業務規定辦理，並於該業務各相關作業完妥後始生效力。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">2.</td>
      <td class="td-title">
        申請人同意由　貴行依據台中銀投信提供之清單、明細表或電子媒體資料（含扣款日期、金額等），逕自申請人於　貴行開立之帳戶進行扣款轉帳作業，並將該筆款項撥入台中銀投信所指定之款項收付專戶，倘若台中銀投信所編製之清單、明細表或電子媒體有錯誤，願由申請人自行與台中銀投信處理，概與
        貴行無涉。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">3.</td>
      <td class="td-title">
        如申請人存款金額不足、帳戶遭法院、檢察署或其他機關扣押或存款帳戶結清時，貴行得不辦理扣款轉帳作業，並將結果通知台中銀投信；倘因　貴行電腦轉帳系統或全國性繳費（稅）業務系統故障、電信中斷等因素致無法交易者，貴行得順延至系統恢復正常始予扣款；其因上開事由所致之損失及責任，由申請人自行負擔。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">4.</td>
      <td class="td-title">
        貴行於同一日需自指定扣款帳戶執行多筆扣款轉帳作業而申請人存款不足時，申請人同意由　貴行自行決定扣款順序。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">5.</td>
      <td class="td-title">
        本申請書經　貴行核對帳戶留存印鑑無誤並台中銀投信通知申請人後，始得辦理扣款轉帳作業；如有更動扣款資料，由申請人自行與台中銀投信接洽。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;">6.</td>
      <td class="td-title">
        申請人同意辦理本件「全國性繳費（稅）系統」業務時，委託單位得將本件資料交付予帳務代理銀行轉交
        貴行辦理；另目前<b>「全國性繳費（稅）系統」扣款金額每筆上限為新臺幣伍佰萬元整及每日最高轉帳扣款金額上限為新臺幣參仟萬元整</b>，若有變更，請依台中銀投信網站公告為準。
      </td>
    </tr>
  </table>
  <table class="td" style="width: 100%;letter-spacing: 3px;line-height: 25px;">
    <tr>
      <td class="td td2-title td-title" style="width: 15%;text-align: center;letter-spacing: 0px;font-size: 16px;">
        <b>扣款銀行</b><br>
        (限受益人本人帳戶)
      </td>
      <td class="td td-title">
        臺灣銀行、土地銀行、合作金庫、第一銀行、華南銀行、彰化銀行、上海商銀、台北富邦銀行、<br>
        國泰世華銀行、高雄銀行、兆豐商銀、台灣中小企銀、渣打銀行、台中商銀、京城銀行、<br>
        瑞興商業銀行、華泰商銀、新光銀行、陽信銀行、板信銀行、聯邦銀行、遠東國際商銀、<br>
        元大商銀、永豐銀行、玉山商銀、凱基商銀、星展銀行、台新商銀、日盛國際商銀、<br>
        安泰商銀
        (上述銀行請擇一填寫於下方表格)
      </td>
    </tr>
  </table>
  <br>
  <div class="td-title" style="text-align: center;font-size: 24px;"><b>全國性繳費業務暨申購授權轉帳繳款申請書　(台中銀投信留存聯)</b> </div>
  <table class="td" style="width: 100%;height: 400px;letter-spacing: 2px;line-height: 25px;">
    <tr>
      <td colspan="2" class="td td-title" style="width: 10%;text-align: center;">申請人姓名<br>
        (受益人)
      </td>
      <td colspan="10" class="td td-title" style="width: 60%;text-align: center;"><b>{{OpenMemberResponse.BF_NAME}}</b>
      </td>
      <td rowspan="6" class="td td-text" style="width: 30%;text-align: center;vertical-align: top;">
        <b> 請簽蓋扣款帳戶留存印鑑<br>
          <span style="letter-spacing: 0px;">(印鑑式樣必須與銀行之留存印鑑一致)</span>
        </b>
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td td-title" style="text-align: center;">身分證字號</td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,0,1)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,1,2)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,2,3)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,3,4)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,4,5)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,5,6)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,6,7)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,7,8)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,8,9)}}</b>
      </td>
      <td class="td td-title" style="width: 6%;text-align: center;"><b>{{strsubstr(OpenMemberResponse.ID_NO,9,10)}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="12" class="td td-title">
        <span *ngIf="Brh == '004'" class="check">■</span>
        <span *ngIf="Brh != '004'" class="check">□</span>
        臺灣銀行
        <span *ngIf="Brh == '005'" class="check">■</span>
        <span *ngIf="Brh != '005'" class="check">□</span>
        土地銀行
        <span *ngIf="Brh == '006'" class="check">■</span>
        <span *ngIf="Brh != '006'" class="check">□</span>
        合作金庫
        <span *ngIf="Brh == '007'" class="check">■</span>
        <span *ngIf="Brh != '007'" class="check">□</span>
        第一銀行
        <span *ngIf="Brh == '008'" class="check">■</span>
        <span *ngIf="Brh != '008'" class="check">□</span>
        華南銀行
        <span *ngIf="Brh == '009'" class="check">■</span>
        <span *ngIf="Brh != '009'" class="check">□</span>
        彰化銀行<br>
        <span *ngIf="Brh == '011'" class="check">■</span>
        <span *ngIf="Brh != '011'" class="check">□</span>
        上海商銀
        <span *ngIf="Brh == '012'" class="check">■</span>
        <span *ngIf="Brh != '012'" class="check">□</span>
        台北富邦銀行
        <span *ngIf="Brh == '013'" class="check">■</span>
        <span *ngIf="Brh != '013'" class="check">□</span>
        國泰世華銀行
        <span *ngIf="Brh == '016'" class="check">■</span>
        <span *ngIf="Brh != '016'" class="check">□</span>
        高雄銀行
        <span *ngIf="Brh == '017'" class="check">■</span>
        <span *ngIf="Brh != '017'" class="check">□</span>
        兆豐商銀<br>
        <span *ngIf="Brh == '050'" class="check">■</span>
        <span *ngIf="Brh != '050'" class="check">□</span>
        台灣中小企銀
        <span *ngIf="Brh == '052'" class="check">■</span>
        <span *ngIf="Brh != '052'" class="check">□</span>
        渣打銀行
        <span *ngIf="Brh == '053'" class="check">■</span>
        <span *ngIf="Brh != '053'" class="check">□</span>
        台中商銀
        <span *ngIf="Brh == '054'" class="check">■</span>
        <span *ngIf="Brh != '054'" class="check">□</span>
        京城銀行
        <span *ngIf="Brh == '101'" class="check">■</span>
        <span *ngIf="Brh != '101'" class="check">□</span>
        瑞興商業銀行<br>
        <span *ngIf="Brh == '102'" class="check">■</span>
        <span *ngIf="Brh != '102'" class="check">□</span>
        華泰商銀
        <span *ngIf="Brh == '103'" class="check">■</span>
        <span *ngIf="Brh != '103'" class="check">□</span>
        新光銀行
        <span *ngIf="Brh == '108'" class="check">■</span>
        <span *ngIf="Brh != '108'" class="check">□</span>
        陽信銀行
        <span *ngIf="Brh == '118'" class="check">■</span>
        <span *ngIf="Brh != '118'" class="check">□</span>
        板信銀行
        <span *ngIf="Brh == '803'" class="check">■</span>
        <span *ngIf="Brh != '803'" class="check">□</span>
        聯邦銀行
        <span *ngIf="Brh == '805'" class="check">■</span>
        <span *ngIf="Brh != '805'" class="check">□</span>
        遠東國際商銀<br>
        <span *ngIf="Brh == '806'" class="check">■</span>
        <span *ngIf="Brh != '806'" class="check">□</span>
        元大商銀
        <span *ngIf="Brh == '807'" class="check">■</span>
        <span *ngIf="Brh != '807'" class="check">□</span>
        永豐銀行
        <span *ngIf="Brh == '808'" class="check">■</span>
        <span *ngIf="Brh != '808'" class="check">□</span>
        玉山商銀
        <span *ngIf="Brh == '809'" class="check">■</span>
        <span *ngIf="Brh != '809'" class="check">□</span>
        凱基商銀
        <span *ngIf="Brh == '810'" class="check">■</span>
        <span *ngIf="Brh != '810'" class="check">□</span>
        星展銀行
        <span *ngIf="Brh == '812'" class="check">■</span>
        <span *ngIf="Brh != '812'" class="check">□</span>
        台新商銀<br>
        <span *ngIf="Brh == '815'" class="check">■</span>
        <span *ngIf="Brh != '815'" class="check">□</span>
        日盛國際商銀
        <span *ngIf="Brh == '816'" class="check">■</span>
        <span *ngIf="Brh != '816'" class="check">□</span>
        安泰商銀
        (上述銀行請擇一勾選)
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td td-title" style="width: 15%;text-align: center;">分行</td>
      <td colspan="3" class="td td-title" style="width: 15%;text-align: center;">
        <b>{{strsubstrBRH(OpenMemberResponse.CusAccount[0].BANK_HQ_NAME,OpenMemberResponse.CusAccount[0].BANK_BRH_NAME)}}</b>
      </td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">帳號</td>
      <td colspan="4" class="td td-title" style="width: 20%;text-align: center;">
        <b>{{OpenMemberResponse.CusAccount[0].ACCOUNT_NO}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="12" class="td td-title" style="height: 100px;">
        費用類別：基金扣款 代碼：00001 委託單位：台中銀投信 代碼：10002031 <br>
        全國性繳費（稅）業務帳務代理銀行：兆豐國際商業銀行世貿分行
      </td>
    </tr>
  </table>
  <div style="height: 40px;"></div>
  <div style="height:45px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<div style="page-break-before:left;"></div>
<!-- =============================================銀 行 代 扣 款 授 權 書 【新台幣】 台中銀留存============================================= -->
<!-- =============================================銀 行 代 扣 款 授 權 書 【外幣】 投信公司留存============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title">
    <b> 銀　行　代　扣　款　授　權　書 </b>
  </div>
  <table style="width: 100%;">
    <tr>
      <td style="width: 33%;"></td>
      <td class="td-title" style="width: 33%;font-size: 24px;text-align: center;"><b>【外幣專用】</b></td>
      <td class="td-title" style="width: 33%;font-size: 24px;text-align: right;"><b>申請日期：
          {{OpenMemberResponse.APPLY_DTTM | date:'yyyy'}}年
          {{OpenMemberResponse.APPLY_DTTM | date:'M'}}月
          {{OpenMemberResponse.APPLY_DTTM | date:'d'}}日</b></td>
    </tr>
  </table>
  <table class="td" style="width: 100%;">
    <tr>
      <td colspan="2" class="td-title"><span><b>※注意事項：</b></span></td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;text-align: right;">1.</td>
      <td class="td-title">
        請受益人正確且完整填寫申請書，若有塗改請加蓋原留印鑑。填妥此申請書請寄回台中銀投信，本公司恕不受理感光紙辦理。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;text-align: right;">2.</td>
      <td class="td-title">
        本授權書之簽訂，代表受益人已詳閱並同意下列授權扣款各項事宜;並遵守台中銀投信『多元理財帳戶開戶申請書』之交易規範-約定及聲明事項。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;text-align: right;">3.</td>
      <td class="td-title">
        受益人填寫本授權書表示同意未來與台中銀投信往來之扣款作業得使用所列帳戶扣款，限受益人本人開立之銀行帳戶；若因銀行功能限制無法配合授權扣款，須待銀行限制取消後始可適用。
      </td>
    </tr>
    <tr>
      <td colspan="2" class="td-title" style="border-top: 1px black solid;">
        立申請人<b>（即扣款人）</b>茲同意台中銀證券投資信託股份有限公司透過台灣票據交換所及財金資訊股份有限公司合作之外幣代收服務平台機制，依照表列資料，自本人外幣存款帳戶劃付費用，並遵守代繳銀行、台灣票據交換所、財金資訊股份有限公司有關規定。本人亦同意發動者將表列資料提供予貴行、發動行、台灣票據交換所及財金資訊股份有限公司，並已詳閱且同意以下個人資料之蒐集、處理及利用相關告知事項。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">一、</td>
      <td class="td-title">
        申請人同意本委託扣繳作業悉依外幣代收服務平台機制之相關規定辦理，並於該業務各相關作業完妥後始生效力。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">二、</td>
      <td class="td-title">
        申請人同意由　貴行依據台中銀投信提供之清單、明細表或電子媒體資料（含扣款日期、金額等），逕自申請人於　貴行開立之帳戶進行扣款轉帳作業，並將該筆款項撥入台中銀投信所指定之款項收付專戶，倘若台中銀投信所編製之清單、明細表或電子媒體有錯誤，願由申請人自行與台中銀投信處理，概與
        貴行無涉。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">三、</td>
      <td class="td-title">
        如申請人存款金額不足、帳戶遭法院、檢察署或其他機關扣押或存款帳戶結清時，貴行得不辦理扣款轉帳作業，並將結果通知台中銀投信；其因上開事由所致之損失及責任，由申請人自行負擔。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">四、</td>
      <td class="td-title">
        外幣代收服務平台、貴行及發動行如因系統故障、電信中斷等因素致無法交易者，申請人同意取消該扣款轉帳交易；其因上開事由所致之損失及責任，申請人不得向貴行發動行及發動者請求損害賠償。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">五、</td>
      <td class="td-title">
        貴行於同一日需自指定扣款帳戶執行多筆扣款轉帳作業而申請人存款不足時，申請人同意由　貴行自行決定扣款順序。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">六、</td>
      <td class="td-title">
        本申請書經　貴行核對帳戶留存印鑑無誤並台中銀投信通知申請人後，始得辦理扣款轉帳作業；如有更動扣款資料，由申請人自行與台中銀投信接洽。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">七、</td>
      <td class="td-title">
        申請人同意本扣款轉帳作業應依外幣代收服務平台機制之相關規定辦理，並於該業務相關作業完妥後始生效；另依現行業務之轉帳規定，<b><u>單筆最高轉帳扣繳限額為等值新臺幣伍百萬元，每日最高轉帳扣繳限額為等值新臺幣參仟萬元。</u></b>但前述金額經調整者，依調整後金額定之。各扣款銀行或有不同限額限制，申購人申購前應自行與該扣款銀行確認相關業務之額度限制，並依該額度限制來申購基金，以避免額度限制造成扣款失敗。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">八、</td>
      <td class="td-title">
        個人資料保護告知事項:為辦理本項自動扣款轉帳業務，茲蒐集扣款人之個人資料，其蒐集之目的、類別、利用之期間、地區、對象及方式，以及其他相關應告知事項如下：
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">
        <!-- 對齊格式留空 -->
      </td>
      <td class="td-title">
        <table>
          <tr>
            <td class="td-title" style="width: 1%;vertical-align: top;">(一)</td>
            <td class="td-title">
              目的：辦理本項自動扣款轉帳業務之用。
            </td>
          </tr>
          <tr>
            <td class="td-title" style="width: 1%;vertical-align: top;">(二)</td>
            <td class="td-title">
              個人資料類別：身分證統一編號、金融機構帳戶之號碼及其他上表所列之個人資料。
            </td>
          </tr>
          <tr>
            <td class="td-title" style="width: 1%;vertical-align: top;">(三)</td>
            <td class="td-title">
              利用之期間、地區、對象及方式：
            </td>
          </tr>
          <tr>
            <td class="td-title" style="width: 1%;vertical-align: top;">
              <!-- 對齊格式留空 -->
            </td>
            <td class="td-title">
              <table>
                <tr>
                  <td class="td-title" style="width: 1%;vertical-align: top;">1、</td>
                  <td class="td-title">
                    期間：個人資料蒐集之特定目的存續期間、依相關法令或契約約定之保存年限，或發動行、台灣票據交換所、財金資訊股份有限公司及扣款行因執行業務所必須之保存期間。
                  </td>
                </tr>
                <tr>
                  <td class="td-title" style="width: 1%;vertical-align: top;">2、</td>
                  <td class="td-title">
                    地區：本國、參加台灣票據交換所及財金資訊股份有限公司合作之外幣代收服務平台之金融機構所在地、依法有調查權機關或主管機關所在地或其指定之調查地。
                  </td>
                </tr>
                <tr>
                  <td class="td-title" style="width: 1%;vertical-align: top;">3、</td>
                  <td class="td-title">
                    對象：發動行、台灣票據交換所、財金資訊股份有限公司、扣款行、依法有調查權機關或主管機關。
                  </td>
                </tr>
                <tr>
                  <td class="td-title" style="width: 1%;vertical-align: top;">4、</td>
                  <td class="td-title">
                    方式：以自動化機器或其他非自動化之利用方式。
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td class="td-title" style="width: 1%;vertical-align: top;">九、</td>
      <td class="td-title">
        扣款銀行(限受益人本人帳戶)，請自以下銀行擇一選擇:
        臺灣銀行、土地銀行、合作金庫、第一銀行、華南銀行、彰化銀行、上海商銀、國泰世華銀行、高雄銀行、兆豐銀行、台中商銀、京城銀行、華泰銀行、新光銀行、三信商銀、元大銀行、永豐銀行、玉山銀行、台新銀行、日盛銀行、安泰銀行。
      </td>
    </tr>
  </table>
  <br>
  <div class="td-title" style="text-align: center;font-size: 24px;"><b>委託轉帳代繳外幣業務費用授權書　(投信公司留存聯)</b> </div>
  <table class="td" style="width: 100%;height: 200px;letter-spacing: 3px;line-height: 25px;">
    <tr>
      <td colspan="4" class="td td-title" style="width: 5%;text-align: center;">申請人姓名<br>
        (同受益人)
      </td>
      <td colspan="8" class="td td-title" style="width: 60%;text-align: center;">
        <b>{{OpenMemberResponse.BF_NAME}}</b>
      </td>
      <td rowspan="5" class="td td-text" style="width: 30%;text-align: center;vertical-align: top;">
        <b>請簽蓋扣款帳戶留存印鑑 </b>
      </td>
    </tr>
    <tr>
      <td colspan="4" class="td td-title" style="text-align: center;">申請人英文戶名</td>
      <td colspan="8" class="td td-title" style="text-align: center;">
        <b>{{OpenMemberResponse.BF_NAME_E}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="4" class="td td-title" style="text-align: center;">
        身分證字號
      </td>
      <td colspan="8" class="td td-title" style="text-align: center;">
        <b>{{OpenMemberResponse.ID_NO}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">銀行</td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">
        <b>{{OpenMemberResponse.CusAccount4[0].BANK_HQ_NAME}}</b>
      </td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">分行</td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">
        <b>{{strsubstrBRH(OpenMemberResponse.CusAccount4[0].BANK_HQ_NAME,OpenMemberResponse.CusAccount4[0].BANK_BRH_NAME)}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="1" class="td td-title" style="text-align: center;">扣款幣別</td>
      <td colspan="2" class="td td-title" style="text-align: center;"><b>綜合存款</b></td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">帳號</td>
      <td colspan="6" class="td td-title" style="width: 20%;text-align: center;">
        <b>{{OpenMemberResponse.CusAccount4[0].ACCOUNT_NO}}</b>
      </td>
    </tr>
  </table>
  <br>
  <table class="td" style="width: 100%;">
    <tr>
      <td class="td td-title">發動者(公司/機構)名稱</td>
      <td class="td td-title">台中銀證券投資信託股份有限公司</td>
      <td class="td td-title">發動者統一編號</td>
      <td class="td td-title">89395580</td>
    </tr>
    <tr>
      <td class="td td-title">交易項目</td>
      <td class="td td-title">基金費</td>
      <td class="td td-title">交易代號</td>
      <td class="td td-title">F10</td>
    </tr>
    <tr>
      <td class="td td-title">發動行名稱</td>
      <td class="td td-title">兆豐國際商業銀行世貿分行</td>
      <td class="td td-title">發動行代號</td>
      <td class="td td-title">0172163</td>
    </tr>
  </table>
  <div style="height: 30px;"></div>
  <div style="height:45px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<div style="page-break-before:left;"></div>
<!-- =============================================銀 行 代 扣 款 授 權 書 【外幣】 投信公司留存============================================= -->
<!-- =============================================銀 行 代 扣 款 授 權 書 【外幣】 扣款銀行留存============================================= -->
<div class="table">
  <div>
    <img id="ctl00_Image1" src="./assets/images/tcbHeader.jpg" style="height:59px;width:1066px;border-width:0px;">
  </div>
  <div class="title">
    <b>委託轉帳代繳外幣業務費用授權書</b>
  </div>
  <br>
  <table style="width: 100%;">
    <tr>
      <td style="width: 33%;"></td>
      <td class="td-title" style="width: 33%;font-size: 24px;text-align: center;"><b>【外幣專用】</b></td>
      <td class="td-title" style="width: 33%;font-size: 24px;text-align: right;"><b>申請日期：
          {{OpenMemberResponse.APPLY_DTTM | date:'yyyy'}}年
          {{OpenMemberResponse.APPLY_DTTM | date:'M'}}月
          {{OpenMemberResponse.APPLY_DTTM | date:'d'}}日</b></td>
    </tr>
  </table>
  <table style="width: 100%;letter-spacing: 3px;line-height: 25px;">
    <tr>
      <td colspan="2" class="td-title">
        立約定書人<b>（即委繳戶）</b>茲同意台中銀證券投資信託股份有限公司透過台灣票據交換所及財金資訊股份有限公司合作之外幣代收服務平台機制，依照表列資料，自本人外幣存款帳戶劃付費用，並遵守代繳銀行、台灣票據交換所、財金資訊股份有限公司有關規定。本人亦同意發動者將表列資料提供予貴行、發動行、台灣票據交換所及財金資訊股份有限公司，並已詳閱且同意以下個人資料之蒐集、
        處理及利用相關告知事項。
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;width: 1%;">
        一、
      </td>
      <td class="td-title">
        個人資料保護告知事項:為辦理本項自動扣款轉帳業務，茲蒐集扣款人之個人資料，其蒐集之目的、類別、利用之期間、地區、對象及方式，以及其他相關應告知事項如下：
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;width: 1%;">
        <!-- 格式對齊留空 -->
      </td>
      <td class="td-title">
        <table>
          <tr>
            <td class="td-title" style="vertical-align: top;width: 1%;">
              (一)
            </td>
            <td class="td-title">
              目的：辦理本項自動扣款轉帳業務之用。
            </td>
          </tr>
          <tr>
            <td class="td-title" style="vertical-align: top;width: 1%;">
              (二)
            </td>
            <td class="td-title">
              個人資料類別：身分證統一編號、金融機構帳戶之號碼及其他上表所列之個人資料。
            </td>
          </tr>
          <tr>
            <td class="td-title" style="vertical-align: top;width: 1%;">
              (三)
            </td>
            <td class="td-title">
              利用之期間、地區、對象及方式：
            </td>
          </tr>
          <tr>
            <td class="td-title" style="vertical-align: top;width: 1%;">
              <!-- 格式對齊留空 -->
            </td>
            <td class="td-title">
              <table>
                <tr>
                  <td class="td-title" style="vertical-align: top;width: 1%;">
                    1、
                  </td>
                  <td class="td-title">
                    期間：個人資料蒐集之特定目的存續期間、依相關法令或契約約定之保存年限，或發動行、台灣票據交換所、財金資訊股份有限公司及扣款行因執行業務所必須之保存期間。
                  </td>
                </tr>
                <tr>
                  <td class="td-title" style="vertical-align: top;width: 1%;">
                    2、
                  </td>
                  <td class="td-title">
                    地區：本國、參加台灣票據交換所及財金資訊股份有限公司合作之外幣代收服務平台之金融機構所在地、依法有調查權機關或主管機關所在地或其指定之調查地。
                  </td>
                </tr>
                <tr>
                  <td class="td-title" style="vertical-align: top;width: 1%;">
                    3、
                  </td>
                  <td class="td-title">
                    對象：發動行、台灣票據交換所、財金資訊股份有限公司、扣款行、依法有調查權機關或主管機關。
                  </td>
                </tr>
                <tr>
                  <td class="td-title" style="vertical-align: top;width: 1%;">
                    4、
                  </td>
                  <td class="td-title">
                    方式：以自動化機器或其他非自動化之利用方式。
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td class="td-title" style="vertical-align: top;width: 1%;">
        二、
      </td>
      <td class="td-title">
        申購人同意本扣款轉帳作業應依外幣代收服務平台機制之相關規定辦理，並於該業務相關作業完妥後始生效；另依現行業務之轉帳規定，<b><u>單筆最高轉帳扣繳限額為等值新臺幣伍百萬元，每日最高轉帳扣繳限額為等值新臺幣參仟萬元</u></b>。但前述金額經調整者，依調整後金額定之。各扣款銀行或有不同限額限制，申購人申購前應自行與該扣款銀行確認相關業務之額度限制，並依該額度限制來申購基金，以避免額度限制造成扣款失敗。
      </td>
    </tr>
  </table>
  <br>
  <div class="td td-title"
    style="width: 98%;letter-spacing: 3px;line-height: 25px;padding-left: 10px;padding-right: 10px;">
    扣款銀行(限受益人本人帳戶)，請自以下銀行擇一選擇:
    臺灣銀行、土地銀行、合作金庫、第一銀行、華南銀行、彰化銀行、上海商銀、國泰世華銀行、高雄銀行、兆豐銀行、台中商銀、京城銀行、華泰銀行、新光銀行、三信商銀、元大銀行、永豐銀行、玉山銀行、台新銀行、日盛銀行、安泰銀行。
  </div>
  <br>
  <div class="td-title" style="text-align: center;font-size: 24px;"><b>委託轉帳代繳外幣業務費用授權書　(扣款銀行留存聯)</b> </div>
  <table class="td" style="width: 100%;height: 350px;letter-spacing: 3px;line-height: 25px;">
    <tr>
      <td colspan="4" class="td td-title" style="width: 5%;text-align: center;">委繳戶名稱<br>
        (受益人)
      </td>
      <td colspan="8" class="td td-title" style="width: 60%;text-align: center;">
        <b>{{OpenMemberResponse.BF_NAME}}</b>
      </td>
      <td rowspan="5" class="td td-text" style="width: 30%;text-align: center;vertical-align: top;">
        <b>請簽蓋扣款帳戶留存印鑑 </b>
      </td>
    </tr>
    <tr>
      <td colspan="4" class="td td-title" style="text-align: center;">委繳戶英文戶名</td>
      <td colspan="8" class="td td-title" style="text-align: center;">
        <b>{{OpenMemberResponse.BF_NAME_E}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="4" class="td td-title" style="text-align: center;height: 30px;">
        身分證字號<br>
        (用戶ID)
      </td>
      <td colspan="8" class="td td-title" style="text-align: center;">
        <b>{{OpenMemberResponse.ID_NO}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">銀行</td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">
        <b>{{OpenMemberResponse.CusAccount4[0].BANK_HQ_NAME}}</b>
      </td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">分行</td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">
        <b>{{strsubstrBRH(OpenMemberResponse.CusAccount4[0].BANK_HQ_NAME,OpenMemberResponse.CusAccount4[0].BANK_BRH_NAME)}}</b>
      </td>
    </tr>
    <tr>
      <td colspan="1" class="td td-title" style="text-align: center;">扣款幣別</td>
      <td colspan="2" class="td td-title" style="text-align: center;"><b>綜合存款</b></td>
      <td colspan="3" class="td td-title" style="width: 20%;text-align: center;">帳號</td>
      <td colspan="6" class="td td-title" style="width: 20%;text-align: center;">
        <b>{{OpenMemberResponse.CusAccount4[0].ACCOUNT_NO}}</b>
      </td>
    </tr>
  </table>
  <br>
  <table class="td" style="width: 100%;">
    <tr>
      <td class="td td-title">發動者(公司/機構)名稱</td>
      <td class="td td-title">台中銀證券投資信託股份有限公司</td>
      <td class="td td-title">發動者統一編號</td>
      <td class="td td-title">89395580</td>
    </tr>
    <tr>
      <td class="td td-title">交易項目</td>
      <td class="td td-title">基金費</td>
      <td class="td td-title">交易代號</td>
      <td class="td td-title">F10</td>
    </tr>
    <tr>
      <td class="td td-title">發動行名稱</td>
      <td class="td td-title">兆豐國際商業銀行世貿分行</td>
      <td class="td td-title">發動行代號</td>
      <td class="td td-title">0172163</td>
    </tr>
  </table>
  <br>
  <table class="td" style="width: 100%;">
    <tr style="text-align: center;">
      <td rowspan="2" class="td td-title" style="width: 20%;">扣款銀行填載</td>
      <td class="td td-title">銀行</td>
      <td class="td td-title">分行</td>
      <td class="td td-title">經辦</td>
      <td class="td td-title">主管</td>
      <td class="td td-title">日期</td>
    </tr>
    <tr style="text-align: center;">
      <td class="td" style="height: 70px;"></td>
      <td class="td" style="height: 70px;"></td>
      <td class="td" style="height: 70px;"></td>
      <td class="td" style="height: 70px;"></td>
      <td class="td" style="height: 70px;"></td>
    </tr>
  </table>
  <div style="height: 5px;"></div>
  <div style="height:45px;border-top: 5px black double;">
    <div style="font-size: 15px;padding-top: 5px;font-family: 標楷體;text-align: center;">
      100510台北市中正區新生南路1段50號4樓 電話：(02)2351-1707 傳真：(02)2321-6053 網址：<a
        href="https://www.tcbsitc.com.tw">https://www.tcbsitc.com.tw</a>
      【11011開戶申請書-自然人】
    </div>
  </div>
</div>
<!-- =============================================銀 行 代 扣 款 授 權 書 【外幣】 扣款銀行留存============================================= -->
