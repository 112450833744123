import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MenuModel } from 'src/app/model/menu';

declare function loginInitPage1(): any; // just change here from arun answer.
declare function loginInitPage2(): any; // just change here from arun answer.
declare function loginInitPage3(): any; // just change here from arun answer.

@Component({
  selector: 'app-logout-navbar',
  templateUrl: './logout-navbar.component.html',
  styleUrls: ['./logout-navbar.component.css']
})
export class LogoutNavbarComponent implements OnInit {
  showList = false; // nick
  menus = [
    {
      "name": "我的e帳戶",
      "class": "fab fa-internet-explorer",
      "url": "",
      "isOpen": true,
      "childMenu": [
        {
          "name": "線上交易",
          "class": "",
          "url": "login",
          "checked": true
        },
        {
          "name": "開戶進度查詢",
          "class": "",
          "url": "EstablishAccountSearch",
          "checked": false
        },
        {
          "name": "立即開戶",
          "class": "",
          "url": "disclaimer",
          "checked": false
        }
      ]
    }
  ] as MenuModel[];

  constructor() {
    this.memory();
  }

  ngOnInit(): void {
    loginInitPage1();
    loginInitPage2();
    loginInitPage3();
  }

  check(data: any) {
    console.log(data.innerText);
    // console.log(data)
    this.menus[0].childMenu.forEach(item => {
      if (item.checked == true) {
        item.checked = false;
      }
    });

    switch (data.innerHTML) {
      case '線上交易':
        this.menus[0].childMenu[0].checked = true;
        localStorage.setItem('page', '線上交易');
        break;
      case '開戶進度查詢':
        this.menus[0].childMenu[1].checked = true;
        localStorage.setItem('page', '開戶進度查詢');
        break;
      case '立即開戶':
        this.menus[0].childMenu[2].checked = true;
        localStorage.setItem('page', '立即開戶');
    }
  }

  memory() {
    var page = localStorage.getItem('page');
    // debugger

    if (page != null) {
      this.menus[0].childMenu.forEach(item => {
        if (item.checked == true) {
          item.checked = false;
        }
      });

      switch (page) {
        case '線上交易':
          this.menus[0].childMenu[0].checked = true;
          break;
        case '開戶進度查詢':
          this.menus[0].childMenu[1].checked = true;
          break;
        case '立即開戶':
          this.menus[0].childMenu[2].checked = true;
      }
    }
  }

  test(data: any) {
    localStorage.setItem('page', data.innerText);
  }

  // nick
  listController(data: any){
    if(data.target.href === 'javascript:void(0)'){
      return;
    }
    this.showList = !this.showList;
  }

}
