import { Page } from "./page";

export class CancellationTradeArgs extends Page {

  CFUNDNAME: string;
  CFUNDNAME2: string;
  Type: number;
  ID: number;
  //CBUYID : number;
  //CREGULARLYBUYID : number;
  //CTRANSACTIONID : number;
  //CSALEID : number;
  //CTSID : number;
}

export class GetCancellationTradeResponse {

  GetBuyResponse: GetBuyResponse[];
  GetTBLREGULARLYBUYResponse: GetTBLREGULARLYBUYResponse[];
  GetRegularlyTransactionResponse: GetRegularlyTransactionResponse[];
  GetSaleResponse: GetSaleResponse[];
  GetTsResponse: GetTsResponse[];
}

export class GetBuyResponse {
  CBUYID: number;
  CFUNDNAME: string;
  CTRANSFERDATE: string;
  CBUYAMOUNT: string;
  cbrank: string;
}
export class GetTBLREGULARLYBUYResponse {
  CREGULARLYBUYID: number;
  CFUNDNAME: string;
  CDATE: string;
  CTRANSFERDATE: string;
  cTEMP04: string;
  CAMOUNT: number;
  cbrank: string;
}
export class GetRegularlyTransactionResponse {
  CTRANSACTIONID: number;
  CFUNDNAME: string;
  CDATE: string;
  CTRANSFERDATE: string;
  CCHARGEDATE: string;
  CAMOUNT: number;
  cChargeState: string;
  cAccount: string;
  CAMOUNT_Check: boolean;
  CCHARGEDATE_Check: boolean;
  CCHARGESTATE_Check: boolean;
  CSUB_BANK_Check: boolean;
}
export class GetSaleResponse {
  CSALEID: number;
  CFUNDNAME: string;
  CTRANSFERDATE: string;
  CSALEDATE: string;
  CSALECOUNT: number;
  CBRANCH: string;
}
export class GetTsResponse {
  CTSID: number;
  OldFund: string;
  NewFund: string;
  CTRANSFERDATE: string;
  CTSDATE: string;
  CTSUNIT: number;
}
