export class PersonalizationSettingsForRetrieveParams { }

export class PersonalizationSettingsForRetrieveResponse {
  CMemberNotify: number;
  CTradeEmail: boolean; // 交易通知 - Email
  CTradeLine: boolean; // 交易通知 - LINE
  CWarningEmail: boolean; // 鎖利通知 - Email
  CWarningLine: boolean; // 鎖利通知 - LINE
  CPersonalEmail: boolean; // 個人通知 - Email
  CPersonalLine: boolean; // 個人通知 - LINE
  CNewEmail: boolean; // 最新消息 - Email
  CNewLine: boolean; // 最新消息 - LINE
  CBfNo: number; // 戶號
  CChangeDate: Date; // 更新時間
  CLineBinding: boolean; // LINE 綁定
  CLineId: string; // 若有綁定 LINE，則將 LINE ID 帶入此欄位
  CAccessToken: string;
}

export class PersonalizationSettingsForSubmitParams {
  CMemberNotify: number;
  CTradeEmail = true; // 交易通知 - Email
  CTradeLine: boolean; // 交易通知 - LINE
  CWarningEmail: boolean; // 鎖利通知 - Email
  CWarningLine: boolean; // 鎖利通知 - LINE
  CPersonalEmail: boolean; // 個人通知 - Email
  CPersonalLine: boolean; // 個人通知 - LINE
  CNewEmail: boolean; // 最新消息 - Email
  CNewLine: boolean; // 最新消息 - LINE
  CBfNo: number; // 戶號
  CChangeDate: Date; // 更新時間
  CLineBinding: boolean; // LINE 綁定
  CLineId: string; // 若有綁定 LINE，則將 LINE ID 帶入此欄位
  CAccessToken: string;
}

export class PersonalizationSettingsForSubmitResponse { }

export class PersonalizationSettingsForLINE {
  redirectUri: string;
  clientId: string;
  clientSecret: string;
  accessUrl: string;
  tokenUrl: string;
  state: string;
  scope: string;
}

export class PersonalizationSettingsForLINETokenParams {
  code: string; // Line 回傳的在網址列參數
}

export class PersonalizationSettingsForLINETokenResponse {
  lineid: string;
  linename: string;
  access_token: string;
}
