import { GetFundListArgs, GetFundListResponse, GetFeeRateArgs, GetFeeRateResponse, GetDivAccArgs, GetDivAccResponse } from './../model/open-data';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from './service-base';
import { ResponseBase } from '../model/ResponseBase';
import { GetKycQuestionArgs, GetKycQuestionResponse, KycHistoryArgs, KycHistoryResponse, PostKycQuestionArgs, PostKycQuestionResponse } from '../model/personal';

@Injectable({
  providedIn: 'root',
})
export class PersonalService extends ServiceBase {
  protected baseUrl = `${this.apiBaseUrl}/Personal`;
  constructor(
    http: HttpClient
  ) {
    super(http);

  }

  GetKycQuestion(request: GetKycQuestionArgs): Observable<ResponseBase<GetKycQuestionResponse>> {
    const url = `${this.baseUrl}/GetKycQuestion`;
    return this.http.post<ResponseBase<GetKycQuestionResponse>>(
      url,
      request,
      this.httpOptions);
  }

  KycHistory(request: KycHistoryArgs): Observable<ResponseBase<KycHistoryResponse>> {
    const url = `${this.baseUrl}/KycHistory`;
    return this.http.post<ResponseBase<KycHistoryResponse>>(
      url,
      request,
      this.httpOptions);
  }

  PostKycQuestion(request: PostKycQuestionArgs): Observable<ResponseBase<PostKycQuestionResponse>> {
    const url = `${this.baseUrl}/PostKycQuestion`;
    return this.http.post<ResponseBase<PostKycQuestionResponse>>(
      url,
      request,
      this.httpOptions);
  }
}
