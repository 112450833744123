//#region 取得KYC題目及選項(開戶使用)
export class GetKYCQuestionArgs {
  BF_COUNTRY_X !: string;
}
export class GetKYCQuestionResponse {
  KYCQuestionList!: KYCQuestionList[];
}
export class KYCQuestionList {
  VERSION_NO!: string;
  QSN_SEQNO!: number;
  QSN_ORDER!: string;
  KYC_QUESTION!: string;
  IS_ALLOW_MULIT!: string;
  KYCQuestionDtl!: KYCQuestionDtl[];
}
export class KYCQuestionDtl {
  ANS_SEQNO!: number;
  ANS_ORDER!: number;
  ANS_ITEM!: string;
  ANS_SCORE!: number;
  IS_FREE_INPUT!: string;
  LAST_IS_CHK_YN: string;
  LAST_ANS_KEY_IN: string;
  LAST_ANS_TEXT_IN: string;
  ANS_SEQNO_SELECTED: any;
}

export class ProductImg {
  ImgType = '';
  ImageProductImg: string | ArrayBuffer | null;
  ImageFile = '';
}
//#endregion
export class ChkMemStatusArgs {
  ID_NO = '';
}

export class ChkMemStatusResponse {
  ID_NO!: string;
  SYSTEM_ID!: string;
  SYSTEM_ID_NM !: string;
  OPEN_STATUS = '';
}

export class GetCountryCDResponse {
  CountryList!: CountryList[];
}

export class CountryList {
  COUNTRY_CD: string;
  COUNTRY_NAME!: string;
}

export class GetBankDataArgs {
  BANK_TYPE: string;
}
export class GetBankDataResponse {
  BankList!: BankList[];
}
export class BankList {
  BANK_HQ: string;
  BANK_HQ_NAME!: string;
  DEPOSIT_LENGTH!: string;
  DEPOSIT_LENGTH1!: string;
  DEPOSIT_LENGTH2!: string;
}

export class GetSubBankDataArgs {
  BANK_HQ: string;
  BANK_TYPE: string;
}
export class GetSubBankDataResponse {
  SubBankDetails!: SubBankDetails[];
}
export class SubBankDetails {
  BANK_HQ: string;
  BANK_BRH!: string;
  BANK_BRH_NAME!: string;
  BANK_BRH_SHNM!: string;
}

export class OpenMemberResponse {
  BF_NAME = '';
  BF_NAME_E!: string;
  ID_NO!: string;
  BIR_DATE1 = '';//年
  BIR_DATE2 = '';//月
  BIR_DATE3 = '';//日
  BIR_DATE!: Date;
  BF_COUNTRY_X = '01';
  HM_TEL_AREA = '';
  HM_TEL = '';
  OF_TEL_AREA = '';
  OF_TEL = '';
  OF_TEL_EXT = '';
  FAX_TEL_AREA1 = '';
  FAX_TEL1 = '';
  CELL_PHONE = '';
  BF_NATIONALITY = '';
  // BF_NATIONALITY_NAME = '';
  NATIONALITY_BIRTH = '';
  // NATIONALITY_BIRTH_NAME = '';
  AGENT_IN_LAW1 = '';
  AGENT_IN_LAW_ID = '';
  AGENT_IN_LAW_NALITY1 = '';
  // AGENT_IN_LAW_NALITY1_NAME = '';
  AGENT_IN_LAW2 = '';
  AGENT_IN_LAW_ID2 = '';
  AGENT_IN_LAW_NALITY2 = '';
  // AGENT_IN_LAW_NALITY2_NAME = '';
  CNT_PERSON = '';
  CNT_OF_TEL_AREA = '';
  CNT_OF_TEL = '';
  CNT_OF_TEL_EXT = '';
  CNT_CELL_PHONE = '';
  EMAIL1 = '';
  PERM_ZIP = '';
  PERM_ADDR = '';
  ADDR_CODE1: string | boolean;
  MAIL_ZIP = '';
  MAIL_ADDR = '';
  IP_ADDR = '';
  SPONSOR_CODE = '';
  EMP_NO = '';
  TAKE_RISK_YN = '';
  IS_USA = '';
  MCD_YN = '';
  APPLY_DTTM!: Date;
  CusAccount = [] as CusAccountList[];
  CusAccount2 = [] as CusAccountList[];
  CusAccount3 = [] as CusAccountList[];
  CusAccount4 = [] as CusAccountList[];
  CusKycInfo = [] as CusKycInfo[];
  KYCQuestionList!: KYCQuestionList[];
  ProductImgList: ProductImg[];
}
export class OpenMemberArgs {
  BF_NAME = '';
  BF_NAME_E = '';
  ID_NO!: string;
  BIR_DATE1 = '';//年
  BIR_DATE2 = '';//月
  BIR_DATE3 = '';//日
  BIR_DATE!: Date;
  BF_COUNTRY_X = '01';
  HM_TEL_AREA = '';
  HM_TEL = '';
  OF_TEL_AREA = '';
  OF_TEL = '';
  OF_TEL_EXT = '';
  FAX_TEL_AREA1 = '';
  FAX_TEL1 = '';
  CELL_PHONE = '';
  BF_NATIONALITY = '';
  // BF_NATIONALITY_NAME = '';
  NATIONALITY_BIRTH = '';
  // NATIONALITY_BIRTH_NAME = '';
  AGENT_IN_LAW1 = '';
  AGENT_IN_LAW_ID = '';
  AGENT_IN_LAW_NALITY1 = '';
  // AGENT_IN_LAW_NALITY1_NAME = '';
  AGENT_IN_LAW2 = '';
  AGENT_IN_LAW_ID2 = '';
  AGENT_IN_LAW_NALITY2 = '';
  // AGENT_IN_LAW_NALITY2_NAME = '';
  CNT_PERSON = '';
  CNT_OF_TEL_AREA = '';
  CNT_OF_TEL = '';
  CNT_OF_TEL_EXT = '';
  CNT_CELL_PHONE = '';
  EMAIL1 = '';
  PERM_ZIP = '';
  PERM_ADDR = '';
  ADDR_CODE1: string | boolean;
  MAIL_ZIP = '';
  MAIL_ADDR = '';
  IP_ADDR = '';
  SPONSOR_CODE = '';
  EMP_NO = '';
  TAKE_RISK_YN = '';
  IS_USA = '';
  MCD_YN = '';
  APPLY_DTTM!: Date;
  CusAccount = [] as CusAccountList[];
  CusKycInfo = [] as CusKycInfo[];
  ProductImgArgs = [] as ProductImg[];
}

export class CheckIdArgs {
  AGENT_IN_LAW_ID = '';
  AGENT_IN_LAW_ID2 = '';
}

export class CusAccountList {
  ACC_SUB_ID: string;
  ACC_NO_TYPE!: string;
  CRNCY_CD = 'TWD';
  BANK_BRH = '';
  BANK_HQ = '';
  BANK_BRH_NAME = '';
  BANK_HQ_NAME = '';
  ACCOUNT_NO = '';
}
export class CusKycInfo {
  KYC_BF_COUNTRY_X = '01';
  VERSION_NO = '';
  KYC_TOTAL_SCORE!: number;
  KYC_RISK_ATTR = '';
  CusKycDetail = [] as CusKycDetail[];
}
export class CusKycDetail {
  QSN_SEQNO: number;
  QSN_ORDER!: string;
  KYC_QUESTION!: string;
  ANS_SEQNO!: number;
  ANS_ORDER!: number;
  ANS_ITEM!: string;
  ANS_SCORE!: number;
  IS_CHK_YN!: string;
  ANS_KEY_IN!: string;
  ANS_TEXT_IN!: string;
  ROW!: number;
  IS_FREE_INPUT!: string;
}

export class BankMsg {
  len = '';
  len1 = '';
  len2 = '';
}

export class Kyc {
  KycDetail = [] as KycDetail[]
}

export class KycDetail {
  KYC_QUESTION: string;
  QSN_SEQNO: number;
  ANS_ORDER: number;
  ANS_SCORE: number;
  IS_CHK_YN: string;
  ANS_KEY_IN: string;
  ANS_TEXT_IN: string;
}
