<div class="TransactionRow3">
  <div><i class="fas fa-user-circle"></i>
    <p>{{strName(jwt.BF_NAME)}}</p>
    <h6 style="margin-left: 10px; margin-right: 0px;">您好</h6>
  </div>
  <div><i class="fas fa-file-invoice-dollar"></i>
    <h6>戶號</h6>
    <p>{{jwt.BF_NO}}</p>
  </div>
  <div><i class="fas fa-id-card"></i>
    <h6>身分證字號</h6>
    <p>{{strID(jwt.UserID)}}</p>
  </div>
  <div><i class="fas fa-search-dollar"></i>
    <h6>風險類型</h6><samp>{{jwt.KYC_RISK_ATTR_NM}}</samp>
  </div>
  <div><i class="fas fa-user-lock"></i>
    <h6>營業員代號</h6>
    <p>{{jwt.EMP_NO}}</p>
  </div>
</div>
