<div class="m-hdr1-sub"><span>開戶進度查詢</span></div>

<div class="Block-content">

  <ng-container *ngIf="isSearched == false">
    <p style="text-align:center;margin:10px 0;">歡迎登入會員專區！請輸入您的身分證。若您尚未開立台中銀投信網路交易帳戶，請至線上開戶申請
      <a  href="disclaimer" class="small-button">ＧＯ</a>
    </p>

    <div class="Sign-box-line">
      <div class="Sign-box">
        <div class="Sign-list">
          <div class="Sign-title">帳號</div>
          <div class="Sign-in"><input placeholder="身分證或統一編號" [(ngModel)]="EstablishAccountSearchArgs.AccountId"></div>
        </div>
        <div class="Sign-list">
          <div class="Sign-title">查詢通行碼</div>
          <div class="Sign-in"><input placeholder="查詢通行碼" [(ngModel)]="EstablishAccountSearchArgs.SearchPass"></div>
        </div>
      </div>
    </div>
    <div class="button-box">
      <a class="Main-button" (click)="establishAccountSearch()">確定<i class="fas fa-chevron-right"></i></a>
    </div>
  </ng-container>

  <ng-container *ngIf="isSearched == true">
    <div class="title-01">查詢結果</div>
    <div class="TransactionRow3">
      <div>
        <h6>客戶名稱</h6>
        <p>{{strName(EstablishAccountSearchResponse.Name)}}</p>
      </div>
      <div>
        <h6>帳號</h6>
        <p>{{strID(EstablishAccountSearchResponse.AccountId)}}</p>
      </div>
      <div>
        <h6>查詢時間</h6>
        <p>{{EstablishAccountSearchResponse.SearchDateTime | date: 'yyyy/MM/dd HH:mm:ss'}}</p>
      </div>
    </div>

    <div class="Successful-account-opening">
      <img src="./assets/images/check-solid.svg">
      <h4>{{EstablishAccountSearchResponse.Status}}</h4>
      <span>預約開戶時間 {{EstablishAccountSearchResponse.ApplicationDate}}</span>
      <p style="color:red;text-align: center;">{{EstablishAccountSearchResponse.StatusDexplain}}</p>
      <a *ngIf="EstablishAccountSearchResponse.PrintVisible" href="{{EstablishAccountSearchResponse.PrintUrl}}"
        Target="_blank">重新列印申請書</a>
    </div>
  </ng-container>

</div>
