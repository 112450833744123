import { Component, OnInit } from '@angular/core';
import { PageParam } from 'src/app/model/member';
import { GetMonthTradeArgs, GetStatementListResponse } from 'src/app/model/open-data';
import { MemberService } from 'src/app/services/member.service';
import { OpenDataService } from 'src/app/services/open-data.service';
import { JwtPayload } from '../../model/base';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-statement-query',
  templateUrl: './statement-query.component.html',
  styleUrls: ['./statement-query.component.css']
})
export class StatementQueryComponent implements OnInit {
  GetStatementListResponse: GetStatementListResponse;
  pageparam = new PageParam();

  jwt = {} as JwtPayload;
  count = 0;

  constructor(
    private openDataService: OpenDataService,
    private _MemberService: MemberService
  ) { }

  ngOnInit(): void {
    this.Weblog();
    this.jwt = jwt_decode(localStorage.getItem('token') || '');
  }

  Weblog() {
    this.pageparam.Value = 9;
    this.pageparam.Page = '電子對帳單查詢';

    var promise = new Promise((resolve) => {
      this._MemberService.WebLog(this.pageparam).subscribe(res => {
        resolve(res);
      });

    })

    promise.then((value: any) => {
      if (value == 0) {
        this.GetStatement();
      }
      else {
        return
      }
    })
  }


  GetStatement() {
    this.openDataService.GetStatement().subscribe(res => {
      this.GetStatementListResponse = res.Entries;

      // this.GetStatementListResponse.StatementList.forEach(item => {
      //   if (item.FileExist) {
      //     this.count++
      //   }
      // });

      for (let i = 0; i < this.GetStatementListResponse.StatementList.length; i++) {
        if (this.GetStatementListResponse.StatementList[i].FileExist) {
          this.count++
          break;
        }
      }
    });
  }


  Download(value: string) {
    var data = new GetMonthTradeArgs();
    data.Month = value;
    data.ID = this.jwt.UserID;
    data.BF_NO = this.jwt.BF_NO.toString();
    this._MemberService.GetMonthTrade(data).subscribe(res => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(res);
      link.download = `${value}_${this.jwt.BF_NO}.pdf`;
      link.click();
    });
  }

}
