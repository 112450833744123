<ng-container *ngIf="LoginResponse.sysLoginType === 0">
  <div class="m-hdr1-sub"><span>線上交易登入</span></div>
  <!--登入-->
  <div class="Block-content" id="ctl00_ContentPlaceHolder1_login">
    <div class="Sign-box-line">
      <div class="Sign-box">
        <div class="Sign-list">
          <div class="Sign-title">帳號</div>
          <div class="Sign-in"><input [(ngModel)]="LoginArgs.Account" placeholder="身分證字號或統一編號"></div>
        </div>
        <div class="Sign-list">
          <div class="Sign-title">密碼</div>
          <div class="Sign-in"><input [(ngModel)]="LoginArgs.Password" placeholder="密碼請注意大小寫" type="password">
            <p style="color: red">(密碼錯誤三次以上即停用，請小心輸入)</p>
          </div>
        </div>
        <div class="Sign-list">
          <div class="Sign-title">驗證碼</div>
          <div class="Sign-in">
            <input placeholder="請輸入驗證碼" style="margin-bottom:10px" [(ngModel)]="LoginArgs.Captcha">
            <img style="height:50%;width:100px" [src]="GetCaptchaImageResponse.url" />
            <a href="javascript:void(0);" style="color: silver;font-size: 12px;" (click)="getCaptchaCode()">更新驗證碼</a>
          </div>
        </div>
      </div>
    </div>
    <div class="button-box">
      <a href="javascript:void(0);" class="cancel-button" (click)="LoginResponse.sysLoginType = 4"
        id="ctl00_ContentPlaceHolder1_LinkButton_Forget">忘記密碼</a>
      <a href="javascript:void(0);" class="cancel-button" (click)="login()" class="Main-button">確認登入<i
          class="fas fa-chevron-right"></i></a>
    </div>
    <div class="note-box">
      <h4>為保障您的資訊安全，建議您：</h4>
      <ol class="note-list">
        <li>每三個月變更密碼一次</li>
        <li>若您正使用公共電腦，記得別讓瀏覽器記得您的密碼，離開前也要隨手登出</li>
        <li>為了避免您的資料被有心人士盜用，若本系統閒置6分鐘未使用，系統將自動把您登出，以確保您的權益</li>
      </ol>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="LoginResponse.sysLoginType === 3">

  <div class="m-hdr1-sub"><span>雙因子登入</span></div>
  <!--登入-->
  <div class="Block-content" id="ctl00_ContentPlaceHolder1_login">
    <div class="Sign-box-line">
      <div class="Sign-box">
        <div class="Sign-list">
          <div class="Sign-title">Email</div>
          <div class="Sign-in">{{LoginResponse.Mail}}</div>
        </div>

        <div class="Sign-list">
          <div class="Sign-title">尚未收到</div>
          <div class="Sign-in">
            <p style="color: red; display:inline-block;">{{LoginResponse.Exp * 1000 | date:'mm:ss' }}</p>
            <a href="javascript:void(0);" style="display:inline-block; margin-left: 30px;"
              (click)="reSendMemberVerification()">點此重發</a>
          </div>
        </div>
        <div class="Sign-list">
          <div class="Sign-title">雙因子驗證</div>
          <div class="Sign-in">
            <input placeholder="請登錄Email收取密碼驗證信" [(ngModel)]="MemberVerificationArgs.Captcha">
          </div>
        </div>
      </div>
    </div>

    <div class="button-box">
      <a href="javascript:void(0);" class="cancel-button" (click)="checkSendMemberVerification()"
        class="Main-button">確認登入<i class="fas fa-chevron-right"></i></a>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="LoginResponse.sysLoginType === 4">

  <!--忘記密碼-->
  <div class="Block-content" id="ctl00_ContentPlaceHolder1_forgetPassword">
    <div class="Sign-box-line">
      <div class="Sign-box">
        <div class="Sign-list">
          <div class="Sign-title">身分證字號</div>
          <div class="Sign-in"><input [(ngModel)]="PswDataArgs.Memberid" placeholder="身分證字號或統一編號"></div>
        </div>

        <div class="Sign-list">
          <div class="Sign-title">投信公司留存電子信箱</div>
          <div class="Sign-in"><input [(ngModel)]="PswDataArgs.Mailaddress" placeholder=""></div>
        </div>
      </div>
    </div>

    <div class="button-box">
      <a href="javascript:void(0);" class="cancel-button" (click)="LoginResponse.sysLoginType = 0">取消</a>
      <a href="javascript:void(0);" class="cancel-button" (click)="ForgotPassword()" rel="modal:open" class="Main-button">寄送密碼<i class="fas fa-chevron-right"></i></a>
    </div>

    <div id="ex1" class="modal">
      <h3>提示訊息</h3>
      <p style="padding:25px 20px;text-align:center;">
        您的密碼發送！
      </p>
      <div class="button-box">
        <a href="#" rel="modal:close" class="Main-button">確認<i class="fas fa-chevron-right"></i></a>
      </div>

    </div>

  </div>
</ng-container>
