import { PswDataArgs } from './../../model/member';
import { BaseService } from './../../services/base.service';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/baseComponent';
import { MemberService } from '../../services/member.service';
import { AllowHelper } from '../../helper/allowHelper';
import { LoginArgs, LoginResponse, MemberVerificationArgs } from '../../model/member';
import { EnumStatusCode } from 'src/app/enum/enumStatusCode';
import { MessageService } from '../../services/message.service';
import { Router } from '@angular/router';
import { GetCaptchaCodeArgs, GetCaptchaCodeResponse, GetCaptchaImageArgs, GetCaptchaImageResponse } from 'src/app/model/base';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedObservable } from '../shared/shared.observable';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

  LoginArgs = new LoginArgs();
  LoginResponse = new LoginResponse();
  MemberVerificationArgs = new MemberVerificationArgs()
  PswDataArgs = new PswDataArgs()
  GetCaptchaCodeArgs = new GetCaptchaCodeArgs();
  GetCaptchaCodeResponse = new GetCaptchaCodeResponse();
  GetCaptchaImageArgs = new GetCaptchaImageArgs();
  GetCaptchaImageResponse = new GetCaptchaImageResponse();

  browser: string;

  interval: any;

  constructor(
    private _MemberService: MemberService,
    private message: MessageService,
    private _BaseService: BaseService,
    private router: Router,
    protected allow: AllowHelper,
    private sanitizer: DomSanitizer,
    private share: SharedObservable) {
    super(allow);



  }

  ngOnInit(): void {

    if (localStorage.getItem('page') != '線上交易') {
      // debugger
      localStorage.setItem('page', '線上交易');
      location.reload()
    }
    else {
      this.getCaptchaCode();
    }
  }

  login() {
    this._MemberService.login(this.LoginArgs).subscribe(res => {
      if (res.StatusCode === EnumStatusCode.Success) {
        this.LoginResponse = res.Entries;
        if (res.Entries.sysLoginType == 0 || res.Entries.sysLoginType == 1 || res.Entries.sysLoginType == 2) {
          localStorage.setItem('token', res.Entries.token);
          this.share.SetToken(res.Entries.token);
          // debugger;
          // this.message.showSucessMSG('登入成功');
          if (res.Entries.sysLoginType == 0) {
            this.router.navigateByUrl('home')
          }
          else if (res.Entries.sysLoginType == 1) {
            alert("跳轉修改密碼");
            this.router.navigateByUrl('ChangePassword')
          }
          else if (res.Entries.sysLoginType == 2) {
            // alert("跳轉修改kyc");
            // this.router.navigateByUrl('kyc')
            if (confirm('您的kyc已過期，無法進行交易，是否導頁到kyc頁填寫?')) {
              this.router.navigateByUrl('kyc');
            }
            else {
              this.router.navigateByUrl('home');
            }
          }
        }
        else if (res.Entries.sysLoginType == 3) {
          this.MemberVerificationArgs.Code = res.Entries.Code;
          this.MemberVerificationArgs.Captcha = '';
          //雙因子
          this.startTimer();
        }
      }
      else {
        this.getCaptchaCode();
      }
    });
  }

  reSendMemberVerification() {
    this._MemberService.reSendMemberVerification(this.MemberVerificationArgs).subscribe(res => {

      if (res.StatusCode === EnumStatusCode.Success) {
        this.LoginResponse = res.Entries;
        this.LoginResponse.sysLoginType = 3;
        this.MemberVerificationArgs.Code = res.Entries.Code;
        this.MemberVerificationArgs.Captcha = '';
      }
    });
  }

  checkSendMemberVerification() {
    this._MemberService.checkSendMemberVerification(this.MemberVerificationArgs).subscribe(res => {
      if (res.StatusCode === EnumStatusCode.Success) {
        if (res.Entries.sysLoginType == 0 || res.Entries.sysLoginType == 1 || res.Entries.sysLoginType == 2) {
          localStorage.setItem('token', res.Entries.token);
          this.share.SetToken(res.Entries.token);
          // this.message.showSucessMSG('登入成功');
          if (res.Entries.sysLoginType == 0) {
            this.router.navigateByUrl('home')
          }
          else if (res.Entries.sysLoginType == 1) {
            alert("跳轉修改密碼");
            this.router.navigateByUrl('ChangePassword')
          }
          else if (res.Entries.sysLoginType == 2) {
            alert("跳轉修改kyc");
            this.router.navigateByUrl('kyc')
          }
        }
      }
    });
  }

  getCaptchaCode() {
    this._BaseService.getCaptchaCode(this.GetCaptchaCodeArgs).subscribe(res => {
      if (res.StatusCode === EnumStatusCode.Success) {
        this.GetCaptchaImageArgs.code = res.Entries.code;
        this.LoginArgs.Code = res.Entries.code;
        this.LoginArgs.Captcha = '';
        this.getCaptchaImage();
      }
    });
  }

  getCaptchaImage() {
    this._BaseService.getCaptchaImage(this.GetCaptchaImageArgs).subscribe(res => {
      let unsafeImageUrl = URL.createObjectURL(res);
      this.GetCaptchaImageResponse.url = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    });
  }
  ForgotPassword() {
    this._MemberService.ForgotPassword(this.PswDataArgs).subscribe(res => {
      if (res.StatusCode === EnumStatusCode.Success) {
        this.message.showSucessMSG(res.Message);
      }
      else {
        // this.message.showSucessMSG(res.Message);
      }
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.LoginResponse.Exp > 0) {
        this.LoginResponse.Exp--;
      } else {
        this.LoginResponse.Exp = 60;
      }
    }, 1000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }
}
