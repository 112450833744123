import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuModel } from '../../model/menu';

@Injectable({
  providedIn: 'root',
})
export class SharedObservable {

  private Clear = new BehaviorSubject(false);
  private Token = new BehaviorSubject('' as string);
  private Menu = new BehaviorSubject([] as MenuModel[]);


  SharedClear = this.Clear.asObservable();
  SharedMenu = this.Menu.asObservable();
  SharedToken = this.Token.asObservable();

  SetClear(Data: boolean) {
    this.Clear.next(Data);
  }
  SetToken(Data: string) {
    this.Token.next(Data);
  }
  SetMenu(Data: MenuModel[]) {
    this.Menu.next(Data);
  }
}
