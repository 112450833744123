export class disclaimerMsg {
  BF_NAME1 = false;  //請輸入姓名
  BF_NAME2 = false;  //姓名格式錯誤
  BF_NAME_E1 = false;  //請輸入英文姓名
  BF_NAME_E2 = false;  //英文姓名格式錯誤
  BIR_DATE = false;  //日期格式不正確
  HM_TEL = false;  //請輸入住家電話
  HM_TEL1 = false;  //住家電話格式不正確
  OF_TEL = false;  //公司電話格式不正確
  FAX_TEL = false;  //傳真電話格式不正確
  CELL_PHONE = false;  //請輸入手機電話
  CELL_PHONE1 = false;  //手機電話格式不正確
  MAIL_ZIP = false;  //請輸入通訊郵遞區號
  MAIL_ZIP1 = false;  //通訊郵遞區號格式不正確
  MAIL_ADDR = false;  //請輸入通訊地址
  MAIL_ADDR1 = false;  //通訊地址格式不正確
  PERM_ZIP = false;  //請輸入戶籍郵遞區號
  PERM_ZIP1 = false;  //戶籍郵遞區號格式不正確
  PERM_ADDR = false;  //請輸入戶籍地址
  PERM_ADDR1 = false;  //戶籍地址格式不正確
  EMAIL = false;  //請輸入電子信箱
  EMAIL1 = false;  //電子信箱格式不正確
  IS_USA = false;  //請選擇是否為美國稅務居民
  CNT_PERSON = false;  //請輸入連絡人
  CNT_PERSON1 = false;  //連絡人格式不正確
  CNT_OF_TEL = false;  //請輸入連絡人公司電話
  CNT_OF_TEL1 = false;  //連絡人公司電話格式不正確
  CNT_CELL_PHONE = false;  //請輸入連絡人手機電話
  CNT_CELL_PHONE1 = false;  //連絡人手機電話格式不正確
  AGENT_IN_LAW = false;  //請輸入法定代理人姓名(一)
  AGENT_IN_LAW1 = false;  //法定代理人姓名(一) 格式不正確
  AGENT_IN_LAW_ID = false;  //請輸入法定代理人姓名(一)身分證
  AGENT_IN_LAW_ID1 = false;  //法定代理人(一) 身分證格式不正確
  AGENT_IN_LAW_ID1_1 = false;  //法定代理人身分證字號(一) 不可與開戶身分證字號相同
  AGENT_IN_LAW2 = false;  //法定代理人姓名(二) 格式不正確
  AGENT_IN_LAW_ID2 = false;  //法定代理人(二) 身分證格式不正確
  AGENT_IN_LAW_ID2_1 = false;  //法定代理人身分證字號(二) 不可與開戶身分證字號相同
  MCD_YN = false;  //請選擇是否接收行銷訊息
  CusAccountList = false;  //請選擇輸入扣款帳號
  CusAccountList_1 = false;  //扣款帳號 格式不正確
  CusAccountList_2 = false;  //扣款帳號 帳號長度不符
  CusAccountList2 = false;  //請選擇輸入約定買回帳戶
  CusAccountList2_1 = false;  //買回價款匯入銀行(一) 格式不正確
  CusAccountList2_2 = false;  //買回價款匯入銀行(一) 帳號長度不符
  CusAccountList3 = false; // 請選擇輸入約定買回帳戶(二)
  CusAccountList3_1 = false;  //買回價款匯入銀行(二) 格式不正確
  CusAccountList3_2 = false;  //買回價款匯入銀行(二) 帳號長度不符
  CusAccountList4 = false;  //請選擇輸入收益配息帳號
  CusAccountList4_1 = false;  //收益配息帳戶 格式不正確
  CusAccountList4_2 = false;  //收益配息帳戶 帳號長度不符
  CusAccountList5 = false; // 請選擇輸入外幣申贖帳號
  CusAccountList5_1 = false;  //外幣申贖帳號 格式不正確
  CusAccountList5_2 = false;  //外幣申贖帳號 帳號長度不符
}
