<link rel="stylesheet" href="/assets/css/main.css">
<link rel="stylesheet" href="/assets/css/style.css">
<link rel="stylesheet" href="/assets/css/jquery-rvnm.css">
<link rel="stylesheet" href="/assets/css/jquery.horizontalmenu.css">
<link rel="stylesheet" href="/assets/css/jquery-ui.css">

<app-header></app-header>
<section class="ec-bg">
  <div class="ec-left">
    <app-logout-navbar></app-logout-navbar>
  </div>
  <div class="ec-right">
    <router-outlet></router-outlet>
  </div>
</section>
<app-footer></app-footer>
<a class="toTop"><i class="fas fa-chevron-up"></i></a>
