import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  ToastForCountParams,
  ToastForCountResponse,
  ToastParams,
  ToastResponse,
} from 'src/app/model/toast';
import { MemberService } from 'src/app/services/member.service';
import { ToastService } from 'src/app/services/toast.service';
import { SharedObservable } from '../../pages/shared/shared.observable';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  token = '';
  isToastDivShow = 'toastDiv fade';
  ToastForCountParams = new ToastForCountParams();
  ToastForCountResponse = new ToastForCountResponse();
  ToastParams = new ToastParams();
  ToastResponse: ToastResponse[] = [];
  toastClassList = ['Red-label', 'green-label', 'blue-label', 'Orange2-label'];

  constructor(
    private router: Router,
    private share: SharedObservable,
    private toastService: ToastService,
    private _MemberService: MemberService
  ) {
    this.token = localStorage.getItem('token') || '';
  }

  ngOnInit(): void {
    if (this.token != '') {
      this.retrieveToastCount();
    }
  }

  logoutweb() {
    let promise = new Promise((resolve) => {
      // debugger
      this._MemberService.logout().subscribe(res => {
        resolve(res);
      });
    })

    promise.then((value: any) => {
      console.log(value);
      this.logout();
    })

  }

  logout() {
    this.token = '';
    this.share.SetToken(this.token);
    localStorage.removeItem('token');
    this.router.navigateByUrl('login');
  }

  clickToast() {
    if (this.isToastDivShow == 'toastDiv fade') {
      if (this.ToastForCountResponse.PersonMsgCount > 0) {
        this.isToastDivShow = 'toastDiv show';
        this.retrieveToastContent();
      }
    } else {
      this.isToastDivShow = 'toastDiv fade';
    }
  }

  retrieveToastCount() {
    this.toastService
      .RetrieveToastCount(this.ToastForCountParams)
      .subscribe((response) => {
        this.ToastForCountResponse = response.Entries;
      });
  }

  retrieveToastContent() {
    this.toastService
      .RetrieveToastContent(this.ToastParams)
      .subscribe((response) => {
        this.ToastResponse = response.Entries;
        this.retrieveToastCount();
      });
  }
}
