export class LoginArgs {
  Account = '';
  Password = '';
  Code = '';
  Captcha = '';
}

export class LoginResponse {
  token = '';
  sysLoginType = 0;
  Code = '';
  Mail = '';
  Exp = 0;
}

export class MemberVerificationArgs {
  Code = '';
  Captcha = '';
}

export class PswDataArgs {
  Memberid = '';
  Mailaddress = '';
}

export class PswDataResponse { }

export class PswTxDataArgs {
  PSW = '';
}

export class PswTxDataResponse { }

export class ChangePswDataArgs {
  ID_NO = '';

  BF_SRNO = 0;

  BF_NO = 0;

  OLD_PSW = '';

  NEW_PSW = '';
}

export class GetPersonalMsgArgs {
  cMsgType = 0;
  PageIndex = 0;
  PageSize = 0;
}

export class GetPersonalMsgResponse {
  cMsgType = 0;
  cMsgName = '';
  cMsgParam1 = '';
  cMsgParam2 = '';
  cMsgParam3 = '';
  cIsRead = 0;
  cReadDt: Date;
  cCreateDt: Date;
}

//取得會員雙因子驗證
export class GetMemberVerificationArgs { }
export class GetMemberVerificationResponse {
  Mail: string;
  IsVerification: boolean;
}
//存檔會員雙因子驗證
export class SaveMemberVerificationArgs {
  IsVerification: boolean;
}
export class SaveMemberVerificationResponse { }

// Load 受益人基本資料 Request Params
export class LoadMemberProfileArgs { }
// Load 受益人基本資料 Response
export class LoadMemberProfileResponse {
  BF_NAME: string;
  APPLY_DTTM: string;
  ID_NO: string;
  BIR_DATE: string;
  BF_COUNTRY_X: string;
  HM_TEL_AREA: string;
  HM_TEL: string;
  OF_TEL_AREA: string;
  OF_TEL: string;
  OF_TEL_EXT: string;
  FAX_TEL_AREA1: string;
  FAX_TEL1: string;
  CELL_PHONE: string;
  BF_NATIONALITY: string;
  NATIONALITY_BIRTH: string;
  AGENT_IN_LAW1: string;
  AGENT_IN_LAW_ID: string;
  AGENT_IN_LAW_NALITY1: string;
  AGENT_IN_LAW2: string;
  AGENT_IN_LAW_ID2: string;
  AGENT_IN_LAW_NALITY2: string;
  CNT_PERSON: string;
  EMAIL1: string;
  PERM_ZIP: string;
  PERM_ADDR: string;
  MAIL_ZIP: string;
  MAIL_ADDR: string;
  SPONSOR_CODE: string;
  EMP_NO: string;
  DATA_STATUS: string;
  DATA_STATUS_NM: string;
  MailZip: string;
  PermZip: string;
  Cisreceivemessages: string; // 行銷訊息
}
// Save 受益人基本資料 Request Params
export class SaveMemberProfileArgs { }
// Save 受益人基本資料 Response
export class SaveMemberProfileResponse { }

//開戶進度查詢
export class EstablishAccountSearchArgs {
  AccountId: string = '';
  SearchPass: string = '';
}
export class EstablishAccountSearchResponse {
  AccountId: string;
  Name: string;
  SearchDateTime: Date;
  Status: string;
  ApplicationDate: string;
  StatusDexplain: string;
  PrintVisible: boolean;
  PrintUrl: string;
}

export class PageParam {
  Value: number;
  Page: string;
}
